import React from "react";
import { useSwipeable } from "react-swipeable";
import PropTypes from 'prop-types';
import {
  Wrapper,
  CarouselContainer,
  CarouselSlot,
  SlideButton,
  PREV,
  NEXT
} from "./components";
import './Carousel.scss';

const getOrder = ({ index, pos, numItems }) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};


const Carousel = props => {
  const {posImage, children, onSlide, totalAttachment} = props;
  
  const initialState = { pos: posImage-1, sliding: false, dir: NEXT };
  function reducer(state, { type, numItems }) {
    switch (type) {
      case "reset":
        return initialState;
      case PREV:
        return {
          ...state,
          dir: PREV,
          sliding: true,
          pos: state.pos === 0 ? numItems - 1 : state.pos - 1
        };
      case NEXT:
        return {
          ...state,
          dir: NEXT,
          sliding: true,
          pos: state.pos === numItems - 1 ? 0 : state.pos + 1
        };
      case "stopSliding":
        return { ...state, sliding: false };
      default:
        return state;
    }
  }
  
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const numItems = React.Children.count(children);
  const slide = dir => {
    if(dir === "NEXT"){
      onSlide("next")
    }
    if(dir === "PREV"){
      onSlide("prev")
    }
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: "stopSliding" });
    }, 50);
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
  return (
    <div {...handlers} className="CaroContainer">
      <Wrapper className="caroWraper" totalAttachment = {totalAttachment}>
        <CarouselContainer dir={state.dir} sliding={state.sliding} className={`${totalAttachment == 1 ? 'caroSubContainerTransform' : 'caroSubContainer'}`}>
          {React.Children.map(children, (child, index) => (
            
            <CarouselSlot
              key={index}
              order={getOrder({ index: index, pos: state.pos, numItems })}
              className="caroSlotBox"
            >
              {child}
            </CarouselSlot>
          ))}
        </CarouselContainer>
      </Wrapper>
      <SlideButton className="prevBtn" onClick={() => {slide(PREV)}} float="left">
        Prev
      </SlideButton>
     <SlideButton className="nxtBtn" onClick={() => {slide(NEXT)}} float="right">
        Next
      </SlideButton>
    </div>
  );
};
Carousel.propTypes = {
  posImage: PropTypes.number,
  children: PropTypes.array,
  onSlide: PropTypes.func
};

export default Carousel;