import React, { useState } from "react";
import PropTypes from "prop-types";
import * as routes from "Router/RoutesURL";
import PayPalSuccess from "../PayPalSuccessComponent/PayPalSuccess";
import PayPalPayment from "../PayPalPaymentComponent/PayPalPayment";
import PaypalFailed from "../PayPalFailedComponent/PaypalFailed";
import back from "Assets/CommonComponent/back.png";
import "./PayPalDashboard.scss";
import { Container, Row } from "reactstrap";

const PayPalDashboard = (props) => {
  const {
    location,
    history,
    setPaymentDataRequest,
    paymentStatus,
    changeUserSubscriptionRequest,
    loader,
    addPaymentStatusSuccess,
  } = props;
  const { state } = location || {};
  const { item } = state || {};
  const [statusFailed, setStatusFailed] = useState(false);
  const [stripePaymentSuccess, setStripePaymentSuccess] = useState(false)

  // -----Back------
  const goBack = () => {
    // addPaymentStatusSuccess();
    history.goBack();
  };
  // -----Back------

  // -----goToAcademic------
  const goToAcademic = () => {
    addPaymentStatusSuccess();
    history.push(routes.ACADEMICDASHBOARD);
  };
  // -----goToAcademic------

  const gotoPaypal = () => {
    history.push(routes.PAYPAL, { item: item });
  };
  const BackTOHome = () => {
    // history.goBack();
    if(sessionStorage?.getItem("userHasFreePlan") != "0") {
      history?.push(routes?.DATAUSAGE);
    } else {
      history?.push(routes?.UPGRADEPLAN);
    }
  };
  return (
    <div className="payPalContainer container" style={{ padding: 0 }}>
      <Row className="mb-4">
        <Container>
          <div className="header-main-container">
            <div className="header-breadcrumb-main-container">
              <div className="header-breadcrumb-back-btn-div">
                <img
                  className="header-breadcrumb-back-btn-img"
                  src={back}
                  alt=""
                  onClick={BackTOHome}
                />
              </div>
              <div className="header-breadcrumb-inner-div">
                <div className="breadcrumb-inner-flex-div">
                  <p className="breadcrumb-attendance-text">Data Usage</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Row>
      {/* <PayPalPayment item={item} goBack={goBack} setPaymentDataRequest={setPaymentDataRequest} /> */}
      {statusFailed ? (
        <PaypalFailed gotoPaypal={gotoPaypal} goToAcademic={goToAcademic} />
      ) : paymentStatus || stripePaymentSuccess ? (
        <PayPalSuccess
          goToAcademic={goToAcademic}
          goBack={goBack}
          item={item}
        />
      ) : (
        <PayPalPayment
          item={item}
          goBack={goBack}
          setStatusFailed={setStatusFailed}
          goToAcademic={goToAcademic}
          setPaymentDataRequest={setPaymentDataRequest}
          loader={loader}
          setStripePaymentSuccess={setStripePaymentSuccess}
          changeUserSubscriptionRequest={changeUserSubscriptionRequest}
        />
      )}
      {/* <PayPalSuccess goToAcademic={goToAcademic} goBack={goBack} item={item} /> */}
    </div>
  );
};
PayPalDashboard.propTypes = {
  setPaymentDataRequest: PropTypes.func,
};
export default PayPalDashboard;
