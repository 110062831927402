import React, { useState, useEffect } from "react";
import {  useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import Logo from "Assets/LoginModule/mainLogo.png";
import "firebase/analytics";
import * as routes from "Router/RoutesURL";
import "../LoginComponent/LoginPage.scss";
import { createTheme } from "@material-ui/core/styles";
import { getStripeProducts, getSubscriptionPlanRequest } from "Actions/SubscriptionPlanAction/subscriptionPlanAction";
import DivLoader from "Components/LoadingComponent/DivLoader";
import { addPaymentStatusSuccess } from "Actions/PayPalPaymentAction/payPalPaymentAction";
import { login } from "Actions/AuthAction/loginAction";
import { createNotification } from "Config/notificationtoast";
import axios from "axios";

const RegisterSubscription = (props) => {
  const dispatch = useDispatch();
  const [showloginModal, setShowloginModal] = useState(false);

  const [allPrices, setAllPrices] = React.useState(null);
  const [allProducts, setAllProducts] = React.useState(null);
  const [activeProd, setActiveProd] = React.useState(null);
  const [activePrices, setActivePrices] = React.useState(null);

  const [allPayPalPrices, setAllPayPalPrices] = React.useState(null);
  const [allPayPalProducts, setAllPayPalProducts] = React.useState(null);
  const [paypalActivePrices, setPaypalActivePrices] = React.useState(null);
  const [planLoader, setPlanLoader] = React.useState(false);

  const handleAllStandardModalClose = () => setShowloginModal(false);

  const loginFetchingState = useSelector((state) => state?.login?.fetching);

    const subscriptionLoader = useSelector(
    (state) => state?.SubscriptionPlan?.loader
  );

  
  const allStripePrices =  [
    {
      "id": "price_1P9MxiI410p5JtG8wpLB44Ar",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1714031002,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": "7",
      "metadata": {},
      "nickname": null,
      "product": "prod_PzLe99bkjygFo6",
      "recurring": {
        "aggregate_usage": null,
        "interval": "year",
        "interval_count": 1,
        "meter": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "tax_behavior": "unspecified",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "recurring",
      "unit_amount": 3999,
      "unit_amount_decimal": "3999"
    },
    {
      "id": "price_1P9MxFI410p5JtG8qoobVD2r",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1714030973,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": "2",
      "metadata": {},
      "nickname": null,
      "product": "prod_PzLe99bkjygFo6",
      "recurring": {
        "aggregate_usage": null,
        "interval": "month",
        "interval_count": 1,
        "meter": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "tax_behavior": "unspecified",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "recurring",
      "unit_amount": 399,
      "unit_amount_decimal": "399"
    },
    {
      "id": "price_1Ool9hI410p5JtG8YX709IGl",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1709119113,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": "3",
      "metadata": {},
      "nickname": null,
      "product": "prod_Pe3FLRACseQc4A",
      "recurring": {
        "aggregate_usage": null,
        "interval": "month",
        "interval_count": 1,
        "meter": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "tax_behavior": "inclusive",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "recurring",
      "unit_amount": 599,
      "unit_amount_decimal": "599"
    },
    {
      "id": "price_1Ool91I410p5JtG8uzizoBK6",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1709119071,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": "8",
      "metadata": {},
      "nickname": null,
      "product": "prod_Pe3FLRACseQc4A",
      "recurring": {
        "aggregate_usage": null,
        "interval": "year",
        "interval_count": 1,
        "meter": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "tax_behavior": "inclusive",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "recurring",
      "unit_amount": 5999,
      "unit_amount_decimal": "5999"
    },
    {
      "id": "price_1MzGCJI410p5JtG8uP0XC0Pc",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1682069047,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": null,
      "metadata": {},
      "nickname": null,
      "product": "prod_NccFhsQ8Lsu64a",
      "recurring": null,
      "tax_behavior": "unspecified",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "one_time",
      "unit_amount": 2500,
      "unit_amount_decimal": "2500"
    },
    {
      "id": "price_1MspJjI410p5JtG8NcBt8dHF",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1680535751,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": null,
      "metadata": {},
      "nickname": null,
      "product": "prod_NccFhsQ8Lsu64a",
      "recurring": null,
      "tax_behavior": "unspecified",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "one_time",
      "unit_amount": 0,
      "unit_amount_decimal": "0"
    },
    {
      "id": "price_1MrN1QI410p5JtG8SHoOfl5X",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1680188656,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": null,
      "metadata": {},
      "nickname": null,
      "product": "prod_NccFhsQ8Lsu64a",
      "recurring": {
        "aggregate_usage": null,
        "interval": "month",
        "interval_count": 1,
        "meter": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "tax_behavior": "exclusive",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "recurring",
      "unit_amount": 14309,
      "unit_amount_decimal": "14309"
    },
    {
      "id": "price_1MpcOWI410p5JtG8ZBZe6HI8",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1679771092,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": null,
      "metadata": {},
      "nickname": null,
      "product": "prod_Nao0LBiiTHHJcS",
      "recurring": null,
      "tax_behavior": "exclusive",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "one_time",
      "unit_amount": 80000,
      "unit_amount_decimal": "80000"
    }
  ]

  const allStripeProducts = [
    {
      "id": "prod_PzLe99bkjygFo6",
      "object": "product",
      "active": true,
      "attributes": [],
      "created": 1714030972,
      "default_price": "price_1P9MxFI410p5JtG8qoobVD2r",
      "description": null,
      "features": [],
      "images": [],
      "livemode": false,
      "marketing_features": [],
      "metadata": {},
      "name": "Chronicle Premium Plan",
      "package_dimensions": null,
      "shippable": null,
      "statement_descriptor": null,
      "tax_code": "txcd_20030000",
      "type": "service",
      "unit_label": null,
      "updated": 1714031012,
      "url": null
    },
    {
      "id": "prod_Pe3FLRACseQc4A",
      "object": "product",
      "active": true,
      "attributes": [],
      "created": 1709119071,
      "default_price": "price_1Ool91I410p5JtG8uzizoBK6",
      "description": "Professional Plan",
      "features": [],
      "images": [],
      "livemode": false,
      "marketing_features": [],
      "metadata": {},
      "name": "Chronicle _Professional Plan",
      "package_dimensions": null,
      "shippable": null,
      "statement_descriptor": null,
      "tax_code": "txcd_20030000",
      "type": "service",
      "unit_label": null,
      "updated": 1714030927,
      "url": null
    }
  ]

  const getPaypalTokenHandler = async () => {
    try {
      const clientId =
        "AfiAu4Pd1gaVDHaTYzKnAtkrQZMhfISX3eyWMwLed_AR61Smw7HLafngwvF_DnHbp3Rs-95zJOFpGX9h";
      const clientSecret =
        "ECdlpLzXIvehwKcIZBO-1TN5m-CYPB8_sD8NYqZANEs4K1rdtFTGBxmPk0-M_7PzIcJCMwLIUDL1pv7S";

      const credentials = `${clientId}:${clientSecret}`;
      const base64Credentials = btoa(credentials);

      const response = await fetch(
        `https://api-m.sandbox.paypal.com/v1/oauth2/token`,
        {
          method: "POST",

          body: "grant_type=client_credentials",

          headers: {
            Authorization: `Basic ${base64Credentials}`,
          },
        }
      );

      const data = await response.json();
      sessionStorage.setItem("tokenHere", data.access_token);
      //   return data.access_token;
    } catch (error) {
      console.error("Failed to generate Access Token:", error);
    }

    //PRODUCTS
    const productsResponse = await axios.get(
      "https://api-m.sandbox.paypal.com/v1/catalogs/products?page_size=20",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenHere")}`,
        },
      }
    );

    setAllPayPalProducts(productsResponse?.data?.products);

    const pricesResponse = await axios.get(
      "https://api-m.sandbox.paypal.com/v1/billing/plans?page_size=20",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenHere")}`,
        },
      }
    );

    let activePRices = pricesResponse?.data?.plans.filter(
      (item) => item?.status == "ACTIVE"
    );
    setAllPayPalPrices(activePRices);
  };

  // useEffect(() => {
  //   getPaypalTokenHandler();
  // }, []);

  const organizePaypalPricesByProduct = (prices, productIds) => {
    const productsWithPrices = [];

    productIds.forEach(({ id, name }) => {
      const productPrices = prices.filter((price) => price.product_id === id);
      if (productPrices.length > 0) {
        const productObject = {
          product_Id: id,
          product_Name: name,
          prices: productPrices.map(({ name, id }) => ({
            id,
            name,
          })),
        };

        productsWithPrices.push(productObject);
      }
    });

    return productsWithPrices;
  };

  const paypalProductsArryHandler = (value) => {
    let productsList = value.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
      };
    });
    const result = organizePaypalPricesByProduct(allPayPalPrices, productsList);
    setPaypalActivePrices(result);
  };

  useEffect(() => {
    setPlanLoader(true)
    dispatch(getSubscriptionPlanRequest());
    dispatch(getStripeProducts())
    // (async () => {
    //   const result = await axios.get(`http://localhost:5000/getAllPrices`);
    //   console.log(result);
    //   setAllPrices(result?.data?.allProduct?.data);
    //   setAllProducts(result?.data?.products?.data);
    // })();
  }, []);

  useEffect(() => {
    if (subscriptionLoader == false) {
      setPlanLoader(false)
    }
  },[subscriptionLoader])

  const stripeStatePlans = useSelector((state) => state?.SubscriptionPlan?.stripeProducts?.data)

  useEffect(() => {
    setAllPrices(stripeStatePlans?.allProduct?.data);
    setAllProducts(stripeStatePlans?.products?.data);
    // setAllPrices(allStripePrices)
    // setAllProducts(allStripeProducts)
  },[stripeStatePlans])

  const organizePricesByProduct = (prices, productIds) => {
    const productsWithPrices = [];

    productIds.forEach(({ id, name }) => {
      const productPrices = prices.filter((price) => price.product === id);
      if (productPrices.length > 0) {
        const productObject = {
          product_Id: id,
          product_Name: name,
          prices: productPrices.map(({ id, unit_amount, recurring }) => ({
            id,
            unit_amount: unit_amount,
            interval: recurring ? recurring.interval : null,
          })),
        };

        productsWithPrices.push(productObject);
      }
    });

    return productsWithPrices;
  };

  const productsArryHandler = (value) => {
    let productsList = value.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
      };
    });
    setActiveProd(productsList);
    const result = organizePricesByProduct(allPrices, productsList);
    setActivePrices(result);
  };

  React.useEffect(() => {
    if (allPrices && allProducts) {
      productsArryHandler(allProducts);
    }
  }, [allPrices, allProducts]);

  React.useEffect(() => {
    if (allPayPalPrices && allPayPalProducts) {
      paypalProductsArryHandler(allPayPalProducts);
    }
  }, [allPayPalPrices, allPayPalProducts]);


  const subscriptionPlans = useSelector(
    (state) => state?.SubscriptionPlan?.plans
  );

  // ---------Input Handle change-------

  // ---------Show password -------
  // ---------User Login-------

  // ---------Custom Theme CSS-------
  const theme = createTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "transparent",
          marginLeft: "-10px !important",
          "&:hover": {
            backgroundColor: "transparent",
            marginLeft: "-10px !important",
          },
          "&$focused": {
            backgroundColor: "transparent",
            marginLeft: "-10px !important",
          },
        },
        underline: {
          "&:before": {
            borderBottomColor: "transparent",
            width: "106%",
            marginBottom: "1px !important",
          },
          "&:hover:not(.Mui-focused):before": {
            borderBottomColor: "transparent",
            width: "106%",
            marginBottom: "1px !important",
          },
          "&:after": {
            // focused
            borderBottomColor: "#386CB5",
            width: "106%",
            marginBottom: "1px !important",
          },
        },
      },
      MuiInputLabel: {
        filled: {
          color: "#222222",
          marginLeft: "-10px !important",
          top: "-1px !important",
          "&$focused": {
            color: "#386CB5",
            marginLeft: "-10px !important",
          },
          ".MuiFormControl-root:hover &:not($focused)": {
            color: "#222222",
            marginLeft: "-10px !important",
          },
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: "-44px",
          marginTop: "1px",
        },
      },
    },
  });

  const choosePlan = (item, value, price, stripeObj, payPalObj) => {
    dispatch(addPaymentStatusSuccess());
    let obj = {
      ...item,
      selectedSubType: value,
      planPrice: price,
      stripeObj: stripeObj,
      payPalObj: payPalObj,
    };
    sessionStorage.setItem("paymentObj", JSON.stringify(obj));
    props.history.push(routes.USERPAYPAL, {
      item: JSON.parse(sessionStorage.getItem("paymentObj")),
    });
  };

  const loginHandler = () => {
    const userEmail = sessionStorage.getItem("RegisterEmail");
    const userPassword = sessionStorage.getItem("Password");
    const keepMeSinged = sessionStorage.getItem("keepMeSinged");
    const sendRequest = {
      user_email_id: userEmail,
      user_password: userPassword,
      keepMeSinged: keepMeSinged,
    };

    dispatch(login(sendRequest));
  };


  let backendPlans = [
    {
      cc_subscription_id: 1,
      cc_subscription_title: "Personal",
      cc_subscription_sub_title: null,
      cc_subscription_description: "5 MB Data Storage",
      cc_subscription_price: 0,
      cc_subscription_size: 5,
      cc_subscription_active: "yes",
      cc_subscription_added_on: "2017-12-09T09:03:20.000Z",
      cc_subscription_modifyed_on: null,
      cc_subscription_pc_status: 0,
      cc_subscription_annual_price: null,
      cc_subscription_annual_subTitle: null,
      cc_subscription_product_id: null,
      cc_subscription_duration: 30,
      cc_subscription_content:
        "* Multimedia capabilities (Audio, Images, Videos).\\n* Customizable Note-taking.\\n* Collaborative teaching via student and class sharing.\\n* Integrated and multifaceted Gradebook.",
      cc_subscription_annual_subTitle_discount: null,
      cc_is_recommended: 0,
      product_ids: {},
      color_code: "#F18F1A",
      plan_logo:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fpersonal.png?alt=media&token=7f45b7f0-e953-4dc0-b922-4e90c5a5f0b6",
      plan_logo_bg:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fpersonal_bg.png?alt=media&token=1b633eae-d9ca-4c76-b15e-99fa0da1a278",
    },
    {
      cc_subscription_id: 2,
      cc_subscription_title: "Premium",
      cc_subscription_sub_title: "Monthly",
      cc_subscription_description: "500 MB Data Storage",
      cc_subscription_price: 3.99,
      cc_subscription_size: 500,
      cc_subscription_active: "yes",
      cc_subscription_added_on: "2017-12-09T09:03:58.000Z",
      cc_subscription_modifyed_on: null,
      cc_subscription_pc_status: 0,
      cc_subscription_annual_price: 39.99,
      cc_subscription_annual_subTitle: "Or prepay Annually",
      cc_subscription_product_id: "com.chroniclecloud.premiumplan",
      cc_subscription_duration: 30,
      cc_subscription_content:
        "* Multimedia capabilities (Audio, Images, Videos).\\n* Customizable Note-taking.\\n* Collaborative teaching via student and class sharing.\\n* Integrated and multifaceted Gradebook.\\n* 1 month Free chargeable from 2nd month.",
      cc_subscription_annual_subTitle_discount: "(Save 16%)",
      cc_is_recommended: 1,
      product_ids: {
        iOS: {
          monthly: "com.chroniclecloud.premiumplan",
          yearly: "com.chroniclecloud.premiumyearlyPlan",
        },
        Android: {
          monthly: "com.chroniclecloud.premiumplan",
          yearly: "com.chroniclecloud.premiumplan.annual",
        },
        Web: {
          monthly: "2",
          yearly: "7",
        },
      },
      color_code: "#E73860",
      plan_logo:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fpremium.png?alt=media&token=175d4f90-c984-4e15-9613-b2bd61736a70",
      plan_logo_bg:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fpremium_bg.png?alt=media&token=11f9ad6a-cb71-4453-906a-05841fcd607f",
    },
    {
      cc_subscription_id: 3,
      cc_subscription_title: "Professional",
      cc_subscription_sub_title: "Monthly",
      cc_subscription_description: "1 GB Data Storage",
      cc_subscription_price: 5.99,
      cc_subscription_size: 1024,
      cc_subscription_active: "yes",
      cc_subscription_added_on: "2017-12-09T09:11:26.000Z",
      cc_subscription_modifyed_on: null,
      cc_subscription_pc_status: 0,
      cc_subscription_annual_price: 59.99,
      cc_subscription_annual_subTitle: "Or prepay Annually",
      cc_subscription_product_id: "com.chroniclecloud.professionalplan",
      cc_subscription_duration: 30,
      cc_subscription_content:
        "* Multimedia capabilities (Audio, Images, Videos).\\n* Customizable Note-taking.\\n* Collaborative teaching via student and class sharing.\\n* Integrated and multifaceted Gradebook.\\n* 1 month Free chargeable from 2nd month.",
      cc_subscription_annual_subTitle_discount: "(Save 16%)",
      cc_is_recommended: 0,
      product_ids: {
        iOS: {
          monthly: "com.chroniclecloud.professionalplan",
          yearly: "com.chroniclecloud.professionalyearlyPlan",
        },
        Android: {
          monthly: "com.chroniclecloud.professionalplan",
          yearly: "com.chroniclecloud.professionalplan.annual",
        },
        Web: {
          monthly: "3",
          yearly: "8",
        },
      },
      color_code: "#0C93A0",
      plan_logo:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fprofressional.png?alt=media&token=41be356f-2eae-4a79-a77b-0d797fd12d77",
      plan_logo_bg:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fprofessional_bg.png?alt=media&token=02f7c46f-53b6-4fa0-977a-323d7c05f646",
    },
  ];


  return (
    <React.Fragment>
      <div className="LoginMainContainer">
        <div className="LoginSubContainer">
          <div data-testid="logo-div">
            <img alt="" src={Logo} className="img-div" />
          </div>
        </div>
        <div className="LoginSubContainer">
          <div
            className="LoginCardDiv h-auto usersubsplanheight"
            style={{ overflow: "scroll" }}
          >
            {planLoader  ? (
              <>
              <div data-testid='loader'>
                <DivLoader />
                </div>
              </>
            ) : (
              <>
                {subscriptionPlans?.map((item, idx) => {
                  let planContent = item?.cc_subscription_content?.split("\\n");
                  // const filteredObj = activePrices?.filter(
                  //   (activePricesItem) =>
                  //     activePricesItem?.product_Name?.toLowerCase() ==
                  //     item?.cc_subscription_title?.toLowerCase()
                  // );
                  const filteredObj = activePrices?.filter(
                    (activePricesItem) =>
                      activePricesItem?.product_Name?.includes(item?.cc_subscription_title_for_stripe)
                  );
                  const selectedMonthlyPriceId = filteredObj?.[0]?.prices?.find(
                    (item) => item?.interval == "month"
                  );
                  const stripeYearlyPriceObj = filteredObj?.[0]?.prices?.find(
                    (item) => item?.interval == "year"
                  );

                  const filteredPayPalObj = paypalActivePrices?.filter(
                    (paypayActivePriceItem) =>
                      paypayActivePriceItem?.product_Name.toLowerCase() ==
                      item?.cc_subscription_title?.toLowerCase()
                  );
                  const paypalMonthlyPlanObj =
                    filteredPayPalObj?.[0]?.prices?.find(
                      (item) => item?.name == "MonthlyTrial"
                    );
                  const paypalYearlyPlanObj =
                    filteredPayPalObj?.[0]?.prices?.find(
                      (item) => item?.name == "YearlyTrial"
                    );
                  return (
                    <div className="planContainerDiv mb-3">
                      <Row>
                        <Col
                          lg={3}
                          md={3}
                          xs={12}
                          sm={12}
                          className="planIconBg d-flex flex-wrap align-items-center"
                        >
                          <img
                            src={item?.plan_logo}
                            className="img-fluid"
                            alt={item?.plan_logo}
                            width={90}
                          />
                        </Col>
                        <Col
                          lg={9}
                          md={9}
                          xs={12}
                          sm={12}
                          className="m-auto position-relative"
                        >
                          <p className="dataPlans mb-0" dataPlans>
                            * {item?.cc_subscription_description}
                          </p>
                          {planContent?.map((planItem, PlanIdx) => {
                            return (
                              <>
                                <p className="dataPlans mb-0" dataPlans>
                                  {planItem}
                                </p>
                              </>
                            );
                          })}
                          <div
                            className="mt-3"
                            style={{ position: "relative", zIndex: 2 }}
                          >
                            {item?.cc_subscription_title == "Personal" ? (
                              <Button
                                variant="contained"
                                className="planFreeBtn float-left font-weight-bold"
                                onClick={() => {
                                  setShowloginModal(true);
                                }}
                              >
                                Free
                              </Button>
                            ) : (
                              <>
                                <div
                                  className="upgradePlanButtonRegsiter"
                                  onClick={() => {
                                    if (item?.product_ids) {
                                      choosePlan(
                                        item,
                                        "monthly",
                                        item.cc_subscription_price,
                                        selectedMonthlyPriceId,
                                        paypalMonthlyPlanObj
                                      );
                                    } else {
                                      createNotification(
                                        "error",
                                        "Product is not available"
                                      );
                                    }
                                  }}
                                >
                                  <p className="planPrice">{`$ ${item.cc_subscription_price} / month`}</p>
                                </div>
                                <div className="maxDetailsParaRegister">
                                  <p className="annualPlanTypeParaRegister mt-0 mb-0">
                                    {item?.cc_subscription_annual_subTitle} &{" "}
                                    <span>
                                      {item?.cc_subscription_annual_subTitle_discount?.slice(
                                        1,
                                        -1
                                      )}
                                    </span>
                                  </p>
                                  <p
                                    className="selectedAnnualPlanPriceRegsiter mt-0 mb-0"
                                    onClick={() => {
                                      if (item?.product_ids) {
                                        choosePlan(
                                          item,
                                          "annual",
                                          item.cc_subscription_annual_price,
                                          stripeYearlyPriceObj,
                                          paypalYearlyPlanObj
                                        );
                                      } else {
                                        createNotification(
                                          "error",
                                          "Product is not available"
                                        );
                                      }
                                    }}
                                  >{`$ ${item.cc_subscription_annual_price} / year`}</p>
                                </div>
                              </>
                            )}
                          </div>
                          <img
                            src={item?.plan_logo_bg}
                            className="img-fluid"
                            alt={"plan_bg"}
                            style={{
                              position: "absolute",
                              bottom: '-25px',
                              right: 13,
                            }}
                          />
                          {item.cc_is_recommended == 1 && (
                            <div className="recommendBoxRegister" style={{top : '-20px'}}>
                              Recommended
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {/*************************Login Modal ********************/}
          <Modal
            centered
            className="delete-modal-warning br-8 edit-standard-modal appendstandardModal"
            show={showloginModal}
            // onHide={editStandardLoader ? false : handleEditModalClose}
            // backdrop={editStandardLoader ? "static" : false}
          >
            <Modal.Body
              style={{ paddingBottom: 0 }}
              className="d-flex align-items-center justify-content-center"
            >
              <p className="addStandardModalPara mb-0">
                Free Plan has limited access upto 5MB data storage for adding
                multimedia.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="default"
                className="delete-modal-warning cancel-btn"
                onClick={handleAllStandardModalClose}
                style={{ background: "transparent", color: "#000" }}
              >
                Cancel
              </Button>
              <Button
                variant="default"
                className="delete-modal-warning continue-login-free-btn"
                style={{ borderRadius: "8px", position: "relative" }}
                onClick={() => {
                  loginHandler();
                }}
              >
                {loginFetchingState ? (
                  <span
                    style={{
                      marginTop: 0,
                      position: "absolute",
                      top: 0,
                      transform: "translate(-50%, 0)",
                    }}
                  >
                    <DivLoader marginTop={0} />
                  </span>
                ) : (
                  "Continue"
                )}
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="bottomDiv">
            <p className="text-1">
              © 2022 All rights reserved | Built for Chronicle Cloud.
            </p>
            <p className="text-2">Powered with Synapse Xtreme Engine (SXE)</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegisterSubscription;
