import { call, takeLatest, put } from 'redux-saga/effects';
import UserProfileReducer from 'Redux/Reducers/ProfileReducer/profileReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { createNotification } from 'Config/notificationtoast';
import { DELETE_USER, UPDATE_USER } from 'Actions/constants';
import { UpdateUser_API, deleteUser_API } from 'Config/Api';
import request from 'Config/request';
import { decryptData, encryptData, logOut } from 'Utils/Helper';


export function* userProfileModuleSaga() {
    yield takeLatest(UPDATE_USER, updateUser);
    yield takeLatest(DELETE_USER, deleteUser);
}

function* updateUser(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = UpdateUser_API;
    const BODY = {
        "user_old_password":  action.data.user_old_password,
        "password": action.data.user_password,
        "user_first_name": action.data.user_first_name,
        "user_last_name": action.data.user_last_name,
        "global_user_id": data.global_user_id,
        "user_pic": action.data.user_pic,
        "platform": "web"
      }
      let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            sessionStorage.setItem("UserData", JSON.stringify(MainDecode.data))
            createNotification('success', MainDecode.message);
            yield put(UserProfileReducer.updateUserSuccess(MainDecode.data));
            yield put(UserLoadingReducer.loadingSuccess(false));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserProfileReducer.updateUserFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserProfileReducer.updateUserFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));
    }
}

function* deleteUser(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    yield put(UserLoadingReducer.loading(true));
    const URL = deleteUser_API;
    const BODY = {
        "user_id": data.cc_user_id,
        "platform": "web"
      }
      let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            createNotification('success', MainDecode.message);
            yield put(UserProfileReducer.deleteUserSuccess(MainDecode.data));
            yield put(UserLoadingReducer.loadingSuccess(false));
            sessionStorage.clear()
            sessionStorage.setItem('deleteUSer',true)
            window.location.href = '/'
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserProfileReducer.deleteUserFailure(MainDecode));
            yield put(UserLoadingReducer.loadingSuccess(false));
        }
    } catch (error) {
        console.log(error)
        createNotification('warning', 'Something went wrong');
        yield put(UserProfileReducer.deleteUserFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));
    }
}
