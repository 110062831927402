import { call, takeLatest, put } from 'redux-saga/effects';
import UserAcademicReducer from 'Redux/Reducers/AcademicReducer/academicReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { createNotification } from 'Config/notificationtoast';
import { GET_ACADEMIC_LIST, GET_ACADEMIC_SCORE, GET_ACADEMIC_YEARS, ADD_ACADEMIC_YEARS, DELETE_ACADEMIC_YEARS, EDIT_ACADEMIC_YEARS, GET_ROSTER_CLASS_BY_ACADEMIC_YEAR } from 'Actions/constants';
import {
  GetAcademicList_API,
  GetAcademicScore_API,
  GetAcademicYearsList_API,
  AddAcademicYear_API,
  DeleteAcademicYear_API,
  EditAcademicYear_API,
  GetClassRosterByAcademicYear_API
} from 'Config/Api';
import * as routes from "Router/RoutesURL";
import {
  chroniclecloud_code,
  app_version,
  device_version
} from 'Config/Constant';
import { decryptData, encryptData, getSharingCondition, logOut } from 'Utils/Helper'
import request from 'Config/request';


export function* userAcademicModuleSaga() {
  yield takeLatest(GET_ACADEMIC_LIST, getAcademicList);
  yield takeLatest(GET_ACADEMIC_SCORE, getAcademicScore);
  yield takeLatest(GET_ACADEMIC_YEARS, getAcademicYears);
  yield takeLatest(ADD_ACADEMIC_YEARS, addAcademicYears);
  yield takeLatest(DELETE_ACADEMIC_YEARS, deleteAcademicYears);
  yield takeLatest(EDIT_ACADEMIC_YEARS, editAcademicYears);
  yield takeLatest(GET_ROSTER_CLASS_BY_ACADEMIC_YEAR, getRosterClassByAcademicYear)
}

function* getAcademicList(action) {
  //for user academic data
  let data = JSON.parse(sessionStorage.getItem('UserData'))
  const URL = `${GetAcademicList_API}?user_id=${data.cc_user_id}&app_version=${app_version}&device_version=${device_version.replace(/"|_| /g, '')}&platform=web`;
  let response;

  try {
    response = yield call(request, URL, {
      method: 'GET',
      // body: { "data": btoa(BODY) },
    });
    const MainDecode = decryptData(response.res);
      
    if (MainDecode.status === 200) {
      window.sessionStorage.removeItem('selected_class_name')
      window.sessionStorage.removeItem('Class_ID_for_LP')
      window.sessionStorage.removeItem('classIDForNotes')
      window.sessionStorage.removeItem('classNameForNotes')
      window.sessionStorage.removeItem('notesclassId')
      window.sessionStorage.removeItem('noteClassName')
      window.sessionStorage.removeItem('classSharePaermission')
      window.sessionStorage.removeItem('FAClassPermission')
      window.sessionStorage.removeItem('viewRubricDetailsStates')
      window.sessionStorage.removeItem('rubricsSelectedClass')
      window.sessionStorage.removeItem('classColorWheel')
      window.sessionStorage.removeItem('studentGradebookScore')
      window.sessionStorage.removeItem('formAssessmentStd')
      window.sessionStorage.removeItem('mainGradebooklist')
      window.sessionStorage.removeItem('assesTag')
      sessionStorage.removeItem('LPPlaiingQuestion')
								sessionStorage.removeItem('LPAudioList')
								sessionStorage.removeItem('LPTopic')
								sessionStorage.removeItem('LPTitle')
								sessionStorage.removeItem('LPUrllist')
								sessionStorage.removeItem('LPReminderTime')
								sessionStorage.removeItem('LPNotification')
								sessionStorage.removeItem('LPFormatDate')
								sessionStorage.removeItem('LPDocList')
								sessionStorage.removeItem('LPMinutes')
								sessionStorage.removeItem('LPPictureVideoList') 
                sessionStorage.removeItem('noteCommentData')
                sessionStorage.removeItem('finalTranscribedAudio')
                sessionStorage.removeItem('finalNoteCommentCardsAfterRemove')
                sessionStorage.removeItem('cLsIdForGBSC')
                sessionStorage.removeItem('cLsNameForGBSC')
                sessionStorage.removeItem('cLsDataForGBSC')
               
      // window.sessionStorage.removeItem('UserInitialLogin')
      yield put(UserAcademicReducer.getAcademicListSuccess(MainDecode));
    } else {
      createNotification('error', MainDecode.message);
      yield put(UserAcademicReducer.getAcademicListFailure(MainDecode));
      if (MainDecode.message === "Your session has been expired. Please login again") {
        logOut('error', MainDecode.message)
      }
    }
  } catch (error) {
    createNotification('warning', 'Something went wrong');
    yield put(UserAcademicReducer.getAcademicListFailure(false));
  }
}

function* getRosterClassByAcademicYear(action) {
  
  let data = JSON.parse(sessionStorage.getItem('UserData'))
  const URL = GetClassRosterByAcademicYear_API;
  const BODY = {
    // "chroniclecloud_code": chroniclecloud_code,
    // "academic_year_id": action.data.academic_year_id,
    // "user_id": data.cc_user_id,
    // "device_udid": sessionStorage.getItem('UDID'),
    // "roster_limit": '',
    // "class_limit": '',
    // "blank_class": '',

    //----------New Payload--------------
    "user_id": data.cc_user_id,
    "academic_year_id": typeof(action.data.academic_year_id) !== "string" ? JSON.stringify(action.data.academic_year_id) : action.data.academic_year_id,
    "roster_limit": '',
    "class_limit": '',
    "blank_class": '',
    "platform": "web",
    "role": 'teacher'
  }

  let response;
  try {
    response = yield call(request, URL, {
      method: 'POST',
      body: { "data": encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res);
    if (MainDecode.status === 200) {
      yield put(UserAcademicReducer.getRosterClassByAcademicYearSuccess(MainDecode));
       if(action?.data?.userInitialLogin == 'true') {
        let isShared = getSharingCondition(MainDecode?.data?.roster_data, MainDecode?.data?.class_data)
        if(isShared) {
          if(action?.data?.editCurrentYear) {
          createNotification('error',  'You cannot edit the Academic Year containing shared Classes or Student');
          } else {
            createNotification('error',  'You cannot delete the Academic Year containing shared Classes or Student');
          }
        } else {
          if(action?.data?.editCurrentYear == 'true') {
            let data = {
              "role": "teacher",
    "academic_year_id": action.data.academic_year_id,
    "academic_year": sessionStorage.getItem('addYear'),
    // "current_year": 0,
    // "school_id": 0,
    "teacher_id": data.cc_user_id,
    "platform": "web"
            }
            yield put(UserAcademicReducer.editAcademicYears(data));
          } else {
          let data = {
            "role": "teacher",
            "academic_year_id": action.data.academic_year_id,
            "is_deleted": 1,
            "platform": "web",
            "user_id": data?.cc_user_id,
          }
          yield put(UserAcademicReducer.deleteAcademicYears(data));
        }
        }
       }
    } else {
      createNotification('error', MainDecode.message);
      yield put(UserAcademicReducer.getRosterClassByAcademicYearFailure(MainDecode));
      if (MainDecode.message === "Your session has been expired. Please login again") {
        logOut('error', MainDecode.message)
      }
    }
  } catch (error) {
    createNotification('warning', 'Something went wrong');
    yield put(UserAcademicReducer.getRosterClassByAcademicYearFailure(false));
  }
}

function* getAcademicScore() {
  let data = JSON.parse(sessionStorage.getItem('UserData'))
  const URL = `${GetAcademicScore_API}?user_id=${data.cc_user_id}&platform=web`
  // const BODY = JSON.stringify({
  //   "chroniclecloud_code": chroniclecloud_code,
  //   "user_id": data.cc_user_id,
  //   "device_udid": sessionStorage.getItem('UDID'),

  //   //----------New Payload--------------
  //   "user_id": data.cc_user_id,
  //   "platform": "web"
  // })
  let response;
  try {
    response = yield call(request, URL, {
      method: 'GET',
      // body: { "data": btoa(BODY) },
    });
    const MainDecode = decryptData(response.res);

    if (MainDecode.status === 200) {
      yield put(UserAcademicReducer.getAcademicScoreSuccess(MainDecode));
      sessionStorage.setItem('userHasFreePlan', MainDecode?.data?.isActive)
      if(MainDecode?.data?.isActive == '0') {
       window.location.href = routes.LOGIN;
       sessionStorage.clear();
      }
    } else {
      createNotification('error', MainDecode.message);
      yield put(UserAcademicReducer.getAcademicScoreFailure(MainDecode));
      if (MainDecode.message === "Your session has been expired. Please login again") {
        logOut('error', MainDecode.message)
      }
    }
  } catch (error) {
    createNotification('warning', 'Something went wrong');
    yield put(UserAcademicReducer.getAcademicScoreFailure(false));
  }
}

function* getAcademicYears() {
  //for master academic year list (PopUp list)
  let data = JSON.parse(sessionStorage.getItem('UserData'))
  const URL = `${GetAcademicYearsList_API}?user_id=${data.cc_user_id}&platform=web`;
  let response;
  try {
    response = yield call(request, URL, {
      method: 'GET',
    });
    const MainDecode = decryptData(response.res);
    
    if (MainDecode.status === 200) {
      yield put(UserAcademicReducer.getAcademicYearsSuccess(MainDecode));
    } else {
      createNotification("error", MainDecode.message);
      yield put(UserAcademicReducer.getAcademicYearsFailure(MainDecode));
      if (
        MainDecode.message ===
        "Your session has been expired. Please login again"
      ) {
        logOut("error", MainDecode.message);
      }
    }
  } catch (error) {
    
    createNotification("warning", "Something went wrong");
    yield put(UserAcademicReducer.getAcademicYearsFailure(false));
  }
}

function* addAcademicYears(action) {
  let data = JSON.parse(sessionStorage.getItem('UserData'))
  const URL = AddAcademicYear_API;
  const BODY = {
    // "role": data.user_role,
    // // "chroniclecloud_code": chroniclecloud_code,
    // "teacher_id": data.cc_user_id,
    // // "device_udid": sessionStorage.getItem('UDID'),
    // "academic_year": action.data.acadmic_year,
    // "platform": 'web',

    //----------New Payload--------------
    // "role": data.user_role,
    "role": "teacher",
    "academic_year": action.data.acadmic_year,
    // "current_year": 0,
    // "school_id": 0,
    "teacher_id": data.cc_user_id,
    "platform": "web"
  }
  let response;
  try {
    response = yield call(request, URL, {
      method: 'POST',
      // ['Authorization'] : `Bearer ${sessionStorage.getItem('authToken')}`,
      body: { "data": encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res)
    
    if (MainDecode.status == 200) {

      yield put(UserAcademicReducer.addAcademicYearsSuccess(MainDecode));
      yield put(UserAcademicReducer.getAcademicYears());
      yield put(UserAcademicReducer.getAcademicList());
      // createNotification('success', MainDecode.message);
    } else {
      createNotification('error', MainDecode.message);
      yield put(UserAcademicReducer.addAcademicYearsFailure(MainDecode));
      if (MainDecode.message === "Your session has been expired. Please login again") {
        logOut('error', MainDecode.message)
      }
    }
  } catch (error) {

    createNotification('warning', 'Something went wrong');
    yield put(UserAcademicReducer.addAcademicYearsFailure(false));
  }
}

function* deleteAcademicYears(action) {
  let data = JSON.parse(sessionStorage.getItem('UserData'))
  const URL = DeleteAcademicYear_API;
  const BODY = {
    // // "chroniclecloud_code": chroniclecloud_code,
    // "role": data.user_role,
    // "is_deleted": 1,
    // "platform": "web",
    // // "user_id": data.cc_user_id,
    // // "device_udid": sessionStorage.getItem('UDID'),
    // "academic_year_id": action.data.academic_year_id,

    //-----------New Payload--------
    "role": "teacher",
    "academic_year_id": action.data.academic_year_id,
    "is_deleted": 1,
    "platform": "web",
    "user_id": data?.cc_user_id,
  }

  let response;
  try {
    response = yield call(request, URL, {
      method: 'PUT',
      body: { "data": encryptData(BODY) },
    });


    const MainDecode = decryptData(response.res)
    
    if (MainDecode.status === 200) {
      yield put(UserAcademicReducer.deleteAcademicYearsSuccess(MainDecode));
      yield put(UserAcademicReducer.getAcademicYears());
      yield put(UserAcademicReducer.getAcademicList());
      var api_data = {
        "academic_year_id": action.data.index === 0 ? action.data.academicListData?.[1]?.academic_year_id : action.data.academicListData?.[0]?.academic_year_id,
        "roster_limit": "",
        "class_limit": "",
        "blank_class": "",
      }
      // if (api_data?.academic_year_id) {
      //   yield put(UserAcademicReducer.getRosterClassByAcademicYear(api_data));
      // }

      // createNotification('success', MainDecode.message);
    } else {
      createNotification('error', MainDecode.message);
      yield put(UserAcademicReducer.deleteAcademicYearsFailure(MainDecode));
      if (MainDecode.message === "Your session has been expired. Please login again") {
        logOut('error', MainDecode.message)
      }
    }
  } catch (error) {
    createNotification('warning', 'Something went wrong');
    yield put(UserAcademicReducer.deleteAcademicYearsFailure(false));
  }
}

function* editAcademicYears(action) {
  yield put(UserLoadingReducer.loadingSuccess(true));
  let data = JSON.parse(sessionStorage.getItem('UserData'))
  // const URL = `${base_url_API}api_update_academic_year`;
  const URL = EditAcademicYear_API;
  const BODY = {
    // "role": data.user_role,
    // // "chroniclecloud_code": chroniclecloud_code,
    // "teacher_id": data.cc_user_id,
    // // "device_udid": sessionStorage.getItem('UDID'),
    // "academic_year_id": action.data.academic_year_id,
    // "academic_year": action.data.acadmic_year,
    // "platform": 'web',

    //----------New Payload--------------
    // "role": data.user_role,
    "role": "teacher",
    "academic_year_id": action.data.academic_year_id,
    "academic_year": action.data.acadmic_year,
    // "current_year": 0,
    // "school_id": 0,
    "teacher_id": data.cc_user_id,
    "platform": "web"
  }

  let response;
  try {
    response = yield call(request, URL, {
      method: 'PUT',
      body: { "data": encryptData(BODY) },
    });
    const MainDecode = decryptData(response.res)

    if (MainDecode.status === 200) {
      yield put(UserAcademicReducer.editAcademicYearsSuccess(MainDecode));
      yield put(UserAcademicReducer.getAcademicYears());
      yield put(UserAcademicReducer.getAcademicList());
      createNotification('success', MainDecode.message);
    }
    else {
      createNotification('error', MainDecode.message);
      yield put(UserLoadingReducer.loadingSuccess(false));
      yield put(UserAcademicReducer.editAcademicYearsFailure(MainDecode));
      if (MainDecode.message === "Your session has been expired. Please login again") {
        logOut('error', MainDecode.message)
      }
    }
  } catch (error) {
    createNotification('warning', 'Something went wrong');
    yield put(UserLoadingReducer.loadingSuccess(false));
    yield put(UserAcademicReducer.editAcademicYearsFailure(false));
  }
}
