import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


// import Logger from '../Lib/Logger'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    login: ['data'],
    loginSuccess: ['data'],
    loginFailure: ['error'],

    getUserPaymentDetails: ["data"],
  getUserPaymentDetailsFailure: ["error"],
  getUserPaymentDetailsSuccess: ["data"],

})

export const UserLoginReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    login_Data: null,
    error: null,
    exists: null,
    action: null,
    fetching: false,
  userPaymentDetails: null,
  userPaymentDetailsLoader: false,
  userPaymentDetailsError: null,
})

/* ------------- Reducers ------------- */

/* signin */
export const login = (state) => {
    return {
        ...state, fetching: true, error: null, login_Data: null,
    }
}
export const loginSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, login_Data: data, action: action.type, exists: true }
}
export const loginFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, }
}

export const getUserPaymentDetails = (state) => {
    return {
      ...state,
      userPaymentDetailsLoader: true,
      userPaymentDetailsError: null,
      userPaymentDetails: null,
    };
  };
  
  export const getUserPaymentDetailsFailure = (state, { error }) => {
    return {
      ...state,
      userPaymentDetailsLoader: false,
      userPaymentDetailsError: error,
      userPaymentDetails: null,
    };
  };
  
  export const getUserPaymentDetailsSuccess = (state, action) => {
    return {
      ...state,
      userPaymentDetailsLoader: false,
      userPaymentDetailsError: null,
      userPaymentDetails: action.data,
    };
  };

  
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.LOGIN]: login,
    [Types.LOGIN_SUCCESS]: loginSuccess,
    [Types.LOGIN_FAILURE]: loginFailure,

    [Types.GET_USER_PAYMENT_DETAILS]: getUserPaymentDetails,
    [Types.GET_USER_PAYMENT_DETAILS_SUCCESS]: getUserPaymentDetailsSuccess,
    [Types.GET_USER_PAYMENT_DETAILS_FAILURE]: getUserPaymentDetailsFailure,

})
