import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ClassRoster.scss';
import CommonTextField from 'Components/CommonTextField/CommonTextField';
import { Button } from 'reactstrap';
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from 'react-bootstrap/Modal';
import UpgradePopUp from '../../upgradePopUp/upgradePopUp'
import selectedRosterIcon from 'Assets/ClassModule/selectedRoster.png'
import importRosterIcon from 'Assets/ClassModule/importRoster.png'
import googleDriveIcon from "Assets/RosterModule/googleDrive.png";
import addRosterIcon from 'Assets/RosterModule/plus.png'
import localFolderIcon from 'Assets/RosterModule/localFolder.png'
import camera from 'Assets/RosterModule/camera.png'
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif';
import cancelBlack from 'Assets/RosterModule/cancelBlack.png'
import addAcademicImage from 'Assets/AcademicModule/addAcademic.png';
import { createNotification } from 'Config/notificationtoast';
import { getRandomName } from "Utils/Helper";
import { FirebseStroageFolder, API_KEY, CLIENT_ID, DISCOVERY_DOCS, SCOPES } from 'Config/Constant';
import iconSearch from "Assets/RosterModule/icon-search.png";
import { storage } from "../../../firebase/firebase"
import {  getRosterImage } from "Config/commonFirebaseImage";
import * as routes from "Router/RoutesURL";
import { getClassRosterList, addClassRoster, deleteClassRoster, updateRosterLevel, searchClassRoster, importClassRoster } from 'Actions/ClassAction/classRosterAction';
import { addSelectedRosters, getNotSelectedRosters } from 'Actions/ClassAction/classSelectRosterAction';
import { getStudentGradebookNullRequest } from 'Actions/GradebookAction/studentGradebookAction';
import ReactFileReader from 'react-file-reader';
import { gapi } from "gapi-script";
import closeIcon from 'Assets/NoteModule/close.svg';
import tickIcon from 'Assets/NoteModule/tick.svg';
import driveFolderIcon from 'Assets/RosterModule/folder.png'
import driveCSVIcon from 'Assets/RosterModule/filecsv_icon.png'
import warningIcon from 'Assets/NoteModule/warning-icon.png'
import back from "Assets/CommonComponent/back.png";
import ClearIcon from '@material-ui/icons/Clear';
import UpdateBtn from 'Assets/ClassModule/UpdateBtn.png';
import CheckIcon from '@material-ui/icons/Check';
import iconNotes from "Assets/RosterModule/icon-notes.png";
import iconShare from "Assets/RosterModule/icon-share.png";
import iconGradeBook from "Assets/RosterModule/icon-gradebook.png";
import deleteIcon2 from "Assets/RosterModule/deleteIcon.png";
import DivLoader from 'Components/LoadingComponent/DivLoader';

class ClassRoster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // class Details.....
            ClassDetails: [],
            academicYear: '',
            academicYearId: '',
            filter: 'by_class',
            loadMoreCount: 1,
            loadMoreCountStatus: false,

            RosterOverAllCount: [],
            rosterCountWithoutSsearch : [],
            ClassRosterList: [],
            StudentsWithoutSearch: [],

            // Add Roster Parameter....
            RosterProfileURL: '',
            UserProfileSize: '',
            UserProfile: '',
            FirstName: '',
            LastName: '',
            Email: '',
            InvelidMessageEmail: '',
            showLoaderUpload: false,
            tabButton: '',

            // Edit Level and Score Modal...
            EditModal: false,
            RemoveRoster: '',
            ModalLevel: '',
            ModalScore: '',
            isAddStudentSubmit: false,

            // Upload Modal....
            UploadFrom: 'drive',

            showPopUp: false,
            searchBar: false,
            searchText: '',

            //Google Drive Para...
            GoogleAuth: JSON.parse(sessionStorage.getItem('GoogleAuth')),
            GoogleAuthItem: JSON.parse(sessionStorage.getItem('GoogleAuthItem')),
            GoogleFiles: [],
            googleSelectedFile: '',
            SelectFileModal: false,
            GetFileLoader: false,
            googleDrivePath: [],
            name: '',
            importLoader: false,
            isDeleted: false,
            deleteStudentId : 0,
            nameFilter:"firstName",
            schoolPopOverId : null,
            selectedEditRoster : "",
            isDeleted3 : false,
            updateLevelScore : false,
            // isDeleted3
            deleteItem3 : null,
            isLoading : false,
            AllRoster : [],
            seletedAllRoster : [],
            selectedRosterId : [],
            searchUnselectStudentText : "",
            statesData : [],
            orderBy : "firstName",
            numBoxes : 100
        }
        this.ref = React.createRef();
        this.modalRef = React.createRef()
        this.noDataref = React.createRef()
        this.classRosterDataRef = React.createRef()
    }

    CheckValue = (item, index) => {
      
        let AllRoster = this.state.statesData;
        // let AllRoster = []
      
        AllRoster[index].isSeleted = !AllRoster[index].isSeleted;
  
        this.pushInArray(item.cc_student_id)
        // this.setState({
          //     AllRoster,
          //     seletedAllRoster: false
          // })
          this.setState({
            AllRoster : AllRoster,
            seletedAllRoster : false
          })
        //   setAllRoster(AllRoster)
          this.forceUpdate()
        // setseletedAllRoster(false)
        // document.getElementById("a").innerHTML = "check_circle"
        
    }
  
    
    // ---------Check/Uncheck For One-------
  
    // ---------Check/Uncheck in API Send Array-------
    pushInArray = (value) => {
        let selectedRosterIds = this.state.selectedRosterId;
  
  
        let idFound = false;
        let fixedIndex = ''
        if (selectedRosterIds.length > 0) {
        
            let index = 0
            for (let item of selectedRosterIds) {
                if (item === value) {
                    idFound = true;
                    fixedIndex = index
                }
                index++
            }
            if (idFound) {
              
                selectedRosterIds.splice(fixedIndex, 1)
            } else {
             
                selectedRosterIds.push(value)
       
  
            }
  
        } else {
         
            this.state.selectedRosterId.push(value)
        
        }
  
        // this.setState({
        //     selectedRosterId
        // }, () => {
        // })
        // setselectedRosterId(selectedRosterIds);
  
    }

    handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = this.modalRef.current;
        if(this?.props?.notSelectedRoster?.length > 0) {
        if(this.state.statesData && this.state.statesData.length != this.props.notSelectedRosterCount) {
        if (scrollTop + clientHeight >= scrollHeight - 10 && !this.state.isLoading) {
        //   setIsLoading(true);
        this.setState({
            isLoading : true
        })
          this.fetchMoreData()
        }
      }
    }
      }

      handleUnselectStudentSearchText = (event) => {
        this.setState({
            searchUnselectStudentText : event.target.value,
            isLoading : true
        },() => {
            if(this.state.searchUnselectStudentText.length > 2) {
                this.setState({
                    statesData : [],
                    isLoading : true
                })
                const value = {
                  class_id:this.props?.history?.location?.state?.classDetail?.cc_class_id,
                  filter_by: "not_in_current_class",
                  page_no: 1,
                  academic_year: this.props?.history?.location?.state?.academicYearId,
                  sort_by: "sort_by_first_name",
                  // tag_id: tag_id,
                  search_text: this.state.searchUnselectStudentText,
                  by_date: "",
                  date_flag: "",
              
              }
              
              this.props.getNotSelectedRosters(value)
              } else {
                this.setState({
                    statesData : [],
                    isLoading : true
                })
                const value = {
                  class_id:this.props?.history?.location?.state?.classDetail?.cc_class_id,
                  filter_by: "not_in_current_class",
                  page_no: 1,
                  academic_year: this.props?.history?.location?.state?.academicYearId,
                  sort_by: "sort_by_first_name",
                  // tag_id: tag_id,
                  search_text: "",
                  by_date: "",
                  date_flag: "",
              
              }
              
              this.props.getNotSelectedRosters(value)
              }
        })
      };

 
      selectRoster = () => {
        // alert("dsada")
        if (this.state.selectedRosterId.length === 0) {
            createNotification('error', 'Please select at least one Roster/student.');
            return;
        }
        // const { location = {} } = this.props
        // const { state = {} } = location;
        // const { ClassDetails = '', academicYearId = '' } = state;
        // const { selectedRosterId, filter, loadMoreCount, sort_by, tag_id } = this.state
        const datas = {
            "student_id": `${this.state.selectedRosterId}`,
            "academic_year_id":this.props?.history?.location?.state?.academicYearId,
            "class_id": this.props?.history?.location?.state?.classDetail?.cc_class_id,
            "by_class_sharing": 'yes',
            "filter_by": this.state.filter,
            "page_no": 1,
            "sort_by": "sort_by_first_name",
            "limit": this.state.numBoxes,
            // "tag_id": tag_id,
            history: this.props.history
        }
        
   
        this.setState({
            // importRoster: true,
            loadMoreCount : 1,
            loadMoreCountStatus : false,
            statesData : []
        },() => {
            this.forceUpdate()
        })
        this.props.addSelectedRosters(datas)
        // this.props.addSelectedRosters(data)
        // dispatch(addSelectedRosters(datas))
        // setLoadMoreCount(1)
        // setLoadMoreCountStatus(false)
        // setStatesData([])
    }     

      fetchMoreData = () => {
 
        let page = this.state.loadMoreCount + 1
        this.setState({
            loadMoreCount : page
        })
        // setLoadMoreCount(page)
        const value = {
          class_id: this.props?.history?.location?.state?.classDetail?.cc_class_id,
          filter_by: "not_in_current_class",
          page_no: page,
          academic_year: this.props?.history?.location?.state?.academicYearId,
          sort_by: "sort_by_first_name",
          // tag_id: tag_id,
          search_text: "",
          by_date: "",
          date_flag: "",
      
      }
      this.props.getNotSelectedRosters(value)
    //   dispatch(getNotSelectedRosters(value))
      
      // if(statesData && statesData.length == notSelectedStudentsCount) {
      //   setHasMore(false)
      // }
      // setHasMore(false)
      }

    componentDidMount() {
        this.getClassRoster()
        document.addEventListener("mousedown", this.handleOnClickOutside);
        document.addEventListener("touchstart", this.handleOnClickOutside);

        // const boxWidth = 405; // width of each box in pixels
        // const boxHeight = 76; // height of each box in pixels
        // const divWidth = this.noDataref?.current?.offsetWidth;
        // // const divHeight = window.innerHeight
        // const divHeight = this.noDataref?.current?.offsetHeight;
        // const numBoxesWidth = Math.floor(divWidth / boxWidth);
        // // const numBoxesWidth = 403
        // const numBoxesHeight = Math.floor(divHeight / boxHeight);
        // const totalNumBoxes = numBoxesWidth * numBoxesHeight;
        // console.log(345, numBoxesHeight, numBoxesWidth, totalNumBoxes, divWidth, divHeight)
        // this.setState({
        //     numBoxes : totalNumBoxes
        // })

      }
    
      componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleOnClickOutside);
        document.removeEventListener("touchstart", this.handleOnClickOutside);
      }
    
      handleOnClickOutside = event => {
        if (!this.ref.current || this.ref.current.contains(event.target)) {
          return;
        }
        this.setState({ schoolPopOverId: null });


        


      }


    // ---------UNSAFE_componentWillMount-------
    UNSAFE_componentWillMount() {
        if(this.state.numBoxes  > 0) {
            console.log(360)
        }
        const value = {
            class_id: this.props?.history?.location?.state?.classDetail?.cc_class_id,
            filter_by: "not_in_current_class",
            page_no: this.state.loadMoreCount,
            academic_year: this.props?.history?.location?.state?.academicYearId,
            sort_by: "sort_by_first_name",
            // tag_id: tag_id,
            search_text: "",
            by_date: "",
            date_flag: "",
        
        }
        
        this.props.getNotSelectedRosters(value)
    }
    // ---------UNSAFE_componentWillMount-------

    // ---------componentWillReceiveProps-------
    async UNSAFE_componentWillReceiveProps(nextProps) {

        // ---------get Roster WillReceiveProps-------
        if (nextProps.rosterData.action === 'GET_CLASS_ROSTER_LIST_SUCCESS') {
            if (nextProps.rosterData.roster_Data && nextProps.rosterData.roster_Data.status === 200) {
                if (nextProps.rosterData.roster_Data.data[0] && nextProps.rosterData.roster_Data.data[0].length > 0) {
                    let getRosterData = []
                    if (this.state.loadMoreCount > 1) {
                        // ---get Roster LoadMore---
                        if (this.state.loadMoreCountStatus) {
                            getRosterData = this.state.StudentsWithoutSearch;
                            for (let value of nextProps.rosterData.roster_Data.data[0]) {
                                getRosterData.push(value)
                            }
                            
                            this.setState({
                                ClassRosterList: getRosterData,
                                StudentsWithoutSearch: getRosterData,
                                RosterOverAllCount: nextProps.rosterData.roster_Data.data[1],
                                rosterCountWithoutSsearch:  nextProps.rosterData.roster_Data.data[1],
                                loadMoreCountStatus: false
                            })
                        } else {
                            getRosterData = this.state.StudentsWithoutSearch;
                        }
                        // ---get Roster LoadMore---
                    } else {

                        this.setState({
                            ClassRosterList: nextProps.rosterData.roster_Data.data[0],
                            StudentsWithoutSearch: nextProps.rosterData.roster_Data.data[0],
                            RosterOverAllCount: nextProps.rosterData.roster_Data.data[1],
                            rosterCountWithoutSsearch:  nextProps.rosterData.roster_Data.data[1],
                        })
                        getRosterData = nextProps.rosterData.roster_Data.data[0];
                    }
                    // ---Fatch Firebase Image---
                    for (let rosterItem of getRosterData) {
                        if (rosterItem.cc_student_pic_url) {
                            if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                                rosterItem.cc_student_pic_url = await getRosterImage(rosterItem.cc_student_pic_url);
                            }
                        }
                    }
                    // ---Fatch Firebase Image---

                    this.setState({
                        ClassRosterList: getRosterData,
                        StudentsWithoutSearch: getRosterData,

                    })
                }
                else {
                    this.setState({
                        ClassRosterList: [],
                        StudentsWithoutSearch: [],
                        RosterOverAllCount: []
                    })
                }

            }

        }
        // ---------get Roster WillReceiveProps-------

        if (nextProps.rosterData && nextProps.rosterData.action === 'UPDATE_ROSTER_LEVEL_FAILURE'
            || 'ADD_CLASS_ROSTER_FAILURE') {
            if (nextProps.rosterData.error && (nextProps.rosterData.error.message === "Note limit has been exceeded. Please upgrade the plan"
                || nextProps.rosterData.error.message === "Media limit has been exceeded. Please upgrade the plan"
                || nextProps.rosterData.error.message === "Please update your subscription plan")) {
                this.setState({
                    showPopUp: true
                })
            }
        }

        // ---------get Roster WillReceiveProps-------
        if (nextProps.rosterData.action === 'ADD_CLASS_ROSTER_SUCCESS') {
            if (nextProps.rosterData.add_roster.status === "Success") {
                if (this.state.tabButton === 'addAnother') {
                    this.setState({
                        RosterProfileURL: '',
                        UserProfile: '',
                        FirstName: '',
                        LastName: '',
                        Email: '',
                        InvelidMessageEmail: '',
                        showLoaderUpload: false,
                        tabButton: '',
                        isAddStudentSubmit: false,
                    })
                    window.$('#addClassRosterModal').modal('show');
                }
            }

        }
        // ---------get Roster WillReceiveProps-------

        // ---------get search Roster-------
        if (this.state.searchBar) {
            if (nextProps.rosterData.action === "SEARCH_CLASS_ROSTER_SUCCESS") {
                if (nextProps.rosterData.search_class_roster !== null) {
                    if (nextProps.rosterData.search_class_roster.status == 200) {
                        if (nextProps.rosterData.search_class_roster.data.roster_data[0]) {
                            this.setState({
                                ClassRosterList: nextProps.rosterData.search_class_roster.data.roster_data[0],
                                RosterOverAllCount: [{ count : nextProps?.rosterData?.search_class_roster?.data?.roster_count}],
                            })
                            let getRosterData = nextProps.rosterData.search_class_roster.data.roster_data;
                            // ---Fatch Firebase Image---
                            for (let rosterItem of getRosterData) {
                                if (rosterItem.cc_student_pic_url) {
                                    if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                                        rosterItem.cc_student_pic_url = await getRosterImage(rosterItem.cc_student_pic_url);
                                    }
                                }

                            }
                            this.setState({
                                ClassRosterList: getRosterData
                            })
                            // ---Fatch Firebase Image---

                        } else {
                            let DummyArray = []
                            this.setState({
                                ClassRosterList: DummyArray
                            })
                        }
                    }
                } else {
                    let DummyArray = []
                    this.setState({
                        ClassRosterList: DummyArray
                    })
                }

            }
        }
        // ---------get search Roster-------

        // ---------not selected roster------
        if(nextProps?.notSelectedRoster?.length == 0) {
            this.setState({
                isLoading : false
            })
        }
        if(nextProps.notSelectedRoster && nextProps.notSelectedRoster.length > 0) {
            let newdata = [...this.state.statesData, ...nextProps.notSelectedRoster]
    
            const uniqueSchools = Array.from(
              new Set(newdata.map((a) => a.cc_student_id))
            ).map((id) => {
              return newdata.find((a) => a.cc_student_id === id);
            });

            let getRosterData = uniqueSchools

             // ---Fatch Firebase Image---
        for (let rosterItem of getRosterData) {
            if (rosterItem.cc_student_pic_url) {
                if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                    rosterItem.cc_student_pic_url = await getRosterImage(rosterItem.cc_student_pic_url);
                }
            }
        }
        // ---Fatch Firebase Image---
            
            this.setState({
                statesData : getRosterData,
                isLoading : false
            },() => {
            })
        }
       
        
    }
    // ---------componentWillReceiveProps-------



    // ---------Load More List-------
    loadMore() {
        let value = this.state.loadMoreCount;
        value++
        this.setState({
            loadMoreCount: value,
            loadMoreCountStatus: true,
        }, () => {
            this.getClassRoster()
        })
    }
    // ---------Load More List-------

    BackTOHome = () => {
        this.props.history.goBack();
    }

    handleClosePopUp = (e) => {
        this.setState({
            showPopUp: false,
        })
    }

    // ---------getRosterList-------
    getClassRoster = () => {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { classDetail = '', academicYearId = '', academicYear = '' } = state;
        this.setState({
            ClassDetails: classDetail,
            academicYearId: academicYearId,
            academicYear: academicYear
        })
        const { filter, loadMoreCount } = this.state
        const value = {
            class_id: classDetail.cc_class_id,
            filter_by: filter,
            page_no: loadMoreCount,
            academic_year: academicYearId,
            search_data : '',
            orderBy : this.state.orderBy,
            limit: this.state.numBoxes
        }
        this.props.getClassRosterList(value)
    }
    // ---------getRosterList-------
    


    // ---------Create New Roster-------
    createNewRoster = (tab) => {
        if (this.state.showLoaderUpload) {
            createNotification('error', 'The image is still uploading.');
            return;
        }
        const { location = {} } = this.props
        const { state = {} } = location;
        const { classDetail = '', academicYearId = '' } = state;
        const { FirstName,
            LastName,
            Email,
            UserProfile,
            UserProfileSize,
            filter } = this.state
        this.setState({
            isAddStudentSubmit: true,
            tabButton: tab
        })

        if (!FirstName || !LastName) {
            return false;
        }
        if (Email) {
            const valid = /^(?![.])[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?:\.[A-Za-z]{2,5})?$/;
            if (!valid.test(Email)) {
                this.setState({
                    InvelidMessageEmail: 'Please enter valid email*',
                });
                return false;
            }
        }
        const value = {
            "academic_year_id": academicYearId,
            "student_pic_size": UserProfileSize,
            "student_first_name": FirstName,
            "student_last_name": LastName,
            "student_registration_email_id": Email,
            "student_secondary_email_id": '',
            "student_pic_url": UserProfile,
            "student_id": '',
            "class_id": classDetail.cc_class_id,
            "by_class_sharing": '',
            "filter_by": filter,
            "page_no": 1,
            "academic_year": academicYearId,
            "limit": this.state.numBoxes
        }
        window.$('#addClassRosterModal').modal('hide');
        this.setState({
            loadMoreCount: 1,
            loadMoreCountStatus: false,
        })
        this.props.addClassRoster(value)

    }
    // ---------Create New Roster-------


    // ---------Delete Student-------
    deleteRoster = (id) => {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { classDetail = '', academicYearId = '' } = state;
        this.setState({
            loadMoreCount: 1,
            loadMoreCountStatus: false,
        }, () => {
            const { filter, loadMoreCount } = this.state
            const value = {
                "student_id": id,

                "class_id": classDetail.cc_class_id,
                "filter_by": filter,
                "page_no": loadMoreCount,
                "academic_year": academicYearId,
                "fromViewSingleClass" : true,
                "limit": this.state.numBoxes
            }
            this.setState({
                RemoveRoster: '',
                isDeleted: false
            })
            this.props.deleteClassRoster(value);

        })

    }
    // ---------Delete Student-------

    openNotes = (item) => {
        let { ClassDetails, academicYear } = this.state;
        const { location = {} } = this.props
        const { state = {} } = location;
        const { classPermission = '', ClassData = '' } = state;
        sessionStorage.setItem("selectedprofile", JSON.stringify(item));
        sessionStorage.setItem("selectedYear", JSON.stringify(academicYear))
        sessionStorage.setItem('classNameForNotes',ClassData?.cc_class_name)
        this.props.history.push(routes.STUDENTNOTE, { studentInfo: item, academicYear: academicYear, academicYearIDFromClassNote : this.props?.history?.location?.state?.academicYearId, rosterClassId: ClassDetails.cc_class_id, pageBYClassRoster: true, pageBYClass: true, BackPage: 'Class Roster', classPermission: classPermission, ClassData: ClassData, directAdd: 'directAddNo' })
    }

    // ---------Edit Level and Score modal open-------
    EditLevel = (item, index) => {
        this.setState({
            EditModal: !this.state.EditModal,
            SelectedEditRoster: item.cc_student_id,
            SelectedEditRosterIndex: index,
            ModalLevel: item.cc_note_level,
            ModalScore: item.cc_note_score,
            isAddStudentSubmit: false,
        })
    }
    // ---------Edit Level and Score modal open-------


    // ---------Update Level and Score-------
    updateScoreLevel = () => {
        this.setState({
            isAddStudentSubmit: true
        })
        const { SelectedEditRoster,
            ModalScore,
            ModalLevel,
            filter,
            loadMoreCount,
            EditModal } = this.state
            
       if(ModalLevel?.length != 0 || ModalScore?.length !=0) {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { classDetail = '', academicYearId = '' } = state;
        this.setState({
            loadMoreCount: 1,
            loadMoreCountStatus: false,
        }, () => {
            const data = {
                "student_id": SelectedEditRoster,
                "student_details_array": [{
                    "student_score": ModalScore || "",
                    "student_level": ModalLevel || ""
                }],
                "screen_name": "class_roster",

                "class_id": this.props?.history?.location?.state?.classDetail?.cc_class_id,
                "filter_by": filter,
                "orderBy" : this.state.orderBy,
                "page_no": loadMoreCount,
                "academic_year": this.props?.history?.location?.state?.academicYearId,
                "limit": this.state.numBoxes
            }
            this.setState({
                // EditModal: !EditModal,
                isAddStudentSubmit: false
            })
            this.setState({
                schoolPopOverId :null
            })
            this.props.updateRosterLevel(data)
        })
    } else {
        console.log('error')
    }
    }
    // ---------Update Level and Score-------

    componentDidUpdate(prevProps, prevState) {

        if (prevState.schoolPopOverId !== this.state.schoolPopOverId) {
          this.forceUpdate();
    
          if (!this.state.schoolPopOverId) {
            this.setState({
              updateLevelScore: false,
              schoolPopOverId: null
            });
          }
        }

        if (this.state.numBoxes !== prevState.numBoxes) {
            // this.getData();
            if(this.state.numBoxes > 0) {
                this.getClassRoster()
            }
          }
      


      }

    handleParentScroll = () => {
        console.log('handlescroll')
       
        const { scrollTop, clientHeight, scrollHeight } = this.classRosterDataRef?.current;
    //     console.log(scrollTop + clientHeight == scrollHeight)
    //     setStudentDivHeight(clientHeight)
    //     console.log(177, ClassOverAllCount?.[0]?.count)
       if(!this.state.searchBar) { 
        if(this.state.ClassRosterList && this.state.ClassRosterList.length != this.state?.rosterCountWithoutSsearch?.[0]?.count) {
        if (parseInt(scrollTop) + clientHeight == scrollHeight) {
          console.log('ifscroll')
          const { location = {} } = this.props
          const { state = {} } = location;
          const { classDetail = '', academicYearId = '', academicYear = '' } = state;
          this.setState({
              ClassDetails: classDetail,
              academicYearId: academicYearId,
              academicYear: academicYear
          })
          const { filter, loadMoreCount } = this.state
          let page = this.state.loadMoreCount
          page++
          this.setState({
            loadMoreCount : page,
            loadMoreCountStatus: true
          })
          const value = {
              class_id: classDetail.cc_class_id,
              filter_by: filter,
              page_no: page,
              academic_year: academicYearId,
              search_data : '',
              orderBy : this.state.orderBy,
              limit: this.state.numBoxes
          }
          this.props.getClassRosterList(value)

        //   pageSet()
        //   console.log(numBoxes)
        //   console.log(223,loadMoreCount)
        //   const { data = "" } = history.location.state;
        //   console.log(227,pageNoData)
        //   setLoadMoreCount(pageNoData)
        //   setLoadMoreCountStatus(true);
        //   setAcademicYear(data.academic_year);
        //   setAcademicYearId(data.academic_year_id);
        //   // this.setState({
        //   //     academicYear: data.academic_year,
        //   //     academicYearId: data.academic_year_id
        //   // })
        //   // const { filter, loadMoreCount } = this.state
          
        //   const value = {
        //     filter_by: filter,
        //     page_no: pageNoData,
        //     class_academic_year: data.year_ids,
        //     limit : numBoxes
        //   };
        //   console.log(201, value)
        //     dispatch(getClassList(value));
         
        }
      }
    } else {
        if(this.state.ClassRosterList && this.state.ClassRosterList.length != this.state?.RosterOverAllCount?.[0]?.count) {
            if (parseInt(scrollTop) + clientHeight == scrollHeight) {
              console.log('ifscroll')
              const { location = {} } = this.props
              const { state = {} } = location;
              const { classDetail = '', academicYearId = '', academicYear = '' } = state;
              this.setState({
                  ClassDetails: classDetail,
                  academicYearId: academicYearId,
                  academicYear: academicYear
              })
              const { filter, loadMoreCount } = this.state
              let page = this.state.loadMoreCount
              page++
              this.setState({
                loadMoreCount : page,
                loadMoreCountStatus: true
              })
              const value = {
                  class_id: classDetail.cc_class_id,
                  filter_by: filter,
                  page_no: page,
                  academic_year: academicYearId,
                  search_data : this.state.searchText,
                  orderBy : this.state.orderBy,
                  limit: this.state.numBoxes
              }
              this.props.getClassRosterList(value)
    
            //   pageSet()
            //   console.log(numBoxes)
            //   console.log(223,loadMoreCount)
            //   const { data = "" } = history.location.state;
            //   console.log(227,pageNoData)
            //   setLoadMoreCount(pageNoData)
            //   setLoadMoreCountStatus(true);
            //   setAcademicYear(data.academic_year);
            //   setAcademicYearId(data.academic_year_id);
            //   // this.setState({
            //   //     academicYear: data.academic_year,
            //   //     academicYearId: data.academic_year_id
            //   // })
            //   // const { filter, loadMoreCount } = this.state
              
            //   const value = {
            //     filter_by: filter,
            //     page_no: pageNoData,
            //     class_academic_year: data.year_ids,
            //     limit : numBoxes
            //   };
            //   console.log(201, value)
            //     dispatch(getClassList(value));
             
            }
          }
    }
      }  



    // ---------Image Upload on Firebase-------
    handleChangeFile = (e) => {
        if (e.length === 0) {
            return false;
        }
        const data = JSON.parse(sessionStorage.getItem('UserData'))
        const imageAsFile = e[0];
        this.setState({
            isAddStudentSubmit: false,
            InvelidMessageEmail: '',
            showLoaderUpload: true
        })

        // ---------Image Type-------
        const ImageTypeArray = imageAsFile.name.split(".")
        const ImageType = ImageTypeArray[ImageTypeArray.length - 1];
        const media_Size = (imageAsFile.size / 1000000);
        // ---------Image Type-------

        // ---------DummyName-------
        const GetRendomName = getRandomName()
        const DummyName = GetRendomName + '.' + ImageType;
        // ---------DummyName-------

        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@")
        const FolderName = FinfFolderNameArray[0]
        // ---------FolderName-------


        const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/roster/${DummyName}`).put(imageAsFile)
        uploadTask.on('state_changed',
            (snapShot) => {

            }, (err) => {
                this.setState({
                    showLoaderUpload: false
                })
                createNotification('success', 'The image was not uploaded. Please try again.');
            }, () => {
                storage.ref(`${FirebseStroageFolder}/${FolderName}/roster/`).child(DummyName).getDownloadURL()

                    .then(fireBaseUrl => {
                        this.setState({
                            showLoaderUpload: false
                        })
                        createNotification('success', 'Great! The Image Upload was successful!');
                        this.setState({
                            RosterProfileURL: fireBaseUrl,
                            UserProfile: `${FirebseStroageFolder}/${FolderName}/roster/${DummyName}`,
                            UserProfileSize: media_Size,
                        }, () => {
                        })
                    })
            })
    }
    // ---------Image Upload on Firebase-------
    handleClick = event => {
        const { target = {} } = event || {};
        target.value = "";
    };

    // ---------handleChange-------
    handleChange = (event) => {
        
        if (event.target.name === "ModalLevel") {
            const scoreValue = /^[a-zA-Z0-9]+$/;
            if (event.target.value.length < 3 && (!event.target.value || event.target.value.match(scoreValue))) {
                this.setState(
                    {
                        isAddStudentSubmit: false,
                        InvelidMessageEmail: '',
                        [event.target.name]: event.target.value,
                    },
                    () => { }
                );
            }
        } else {
            // this.setState(
            //     {
            //         [event.target.name]: event.target.value,
            //         isSaved: false
            //     }
            // );
             if(event.target.name === "ModalScore"){
                const scoreValue = /^[0-9]{0,2}$/;
            if (event.target.value.length < 3 && (!event.target.value || event.target.value.match(scoreValue))) {
                this.setState(
                    {
                        [event.target.name]: event.target.value,
                        InvelidMessageEmail: '',
                        isAddStudentSubmit: false,
                    }
                );
            }
            else {
                this.setState(
                    {
                        [event.target.name]: "" })
              }
        }else {
            this.setState(
                {
                    [event.target.name]: event.target.value,
                    InvelidMessageEmail: '',
                    isAddStudentSubmit: false,
                }
            );
        }
        }

    }
    // ---------handleChange-------

    // ---------Auto search  -------
    handleSearchText = (event) => {
        this.setState({
            isAddStudentSubmit: false,
            InvelidMessageEmail: '',
            searchText: event.target.value,
            searchBar : true
        }
            , () => {
                const delayDebounceFn = setTimeout(() => {
                    // Send Axios request here
                    if (this.state.searchText.length > 2) {
                    const { location = {} } = this.props
                    const { state = {} } = location;
                    const { classDetail = '', academicYearId = '' } = state;
                    const { searchText } = this.state
                    let value = {
                        class_id: classDetail.cc_class_id,
                        // class_id: '',
                        // student_type: 'not_part_of_class',
                        search_data: searchText,
                        academic_year: academicYearId,
                        limit: this.state.numBoxes
                    }
                    this.props.searchClassRoster(value)

                } else {
                    this.setState({
                        // ClassRosterList: this.state.StudentsWithoutSearch,
                        searchBar : false
                    }
                    ,() => {
                        const value = {
                            class_id: this.state.ClassDetails.cc_class_id,
                            filter_by: this.state.filter,
                            page_no: 1,
                            academic_year: this.props?.history?.location?.state?.academicYearId,
                            search_data : '',
                            orderBy : this.state.orderBy,
                            limit: this.state.numBoxes
                        }
                        this.props.getClassRosterList(value)
                    }
                    )
                }
                  }, 1000)
        
                  return () => clearTimeout(delayDebounceFn)
                
            }
        )

        

        // if (event.target.value.length > 2) {
        //     const { location = {} } = this.props
        //     const { state = {} } = location;
        //     const { classDetail = '', academicYearId = '' } = state;
        //     const { searchText } = this.state
        //     let value = {
        //         class_id: classDetail.cc_class_id,
        //         // class_id: '',
        //         // student_type: 'not_part_of_class',
        //         search_data: searchText,
        //         academic_year: academicYearId
        //     }
        //     console.log(value)
        //     this.props.searchClassRoster(value)

        // } else {
        //     console.log(942, this.state.orderBy, event.target.value.length)
        //     this.setState({
        //         ClassRosterList: this.state.StudentsWithoutSearch,
        //         searchBar : false
        //     })
        //     //  {
        //         // const value = {
        //         //     class_id: this.state.ClassDetails.cc_class_id,
        //         //     filter_by: this.state.filter,
        //         //     page_no: 1,
        //         //     academic_year: this.props?.history?.location?.state?.academicYearId,
        //         //     search_data : '',
        //         //     orderBy : this.state.orderBy
        //         // }
        //         // this.props.getClassRosterList(value)
        //     // }
        //     // )
        // }

    }
    // ---------Auto search  -------

    // --------- handleClassImportFiles Roster  -------
    handleImportFiles = files => {
        try {
            var reader = new FileReader();

            reader.onload = (e) => {
                if (reader.result === undefined || reader.result === 'undefined' || reader.result === '') {
                    createNotification('error', 'Selected file is empty. Please select another csv file having content in it');
                    return;
                }
                const str = reader.result;
                const reg = new RegExp(/(\r\n?|\n|\t)/g);
                const student_Data = str.replace(reg, ',#*#');
                this.setState({
                    loadMoreCount: 1,
                    loadMoreCountStatus: false,
                }, () => {
                    const { location = {} } = this.props
                    const { state = {} } = location;
                    const { classDetail = '', academicYearId = '' } = state;
                    const { filter, loadMoreCount } = this.state
                    const value = {
                        class_id: classDetail.cc_class_id,
                        filter_by: filter,
                        page_no: loadMoreCount,
                        academic_year_id: academicYearId,
                        student_data: student_Data,
                    }
                    this.importCSVFile(value)
                })

            }
            reader.readAsText(files[0]);
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able to read File');
            return;
        }
    }
    // --------- handleClassImportFiles Roster  -------

    // ----------To Get Load For SignIN---------
    handleClientLoad = async () => {
        let GoogleAuth = ''
        gapi.load('client:auth2', () => {
            gapi.client.init({
                'apiKey': API_KEY,
                'clientId': CLIENT_ID,
                'scope': SCOPES,
                'discoveryDocs': DISCOVERY_DOCS
            }).then(() => {
                GoogleAuth = gapi.auth2.getAuthInstance()
                GoogleAuth.isSignedIn.listen(this.updateSigninStatus());

            });
        });
    };
    // ----------To Get Load For SignIN---------


    // ----------To SignIn In Google Drive---------
    handleAuthClick = (event) => {
        try {
            gapi.auth2.getAuthInstance().signIn().then((res) => {
                this.setSignedInUser(res)
            }).catch(function (e) {
                createNotification('error', e);
            })
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able Login');
            return;
        }
    };
    // ----------To SignIn In Google Drive---------



    // ----------To Update SignIn In Google Drive---------
    updateSigninStatus = (isSignedIn) => {
        try {
            if (isSignedIn) {
                this.setSignedInUser(gapi.auth2.getAuthInstance().currentUser.je);
            } else {
                this.handleAuthClick();
            }
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able Login');
            return;
        }
    };
    // ----------To Update SignIn In Google Drive---------


    // ----------To SignIn Auth Save for Local Google Drive---------
    setSignedInUser = (value) => {
        try {
            this.setState({
                GoogleAuth: true,
                GoogleAuthItem: value
            })
            sessionStorage.setItem('GoogleAuth', true)
            sessionStorage.setItem('GoogleAuthItem', JSON.stringify(value))
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able Login');
            return;
        }
    }
    // ----------To SignIn Auth Save for Local Google Drive---------

    // ----------To Get Load For SignOut---------
    handleSignOutClick = (event) => {
        try {
            gapi.load('client:auth2', () => {
                gapi.client.init({
                    'apiKey': API_KEY,
                    'clientId': CLIENT_ID,
                    'scope': SCOPES,
                    'discoveryDocs': DISCOVERY_DOCS
                }).then(() => {
                    var auth2 = gapi.auth2.getAuthInstance();
                    auth2.disconnect();
                    this.setState({
                        GoogleAuth: false,

                    })
                    sessionStorage.setItem('GoogleAuth', false)
                });
            });
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able Logout');
            return;
        }
    };
    // ----------To Get Load For SignOut---------

    // ----------To Get File From Drive ---------

    handleFileLoad = () => {
        window.$('#myModalRosterupload').modal('hide');
        const path = {
            name: 'My drive',
            id: 'root'
        }
        this.setState({
            googleDrivePath: []
        }, () => {
            this.getFolderFiles(path);
        })


    }
    // ----------To Get File From Drive ---------

    // ----------To Get File From Path ---------
    getFolderFilesPath = (item) => {
        let googleDrivePath = this.state.googleDrivePath;
        let CreatePath = [];
        for (let value of googleDrivePath) {
            if (value.id === item.id) {
                break;
            }
            else {
                const path = {
                    name: value.name,
                    id: value.id
                }
                CreatePath.push(path)
            }
        }
        this.setState({
            googleDrivePath: CreatePath
        }, () => {
            this.getFolderFiles(item);
        })

    }
    // ----------To Get File From Path ---------

    // ----------To Get File From Folder ---------
    getFolderFiles = (value) => {
        try {
            gapi.load('client:auth2', async () => {
                let query = `'${value.id}'  in parents and trashed=false`;
                let path = {
                    name: value.name,
                    id: value.id
                }
                let googleDrivePath = this.state.googleDrivePath;
                googleDrivePath.push(path)
                const uniqueArray = Array.from(
                    new Set(googleDrivePath.map((item) => item.id))
                  ).map((id) => {
                    return googleDrivePath.find((item) => item.id === id);
                  });
                  const finalBreadCrumbArr = uniqueArray.slice(
                    0,
                    uniqueArray.findIndex((item) => item.id === path.id) + 1
                  );
                this.setState({
                    SelectFileModal: true,
                    GetFileLoader: true,
                    googleSelectedFile: '',
                    GoogleFiles: [],
                    googleDrivePath: finalBreadCrumbArr
                })
                await gapi.client.init({
                    'apiKey': API_KEY,
                    'clientId': CLIENT_ID,
                    'scope': SCOPES,
                    'discoveryDocs': DISCOVERY_DOCS
                }).then(() => {
                    gapi.client.drive.files
                        .list({
                            pageSize: 1000,
                            fields: 'nextPageToken, files(id,size, name, mimeType, fileExtension, modifiedTime, webViewLink)',
                            q: query
                        }).then((res) => {
                            let GoogleFiles = []
                            for (let item of res.result.files) {
                                if (item.fileExtension) {
                                    if (item.fileExtension === "csv") {
                                        GoogleFiles.push(item)
                                    }
                                }
                                let type = item.mimeType
                                let typeCheck = type.split('.');
                                if (typeCheck[typeCheck.length - 1] === 'folder') {
                                    item.fileExtension = 'folder';
                                    GoogleFiles.push(item)
                                }


                            }
                            this.setState({
                                GoogleFiles: GoogleFiles,
                                GetFileLoader: false,
                            }, () => {
                            })
                        });
                });

            });
        } catch (e) {
            this.setState({
                GoogleFiles: [],
                GetFileLoader: false,
            })
        }
    }
    // ----------To Get File From Folder ---------

    // ----------To Get File Read from Drive ---------
    getFileDetails = () => {
        try {
            this.setState({
                importLoader: true
            })
            gapi.load('client:auth2', () => {
                const data = this.state.googleSelectedFile;
                gapi.client.init({
                    'apiKey': API_KEY,
                    'clientId': CLIENT_ID,
                    'scope': SCOPES,
                    'discoveryDocs': DISCOVERY_DOCS
                }).then(async () => {
                    const file = data
                    const url = `https://www.googleapis.com/drive/v2/files/${data.id}?alt=media`;
                    if (file.webViewLink) {
                        var accessToken = gapi.auth.getToken().access_token;
                        var xhr = new XMLHttpRequest();
                        xhr.open('GET', url);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                        xhr.onload = () => {
                            if (xhr.status === 200) {
                                const str = xhr.responseText;
                                try {
                                    this.setState({
                                        importLoader: false
                                    })
                                    if (str === undefined || str === 'undefined' || str === '') {
                                        createNotification('error', 'Selected file is empty. Please select another csv file having content in it');
                                        this.setState({
                                            googleSelectedFile: ''
                                        })
                                        return;
                                    }
                                    const reg = new RegExp(/(\r\n?|\n|\t)/g);
                                    const student_Data = str.replace(reg, ',#*#');
                                    this.setState({
                                        loadMoreCount: 1,
                                        loadMoreCountStatus: false,
                                    }, () => {
                                        const { location = {} } = this.props
                                        const { state = {} } = location;
                                        const { classDetail = '', academicYearId = '' } = state;
                                        const { filter, loadMoreCount } = this.state;
                                        const value = {
                                            class_id: classDetail.cc_class_id,
                                            filter_by: filter,
                                            page_no: loadMoreCount,
                                            academic_year_id: academicYearId,
                                            student_data: student_Data,
                                        }
                                        this.importCSVFile(value)
                                    })

                                } catch (e) {
                                    createNotification('error', 'Something Went Wrong, Not able to read File');
                                    return;
                                }
                            } else {
                                createNotification('error', 'Something Went Wrong, Not able to fetch file Detail');
                                this.setState({
                                    GetFileLoader: false,
                                    importLoader: false
                                })
                                return;
                            }

                        };
                        xhr.onerror = function () {
                        };
                        xhr.send();
                    } else {
                    }
                })

            });
        } catch (e) {
            createNotification('error', 'Something Went Wrong, Not able to fetch file Detail');
            this.setState({
                GetFileLoader: false,
                importLoader: false
            })
            return;
        }
    }
    // ----------To Get File Read from Drive ---------

    // ----------To Send File Read Data In API ---------
    importCSVFile = (value) => {
        window.$('#myModalRosterupload').modal('hide');
        this.setState({
            SelectFileModal: false,
            GetFileLoader: false,
            googleSelectedFile: '',
            GoogleFiles: [],
            googleDrivePath: []
        })
        let data = value
        data.limit = this.state.numBoxes
        this.props.importClassRoster(data)

    }
    // ----------To Send File Read Data In API ---------

    render() {
        let { ClassRosterList,
            ClassDetails,
            RosterOverAllCount,
            GoogleAuthItem,
            GoogleFiles,
            GetFileLoader,
            googleDrivePath,
            importLoader, FirstName , LastName } = this.state;
        const { loader, getStudentGradebookNullRequest } = this.props;
        const { location = {} } = this.props
        const { state = {} } = location;
        const { classPermission = '' } = state;  
        let noteStrinf = "(Home>Class>Roster)"
        let driveEmail = '';
        if (GoogleAuthItem) {
            if (GoogleAuthItem.hasOwnProperty("wt")) {
                driveEmail = GoogleAuthItem.wt.cu
            } else if (GoogleAuthItem.hasOwnProperty("Qt")) {
                driveEmail = GoogleAuthItem.Qt.zu
            }
        }
        const disAddClassRoster = ["addNewClassRoster", classPermission !== null && classPermission === "read" ? "opacityProp" : ""]
        const deSelectedRosterButton = ["selectedRosterButton", classPermission !== null && classPermission === "read" ? "opacityProp" : ""]
        const disImportNewClassRoster = ["importNewClassRoster", classPermission !== null && classPermission === "read" ? "opacityProp" : ""]
        const disClassRosterOptionRemove = ["classRosterOptionRemove", classPermission !== null && classPermission === "read" ? "opacityProp" : ""]
        const disClassRosterOptionShare = ["classRosterOptionShare", classPermission !== null && classPermission === "read" ? "opacityProp" : ""]
        const _ = require('lodash');
        const saveButtonNW = [(FirstName != "" && LastName != "") ? 'saveButton align-items-center classAddRosterButtonDiv-saveButton' : 'saveButton saveButtonlessOpac align-items-center classAddRosterButtonDiv-saveButton']
        const addAnotherButtonNW = [(FirstName != "" && LastName != "") ? 'addAnotherButton align-items-center classAddRosterButtonDiv-saveButton' : 'addAnotherButton saveButtonlessOpac align-items-center classAddRosterButtonDiv-saveButton']

        return (
            <div className='classRosterContainer'>

                {/* ---------Class Roster Add ------ */}
                <div className="ClassRosterYearMainDiv">
                    {/* ---------Class Roster Details ------ */}
                    <div className="display-inline">
          <div className="rosterYearBackDiv-RD">
            <img
              className="calIcon-RD"
              src={back}
              alt=""
              width="60"
              height="40"
              onClick={() => {
                // goBackPrev();
                this.props?.history?.replace("/home/academic");
              }}
            />
          </div>
          <div className="totalStudentDiv-RD">
            <div style={{whiteSpace: "nowrap"}} className="attRosterYearDiv-RD singleClassYearDiv classRostersingleClassYearDiv">
              <p
                className="attClassGrade-RD"
                onClick={() => {
                //   goBackPrev();
                this.props?.history?.replace("/home/academic");
                }}
              >
             {this.props?.history?.location?.state?.academicYear}
              </p>
              <i className="rosterForIcon-RD material-icons ">chevron_right</i>
              
                                            <p className='attClassGrade-RD' onClick={() => {this.props?.history?.goBack()}}>Class</p>
                                            <i className="rosterForIcon-RD material-icons">chevron_right</i>
             <div className="d-flex flex-wrap">                           
              <p className="attClassGrade2-RD mb-0" style={{position : 'relative', top : 1}}> Class:  {ClassDetails.cc_class_name}</p>
                            </div>
            </div>
          </div>
        </div>
                    {/* <div className='classRosterDetailsMain'>
                        <div className="rosterClassBackDiv" onClick={() => { this.BackTOHome() }}>
                            <i className="rosterClassBackIcon material-icons">chevron_left</i>
                            <p className='rosterClassBactText'>Class</p>
                        </div>
                        <div className='rosterClassDetailTextDiv'>
                            <p className='rosterClassName'>{ClassDetails.cc_class_name}</p>
                            <div className='displayFlexClassRoster'>
                                <p className='rosterClassGrade'>Section: {ClassDetails.cc_class_section}</p>
                                <p className='rosterClassGrade'>Grade: {ClassDetails.cc_class_grade}</p>
                            </div>


                        </div>

                    </div> */}
                    {/* ---------Class Roster Details ------ */}


                    {/* ---------Class Roster New-------- */}
                    <div className='buttonMarginTop'>

                        {/* <Button className='searchButtonFilter' onClick={() => {
                            this.setState({
                                searchBar: true,
                                searchText: ''
                            })
                        }} color="primary"  >
                            <i className="fa fa-search searchFilterIcon"></i>
                        </Button> */}

                        {classPermission !== null && classPermission === "read" && JSON.parse(sessionStorage.getItem('UserData')).user_type != "school_teacher" ?

                            <Button className={disAddClassRoster.join(' ')} color="primary"  >
                                <img className="addNewClassRosterIcon" src={addRosterIcon} alt='' />
                                <span className='buttonClassRosterText' > Add New</span>
                            </Button> :
                            <Button onClick={() => {
                                this.setState({
                                    RosterProfileURL: '',
                                    UserProfile: '',
                                    FirstName: '',
                                    LastName: '',
                                    Email: '',
                                    InvelidMessageEmail: '',
                                    showLoaderUpload: false,
                                    isAddStudentSubmit: false
                                })
                            }} className={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? 'addNewClassRoster schltchroption' : "addNewClassRoster"}
                            data-toggle="modal"
                            // data-target="#addClassRosterModal"
                            data-target={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? '' : '#addClassRosterModal'}
                            color="primary"  >
                                <img className="addNewClassRosterIcon" src={addRosterIcon} alt='' />
                                <span className='buttonClassRosterText' >Student</span>
                            </Button>}


                        {classPermission !== null && classPermission === "read" && JSON.parse(sessionStorage.getItem('UserData')).user_type != "school_teacher"  ?
                            <Button className={deSelectedRosterButton.join(' ')} >
                                <img className="selectedRosterIcon" src={selectedRosterIcon} alt='' />
                                <span className='selectedRosterbuttonText' style={{whiteSpace : "nowrap"}}> Select Student(s)</span>
                            </Button> :
                            <Button className={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? 'selectedRosterButton schltchroption' : "selectedRosterButton"} 
                            // onClick={() => {
                            //     if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                            //         return;
                            //     } else {
                            //     // this.props.history.push(routes.CLASSROSTERSELECT, {
                            //     //     ClassDetails: this.state.ClassDetails,
                            //     //     academicYearId: this.state.academicYearId
                            //     // })
                            // }
                            // }}
                            data-toggle="modal"
                            data-target="#myModal3"
                            
                            >
                                <img className="selectedRosterIcon" src={selectedRosterIcon} alt='' />
                                <span className='selectedRosterbuttonText' style={{whiteSpace : "nowrap"}}> Select Student(s)</span>
                            </Button>}
                        {classPermission !== null && classPermission === "read" 
                        && JSON.parse(sessionStorage.getItem('UserData')).user_type != "school_teacher" ?
                            <Button className={disImportNewClassRoster.join(' ')}>
                                <img className="importNewClassRosterIcon" src={importRosterIcon} alt='' />
                                <span className='importButtonClassRosterText' > Import</span>
                            </Button> :
                            <Button className= {JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? 'importNewClassRoster schltchroption' : "importNewClassRoster"}
                            data-toggle="modal"
                            // data-target="#myModalRosterupload"
                            data-target={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? '' : '#myModalRosterupload'}
                             >
                                <img className="importNewClassRosterIcon" src={importRosterIcon} alt='' />
                                <span className='importButtonClassRosterText' > Import</span>
                            </Button>}
                    </div>
                    {/* ---------Class Roster New-------- */}

                </div>
                {/* ---------Class Roster Add  ------ */}

                <Card className="rst-refresh-section-RD br-8 totalStudentCardMarginTop">
          <Card.Body>
            <Row className="rst-filterRow-RD">
              <Col xs="12" md="5" lg="6" className="rst-desc-search-col-RD">
              <span className="rst-total-student-RD w-auto" style={{marginRight : 15}}>
              {/* <div style={{borderRight  :'1px solid lightgray', height : 34, marginRight : 15 , width : "159px"}} className='d-flex align-items-center'> */}
              <p style={{    whiteSpace: "nowrap", paddingRight: "9px", marginRight: "6px"}} className='rosterClassGrade rosterClassGradeMargin sectionGradeHeader'>
                                Section: {this?.state?.ClassDetails?.cc_class_section} <span className='ml-3'> Grade: {this?.state?.ClassDetails?.cc_class_grade}</span>
                            </p>
                {/* </div> */}
                </span>
                <span className="rst-total-student-RD w-auto">
              {/* <div style={{borderRight  :'1px solid lightgray', height : 34, marginRight : 15 , width : "159px"}} className='d-flex align-items-center'> */}
              <p style={{    whiteSpace: "nowrap", paddingRight: "9px", marginRight: "6px"}} className='rosterClassGrade rosterClassGradeMargin sectionGradeHeader'>
                                Total Students: {this.state.searchBar ? (this.state?.RosterOverAllCount?.[0]?.count || 0) : (this.state?.rosterCountWithoutSsearch?.[0]?.count || 0 )}
                            </p>
                {/* </div> */}
                </span>
                {/* <span className="rst-total-student-RD d-flex" style={{minWidth : 140}}>
                  Total Students:{" "}
                  <span style={{paddingRight : 15}}>{this.state.searchBar ? (this.state?.RosterOverAllCount?.[0]?.count || 0) : (this.state?.rosterCountWithoutSsearch?.[0]?.count || 0 )}</span>
                </span> */}
                {/* ---------------Filters------------ */}
                <div
                  className="searchFilter-RD classRosterSearchFilter"
                  onClick={() => {
                    // this.setState({
                    //     // searchBar: true,
                    //     // searchText: ''
                    // })
                  }}
                  style={{minWidth : 290}}
                >
                  <img
                    className="searchFilterIcon-RD"
                    src={iconSearch}
                    alt=""
                  />
                  <CommonTextField
                    margin="normal"
                    // variant="outlined"
                    type="FirstName"
                    // value={searchText}
                    // onChange={(event) => {
                    // //   handleSearchText(event);
                    // //   setSearchText(event.target.value);
                    // }}
                    name="searchText"
                    className="searchFilterInputBox-RD"
                    placeholder="Enter Keywords…"
                    value={this.state.searchText}
                    onChange={(event) => {this.handleSearchText(event)}}
                  />
                </div>
              </Col>
              <Col xs="12" md="7" lg="6" className="rst-sort-by-col-RD">
                <div className="rosterfilterDiv-RD">
                  <div className="rosterfilterDiv2-RD rosterFileter-CR" style={{alignItems : 'flex-end'}}>
                    <div className="totalStudentsDiv-RD" style={{position : 'relative', bottom : 8}}>
                      <div>
                        <p className="RosterYearSortNameText-RD sortByText-CR">Sort By </p>
                      </div>
                    </div>
                    <div style={{border : "1px solid rgba(0, 0, 0, 0.25)", height : '100%', opacity : "0.4"}}></div>
                    {this.state.orderBy === "firstName" ? (
                      <div
                        className="firstNameBlueActiveBtn-RD"
                        onClick={() => {
                        //   filterNameSelect("firstName");
                        this.setState({
                            orderBy : "firstName"
                        },() =>  {
                            this.getClassRoster()
                        })
                        }}
                        style={{marginLeft : 15}}
                      >
                        <p className="listAllActiveText-RD">First Name</p>
                      </div>
                    ) : (
                      <div
                        className="firstName-RD"
                        onClick={() => {
                        //   filterNameSelect("firstName");
                        this.setState({
                            orderBy : "firstName"
                        },() =>  {
                            this.getClassRoster()
                        })
                        
                        }}
                        style={{marginLeft : 15}}
                      >
                        <p className="listAllText-RD">First Name</p>
                      </div>
                    )}
                    {this.state.orderBy === "lastName" ? (
                      <div
                        className="lastNameBlueActiveBtn-RD"
                        onClick={() => {
                        //   filterNameSelect("lastName");
                        this.setState({
                            orderBy : "lastName"
                        },() => {
                            this.getClassRoster()
                        })
                       
                        }}
                      >
                        <p className="listAllActiveText-RD">Last Name</p>
                      </div>
                    ) : (
                      <div
                        className="lastName-RD"
                        onClick={() => {
                        //   filterNameSelect("lastName");
                        this.setState({
                            orderBy : "lastName"
                        },() => {
                            this.getClassRoster()
                        })
                        }}
                      >
                        <p className="listAllText-RD">Last Name</p>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

                {/* ---------------Search Filter ------------ */}
                {/* {this.state.searchBar && <div className="totalClassSearchDiv">
                    <div style={{ width: '90%' }}>
                        <div className="searchStudentdiv">
                            <div className="input-group-prepend border-0">
                                <button id="button-addon4" type="button" className="btn btn-link text-info searchIconDiv"><i className="fa fa-search searchIcon"></i></button>
                            </div>
                            <input
                                autoFocus
                                margin="normal"
                                type='text'
                                value={this.state.searchText}
                                onChange={this.handleSearchText}
                                name="searchText"
                                className='searchStudent'
                                placeholder="Search"
                            ></input>
                        </div>
                    </div>
                    <div>
                        <img src={cancelBlack} alt='' className='closeIconSearch' onClick={() => {
                            this.setState({
                                searchBar: false,
                                ClassRosterList: this.state.StudentsWithoutSearch

                            })
                        }} />
                    </div>
                </div>} */}
                {/* ---------------Search Filter ------------ */}

                {/* ------ Loader Div----- */}
                {loader && <div>
                    <img src={loaderImag} alt='' className='loaderIconCss' />
                </div>}
                {/* ------ Loader Div----- */}

                {/* -------Class Roster List------ */}
                {ClassRosterList.length > 0 ?
                <>
               <div
                ref={this.classRosterDataRef} 
                // // style={{height : "calc(100vh - 110px)"}}
                style={{
                  // height : StudentList.length > 0 ? `70vh` : "82vh", 
                //   height : ClassList.length > 0 ? `100vh` : "82vh",
                height : "calc(100vh - 200px)",
                // height : "300px",
                  overflowY : 'scroll',
                  overflowX : 'hidden'
                  }}  
                 onScroll={() => {
                    this.handleParentScroll()
                 }}
               >
               <div className='classRosterCardMainDiv row singleParentCard'>
                {ClassRosterList && ClassRosterList.length > 0 && ClassRosterList.map((item, index) => (
         
                <React.Fragment key={index}>
                    {this.state.RemoveRoster === item.cc_student_id ? 
                    <>
                    {/* <div className='deleteClassRosterCard'>
                        <p className='deleteClassRosterText'>Remove Roster</p>
                        <p className='deleteWorningClassRosterText'>Are you sure want to Remove all Roster data associated with the Class?</p>
                        <div className='deleteButtonsClassRosterDiv'>
                            <div className='cancelButtonClassRosterText' onClick={() => { 
                                // setRemoveRoster('')
                                this.setState({
                                    RemoveRoster : ''
                                })
                                 }}>
                                Cancel
                            </div>
                            <div>
                                <Button onClick={() => { deleteRoster(item.cc_student_id) }} className='deleteButtonClassRoster' color="primary" >
                                    <span className='deleteButtonClassRosterText' > Remove</span>
                                </Button>
                            </div>
                        </div>
                    </div> */}
                    </>
                        : 
                        <div className=' col-xs-12 col-sm-12 col-lg-3 col-md-3'>
                        <div style={{height : "4.8rem"}} className='classRosterCardDiv singleClassRosterCardDiv'>
                            <div className='classRosterDetailsCard' style={{padding : 0}}>
                                <div className='classRosterProfileImageDiv d-flex justify-content-center align-items-center'>
                                    {item.cc_student_pic_url ? <img src={item.cc_student_pic_url} alt='' className='studentProfileImage' /> :
                                        <div className='imageDummyDivProfile d-flex justify-content-center align-items-center' style={{marginLeft : 0, marginTop : 0}}>
                                            <h5 className='profileImageDummyText mb-0 pt-0'>{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
                                        </div>}
                                </div>
                                <div className='classRosterTextInfoDiv d-flex flex-column justify-content-center flex-wrap mt-0'>
                                    <p 
                                    // style={item.cc_student_email_id == "" ? {paddingTop : "8px"} : {paddingTop : "0px"}} 
                                    className='classRosterName'>{item.cc_student_last_name?.replace(/(.{10})..+/, "$1…")}, {item.cc_student_first_name?.replace(/(.{10})..+/, "$1…")}</p>
                                    {item.cc_student_email_id.length > 30 ? <p data-title={item.cc_student_email_id}>
                                        {_.truncate(item.cc_student_email_id, {
                                            'length': 30,
                                            'omission': '...'
                                        })}</p> : <p className='classRosterEmail'>{item.cc_student_email_id}</p>}
                                    {/* <div onClick={() => { EditLevel(item, index) }} className='displayFlexClassRoster'>
                                        <i className="classRosterEditicon material-icons">edit</i>
                                        <p className='classRosterEmail'>Level: {item.cc_note_level === '' ? 0 : item.cc_note_level} | Score: {item.cc_note_score === '' ? 0 : item.cc_note_score}</p>
                                    </div> */}
                                </div>
                                <div
                              id={`PopoverLegacy${index}`}
                              type="button"
                              // onClick={() => editStudent(item.cc_student_id)}
                              onClick={() => {
                                // setSchoolPopOverId(item.cc_student_id)
                                this.setState({
                                    schoolPopOverId : item.cc_student_id,
                                    SelectedEditRoster : item.cc_student_id
                                })
                                // setSelectedEditRoster(item.cc_student_id);
                              }}
                              className="studentMenuIcon-RD"
                              // style={{ display: this.state.isHovered[index] ? this.state.isHovered[index] : 'none' }}
                            >
                              <i className="material-icons iconhover">more_vert</i>
                              {/* <UncontrolledPopover
                                // trigger="legacy"
                                placement="right-start"
                                isOpen={item.editOpen}
                                target={`PopoverLegacy${index}`}
                                toggle={() => editStudent(item.cc_student_id)}
                                style={{ borderRadius: 20 }}
                                className="popover academicPopOver-RD"
                              >
                                <PopoverBody> */}
                                {
                                  item.cc_student_id == this.state.schoolPopOverId &&
                                <div className={`singleClassPopoverParentDiv`}>
                                  <div 
                                  className={`popover ${this.state.isDeleted || this?.ref?.current?.style?.display == 'none' ? '' :  `${(index + 1) % 4 == 0 ? `singleClassPopoverParentDiv-CR` : 'singlClassPopover-RD'}`}
                                   
                                   
                                   `}
                                // className={`popover ${this.state.isDeleted ? ''  : 'singleClassPopoverParentDiv-CR'} `}
                                   >
                                  <div className="editInputDiv-RD text-center" ref={this.ref}>
                                    <div className="inputMaskUpdateParentDiv-RD">
                                      <div className="inputMaskUpdateDiv-RD">
                                        <div className="studentOptionDiv-RD">
                                        <div className='levelEditmainFieldDiv align-items-center singleClassLevelEditField'>
                                          {
                                            !this.state.updateLevelScore ? 
                                            <>
                                            <p className='inputFieldLabelLevelEditText'>Level : {item.cc_note_level}</p>
                                <p className='inputFieldLabelLevelEditText'>Score : {item.cc_note_score}</p>
                                            </> : 
                                            <>
                                            <div className='d-flex align-items-center'>Level: <CommonTextField
                                    style={{marginLeft : 11, marginBottom : 0}}
                                    margin="normal"
                                    variant="outlined"
                                    type='text'
                                    value={this.state.ModalLevel}
                                    defaultValue={item.cc_note_level ? item.cc_note_level : ""}
                                    // onChange={(event) => handleChangeLevel(event)}
                                    onChange={(event) => {this.handleChange(event)}}
                                    name="ModalLevel"
                                    // className="InfoInputType"
                                    className="InfoInputTypee"
                                    // placeholder="Enter Level" onClick={() => {setSchoolPopOverId(item.cc_student_id)}}
                                    />
                                    {/* {(!modalLevel ) && <p className="errormessageRegister">Please enter level*</p>} */}
                                     </div>

                                     <div className='d-flex align-items-center'>Score:  <CommonTextField
                                    margin="normal"
                                    variant="outlined"
                                    type='text'
                                    value={this.state.ModalScore}
                                    defaultValue={item.cc_note_score ? item.cc_note_score : ""}
                                    // onChange={handleChangeScore}
                                    onChange={(event) => {this.handleChange(event)}}
                                    name="ModalScore"
                                    // className="InfoInputType"
                                    className="InfoInputTypee"
                                    // placeholder="Enter Score"
                                     />
                                    {/* {(!modalScore ) && <p className="errormessageRegister">Please enter score*</p>} */}
                                    </div>
                                            </>
                                          }
                                          <div className='d-flex justify-content-around' style={{width : !this.state.updateLevelScore ? 'auto' : 60}}>
                                            {
                                              !this.state.updateLevelScore ?  
                                              <i className="editClassIcon material-icons" style={{fontSize : 20}} onClick={() => { 
                                                // setUpdateLevelScore(true); 
                                                // setModalScore(item.cc_note_score); 
                                                // setModalLevel(item.cc_note_level)
                                                this.setState({
                                                    updateLevelScore : true,
                                                    ModalScore : item.cc_note_score,
                                                    ModalLevel : item.cc_note_level
                                                })
                                             }}>edit</i>
                                                :
                                                <>
                                                <ClearIcon onClick={() => { 
                                                    // setUpdateLevelScore(false); 
                                                    // setModalLevel(""); 
                                                    // setModalScore("") 
                                                    this.setState({
                                                        updateLevelScore : false,
                                                        ModalLevel : "",
                                                        ModalScore : ""
                                                    })
                                                    }} style={{color : 'red'}}/>
                                     <CheckIcon onClick={() => {
                                        //  updateScoreLevel(item); 
                                        this.updateScoreLevel()
                                        
                                         this.ref.current.style.display = 'none' }} style={{color  :"#08B596"}}/>
                                                </>
                                            }
                                
                                        
                                    
                                     
                                      </div>
                            {/* <div className='inputFieldAdditionalModal'> */}
                                {/* <CommonTextField
                                    
                                    margin="normal"
                                    variant="outlined"
                                    // type='number'
                                    value={modalLevel || item.cc_note_level}
                                    onChange={handleChangeLevel}
                                    name="ModalLevel"
                                    // className="InfoInputType"
                                    className="InfoInputTypee"
                                    placeholder="Enter Level" onClick={() => {setSchoolPopOverId(item.cc_student_id)}}/> */}
                                {/* {(!modalLevel && isAddStudentSubmit) && <p className="errormessageRegister">Please enter level*</p>} */}
                            {/* </div> */}

                            {/* <div className='inputFieldLabelLevelEdittt'> */}
                                {/* <p className='inputFieldLabelLevelEditText'>Score : 22</p> */}
                            {/* </div> */}
                            {/* <div className='inputFieldAdditionalModal'>
                                <CommonTextField
                                    margin="normal"
                                    variant="outlined"
                                    type='number'
                                    value={modalScore || item.cc_note_score}
                                    onChange={handleChangeScore}
                                    name="ModalScore"
                                    // className="InfoInputType"
                                    className="InfoInputTypee"
                                    placeholder="Enter Score" />
                                {(!modalScore && isAddStudentSubmit) && <p className="errormessageRegister">Please enter score*</p>}
                            </div> */}
                        </div>
                  
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                           

                                            {/* ------------Attendance Div----------  */}

                                            {/* ------------Notes Div----------  */}
                                            <div
                                              className="optionBtnDiv-RD noteOptionBtn-RD"
                                              onClick={() => {
                                                // openNotes(item)
                                                this.openNotes(item, this.state.academicYear)
                                            }}
                                            >
                                              <img
                                                src={iconNotes} 
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p className="optionText-RD">
                                                Notes
                                              </p>
                                            </div>

                                            {/* ------------Notes Div----------  */}

                                            {/* ------------Class Div----------  */}
                                          

                                            {/* ------------Class Div----------  */}

                                            {/* ------------GradeBook Div----------  */}
                                            <div
                                              className="optionBtnDiv-RD gradebookOptionBtn-RD"
                                              onClick={() => {

                                                if (item.is_associated_with_class === 'no') {
                                                    createNotification('error', 'This student is not associated with any Class.');
                                                    return;
                                                }
                                                getStudentGradebookNullRequest()
                                                this.props.history.push(routes.STUDENTGRADEBOOK, { id: item.cc_student_id, academicYear: this.state.academicYear, classId: this.state.ClassDetails.cc_class_id, studentInfo: item, classPermission: classPermission })                                            }}
                                            >
                                              <img
                                                src={iconGradeBook}
                                                alt=""
                                                className="optionGreadeBookIcons-RD"
                                              />
                                              <p className="optionText-RD">
                                                GradeBook
                                              </p>
                                            </div>
                                            {(item.cc_student_share_permission !==
                                              null &&
                                            item.cc_student_share_permission ===
                                              "read" 
                                              || ClassDetails.cc_class_share_permission !== null && (ClassDetails.cc_class_share_permission === "read" || ClassDetails.cc_class_share_permission == 'collaborate')
                                              ) 
                                              ? (
                                              <div
                                                className="disShareOptionDiv-CRD"
                                                style={{ margin: "5px",
                                                //  opacity : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" 
                                                // || ClassDetails.cc_class_share_permission !== null && ClassDetails.cc_class_share_permission === "read"
                                                // ) && '0.5', 
                                                // cursor : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'not-allowed'
                                             }}
                                                // disabled={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? true : false}
                                                onClick= {() => {
                                                    // ClassDetails.cc_class_share_permission == null && ClassDetails.cc_class_share_permission !== "read" &&
                                                // history.push(routes.CLASSSHARE, {
                                                //     classDetail: data
                                                // })
                                                this.props.history.push(routes.STUDENTSHARE, {
                                                    studentInfo: item.cc_student_id,
                                                    classDetail : ClassDetails
                                                });
                                             } }
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                            ) : (
                                              <div
                                                className="shareOptionDiv-RD"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                //   opacity : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && '0.5',
                                                //   cursor : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'not-allowed'
                                                }}
                                                onClick= {() => {
                                                    // ClassDetails.cc_class_share_permission == null && ClassDetails.cc_class_share_permission !== "read" &&
                                                // history.push(routes.CLASSSHARE, {
                                                //     classDetail: data
                                                // })
                                                this.props.history.push(routes.STUDENTSHARE, {
                                                    studentInfo: item.cc_student_id,
                                                    classDetail : ClassDetails
                                                });
                                             } }
                                                // disabled={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? true : false}
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                            )}
                                            {/* ------------GradeBook Div----------  */}
                                          </div>
                                        
                                          {/* </div> */}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "start",
                                              // paddingLeft : "8px"
                                            }}
                                          >
                                            {/* ------------Share Div----------  */}
                                            {/* {item.cc_student_share_permission !==
                                              null &&
                                            item.cc_student_share_permission ===
                                              "read" ? (
                                              <div
                                                className="disShareOptionDiv-RD"
                                                style={{ margin: "5px" }}
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                            ) : (
                                              <div
                                                className="shareOptionDiv-RD"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                }}
                                                onClick= {() => {data.cc_class_share_permission == null && data.cc_class_share_permission !== "read" &&
                                                history.push(routes.CLASSSHARE, {
                                                    classDetail: data
                                                }) } }
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                            )} */}
                                            {/* ------------Share Div----------  */}

                                            {/* ------------Profile Div----------  */}
                                            {/* <div
                                              className="optionBtnDiv-RD profileOptionBtn-RD"
                                            //   onClick={() => {
                                            //     // if (item.is_associated_with_class === 'no') {
                                            //     //     createNotification('error', 'This student is not associated with any Class.');
                                            //     //     return;
                                            //     // }
                                            //     history.push(
                                            //       routes.STUDENTPROFILE,
                                            //       {
                                            //         id: item.cc_student_id,
                                            //         studentPermission:
                                            //           item.cc_student_share_permission,
                                            //       }
                                            //     );
                                            //   }}
                                            >
                                              <img
                                                src={iconProfile}
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p
                                                className="optionText-RD"
                                                style={{ color: "#386CB5" }}
                                              >
                                                Profile
                                              </p>
                                            </div> */}

                                            {/* ------------Profile Div----------  */}

                                            {/* ------------Delete Div----------  */}
                                            {(item.cc_student_share_permission !==
                                              null &&
                                            item.cc_student_share_permission ===
                                              "read" 
                                              || ClassDetails.cc_class_share_permission !== null && ClassDetails.cc_class_share_permission === "read"
                                              ) ? (
                                              <div className="optionBtnDiv-RD deleteOptionBtn-RD"
                                              style={{display : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'none'}}
                                              >
                                                <img
                                                  src={deleteIcon2}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                  style={{
                                                  opacity : (item.cc_student_share_permission !==
                                                    null &&
                                                  item.cc_student_share_permission ===
                                                    "read" 
                                                    || ClassDetails.cc_class_share_permission !== null && ClassDetails.cc_class_share_permission === "read"
                                                    ) && '0.5',
                                                    cursor : ((item.cc_student_share_permission !==
                                                      null &&
                                                    item.cc_student_share_permission ===
                                                      "read") 
                                                      || (ClassDetails.cc_class_share_permission !== null && ClassDetails.cc_class_share_permission === "read")
                                                      ) && 'pointer'
                                                }}
                                                />
                                                <p
                                                  className="optionText-RD"
                                                  style={{ color: "#FF4444" }}
                                                >
                                                  Delete
                                                </p>
                                              </div>
                                            ) : (
                                              <div
                                                // onClick={() => {
                                                //   deletePopoverHandler(
                                                //     item.cc_student_id
                                                //   );
                                                //   setSelectStudentId(
                                                //     item.cc_student_id
                                                //   );
                                                //   setSelectIndex(index);
                                                  // this.setState({selectStudentId: item.cc_student_id});
                                                  // this.setState({selectIndex: index}); }}
                                                // }}
                                                // onClick={() => {
                                                //     // setIsDeleted3(true)
                                                //     // setDeleteItem3(item.cc_student_id)
                                                //     // setSchoolPopOverId(null);
                                                //     //     forceUpdate()
                                                //     // setSchoolPopOverId((prev) => console.log(prev), console.log(item.cc_student_id))
                                                // }} 
                                                onClick={() => { this.ref.current.style.display = 'none'; 
                                                // setIsDeleted3(true); 
                                                // setDeleteItem3(item.cc_student_id)
                                                this.setState({
                                                    isDeleted3 : true,
                                                    deleteItem3 : item.cc_student_id,
                                                    isDeleted: true,
                                                    deleteStudentId: item.cc_student_id
                                                })
                                             }}
                                                className="optionBtnDiv-RD deleteOptionBtn-RD"
                                                style={{display : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'none'}}
                                              >
                                                <img
                                                  src={deleteIcon2}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p
                                                  className="optionText-RD"
                                                  style={{ color: "#FF4444" }}
                                                >
                                                  Deletee
                                                </p>
                                              </div>
                                            )}

                                            {/* ------------Delete Div----------  */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                                  </div>
                                }
                                {/* </PopoverBody>
                              </UncontrolledPopover> */}
                            </div>
                            </div>
                            
                            {/* <div className='classRosterAllOptionDiv'>
                                <div className='classRosterOptionNote' onClick={() => {
                                    openNotes(item)
                                }}>
                                    <img src={noteIcon} alt='' className='classRosterOptionIcons' />
                                    <p className='classRosterOptionText'>Notes</p>
                                </div>

                                <div className='classRosterOptionGradebook' onClick={() => {

                                    if (item.is_associated_with_class === 'no') {
                                        createNotification('error', 'This student is not associated with any Class.');
                                        return;
                                    }
                                    getStudentGradebookNullRequest()
                                    history.push(routes.STUDENTGRADEBOOK, { id: item.cc_student_id, academicYear: AcademicData.academic_year, classId: data.cc_class_id, studentInfo: item, classPermission: data.cc_class_share_permission })
                                }}>
                                    <img src={gradebookIcon} alt='' className='classRosterOptionIcons' />
                                    <p className='classRosterOptionText'>Gradebook</p>
                                </div>

                                {data.cc_class_share_permission !== null && data.cc_class_share_permission === "read" ?
                                    <div className={disClassRosterOptionShare.join(' ')}>
                                        <img src={userShareIcon} alt='' className='classRosterOptionIcons' />
                                        <p className='classRosterOptionText'>Share</p>
                                    </div> :
                                    <div className='classRosterOptionShare' onClick={() => {
                                        history.push(routes.STUDENTSHARE, {
                                            studentInfo: item.cc_student_id
                                        });
                                    }}>
                                        <img src={userShareIcon} alt='' className='classRosterOptionIcons' />
                                        <p className='classRosterOptionText'>Share</p>
                                    </div>}
                                {data.cc_class_share_permission !== null && data.cc_class_share_permission === "read" ?
                                    <div className={disClassRosterOptionRemove.join(' ')} >
                                        <img src={deleteIcon} alt='' className='classRosterOptionIcons' />
                                        <p className='classRosterOptionText'>Remove</p>
                                    </div> :
                                    <div className='classRosterOptionRemove' onClick={() => {
                                        setRemoveRoster(item.cc_student_id)
                                    }} >
                                        <img src={deleteIcon} alt='' className='classRosterOptionIcons' />
                                        <p className='classRosterOptionText'>Remove</p>
                                    </div>}

                            </div> */}
                        </div>
                        </div>
                        }
                </React.Fragment>))}
            </div>
               </div>
                </>
                 :
                    <div className='noRoster'
                    ref={this.noDataref}
                    style={{height : "800px"}}
                    >
                        {loader ? "" : 
                        <>
                         {this.state.searchBar && (!Array.isArray(this.state.ClassRosterList)) && <div><DivLoader /></div>}

                        <div data-toggle="modal" data-target="#addClassRosterModal">
                            <p className='tapToAddText'>{this.state.searchBar && (!Array.isArray(this.state.ClassRosterList)) ? "" : "Add Students to Class"}</p>
                            {!this.state.searchBar && <img src={addAcademicImage} alt='' className='addAcademicImage' />}
                        </div>
                        </>
                            }

                    </div>
                }
                {!this.state.searchBar && <div>
                    {/* {(RosterOverAllCount?.length > 0 && ClassRosterList?.length > 0) &&
                        <div style={{ padding: 5 }}>
                            {this.state.loadMoreCountStatus ? <Button className='loaderNewRoster loaderNewRoster-CR' color="primary" >
                                <img src={loaderImag} alt='' className='loaderIconCssAlreadySelected' />

                            </Button>
                                :
                                <div>
                                    {RosterOverAllCount && RosterOverAllCount[0].count > ClassRosterList.length &&
                                        <Button onClick={() => { this.loadMore() }} className='loaderNewRoster loadMoreRoster-RD loaderNewRoster-CR' color="primary" >
                                            <i className="material-icons" style={{fontSize : 20}}>autorenew</i>
                                            <span className='LoadButtonText LoadButtonText-RD' > Load More</span>


                                        </Button>}
                                </div>}
                        </div>} */}
                </div>}
                {/* -------Class Roster List------ */}

                {/* ---------------Modal for Link  Div--------------- */}
                <div>
                    <Modal size="sm" show={this.state.EditModal} centered={true}>


                        <Modal.Body className='levelModalMain'>
                            <div className='levelEditModalHeader'>
                                <p className='levelEditModalHeadind'>Update Level & Score</p>
                            </div>
                            <div className='levelEditmainFieldDiv'>
                                <div className='inputFieldLabelLevelEdit'>
                                    <p className='inputFieldLabelLevelEditText'>Level : </p>
                                </div>
                                <div className='inputFieldAdditionalModal'>

                                    <CommonTextField
                                        margin="normal"
                                        variant="outlined"
                                        // type='number'
                                        value={this.state.ModalLevel}
                                        onChange={this.handleChange}
                                        name="ModalLevel"
                                        className="InfoInputType"
                                        placeholder="Enter Level" />
                                    {(!this.state.ModalLevel && this.state.isAddStudentSubmit) && <p className="errormessageRegister">Please enter level*</p>}
                                </div>
                            </div>
                            <div className='levelEditmainFieldDiv'>
                                <div className='inputFieldLabelLevelEdit'>
                                    <p className='inputFieldLabelLevelEditText'>Score : </p>
                                </div>
                                <div className='inputFieldAdditionalModal'>

                                    <CommonTextField
                                        margin="normal"
                                        variant="outlined"
                                        // type='number'
                                        value={this.state.ModalScore}
                                        onChange={this.handleChange}
                                        name="ModalScore"
                                        className="InfoInputType"
                                        placeholder="Enter Score" />
                                    {(!this.state.ModalScore && this.state.isAddStudentSubmit) && <p className="errormessageRegister">Please enter score*</p>}
                                </div>
                            </div>


                            <div className='levelMainDivForCenter'>
                                <div className='levelModalCancelButtonDiv'>
                                    <div onClick={() => { this.setState({ EditModal: !this.state.EditModal }) }} className='linkCancelButton'>
                                        Cancel
                                    </div>
                                    <div>
                                        <Button onClick={() => { this.updateScoreLevel() }} className='levelModalUpdateButton' color="primary" >
                                            <span className='levelEditUpdateButtonText' > Update</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>

                    </Modal>
                </div>
                {/* ---------------Modal  Div--------------- */}

                {/* ---------------Modal Add Student------------ */}
                <div className="modal right fade rosterOfClass" id="addClassRosterModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2" >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel2">Add Roster</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span className='cancelText' aria-hidden="true">  <img src={cancelBlack} alt='' className='closeIconInModal' /> Cancel</span></button>
                            </div> */}
  <div className="modal-header modal-header-add-student">
              <h4 className="modal-title Addstudentmodal" id="myModalLabel2">
                Add Student
              </h4>
              <button
                type="button"
                className="close closeround"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="cancelText" aria-hidden="true">
                  {" "}
                  <img
                    src={cancelBlack}
                    alt=""
                    className="closeIconInModal"
                  />{" "}
                 
                </span>
              </button>
            </div>
                            <div className="modal-body">
                                {/* -----------Add Student Profile----------- */}
                                {this.state.RosterProfileURL === '' || this.state.RosterProfileURL === undefined ? <div className='RosterImageMainDiv'>
                                    <div className='addStudentImageDiv'>

                                        {this.state.showLoaderUpload ? <img src={loaderImag} alt='' className='loaderIconCss' /> :
                                            <label htmlFor="file"><img src={camera} alt='' className='cameraIconCss' /></label>}

                                    </div>
                                    <input className="custom-file-input" onChange={(e) => this.handleChangeFile(e.target.files)} onClick={this.handleClick} type="file" id="file" name="file" accept="image/*" />

                                </div> :
                                    <div style={{ backgroundImage: `url(${this.state.RosterProfileURL})` }} className='RosterImageMainSelectedDiv'>
                                        <div className='addStudentImageDiv'>
                                            <img src={this.state.RosterProfileURL} alt='' className='RosterImageWhenSelect' />
                                        </div>
                                        <Button className='changeButton'>
                                            {this.state.showLoaderUpload ? <img src={loaderImag} alt='' className='loaderIconCssAlreadySelected' /> :
                                                <label htmlFor="file" className='changeButtonText'>Change</label>}
                                        </Button>
                                        <input className="custom-file-input" onChange={(e) => this.handleChangeFile(e.target.files)} onClick={this.handleClick} type="file" id="file" name="file" accept="image/*" />

                                    </div>}

                                {/* -----------Add Student Profile----------- */}

                                {/* -----------All Input Type----------- */}
                                <div className='allInputMainDiv'>
                                    <div className='studentAddInputDiv m-0'>
                                        {/* <h5 className='studentInputLableInfo'>First name*</h5> */}
                                        <CommonTextField
                                            margin="normal"
                                            variant="outlined"
                                            type='FirstName'
                                            value={this.state.FirstName}
                                            onChange={this.handleChange}
                                            name="FirstName"
                                            className="studentAddInput"
                                            placeholder="First Name*" />
                                        {(!this.state.FirstName && this.state.isAddStudentSubmit) && <p className="errormessageRegister">Please enter first name</p>}


                                    </div>
                                    <div className='studentAddInputDiv m-0'>
                                        {/* <h5 className='studentInputLableInfo'>Last name*</h5> */}
                                        <CommonTextField
                                            margin="normal"
                                            variant="outlined"
                                            type='LastName'
                                            value={this.state.LastName}
                                            onChange={this.handleChange}
                                            name="LastName"
                                            className="studentAddInput"
                                            placeholder="Last Name*" />
                                        {(!this.state.LastName && this.state.isAddStudentSubmit) && <p className="errormessageRegister">Please enter last name</p>}


                                    </div>
                                    <div className='studentAddInputDiv m-0'>
                                        {/* <h5 className='studentInputLableInfo'>Email ID (Optional)</h5> */}
                                        <CommonTextField
                                            margin="normal"
                                            variant="outlined"
                                            type='Email'
                                            value={this.state.Email}
                                            onChange={this.handleChange}
                                            name="Email"
                                            className="studentAddInput"
                                            placeholder="Email ID" />
                                        {(this.state.Email && this.state.InvelidMessageEmail) && <p className="errormessageRegister">Please enter valid email*</p>}


                                    </div>
                                    {/* -----------All Input Type----------- */}

                                    {/* -----------All Button----------- */}
                                    <div className="modalButtonClass classAddRosterButtonDiv modalNW">
                                        <div>
                                            <Button onClick={() => { this.createNewRoster('no') }} className={saveButtonNW.join(' ')} color="primary" >
                                                <span className='saveText' > Save</span>
                                            </Button>
                                        </div>
                                        <div>
                                            <Button onClick={() => { this.createNewRoster('addAnother') }} className={addAnotherButtonNW.join(' ')}color="primary" >
                                                <span className='saveText' > Save and Add Another</span>
                                            </Button>

                                        </div>
                                    </div>
                                    {/* -----------All Button----------- */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ---------------Modal Add Student------------ */}

                {/* ---------------Modal Upload------------ */}
                <div className="modal right fade rosterImportOfClass" id="myModalRosterupload" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <div className='uploadDocHeaderRosterDiv'>
                                    <div>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span className='cancelText' aria-hidden="true">  <img src={cancelBlack} alt='' className='closeIconInModal' /> Cancel</span></button>
                                    </div>
                                    <div>
                                        {this.state.UploadFrom !== 'drive' ? <Button style={{left: "-156px"}} disabled={true} className='importRosterButton' color="primary" >
                                            <span className='importRosterText' > Import</span>
                                        </Button> :
                                            <Button disabled={this.state.GoogleAuth ? false : true} onClick={() => { this.handleFileLoad() }} style={{left: "-156px"}}  className='importRosterButton' color="primary" >
                                                <span className='importRosterText' > Import</span>
                                            </Button>}
                                    </div>

                                </div>
                            </div> */}
 <div style={{paddingTop: "9px", paddingLeft: "2px"}} className="modal-header">
              <div  className="uploadDocHeaderDiv" >
                <div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{padding: "-1px" , paddingLeft : "0px"}}
                  >
                    <span className="cancelText" aria-hidden="true">
                      {" "}
                      <img
                      style={{paddingRight : "5px" , width : "16px"}}
                        src={cancelBlack}
                        alt=""
                        className="closeIconInModal"
                      />{" "}
                      Import Roster
                    </span>
                  </button>
                </div>
              </div>
             
            </div>
            {this.state.UploadFrom !== "drive" ? (
                    <Button
                      disabled={true}
                      className="importRosterButton importdrivebtn"
                      color="primary"
                      style={{
                        position : 'absolute',
                        top : 13,
                        roght : 10,
                        left : 'auto',
                        right: 5
                      }}
                    >
                      <span className="importRosterText"> Import</span>
                    </Button>
                  
                  ) : (
                    <Button
                    disabled={this.state.GoogleAuth ? false : true} onClick={() => { this.handleFileLoad() }}
                      className="importRosterButton importdrivebtn"
                      color="primary"
                      style={{
                        position : 'absolute',
                        top : 13,
                        roght : 10,
                        left : 'auto',
                        right: 5
                      }}
                    >
                      <span className="importRosterText"> Import</span>
                    </Button>
                   
                  )}
                            <div className="modal-body">

                                {/* ---------------Note option------------ */}
                                <div className='uploadNodeDiv'>
                                    <p className='uploadClassNoteText'>Note</p>
                                    <p className='uploadNoteRosterText'>1. Open and name a spreadsheet</p>
                                    <p className='uploadNoteRosterText'>2. Input students (first column = First Name, Second column = Last Name)</p>
                                    <p className='uploadNoteRosterText'>3. Download spreadsheet as a CSV file (comma separated values)</p>
                                    <p className='uploadNoteRosterText'>4. Return here, authorize your account, choose the CSV file, and import!</p>
                                    <p style={{ marginTop: 15 }} className='uploadNoteText'>P.S. - You can also import your roster directly into a class {noteStrinf}</p>
                                </div>
                                {/* ---------------Note option------------ */}


                                {/* --------------- Upload option------------ */}
                                <div className='selectMainDivforRosterUpload'>
                                    <div className='selectOptionInRosterTag'>
                                        <div className='checkbozRosterCircle' onClick={() => { this.setState({ UploadFrom: 'drive' }) }}>
                                            {this.state.UploadFrom === 'drive' && <div className='checkbozCircleRosterSelected'></div>}
                                        </div>
                                        <img src={googleDriveIcon} alt='' className='selectoptionUploadImage' />
                                        {!this.state.GoogleAuth ? <p className='selectTagRosterText'>Google Drive</p> : <p className='loginEmailText'>{driveEmail}</p>}
                                        <div>
                                            {!this.state.GoogleAuth ?
                                                <Button  disabled={this.state.UploadFrom === 'drive' ? false : true} className='autharizedRosterButton btnforroster' color="primary" onClick={() => this.handleClientLoad()} >
                                                    <span className='autharizedRosterText' > Authorize</span>
                                                </Button> :
                                                <Button disabled={this.state.UploadFrom === 'drive' ? false : true} className='logoutButton' color="primary" onClick={() => this.handleSignOutClick()}  >
                                                    <span className='logoutText' > Logout</span>
                                                </Button>}

                                        </div>
                                    </div>

                                    <div className='selectOptionInRosterTag'>
                                        <div className='checkbozRosterCircle' onClick={() => { this.setState({ UploadFrom: 'drop' }) }}>
                                            {this.state.UploadFrom !== 'drive' && <div className='checkbozCircleRosterSelected'></div>}
                                        </div>
                                        <img src={localFolderIcon} alt='' className='selectoptionUploadImageforRosterDrop' />
                                        <p className='selectTagRosterText'>File App</p>
                                        <div>
                                            <ReactFileReader disabled={this.state.UploadFrom !== 'drive' ? false : true} handleFiles={this.handleImportFiles} fileTypes={'.csv'}>

                                                <Button  disabled={this.state.UploadFrom !== 'drive' ? false : true} className='autharizedRosterButton btnforroster loaderNewRoster-CR' color="primary" >
                                                    <span className='autharizedRosterText' > Select</span>
                                                </Button>
                                            </ReactFileReader>
                                        </div>

                                    </div>
                                </div>
                                {/* ---------------Upload option------------ */}


                            </div>
                        </div>
                    </div>
                </div>
                {/* ---------------Modal Add Student------------ */}

                {/* ---------------Import File From Drive Modal------------ */}
                <Modal centered className="selectClass-DriveFile-modal br-8" show={this.state.SelectFileModal} >
                    <Modal.Header>
                        <Modal.Title>Google Drive Import</Modal.Title>
                        {this.state.googleSelectedFile && <Button className='modalSaveTagButton' variant="success" onClick={() => { this.getFileDetails() }}>
                            <img src={tickIcon} alt='' height={20} width={20} />
                        </Button>}
                    </Modal.Header>
                    <Modal.Body>

                        {importLoader ? <div style={{ width: '100%', textAlign: 'center' }}>
                            <img src={loaderImag} alt='' className='importDriveLoader' />
                        </div> :
                            <div>
                                <div className='inline-Path'>
                                    <ul className="breadcrumb">
                                        {googleDrivePath.map((value, i) => (<li key={i} onClick={() => { this.getFolderFilesPath(value) }}><span className='cursor'>{value.name}</span></li>))}
                                    </ul>
                                </div>

                                <div className='modal-list-body'>
                                    {GetFileLoader && <div style={{ width: '100%', textAlign: 'center' }}>
                                        <img src={loaderImag} alt='' className='loaderIconCss' />
                                    </div>}
                                    {GoogleFiles && GoogleFiles.length > 0 ? <>
                                        {GoogleFiles && GoogleFiles.length > 0 && GoogleFiles.map((item, index) => (
                                            <div className='file_listDiv' key={index}>
                                                {item.fileExtension === "csv" ? <div style={{ display: 'inline-flex' }}>
                                                    <img src={driveCSVIcon} alt='' className='selectoptionUploadFolder' />

                                                    <p className='fileName'>{item.name}</p>
                                                </div> :
                                                    <div className='selectFileCursor' onClick={() => { this.getFolderFiles(item) }}>
                                                        <img src={driveFolderIcon} alt='' className='selectoptionUploadFolder' />

                                                        <p className='fileName'>{item.name}</p>
                                                    </div>}
                                                {item.fileExtension === "csv" && <i onClick={() => {
                                                    this.setState({
                                                        googleSelectedFile: item
                                                    })
                                                }} className={`${this.state.googleSelectedFile.id === item.id ? 'checkBoxSelect' : 'unCheckBoxSelect'} material-icons`} >
                                                    {this.state.googleSelectedFile.id === item.id ? 'check_circle' : 'radio_button_unchecked'}
                                                </i>}

                                            </div>
                                        ))}
                                    </> :
                                        <>
                                            {GetFileLoader ? <div></div> :
                                                <div style={{ textAlign: 'center', marginTop: 20 }}>
                                                    <p className='unSelectedText'>No File Avaliable</p>
                                                </div>}
                                        </>}
                                </div>
                            </div>}

                    </Modal.Body>

                    <Modal.Footer>
                        <Button className='modalcancleTagButton' variant="danger" onClick={() => {
                            this.setState({
                                SelectFileModal: false
                            })
                        }}>
                            <img src={closeIcon} alt='' height={40} width={30} />
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* ---------------Import File From Drive Modal------------ */}

                 {/* --------------Delete Rsoter ---------------------------*/}

                 { this.state.isDeleted === true &&
                    <Modal centered className="delete-modal-warning br-8" show={this.state.isDeleted} >
                        <Modal.Header>
                            <Modal.Title>
                                <div style={{display : 'flex', flexDirection: 'column'}}>
                                    <img src={warningIcon} alt="" style={{ width: "60px", height: "60px"}} />
                                    <span style={{fontSize: '24px', color: '#ff1f1f', marginTop: '10px'}}>Wait!</span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure want to delete all Data associated with the student?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" className="delete-modal-warning cancel-btn"
                            onClick={() => {
                                // editStudent(selectIndex);
                                this.setState({ isDeleted: false })
                                // setIsDeleted(false)

                            }}
                            >
                                Cancel
                            </Button>
                            <Button variant="default" className="delete-modal-warning delete-btn" style={{borderRadius: "8px"}}
                            // onClick={() => { deleteStudentHandler(selectStudentId, selectIndex) }}
                            onClick={() => { this.deleteRoster(this.state.deleteStudentId) }}
                            >
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }


                {/******************select student modal */}

                <div
        className="modal right fade ClassRosterModal"
        id="myModal3"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog" role="document">
           
          <div className="modal-content"
            ref={this.modalRef} 
            onScroll={this.handleScroll}
            // onScroll={() => console.log('scroll')} 
          >
             {/* <InfiniteScroll
                  dataLength={statesData && statesData.length}
                  //  next={fetchMoreData}
                 next={() => fetchMoreData()}
                  // hasMore={hasMore}
                  hasMore={statesData && statesData.length == notSelectedStudentsCount ? false : true}
                  // height={800}
                  // height={`calc(100% - 90px)`}
                  loader={<h4>Loading...</h4>}
                  //  height={400}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                > */}
                 <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          background: "#fff",
        }}
      >
            <div className="modal-header"
            // ref={modalRef} 
            // onScroll={handleScroll}
            >
            {/* <img
            style={{marginLeft: "0px" , paddingTop: "4px", width: "48px"}}
            //  className=""
             data-dismiss="modal"
             aria-label="Close"
                    src={cancelBlack}
                    alt=""
                    className="closeIconInModal close closeround"
                  />{" "} */}
               <button
                type="button"
                className="close closeround"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="cancelText" aria-hidden="true">
                  {" "}
                  <img
                    src={cancelBlack}
                    alt=""
                    className="closeIconInModal"
                  />{" "}
                 
                </span>
              </button>
              <button
              style={{paddingTop: "0px", paddingRight: "21px"}}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <h4 style={{paddingRight : "77px" , paddingTop : "4px"}} className="modal-title" id="myModalLabel2">
                Select students
              </h4>
                <span className="cancelText" aria-hidden=" true" style={{position : 'relative', bottom : 3}}>
                  {" "}
                  <img
                  onClick={() => this.selectRoster()}
                  style={{width: "100px" , height: "40px"}}
                    src={UpdateBtn}
                    alt=""
                    className="closeIconInModal"
                  />{" "}
                  {/* Cancel */}
                </span>
              </button>
            </div>

            <div style={{padding : '5px 15px'}}>
            <div
                  className="singleClassSearchFilterSelectStudent"
                  onClick={() => {
                    // this.setState({
                    //     // searchBar: true,
                    //     // searchText: ''
                    // })
                  }}
                >
                  <img
                    className="searchFilterIcon-RD"
                    src={iconSearch}
                    alt=""
                  />
                  <CommonTextField
                    margin="normal"
                    // variant="outlined"
                    type="FirstName"
                    // value={searchUnselectStudentText}
                    onChange={(event) => {
                      // console.log(event.target.value)
                      this.handleUnselectStudentSearchText(event)
                      // handleSearchText(event);
                      // setSearchText(event.target.value);
                    }}
                    name="searchText"
                    className="searchFilterInputBox-RD"
                    placeholder="Enter Keywords…"
                  />
                </div>
            </div>
            <div style={{height : 40}}></div>
            </div>
            <div className="modal-body">
              {/* -----------Add Student Profile----------- */}
              {"UserProfileURL" == "UserProfileURL"  ? (
              <>
                <div className="studentList"  
                onScroll={() => console.log('')}
                >
                 

                  {/* {statesData && statesData.length > 0 ?  */}
                  {this.state.statesData && this.state.statesData.length > 0 ?
                  <div className='classRosterSelectCardMainDiv' 
                  // ref={modalRef} 
                  // // onScroll={handleScroll}
                  // onScroll={() => console.log('scroll')}  
                  >
                    {this.state.statesData && this.state.statesData.map((item, index) => (

                        <div key={index} className='classRosterSelectCardDiv' >
                            <div className='selectRosterImageDiv'>
                                {item.cc_student_pic_url ? <img src={item.cc_student_pic_url} alt='' className='selectRosterProfileImage' /> :
                                    <div className='selectRosterProfileDummy'>
                                        <h5 className='profileSelectDummyText'>{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
                                    </div>}
                            </div>
                            <div className='selectRosterTextInfoDiv' >
                                <p className='selectRosterName'>{item.cc_student_last_name},{item.cc_student_first_name}</p>
                                <p className='selectRosterEmail'>{item.cc_student_email_id}</p>

                            </div>
                            <div className='selectRosterCheckBoxDiv'>
                                <i 
                                onClick={() => {
                                  
                                  this.CheckValue(item, index)
                                }}
                                // onClick={() => {CheckValue(item, index)
                                
                                //   console.log('item.isSeleted', item.isSeleted)
                                 
                                //  }}
                                  id = "a"
                                  className="checkBoxSelectRoster material-icons">{item.isSeleted ? 'check_circle' : 'radio_button_unchecked'}</i>
                                
                            </div>
                        </div>
                        
                        ))}
                </div> :
                    <div className='noRoster'>
                        {this.state.isLoading && this.state.searchUnselectStudentText.length > 0 ? 
                        <div>
                <DivLoader />
            </div> : <div data-toggle="modal" data-target="#addClassRosterModal">
                            <p className='tapToAddText'>{`${this.state.notSelectedRosterCount == 0 ? 'All Rosters Added in class' : ''} `}</p>

                        </div>}

                    </div>
                }
                 {(this.state.isLoading && this.state.searchUnselectStudentText.length == 0) &&  <div>
                <DivLoader />
            </div>}
                  {/* <input
                    className="custom-file-input"
                    // onChange={(e) => handleChangeFile(e.target.files)}
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    type="file"
                    id="file"
                    name="file"
                    accept="image/*"
                  /> */}
                </div>
                </>
                
              ) : (
                <div
                //   style={{ backgroundImage: `url(${UserProfileURL})` }}
                  className="RosterImageMainSelectedDiv"
                >
                 
                </div>
                
              )}

              {/* -----------Add Student Profile----------- */}

              {/* -----------All Input Type----------- */}
              {/* <div className="allInputMainDiv">
                <div className="studentAddInputDiv">
                  <h5 className="studentInputLableInfo">First name*</h5>
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="FirstName"
                    value={FirstName}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    name="FirstName"
                    className="studentAddInput"
                    placeholder="Enter first name"
                  />
                  {!FirstName && isAddStudentSubmit && (
                    <p className="errormessageRegister">
                      Please enter first name
                    </p>
                  )}
                </div>
                <div className="studentAddInputDiv">
                  <h5 className="studentInputLableInfo">Last name*</h5>
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="LastName"
                    value={LastName}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    name="LastName"
                    className="studentAddInput"
                    placeholder="Enter last name"
                  />
                  {!LastName && isAddStudentSubmit && (
                    <p className="errormessageRegister">
                      Please enter last name
                    </p>
                  )}
                </div>
                <div className="studentAddInputDiv">
                  <h5 className="studentInputLableInfo">Email ID (Optional)</h5>
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="Email"
                    value={Email}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      emailHandler(e);
                    }}
                    name="Email"
                    className="studentAddInput"
                    placeholder="Enter email id"
                  />
                  {Email && InvelidMessageEmail && (
                    <p className="errormessageRegister">
                      Please enter valid email*
                    </p>
                  )}
                </div>
                {/* -----------All Input Type----------- */}

                {/* -----------All Button----------- */}
                {/* <div className="modalButtonClass addRosterModalButtonClass">
                  <div>
                    <Button
                      onClick={() => {
                        createNewRoster("no");
                      }}
                      className="saveButton"
                      color="primary"
                    >
                      <span className="saveText"> Save</span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        createNewRoster("addAnother");
                      }}
                      className="addAnotherButton"
                      color="primary"
                    >
                      <span className="saveText"> Save and add another</span>
                    </Button>
                  </div>
                </div> */}
                {/* -----------All Button----------- */}
              {/* </div> } */}
            </div>
            {/* </InfiniteScroll> */}
          </div>
          {/* </InfiniteScroll> */}
        </div>
      </div>


                {this.state.showPopUp && <UpgradePopUp handleClosePopUp={this.handleClosePopUp} />}
            </div>
        )
    }
}
const mapStateToProps = state => ({
    state: state,
    rosterData: state.classRoster,
    loader: state.classRoster.loader,
    notSelectedRoster : state?.classRosterSelect?.notSelectedRoster?.student_data,
    notSelectedRosterCount : state?.classRosterSelect?.notSelectedRoster?.total_count
});
const mapDispatchToProps = dispatch => {
    return {
        addClassRoster: (data) => dispatch(addClassRoster(data)),
        getClassRosterList: (data) => dispatch(getClassRosterList(data)),
        deleteClassRoster: (data) => dispatch(deleteClassRoster(data)),
        updateRosterLevel: (data) => dispatch(updateRosterLevel(data)),
        importClassRoster: (data) => dispatch(importClassRoster(data)),
        searchClassRoster: (data) => dispatch(searchClassRoster(data)),
        getStudentGradebookNullRequest: (data) => dispatch(getStudentGradebookNullRequest(data)),
        getNotSelectedRosters: (data) => dispatch(getNotSelectedRosters(data)),
        addSelectedRosters: (data) => dispatch(addSelectedRosters(data)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassRoster
);