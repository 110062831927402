import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import back from "Assets/CommonComponent/back.png";
import UpgradePlan from "../UpgradePlanComponent/UpgradePlan";
import DivLoader from "Components/LoadingComponent/DivLoader";
import "./UpgradePlanDashboard.scss";
import * as routes from "Router/RoutesURL";
import { Container } from "reactstrap";
import axios from "axios";

const UpgradePlanDashboard = (props) => {
  const {
    getSubscriptionPlanRequest,
    getDataUsageRequest,
    getStripeProducts,
    getUserPaymentDetails,
    userPaymentDetailsData,
    Plans,
    loader,
    history,
    DataUsage,
    addPaymentStatusSuccess,
  } = props;

  const allStripePrices =  [
    {
      "id": "price_1P9MxiI410p5JtG8wpLB44Ar",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1714031002,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": "7",
      "metadata": {},
      "nickname": null,
      "product": "prod_PzLe99bkjygFo6",
      "recurring": {
        "aggregate_usage": null,
        "interval": "year",
        "interval_count": 1,
        "meter": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "tax_behavior": "unspecified",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "recurring",
      "unit_amount": 3999,
      "unit_amount_decimal": "3999"
    },
    {
      "id": "price_1P9MxFI410p5JtG8qoobVD2r",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1714030973,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": "2",
      "metadata": {},
      "nickname": null,
      "product": "prod_PzLe99bkjygFo6",
      "recurring": {
        "aggregate_usage": null,
        "interval": "month",
        "interval_count": 1,
        "meter": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "tax_behavior": "unspecified",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "recurring",
      "unit_amount": 399,
      "unit_amount_decimal": "399"
    },
    {
      "id": "price_1Ool9hI410p5JtG8YX709IGl",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1709119113,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": "3",
      "metadata": {},
      "nickname": null,
      "product": "prod_Pe3FLRACseQc4A",
      "recurring": {
        "aggregate_usage": null,
        "interval": "month",
        "interval_count": 1,
        "meter": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "tax_behavior": "inclusive",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "recurring",
      "unit_amount": 599,
      "unit_amount_decimal": "599"
    },
    {
      "id": "price_1Ool91I410p5JtG8uzizoBK6",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1709119071,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": "8",
      "metadata": {},
      "nickname": null,
      "product": "prod_Pe3FLRACseQc4A",
      "recurring": {
        "aggregate_usage": null,
        "interval": "year",
        "interval_count": 1,
        "meter": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "tax_behavior": "inclusive",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "recurring",
      "unit_amount": 5999,
      "unit_amount_decimal": "5999"
    },
    {
      "id": "price_1MzGCJI410p5JtG8uP0XC0Pc",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1682069047,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": null,
      "metadata": {},
      "nickname": null,
      "product": "prod_NccFhsQ8Lsu64a",
      "recurring": null,
      "tax_behavior": "unspecified",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "one_time",
      "unit_amount": 2500,
      "unit_amount_decimal": "2500"
    },
    {
      "id": "price_1MspJjI410p5JtG8NcBt8dHF",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1680535751,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": null,
      "metadata": {},
      "nickname": null,
      "product": "prod_NccFhsQ8Lsu64a",
      "recurring": null,
      "tax_behavior": "unspecified",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "one_time",
      "unit_amount": 0,
      "unit_amount_decimal": "0"
    },
    {
      "id": "price_1MrN1QI410p5JtG8SHoOfl5X",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1680188656,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": null,
      "metadata": {},
      "nickname": null,
      "product": "prod_NccFhsQ8Lsu64a",
      "recurring": {
        "aggregate_usage": null,
        "interval": "month",
        "interval_count": 1,
        "meter": null,
        "trial_period_days": null,
        "usage_type": "licensed"
      },
      "tax_behavior": "exclusive",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "recurring",
      "unit_amount": 14309,
      "unit_amount_decimal": "14309"
    },
    {
      "id": "price_1MpcOWI410p5JtG8ZBZe6HI8",
      "object": "price",
      "active": true,
      "billing_scheme": "per_unit",
      "created": 1679771092,
      "currency": "usd",
      "custom_unit_amount": null,
      "livemode": false,
      "lookup_key": null,
      "metadata": {},
      "nickname": null,
      "product": "prod_Nao0LBiiTHHJcS",
      "recurring": null,
      "tax_behavior": "exclusive",
      "tiers_mode": null,
      "transform_quantity": null,
      "type": "one_time",
      "unit_amount": 80000,
      "unit_amount_decimal": "80000"
    }
  ]

  const allStripeProducts = [
    {
      "id": "prod_PzLe99bkjygFo6",
      "object": "product",
      "active": true,
      "attributes": [],
      "created": 1714030972,
      "default_price": "price_1P9MxFI410p5JtG8qoobVD2r",
      "description": null,
      "features": [],
      "images": [],
      "livemode": false,
      "marketing_features": [],
      "metadata": {},
      "name": "Chronicle Premium Plan",
      "package_dimensions": null,
      "shippable": null,
      "statement_descriptor": null,
      "tax_code": "txcd_20030000",
      "type": "service",
      "unit_label": null,
      "updated": 1714031012,
      "url": null
    },
    {
      "id": "prod_Pe3FLRACseQc4A",
      "object": "product",
      "active": true,
      "attributes": [],
      "created": 1709119071,
      "default_price": "price_1Ool91I410p5JtG8uzizoBK6",
      "description": "Professional Plan",
      "features": [],
      "images": [],
      "livemode": false,
      "marketing_features": [],
      "metadata": {},
      "name": "Chronicle _Professional Plan",
      "package_dimensions": null,
      "shippable": null,
      "statement_descriptor": null,
      "tax_code": "txcd_20030000",
      "type": "service",
      "unit_label": null,
      "updated": 1714030927,
      "url": null
    }
  ]

  // const { location } = history;
  // const { state } = location;
  // const { Item, BackPage } = props?.history?.location?.state;

  const [allPrices, setAllPrices] = React.useState(null);
  const [allProducts, setAllProducts] = React.useState(null);
  const [activeProd, setActiveProd] = React.useState(null);
  const [activePrices, setActivePrices] = React.useState(null);

  const [allPayPalPrices, setAllPayPalPrices] = React.useState(null);
  const [allPayPalProducts, setAllPayPalProducts] = React.useState(null);
  const [paypalActivePrices, setPaypalActivePrices] = React.useState(null);

  const getPaypalTokenHandler = async () => {
    try {
      const clientId =
        "AfiAu4Pd1gaVDHaTYzKnAtkrQZMhfISX3eyWMwLed_AR61Smw7HLafngwvF_DnHbp3Rs-95zJOFpGX9h";
      const clientSecret =
        "ECdlpLzXIvehwKcIZBO-1TN5m-CYPB8_sD8NYqZANEs4K1rdtFTGBxmPk0-M_7PzIcJCMwLIUDL1pv7S";

      const credentials = `${clientId}:${clientSecret}`;
      const base64Credentials = btoa(credentials);

      const response = await fetch(
        `https://api-m.sandbox.paypal.com/v1/oauth2/token`,
        {
          method: "POST",

          body: "grant_type=client_credentials",

          headers: {
            Authorization: `Basic ${base64Credentials}`,
          },
        }
      );

      const data = await response.json();
      sessionStorage.setItem("tokenHere", data.access_token);
      //   return data.access_token;
    } catch (error) {
      console.error("Failed to generate Access Token:", error);
    }

    //PRODUCTS
    const productsResponse = await axios.get(
      "https://api-m.sandbox.paypal.com/v1/catalogs/products",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenHere")}`,
        },
      }
    );
    setAllPayPalProducts(productsResponse?.data?.products);

    const pricesResponse = await axios.get(
      "https://api-m.sandbox.paypal.com/v1/billing/plans",
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("tokenHere")}`,
        },
      }
    );

    let activePRices = pricesResponse?.data?.plans.filter(
      (item) => item?.status == "ACTIVE"
    );
    setAllPayPalPrices(activePRices);
  };

  useEffect(() => {
    // (async () => {
    //   const result = await axios.get("https://stripe-server-beta.vercel.app/getAllPrices");
    //   console.log(102, result);
     
    // })();
    // getPaypalTokenHandler();
    getStripeProducts()
    getUserPaymentDetails()
  }, []);

  useEffect(() => {
    setAllPrices(props?.stripePlans?.allProduct?.data);
    setAllProducts(props?.stripePlans?.products?.data);
    // setAllPrices(allStripePrices)
    // setAllProducts(allStripeProducts)
  },[props?.stripePlans])

  const organizePricesByProduct = (prices, productIds) => {
    const productsWithPrices = [];

    productIds.forEach(({ id, name }) => {
      const productPrices = prices.filter((price) => price.product === id);
      if (productPrices.length > 0) {
        const productObject = {
          product_Id: id,
          product_Name: name,
          prices: productPrices.map(({ id, unit_amount, recurring }) => ({
            id,
            unit_amount: unit_amount,
            interval: recurring ? recurring.interval : null,
          })),
        };

        productsWithPrices.push(productObject);
      }
    });

    return productsWithPrices;
  };

  const productsArryHandler = (value) => {
    let productsList = value.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
      };
    });
    setActiveProd(productsList);
    const result = organizePricesByProduct(allPrices, productsList);
    setActivePrices(result);
  };

  const organizePaypalPricesByProduct = (prices, productIds) => {
    const productsWithPrices = [];

    productIds.forEach(({ id, name }) => {
      const productPrices = prices.filter((price) => price.product_id === id);
      if (productPrices.length > 0) {
        const productObject = {
          product_Id: id,
          product_Name: name,
          prices: productPrices.map(({ name, id }) => ({
            id,
            name,
          })),
        };

        productsWithPrices.push(productObject);
      }
    });

    return productsWithPrices;
  };

  const paypalProductsArryHandler = (value) => {
    let productsList = value.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
      };
    });
    const result = organizePaypalPricesByProduct(allPayPalPrices, productsList);
    setPaypalActivePrices(result);
  };

  React.useEffect(() => {
    if (allPrices && allProducts) {
      productsArryHandler(allProducts);
    }
  }, [allPrices, allProducts]);

  React.useEffect(() => {
    if (allPayPalPrices && allPayPalProducts) {
      paypalProductsArryHandler(allPayPalProducts);
    }
  }, [allPayPalPrices, allPayPalProducts]);

  useEffect(() => {
    if (props?.history?.location?.state?.BackPage === "Footer") {
      getDataUsageRequest();
    }
    getSubscriptionPlanRequest();
    addPaymentStatusSuccess();
  }, [
    getSubscriptionPlanRequest,
    getDataUsageRequest,
    addPaymentStatusSuccess,
    props?.history?.location?.state?.BackPage,
  ]);

  const BackTOHome = () => {
    // history.goBack();
    if(sessionStorage?.getItem("userHasFreePlan") != "0") {
      history?.push(routes?.DATAUSAGE);
    } else {
      history?.push(routes?.UPGRADEPLAN);
    }
  };

  let backendPlans = [
    {
      cc_subscription_id: 1,
      cc_subscription_title: "Personal",
      cc_subscription_sub_title: null,
      cc_subscription_description: "5 MB Data Storage",
      cc_subscription_price: 0,
      cc_subscription_size: 5,
      cc_subscription_active: "yes",
      cc_subscription_added_on: "2017-12-09T09:03:20.000Z",
      cc_subscription_modifyed_on: null,
      cc_subscription_pc_status: 0,
      cc_subscription_annual_price: null,
      cc_subscription_annual_subTitle: null,
      cc_subscription_product_id: null,
      cc_subscription_duration: 30,
      cc_subscription_content:
        "* Multimedia capabilities (Audio, Images, Videos).\\n* Customizable Note-taking.\\n* Collaborative teaching via student and class sharing.\\n* Integrated and multifaceted Gradebook.",
      cc_subscription_annual_subTitle_discount: null,
      cc_is_recommended: 0,
      product_ids: {},
      color_code: "#F18F1A",
      plan_logo:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fpersonal.png?alt=media&token=7f45b7f0-e953-4dc0-b922-4e90c5a5f0b6",
      plan_logo_bg:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fpersonal_bg.png?alt=media&token=1b633eae-d9ca-4c76-b15e-99fa0da1a278",
    },
    {
      cc_subscription_id: 2,
      cc_subscription_title: "Premium",
      cc_subscription_sub_title: "Monthly",
      cc_subscription_description: "500 MB Data Storage",
      cc_subscription_price: 3.99,
      cc_subscription_size: 500,
      cc_subscription_active: "yes",
      cc_subscription_added_on: "2017-12-09T09:03:58.000Z",
      cc_subscription_modifyed_on: null,
      cc_subscription_pc_status: 0,
      cc_subscription_annual_price: 39.99,
      cc_subscription_annual_subTitle: "Or prepay Annually",
      cc_subscription_product_id: "com.chroniclecloud.premiumplan",
      cc_subscription_duration: 30,
      cc_subscription_content:
        "* Multimedia capabilities (Audio, Images, Videos).\\n* Customizable Note-taking.\\n* Collaborative teaching via student and class sharing.\\n* Integrated and multifaceted Gradebook.\\n* 1 month Free chargeable from 2nd month.",
      cc_subscription_annual_subTitle_discount: "(Save 16%)",
      cc_is_recommended: 1,
      product_ids: {
        iOS: {
          monthly: "com.chroniclecloud.premiumplan",
          yearly: "com.chroniclecloud.premiumyearlyPlan",
        },
        Android: {
          monthly: "com.chroniclecloud.premiumplan",
          yearly: "com.chroniclecloud.premiumplan.annual",
        },
        Web: {
          monthly: "2",
          yearly: "7",
        },
      },
      color_code: "#E73860",
      plan_logo:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fpremium.png?alt=media&token=175d4f90-c984-4e15-9613-b2bd61736a70",
      plan_logo_bg:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fpremium_bg.png?alt=media&token=11f9ad6a-cb71-4453-906a-05841fcd607f",
    },
    {
      cc_subscription_id: 3,
      cc_subscription_title: "Professional",
      cc_subscription_sub_title: "Monthly",
      cc_subscription_description: "1 GB Data Storage",
      cc_subscription_price: 5.99,
      cc_subscription_size: 1024,
      cc_subscription_active: "yes",
      cc_subscription_added_on: "2017-12-09T09:11:26.000Z",
      cc_subscription_modifyed_on: null,
      cc_subscription_pc_status: 0,
      cc_subscription_annual_price: 59.99,
      cc_subscription_annual_subTitle: "Or prepay Annually",
      cc_subscription_product_id: "com.chroniclecloud.professionalplan",
      cc_subscription_duration: 30,
      cc_subscription_content:
        "* Multimedia capabilities (Audio, Images, Videos).\\n* Customizable Note-taking.\\n* Collaborative teaching via student and class sharing.\\n* Integrated and multifaceted Gradebook.\\n* 1 month Free chargeable from 2nd month.",
      cc_subscription_annual_subTitle_discount: "(Save 16%)",
      cc_is_recommended: 0,
      product_ids: {
        iOS: {
          monthly: "com.chroniclecloud.professionalplan",
          yearly: "com.chroniclecloud.professionalyearlyPlan",
        },
        Android: {
          monthly: "com.chroniclecloud.professionalplan",
          yearly: "com.chroniclecloud.professionalplan.annual",
        },
        Web: {
          monthly: "3",
          yearly: "8",
        },
      },
      color_code: "#0C93A0",
      plan_logo:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fprofressional.png?alt=media&token=41be356f-2eae-4a79-a77b-0d797fd12d77",
      plan_logo_bg:
        "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-dev/o/rgnl%2Fplan_logos%2Fprofessional_bg.png?alt=media&token=02f7c46f-53b6-4fa0-977a-323d7c05f646",
    },
  ];

  return (
    <div className="upgradePlanContainer">
      <Row>
        <Container>
          <div className="header-main-container">
            <div className="header-breadcrumb-main-container">
              <div className="header-breadcrumb-back-btn-div">
                <img
                  className="header-breadcrumb-back-btn-img"
                  src={back}
                  alt=""
                  onClick={BackTOHome}
                />
              </div>
              <div className="header-breadcrumb-inner-div">
                <div className="breadcrumb-inner-flex-div">
                  <p className="breadcrumb-attendance-text">Data Usage</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Row>
      {loader && !activePrices ? (
        <DivLoader />
      ) : (
        <div>
          <Row className="cardOfPlan justify-content-center">
            {Plans?.map((plans, index) => {
              const filteredObj = activePrices?.filter(
                (item) =>
                  item?.product_Name?.includes(plans?.cc_subscription_title_for_stripe)
              );
              const selectedMonthlyPriceId = filteredObj?.[0]?.prices?.find(
                (item) => item?.interval == "month"
              );
              const stripeYearlyPriceObj = filteredObj?.[0]?.prices?.find(
                (item) => item?.interval == "year"
              );
              const filteredPayPalObj = paypalActivePrices?.filter(
                (item) =>
                  item?.product_Name.toLowerCase() ==
                  plans?.cc_subscription_title_for_stripe?.toLowerCase()
              );
              const paypalMonthlyPlanObj = filteredPayPalObj?.[0]?.prices?.find(
                (item) => item?.name == "Monthly"
              );
              const paypalYearlyPlanObj = filteredPayPalObj?.[0]?.prices?.find(
                (item) => item?.name == "Yearly"
              );
              return (
                <UpgradePlan
                  key={index}
                  item={plans}
                  stripeObj={filteredObj?.[0]}
                  stripeMonthlyPriceObj={selectedMonthlyPriceId}
                  stripeYearlyPriceObj={stripeYearlyPriceObj}
                  paypalMonthlyPlanObj={paypalMonthlyPlanObj}
                  paypalYearlyPlanObj={paypalYearlyPlanObj}
                  history={props.history}
                  addPaymentStatusSuccess={addPaymentStatusSuccess}
                  selectedPlan={
                    props?.history?.location?.state?.BackPage === "Footer" ? DataUsage.user_space_response : props?.history?.location?.state?.Item
                  }
                  userPaymentDetails={userPaymentDetailsData}
                />
              );
            })}
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={10}>
              <Card className="cardOfPlanRules">
                <div className="planDetailsPoint planDetailsPointDashboardUpgrade">
                <p className="dataPlans">
                    * Your PayPal account will be charged upon confirmation of the purchase.
                  </p>
                  <p className="dataPlans">
                    * The Stripe account offers you a 30-day trial period.
                  </p>
                  <p className="dataPlans">
                    * Subscription automatically renews unless auto-renew is
                    turned off at least 24 hours before the end of the current
                    period.
                  </p>
                  <p className="dataPlans">
                    * To turn off the auto-renewal, go to Paypal Settings and
                    make the necessary changes.
                  </p>
                  <p className="dataPlans">
                    * The account will identify the renewal cost and charge the
                    renewal fee within 24 hours before the end of the current
                    period.
                  </p>
                  <p className="dataPlans">
                    * In cases of an upgrade, the data will be added to the
                    upgraded plan.
                  </p>
                  <Row>
                    <Col className="refCol" md={1.2} lg={1.2}>
                      <p className="refrenceLink">Reference Links :</p>
                    </Col>

                    <Col md={9} lg={9}>
                      <a
                        className="termsLink"
                        href="https://www.chroniclecloud.com/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.chroniclecloud.com/terms{" "}
                      </a>{" "}
                      <br></br>
                      <a
                        href="https://www.chroniclecloud.com/faq"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        https://www.chroniclecloud.com/faq
                      </a>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          {/* <Card className='cardOfPlanRules'>
                <div className='planDetailsPoint'>
                    <p className="dataPlans">* Your Paypal Account will be charged at the confirmation of purchase.</p>
                    <p className="dataPlans">* Subscription automatically renews unless auto-renew is turned off at least 24 hours before the end of the current period.</p>
                    <p className="dataPlans">* To turn off the auto-renewal, go to Paypal Settings and make the necessary changes.</p>
                    <p className="dataPlans">* Account will identify the renewal cost and charge the renewal fee within 24 hours before the end of the current period.</p>
                    <p className="dataPlans">* In cases of an upgrade, the data will be added to the upgraded plan.</p>
                    <Row>
                        <Col className="refCol" md={1.2} lg={1.2}>
                            <p className='refrenceLink'>Reference Links :</p>
                        </Col>

                        <Col md={9} lg={9}>
                            <a className="termsLink"href="https://www.chroniclecloud.com/terms" target='_blank' rel="noopener noreferrer">https://www.chroniclecloud.com/terms </a> <br></br>
                            <a href="https://www.chroniclecloud.com/faq-app" target='_blank' rel="noopener noreferrer"> https://www.chroniclecloud.com/faq-app</a>
                        </Col>
                    </Row>
                </div>

            </Card> */}
        </div>
      )}
    </div>
  );
};
UpgradePlanDashboard.propTypes = {
  getSubscriptionPlanRequest: PropTypes.func,
  loader: PropTypes.bool,
};

export default UpgradePlanDashboard;
