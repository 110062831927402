import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import "./RubricsAssessmentTable.scss";

const RubricsAssessmentTable = (props) => {
    const {
        numbersArr,
        changePercentage,
        changeCommentUpdate,
        saveCommentCriteria,
        studentInfo,
        saveAssessmentState,
        viewRubricDetailsState,
        viewRubricDetailsStates,
        selectedStudentForData,
        setShowReset,
        showReset,
        enableEditScores,
        enableEditScore,
        setEnableEditScore,
        doReset,
        setViewRubricDetailsState,
        viewStudentsAssessmentDetailsState,
        selectStudentScore,
        resetAll,
        loader
    } = props;
    const [blurSaveBtn, setBlurSaveBtn] = useState(true);
    const [avgrageValue, setAverageValue] = useState("");
    const [changeBtn, setChangeBtn] = useState(false);
    const [stdInfo, setStdInfo] = useState([]);
    const [stdAvgScore, setStdAverageScore] = useState(0)
    const [show, setShow] = useState(false);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [showEditBtn, setShowEditBtn] = useState(false);
    const [showBlur, setShowBlur] = useState(true);
    const [newRub, setNewRub] = useState(false);
    const [clickedEdit, setClickedEdit] = useState(false);
    const [blurEditScore, setBlurEditScore] = useState(false);
    const saveBtn = ["saveBtn", !showReset ? "opacityBtn" : ""];
    const editScoreBtn = ["editScoreBtn", clickedEdit ? "opacityBtn" : ""];

    useEffect(() => {
        if (enableEditScore) {
            setShowEditBtn(true);
            setClickedEdit(true);
        } else {
            setShowEditBtn(false);
            setClickedEdit(false);
        }
    }, [enableEditScore])

    useEffect(() => {
        if (doReset) {
            let defaultArr = JSON.parse(sessionStorage.getItem("viewRubricDetailsStates"));
            setViewRubricDetailsState(defaultArr);
        }
    }, [doReset])

    useEffect(() => {
        if (clickedEdit) {
            setBlurEditScore(true);
        } else {
            setBlurEditScore(false);
        }
    }, [clickedEdit])

    useEffect(() => {
        
        viewRubricDetailsStates && viewRubricDetailsStates.studentArr.map(val => {
            if (val.cc_student_id === selectedStudentForData && val.availability_status !== "absent") {
                if (val.assessmentArr.length !== viewRubricDetailsStates.criteriaArr.length) {
                    setNewRub(true);
                } else {
                    setNewRub(false);
                }
            }
        })
    }, [])

    useEffect(() => {
        if (newRub) {
            viewRubricDetailsStates && viewRubricDetailsStates.studentArr.map(val => {
                if (val.cc_student_id === selectedStudentForData && val.availability_status !== "absent") {
                    if (val.assessmentArr.length !== viewRubricDetailsStates.criteriaArr.length) {
                        setNewRub(true);
                    } else {
                        setNewRub(false);
                    }
                }
            })
        }
    }, [selectedStudentForData])

    useEffect(() => {
        if (newRub && saveAssessmentState && saveAssessmentState.status === 200 && saveAssessmentState.data.assessment_details_array[0].availability_status === "present"
            && saveAssessmentState.data.assessment_details_array.length === viewRubricDetailsStates.criteriaArr.length) {
            setNewRub(false);

        }
    })
    useEffect(() => {
        if (newRub) {
            let currentAssessInfo = [];
            viewRubricDetailsState && viewRubricDetailsState.studentArr.map(val => {
                if (val.cc_student_id === selectedStudentForData && val.availability_status !== "absent") {
                    val.assessmentArr.map(value => {
                        if (value.cc_ra_assessment_point !== "") {
                            currentAssessInfo.push(value);
                        }
                    })
                }
            })
            if (currentAssessInfo.length === viewRubricDetailsState.criteriaArr.length) {
                setShowBlur(false);
            } else {
                setShowBlur(true);
            }
        }
    })

    useEffect(() => {
        
        viewRubricDetailsState && viewRubricDetailsState.studentArr.map(val => {
            if (val.cc_student_id === selectedStudentForData) {
                if (val.availability_status !== "absent") {
                    setShow(true);
                    setStdInfo(val.assessmentArr)
                    setStdAverageScore(val.average_score)
                } else {
                    setShow(false);
                    setStdInfo("")
                }
            }
        })
    }, [viewRubricDetailsState, selectedStudentForData])

  

    useEffect(() => {
        const resIds = [];
        viewRubricDetailsState && stdInfo && stdInfo.map(value => {
            if (value.cc_ra_assessment_point > 0) {
                resIds.push(value.cc_ra_assessment_point);
            }
        })
        let sumation = resIds.reduce((a, b) => a + b, 0)
        let sum = sumation / viewRubricDetailsState.criteriaArr.length;
        setAverageValue(sum * 10);
    }, [stdInfo, viewRubricDetailsState])


    useEffect(() => {
        const resIds = [];
        viewRubricDetailsState && stdInfo && stdInfo.map(value => {
            if (value.cc_ra_assessment_point !== "") {
                resIds.push(value);
            }
        })
        if (viewRubricDetailsState && stdInfo && resIds.length === stdInfo.length) {
            setBlurSaveBtn(false);
        } else {
            setBlurSaveBtn(true);
        }
    }, [viewRubricDetailsState])

    useEffect(() => {

        let currentAssessInfo = [];
        viewRubricDetailsState && viewRubricDetailsState.studentArr.map(val => {
            
            if (val.cc_student_id === selectedStudentForData) {
                if (val.assessment_completed !== "0" || (selectStudentScore == "" && selectStudentScore > 0)) {
                    setShowUpdateBtn(true);
                } else {
                    setShowUpdateBtn(false);
                    setEnableEditScore(true);
                }
            }
        })

        if (!showUpdateBtn) {
            viewRubricDetailsState && viewRubricDetailsState.studentArr.map(val => {
                if (val.cc_student_id === selectedStudentForData && val.availability_status !== "absent") {
                    val.assessmentArr.map(value => {
                        if (value.cc_ra_assessment_point !== "") {
                            currentAssessInfo.push(value);
                        }
                    })
                }
            })
            if (currentAssessInfo.length === viewRubricDetailsState.criteriaArr.length) {
                setShowBlur(false);
            } else {
                setShowBlur(true);
            }
        }
    })
    

    const saveCriteria = (value) => {
        if (enableEditScore) {
            setChangeBtn(true);
            saveCommentCriteria(value);
            setShowBlur(false);
            setEnableEditScore(false);
        }
    }

    const hideEnableEdit = (value) => {
        setShowEditBtn(false);
        setEnableEditScore(false);
        resetAll();
    }



    return (
        <div>
            {show && <div>
                <div className="rubricsAssesTabContainer">
                    <div className="assessmentMainDiv">
                        <div className="assessmentHeaderDiv">
                            <p className='tableTitles'>Criteria</p>
                        </div>
                        <div className="assessmentNumbersTitle">
                            <div className='beginning'><p className='nameTexts '>Beginning</p></div>
                            <div className='developing'><p className='nameTexts'>Developing</p></div>
                            <div className='accomplished'><p className='nameTexts'>Accomplished</p></div>
                            <div className='exemplary'><p className='nameTexts'>Exemplary</p></div>
                        </div>
                        <div className="verticalLines"><p className="verticalLine"></p></div>
                        <div className='commentsTitle'>
                            <p className='cmtTitleText'>Comments</p>
                            {!loader && showUpdateBtn && clickedEdit ?
                                <div>{<Button variant="default" className={saveBtn.join(" ")} onClick={() => { saveCriteria(stdInfo) }}>Save</Button>
                                }
                                </div> : ""}
                        </div>
                    </div>
                    <div className='assessmentMainDataDiv'>
                        {viewStudentsAssessmentDetailsState && viewRubricDetailsState && stdInfo && stdInfo.map((value, j) => {
                            return (
                                <div className='mainAssessDiv' key={j}>
                                    <div className='criteriaTitle' key={j} ><p className='criteriaText'>{value.cc_criteria_title?.replace(/(.{20})..+/, "$1…")}</p></div>
                                    <div className='criNumbers'>
                                        <div className='numbersDiv'>
                                            {numbersArr && numbersArr.map((val, k) => {
                                                const criteriaNumbers = ["criteriaNumbers", value.cc_ra_assessment_point <= 3 && val === value.cc_ra_assessment_point ? "beging" : value.cc_ra_assessment_point > 3 && value.cc_ra_assessment_point <= 6 && val === value.cc_ra_assessment_point ? "develope" : value.cc_ra_assessment_point > 6 && value.cc_ra_assessment_point <= 8 && val === value.cc_ra_assessment_point ? "accomplish" : value.cc_ra_assessment_point > 8 && value.cc_ra_assessment_point <= 10 && val === value.cc_ra_assessment_point ? "exemp" : "", !enableEditScore ? "dfltCursor" : ""];
                                                return (
                                                    <div className="numDiv" key={k}>
                                                        <p className={criteriaNumbers.join(' ')} id={`mypopOver${k}${j}`} onClick={() => { changePercentage(value, j, val) }}> {val !== 10 ? "0" + val : val}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="verticalLines"><p className="verticalLine"></p></div>
                                    <div className='rationalCommentTextDiv'>
                                        <input disabled={!clickedEdit} name="message" type="text" className="rationalCommentText" value={value.cc_ra_assessment_comment} onChange={(e) => { changeCommentUpdate(value, j, e.target.value) }}
                                            placeholder="Add Comment" />
                                    </div>
                                </div>)
                        }
                        )}
                    </div>

                </div>
                {viewStudentsAssessmentDetailsState && <div className="avgScoreDiv">
                    <span className='avgText'>
                        Average score: {avgrageValue && <p className='avgTextScore'>{avgrageValue === "00.00" ? avgrageValue : (avgrageValue).toFixed(2)} %</p>}
                    </span>
                    {!showUpdateBtn && <div>{showBlur ? <Button variant="default" className="saveBtn opacityBtn">Save</Button> :
                        <Button variant="default" className="saveBtn" onClick={() => { saveCriteria(stdInfo); setShowReset(false) }}>Save</Button>}
                    </div>}
                    {/* {(!stdAvgScore || stdAvgScore == 0) && <div>{showBlur ? <Button variant="default" className="saveBtn opacityBtn">Save</Button> :
                        <Button variant="default" className="saveBtn" onClick={() => { saveCriteria(stdInfo) }}>Save</Button>}
                    </div>} */}
                    {showUpdateBtn &&
                        <div>
                            {clickedEdit ? <div>{showReset ? <Button variant="default" className="editScoreBtn" onClick={hideEnableEdit}>Cancel</Button> : ""}
                                <Button variant="default" className={editScoreBtn.join(' ')} onClick={!enableEditScores && enableEditScores}>Edit Score</Button></div>
                                : <Button variant="default" className={editScoreBtn.join(' ')} onClick={enableEditScores}>Edit Score</Button>}
                        </div>
                    }
                     {/* {stdAvgScore && stdAvgScore > 0 &&
                        <div>
                            {clickedEdit ? <div>{showReset ? <Button variant="default" className="editScoreBtn" onClick={hideEnableEdit}>Cancel</Button> : ""}
                                <Button variant="default" className={editScoreBtn.join(' ')} onClick={!enableEditScores && enableEditScores}>Edit Score</Button></div>
                                : <Button variant="default" className={editScoreBtn.join(' ')} onClick={enableEditScores}>Edit Score</Button>}
                        </div>
                    } */}
                </div>}
            </div>}
        </div>
    );
}
export default RubricsAssessmentTable
