import { call, takeLatest, put } from 'redux-saga/effects';
import UserLoginReducer from 'Redux/Reducers/AuthModuleReducer/loginReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import { createNotification } from 'Config/notificationtoast';
// import { setUserIdOnFirebase } from "Config/commonFirebaseImage";
import { GET_USER_PAYMENT_DETAILS, LOGIN } from 'Actions/constants';
import { Login_API, getUserPaymentDetails_API } from 'Config/Api';
import {
  chroniclecloud_code, device_type, device_udid, device_token_id
} from 'Config/Constant';
import request from 'Config/request';
import { auth } from 'firebase';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import * as routes from "Router/RoutesURL";


export function* userLoginModuleSaga() {
  yield takeLatest(LOGIN, doLogin);
  yield takeLatest(GET_USER_PAYMENT_DETAILS, getUserPaymentDetails);
}

const loginUserWithEmail = async (userData) => {
  try {
    const response = await auth().signInWithEmailAndPassword(userData.user_email_id, userData.user_password)
    return response
  } catch (error) {
    return error
  }
}

function* doLogin(action) {
  yield put(UserLoadingReducer.loading(true));
  // const URL = `${base_url_API}api_user_login`;

  const firebaseToken = sessionStorage.getItem("firebaseToken");
  const URL = Login_API;
  const BODY = JSON.stringify({
    "chroniclecloud_code": chroniclecloud_code,
    "user_email_id": action.data.user_email_id,
    "user_password": action.data.user_password,
    "device_udid": device_udid,
    "device_token_id":  sessionStorage.getItem("FBDeviceToken") ? sessionStorage.getItem("FBDeviceToken") : "",
    "device_type": device_type,
  })
  let response;
  try {
    const result = yield call(loginUserWithEmail, JSON.parse(BODY))
    if (result.user) {
      const loginData = {
        "refresh_token": result?.user?.refreshToken,
        "platform": "web",
        "device_token": sessionStorage.getItem("FBDeviceToken") ? sessionStorage.getItem("FBDeviceToken") : ""
      }
      // response = yield call(request, "https://dev-mgt-api.chroniclecloud.com/teacher/teacherLogIn", {
      response = yield call(request, Login_API, {
        method: 'POST',
        body: { "data": encryptData(loginData) },
      });
      const MainDecode = decryptData(response.res)
      if (MainDecode.status === 200) {
        sessionStorage.setItem("userHasFreePlan",MainDecode?.data?.isActive)
        // sessionStorage.setItem("UDID", MainDecode.response_data.cc_user_device_udid)
        sessionStorage.setItem('authToken', MainDecode.authToken)
        sessionStorage.setItem("UserData", JSON.stringify(MainDecode.data.user_details))
        sessionStorage.setItem("IsLogin", true)
        sessionStorage.setItem("keepMeSinged", true)
        sessionStorage.setItem("GoogleAuth", false)
        sessionStorage.setItem("GoogleAuthItem", false)

        sessionStorage.setItem("FirstTimeLogin", MainDecode.data.first_time_login)
        sessionStorage.setItem("RegionalUrl", MainDecode.data.regional_url)
        sessionStorage.setItem("UserInitialLogin", true)
        const isLogin = sessionStorage.getItem("IsLogin")
        const FTL = sessionStorage.getItem("FirstTimeLogin")
      if (MainDecode?.data?.isActive == '0') {
        if (MainDecode.data.first_time_login == 1) {
          yield put(UserLoginReducer.loginSuccess(MainDecode));
          sessionStorage.setItem("IsLogin", false)
          yield put(UserLoadingReducer.loadingSuccess(false));
          return
        }

          window.location.href = routes.UPGRADEPLAN;
          yield put(UserLoadingReducer.loadingSuccess(false));
      } else {
          window.location.href = routes.ACADEMICDASHBOARD; 
          createNotification('success', MainDecode.message);
          yield put(UserLoginReducer.loginSuccess(MainDecode));
          yield put(UserLoadingReducer.loadingSuccess(false));
      }
        // if(MainDecode.data.first_time_login === 0) {
          
        //     window.location.href = routes.ACADEMICDASHBOARD;
        // }
        // // setUserIdOnFirebase();
        // createNotification('success', MainDecode.message);
        // yield put(UserLoginReducer.loginSuccess(MainDecode));
        // yield put(UserLoadingReducer.loadingSuccess(false));

      } else {

        createNotification('error', MainDecode.message);
        if (MainDecode.message === 'User not verified') {
          yield put(UserLoginReducer.loginSuccess(MainDecode));
          yield put(UserLoadingReducer.loadingSuccess(false));
        } else {
          yield put(UserLoginReducer.loginFailure(MainDecode));
          yield put(UserLoadingReducer.loadingSuccess(false));
        }

      }
    } else {
      createNotification('error', result.message)
      yield put(UserLoadingReducer.loadingSuccess(false));
    }
  } catch (error) {
    createNotification('warning', 'Somthing went wrong');
    yield put(UserLoginReducer.loginFailure(false));
    yield put(UserLoadingReducer.loadingSuccess(false));

  }
}

function* getUserPaymentDetails(action) {
  let data = JSON.parse(sessionStorage.getItem("UserData"));
  const URL = `${getUserPaymentDetails_API}?customer_id=${data?.cc_user_id}&platform=web`;
  let response;
  try {
    response = yield call(request, URL, {
      method: "GET",
    });
    const MainDecode = decryptData(response.res);
    if (MainDecode.status === 200) {
      yield put(UserLoginReducer.getUserPaymentDetailsSuccess(MainDecode.data));
    } else {
      if (
        MainDecode.message ===
        "Your session has been expired. Please login again"
      ) {
        logOut("error", MainDecode.message);
      }
      if(MainDecode.message != "Data not found.") {
        createNotification("error", MainDecode.message);
        yield put(UserLoginReducer.getUserPaymentDetailsFailure(MainDecode));
      }
      
    }
  } catch (error) {
    createNotification("warning", "Something went wrong");
    yield put(UserLoginReducer.getUSerPaymentDetailsFailure(error));
  }
}
