import axios from 'axios';
import { call, takeLatest, put } from 'redux-saga/effects';
import UserRubricsReducer from 'Redux/Reducers/ClassReducer/classRubricsReducer';
import { createNotification } from 'Config/notificationtoast';
import {
    GET_RUBRIC_LIST,
    ADD_RUBRIC,
    VIEW_RUBRIC_DETAILS,
    DELETE_CRITERIA,
    SAVE_ASSESSMENT,
    VIEW_STUDENTS_ASSESSMENT_DETAILS,
    DELETE_RUBRIC,
    FORWARD_RUBRICS_REPORT,
    GET_DYNAMIC_RUBRICS_CRITERIA,
    DELETE_PREVIOUS_CRITERIA_DATA

} from 'Actions/constants';

import {
    GetRubricsList_API,
    AddNewRubrics_API,
    ViewRubricDetails_API,
    DeleteCriteria_API,
    SaveAssessment_API,
    ViewStudentsAssessmentDetails_API,
    DeleteRubric_API,
    SendRubricEmail_API,
    getDynamicCriteria_API,
    DeletePreviousCriteria_API
} from 'Config/Api';
import { logOut } from 'Utils/Helper';
import request from 'Config/request';
import { decryptData, encryptData } from 'Utils/Helper';
import { getRosterImage } from "Config/commonFirebaseImage";

export function* userRubricsModuleSaga() {
    yield takeLatest(GET_RUBRIC_LIST, getRubricList);
    yield takeLatest(ADD_RUBRIC, addRubric);
    yield takeLatest(VIEW_RUBRIC_DETAILS, viewRubricDetails);
    yield takeLatest(DELETE_CRITERIA, deleteCriteria);
    yield takeLatest(SAVE_ASSESSMENT, saveAssessment);
    yield takeLatest(VIEW_STUDENTS_ASSESSMENT_DETAILS, viewStudentsAssessmentDetails);
    yield takeLatest(DELETE_RUBRIC, deleteRubric);
    yield takeLatest(DELETE_PREVIOUS_CRITERIA_DATA, deletePreviousCriteriaData);
    yield takeLatest(FORWARD_RUBRICS_REPORT, forwardRubricsReport);
    yield takeLatest(GET_DYNAMIC_RUBRICS_CRITERIA, getDynamicRubricsCriteria)
}

function* getRubricList(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    // const URL = GetRubricsList_API;
    const URL = `${GetRubricsList_API}?user_id=${data.cc_user_id}&class_id=${action.data.class_id}&rubric_status=${action.data.rubric_status}&start_date=${action.data.start_date}&end_date=${action.data.end_date}&platform=web`;

    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "rubric_status": action.data.rubric_status,
        "start_date": action.data.start_date,
        "end_date": action.data.end_date,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);

        if (MainDecode.status === 200) {
            yield put(UserRubricsReducer.getRubricListSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserRubricsReducer.getRubricListFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRubricsReducer.getRubricListFailure(false));
    }
}

function* addRubric(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = AddNewRubrics_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "title": action.data.title,
        "rubric_date": action.data.rubric_date,
        "rubric_status": action.data.rubric_status,
        "rubric_id": action.data.rubric_id,
        "criteria_details_array": action.data.criteria_details_array,
        "role": data.user_type,
        "platform": "web"
        //"message": "string",
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserRubricsReducer.addRubricSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserRubricsReducer.addRubricFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRubricsReducer.addRubricFailure(false));
    }
}

function* viewRubricDetails(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    // const URL = ViewRubricDetails_API;
    const URL = `${ViewRubricDetails_API}?user_id=${data.cc_user_id}&class_id=${action.data.class_id}&rubric_id=${action.data.rubric_id}&platform=web`;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "rubric_id": action.data.rubric_id,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            for (let studentArrData of MainDecode.data.studentArr) {
                if (studentArrData.cc_student_pic_url) {
                    if (!(studentArrData.cc_student_pic_url.includes("https") === true)) {
                        studentArrData.cc_student_pic_url = yield getRosterImage(studentArrData.cc_student_pic_url);
                    }
                }
            }
            yield put(UserRubricsReducer.viewRubricDetailsSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserRubricsReducer.viewRubricDetailsFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRubricsReducer.viewRubricDetailsFailure(false));
    }
}

function* deleteCriteria(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    let user_id = JSON.stringify(data.cc_user_id)
    const URL = DeleteCriteria_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "rubric_id": action.data.rubric_id,
        "id": action.data.id,
        "role": data.user_type,
        "platform": "web"

    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserRubricsReducer.deleteCriteriaSuccess(MainDecode));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserRubricsReducer.deleteCriteriaFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRubricsReducer.deleteCriteriaFailure(false));
    }
}

function* viewStudentsAssessmentDetails(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    let user_id = JSON.stringify(data.cc_user_id)
    const URL = ViewStudentsAssessmentDetails_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "rubric_id": action.data.rubric_id,
        "student_id": action.data.student_id,
        "platform": "web"

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserRubricsReducer.viewStudentsAssessmentDetailsSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserRubricsReducer.viewStudentsAssessmentDetailsFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRubricsReducer.viewStudentsAssessmentDetailsFailure(false));
    }
}

function* saveAssessment(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    let user_id = JSON.stringify(data.cc_user_id)
    const URL = SaveAssessment_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "rubric_id": action.data.rubric_id,
        "student_id": action.data.student_id,
        "class_id": action.data.class_id,
        "assessment_details_array": action.data.assessment_details_array,
        "platform": "web"
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            createNotification('success', MainDecode.message);
            yield put(UserRubricsReducer.saveAssessmentSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserRubricsReducer.saveAssessmentFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRubricsReducer.saveAssessmentFailure(false));
    }
}

function* deleteRubric(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeleteRubric_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "rubric_id": action.data.rubric_id,
        "platform": "web"
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            createNotification('success', MainDecode.message);
            yield put(UserRubricsReducer.deleteRubricSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserRubricsReducer.deleteRubricFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRubricsReducer.deleteRubricFailure(false));
    }
}

function* forwardRubricsReport(action) {
    const URL = SendRubricEmail_API;
    const { toEmailId, subject, messageBody, shareFile } = action.data;
    const fd = new FormData();
    // fd.append('chroniclecloud_code', chroniclecloud_code);
    fd.append('email_id', toEmailId);
    fd.append('subject', subject);
    fd.append('message_body', messageBody);
    fd.append('share_file', shareFile);
    fd.append('platform', "web");
    axios.post(URL, fd).then(response => {
        const MainDecode = decryptData(response.data.res);
        if (MainDecode.status === 200) {
            createNotification('success', 'Email Sent');
        }
    }).catch((error) => {
        createNotification('warning', 'Something went wrong');
    })
}

function* getDynamicRubricsCriteria(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = getDynamicCriteria_API;
    const BODY = {
        "rubric_title": action.data.rubric_title,
        "criteria_count": `${action?.data?.criteria_count}`,
        "user_id": `${data.cc_user_id}`,
        "platform": "web"
    }
    

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        
        if (MainDecode.status === 200) {
            yield put(UserRubricsReducer.getDynamicRubricsCriteriaSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserRubricsReducer.getDynamicRubricsCriteriaError(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        console.log(error)
        yield put(UserRubricsReducer.getDynamicRubricsCriteriaError(false));
    }
}

function* deletePreviousCriteriaData(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeletePreviousCriteria_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        // "class_id": action.data.class_id,
        "rubric_id": action.data.rubric_id,
        "platform": "web"
    }

    let response;
    
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        
        if (MainDecode.status === 200) {
            yield put(UserRubricsReducer.deletePreviousCriteriaDataSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserRubricsReducer.deletePreviousCriteriaDataError(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRubricsReducer.deletePreviousCriteriaDataError(false));
    }
}