import { call, takeLatest, put } from 'redux-saga/effects';
import UserClassGradebookReducer from 'Redux/Reducers/GradebookReducer/classGradebookReducer';
import { createNotification } from 'Config/notificationtoast';
import {
    GET_CLASS_GRADEBOOK_REQUEST,
    ADD_NEW_CLASS_GRADEBOOK_CATEGORY_REQUEST,
    ADD_NEW_CLASS_GRADEBOOK_EVENT_REQUEST,
    UPDATE_CLASS_GRADEBOOK_EVENT_REQUEST,
    DELETE_CLASS_GRADEBOOK_EVENT_REQUEST,
    UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_REQUEST,
    UPDATE_CLASS_GRADEBOOK_DRAG_DATA_REQUEST,
    UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_REQUEST
} from 'Actions/constants';
import {
    GetClassGradebook_List_API,
    AddClassGradebook_Category_API,
    AddClassGradebook_Event_API,
    UpdateClassGradebook_Event_API,
    DeleteClassGradebook_Event_API,
    UpdateClassGradebook_ScoreComment_API,
    UpdateClassGradebook_Index_API,
    UpdateClassGradebook_EventIndex_API
} from 'Config/Api';
import { chroniclecloud_code } from 'Config/Constant';
import request from 'Config/request';
import { logOut } from 'Utils/Helper';
import { getRosterImage } from "Config/commonFirebaseImage";
import { decryptData, encryptData } from 'Utils/Helper';

export function* userClassGradebookModuleSaga() {
    yield takeLatest(GET_CLASS_GRADEBOOK_REQUEST, getClassGradebookRequest);
    yield takeLatest(ADD_NEW_CLASS_GRADEBOOK_CATEGORY_REQUEST, addNewClassGradebookCategoryRequest);
    yield takeLatest(ADD_NEW_CLASS_GRADEBOOK_EVENT_REQUEST, addNewClassGradebookEventRequest);
    yield takeLatest(UPDATE_CLASS_GRADEBOOK_EVENT_REQUEST, updateClassGradebookEventRequest);
    yield takeLatest(DELETE_CLASS_GRADEBOOK_EVENT_REQUEST, deleteClassGradebookEventRequest);
    yield takeLatest(UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_REQUEST, updateClassGradebookScoreCommentRequest);
    yield takeLatest(UPDATE_CLASS_GRADEBOOK_DRAG_DATA_REQUEST, updateClassGradebookDragDataRequest);
    yield takeLatest(UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_REQUEST, updateClassGradebookDragEventDataRequest);

}

// ---------- Get Class Gradebook-----
function* getClassGradebookRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = GetClassGradebook_List_API;
    // const BODY = JSON.stringify({
    //     "chroniclecloud_code": chroniclecloud_code,
    //     "user_id": data.cc_user_id,
    //     "device_udid": sessionStorage.getItem('UDID'),
    //     "class_id": action.data.classId,
    //     "page_no": action.data.page_no
    // })
    const BODY = {
        "user_id": data.cc_user_id.toString(),
        "class_id": action.data.classId,
        "page_no": action.data.page_no,
        "platform": "web"
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            let responseData = MainDecode
            yield put(UserClassGradebookReducer.getClassGradebookSuccess(MainDecode));
            if (responseData && responseData?.data && responseData?.data?.students && responseData?.data?.students?.length > 0) {
                let ImageData = responseData?.data?.students

                for (let rosterItem of ImageData) {
                    if (rosterItem.cc_student_pic_url) {
                        if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                            rosterItem.cc_student_pic_url = yield getRosterImage(rosterItem.cc_student_pic_url);
                        }
                    }
                    responseData.data.students = ImageData;
                }
                yield put(UserClassGradebookReducer.getClassGradebookSuccess(responseData));
            }

        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassGradebookReducer.getClassGradebookFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassGradebookReducer.getClassGradebookFailure(false));

    }
}
// ---------- Get Class Gradebook------


// ---------- Add Class Gradebook Category------
function* addNewClassGradebookCategoryRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = AddClassGradebook_Category_API;
    // const BODY = JSON.stringify({
    //     "chroniclecloud_code": chroniclecloud_code,
    //     "user_id": data.cc_user_id,
    //     "device_udid": sessionStorage.getItem('UDID'),
    //     "class_id": action.data.classId,
    //     "category_name": action.data.categoryName
    // })

    const BODY = {
        "user_id": data.cc_user_id.toString(),
        "category_name": action.data.categoryName,
        "class_id": action.data.classId,
        "platform": "web"
      }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            const APIData = {
                "categoryId": MainDecode.data.category_id,
                "classId": action.data.classId,
                "eventName": action.data.eventName,
                "date": action.data.date,
                "studentDetailsArray": action.data.studentDetailsArray
            }
            yield put(UserClassGradebookReducer.addNewClassGradebookEventRequest(APIData));
            yield put(UserClassGradebookReducer.addNewClassGradebookCategorySuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            if (MainDecode.message === "Media limit has been exceeded. Please upgrade the plan") {
                yield put(UserClassGradebookReducer.upgradePopUpOpenGradebook(MainDecode));
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassGradebookReducer.addNewClassGradebookCategoryFailure(MainDecode));
        }
    } catch (error) {

        createNotification('warning', 'Something went wrong');
        yield put(UserClassGradebookReducer.addNewClassGradebookCategoryFailure(false));

    }
    finally {
        const apiValue = {
            "classId": action.data.classId,
            "page_no": 1,
        }
        yield put(UserClassGradebookReducer.getClassGradebookRequest(apiValue));
    }
}
// ---------- Add Class Gradebook Category-----


// ---------- Add Class Gradebook Event------
function* addNewClassGradebookEventRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = AddClassGradebook_Event_API;
    // const BODY = JSON.stringify({
    //     "chroniclecloud_code": chroniclecloud_code,
    //     "user_id": data.cc_user_id,
    //     "device_udid": sessionStorage.getItem('UDID'),
    //     "class_id": action.data.classId,
    //     "category_id": action.data.categoryId,
    //     "date": action.data.date,
    //     "event_name": action.data.eventName,
    //     "student_details_array": JSON.stringify(action.data.studentDetailsArray)
    // })

    const BODY = {
        "user_id": data.cc_user_id.toString(),
        "category_id": action.data.categoryId,
        "event_name": action.data.eventName,
        "class_id": action.data.classId,
        "date": action.data.date,
        "student_details_array": action.data.studentDetailsArray,
        "platform": "web"
      }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);

        if (MainDecode.status === 200) {
            // createNotification('success', MainDecode.message);
            yield put(UserClassGradebookReducer.addNewClassGradebookEventSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassGradebookReducer.addNewClassGradebookEventFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassGradebookReducer.addNewClassGradebookEventFailure(false));

    }
    finally {
        const apiValue = {
            "classId": action.data.classId,
            "page_no": 1,
        }
        yield put(UserClassGradebookReducer.getClassGradebookRequest(apiValue));
    }
}
// ---------- Add Class Gradebook Event-----

// ---------- Update Class Gradebook Event-----
function* updateClassGradebookEventRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = UpdateClassGradebook_Event_API;
    // const BODY = JSON.stringify({
    //     "chroniclecloud_code": chroniclecloud_code,
    //     "user_id": data.cc_user_id,
    //     "device_udid": sessionStorage.getItem('UDID'),
    //     "class_id": action.data.classId,
    //     "category_id": action.data.categoryId,
    //     "date": action.data.date,
    //     "event_name": action.data.eventName,
    //     "event_id": action.data.eventId,
    //     "student_details_array": JSON.stringify(action.data.studentDetailsArray)
    // })

    const BODY = {
        "user_id": data.cc_user_id.toString(),
        "category_id": action.data.categoryId,
        "event_name": action.data.eventName,
        "event_id": action.data.eventId,
        "category_name": action.data.categoryName,
        "date": action.data.date,
        "student_details_array": action.data.studentDetailsArray,
        "platform": "web"
      }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);

        if (MainDecode.status === 200) {
            createNotification('success', MainDecode.message);
            yield put(UserClassGradebookReducer.updateClassGradebookEventSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassGradebookReducer.updateClassGradebookEventFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassGradebookReducer.updateClassGradebookEventFailure(false));

    }
    finally {
        const apiValue = {
            "classId": action.data.classId,
            "page_no": 1,
        }
        yield put(UserClassGradebookReducer.getClassGradebookRequest(apiValue));
    }
}
// ---------- Update Class Gradebook Event-----


// ---------- Delete Class Gradebook Event------
function* deleteClassGradebookEventRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeleteClassGradebook_Event_API;
    // const BODY = JSON.stringify({
    //     "chroniclecloud_code": chroniclecloud_code,
    //     "user_id": data.cc_user_id,
    //     "device_udid": sessionStorage.getItem('UDID'),
    //     "event_id": action.data.eventId,
    // })

    const BODY = {
        "user_id": data.cc_user_id.toString(),
        "event_id": action.data.eventId,
        "platform": "web"
      }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);

        if (MainDecode.status === 200) {
            // createNotification('success', MainDecode.message);
            yield put(UserClassGradebookReducer.deleteClassGradebookEventSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassGradebookReducer.deleteClassGradebookEventFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassGradebookReducer.deleteClassGradebookEventFailure(false));

    }
    finally {
        const apiValue = {
            "classId": action.data.classId,
            "page_no": 1,
        }
        yield put(UserClassGradebookReducer.getClassGradebookRequest(apiValue));
    }
}
// ---------- Delete Class Gradebook Event-----

// ---------- Update Class Gradebook Score and Comment------
function* updateClassGradebookScoreCommentRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = UpdateClassGradebook_ScoreComment_API;
    // const BODY = JSON.stringify({
    //     "chroniclecloud_code": chroniclecloud_code,
    //     "user_id": data.cc_user_id,
    //     "device_udid": sessionStorage.getItem('UDID'),
    //     "event_id": action.data.eventId,
    //     "comments": action.data.comments,
    //     "color_code": action.data.colorCode,
    //     "score": action.data.score,
    //     "student_id": action.data.studentId
    // })

    const BODY = {
        "user_id": data.cc_user_id.toString(),
        "event_id": action.data.eventId,
        "comments": action.data.comments,
        "color_code": action.data.colorCode,
        "score": action.data.score,
        "student_id": action.data.studentId,
        "platform": "web"
      }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            createNotification('success', MainDecode.message);
            yield put(UserClassGradebookReducer.updateClassGradebookScoreCommentSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            if (MainDecode.message === "Note limit has been exceeded. Please upgrade the plan"
            ||"Media limit has been exceeded. Please upgrade the plan"
            || "Please update your subscription plan") {
                yield put(UserClassGradebookReducer.upgradePopUpOpenGradebook(MainDecode));
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassGradebookReducer.updateClassGradebookScoreCommentFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassGradebookReducer.updateClassGradebookScoreCommentFailure(false));

    }
    finally {
        const apiValue = {
            "classId": action.data.classId,
            "page_no": 1,
        }
        yield put(UserClassGradebookReducer.getClassGradebookRequest(apiValue));
    }
}
// ---------- Update Class Gradebook Score and Comment-----


// ---------- Update Class Gradebook Drag Data------ (Need To Update)
function* updateClassGradebookDragDataRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = UpdateClassGradebook_Index_API;
    // const BODY = JSON.stringify({
    //     "chroniclecloud_code": chroniclecloud_code,
    //     "user_id": data.cc_user_id,
    //     "device_udid": sessionStorage.getItem('UDID'),
    //     "category_id": action.data.categoryId,
    //     "new_index": action.data.newIndex.toString(),
    // })

    const BODY = {
        "user_id": data.cc_user_id.toString(),
        "category_id": action.data.categoryId,
        "new_index": action.data.newIndex.toString(),
        "platform": "web"
      }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            // createNotification('success', MainDecode.message);
            yield put(UserClassGradebookReducer.updateClassGradebookDragDataSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            if (MainDecode.message === "Media limit has been exceeded. Please upgrade the plan") {
                yield put(UserClassGradebookReducer.upgradePopUpOpenGradebook(MainDecode));
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassGradebookReducer.updateClassGradebookDragDataFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassGradebookReducer.updateClassGradebookDragDataFailure(false));

    }
    finally {
        const apiValue = {
            "classId": action.data.classId,
            "page_no": 1,
        }
        yield put(UserClassGradebookReducer.getClassGradebookRequest(apiValue));
    }
}
// ---------- Update Class Gradebook Drag Data-----


// ---------- Update Class Gradebook Drag Data------
function* updateClassGradebookDragEventDataRequest(action) {
    const data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = UpdateClassGradebook_EventIndex_API;
    // const BODY = JSON.stringify({
    //     "chroniclecloud_code": chroniclecloud_code,
    //     "user_id": data.cc_user_id,
    //     "device_udid": sessionStorage.getItem('UDID'),
    //     "event_id": action.data.eventId,
    //     "event_index": action.data.newIndex.toString(),
    // })

    const BODY = {
        "user_id": data.cc_user_id.toString(),
        "event_id": action.data.eventId,
        "event_index": action.data.newIndex.toString(),
        "platform": "web"
      }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);

        if (MainDecode.status === 200) {
            // createNotification('success', MainDecode.message);
            yield put(UserClassGradebookReducer.updateClassGradebookDragEventDataSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            if (MainDecode.message === "Media limit has been exceeded. Please upgrade the plan") {
                yield put(UserClassGradebookReducer.upgradePopUpOpenGradebook(MainDecode));
            }
            createNotification('error', MainDecode.message);
            yield put(UserClassGradebookReducer.updateClassGradebookDragEventDataFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserClassGradebookReducer.updateClassGradebookDragEventDataFailure(false));

    }
    finally {
        const apiValue = {
            "classId": action.data.classId,
            "page_no": 1,
        }
        yield put(UserClassGradebookReducer.getClassGradebookRequest(apiValue));
    }
}
// ---------- Update Class Gradebook Drag Data-----