import React, { useEffect, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';

import './ClassGradeBookAverageFooter.scss'
import { checkArrayForChars } from 'Utils/Helper';

const ClassGradeBookAverageFooter = (props) => {
    const [allStudentAverageList, setAllStudentAverageList] = useState([])
    const { getClassGradebookCatList } = props
    useEffect(() => {
        let data = []
        getClassGradebookCatList.map((item) => {
            item.category_events.map((average) => {
                data.push(average?.event_average)
            })
        })
    },[getClassGradebookCatList])

    const deciamalPattern = '^\d+?\.\d+?$'
    return (
        <div className="table-gradebooks">
            {/* -------------Average Score ------ */}
            <div className="table-left-section-Average">
                <div className="avg-row-header" >
                    <div className="avgTitle"><label>Average Score</label></div>
                    {/* <div className="avgScoreNull"><label>00.00</label></div> */}
                </div>
            </div>
            <ScrollSyncPane group={["horizontal"]}>
                <div className='table-Average-section'>
                    {getClassGradebookCatList?.map((item, index) => (
                        <div key={index} className={`${item.category_events.length > 0 && "displayFlex"}`}>
                            {/* Avg Section */}
                            {item.category_events?.map((subItem, eventIndex) => {
                                let data = []
                                subItem.event_student.map((score,idx) => {
                                    data.push(score?.grading_event_student_score)
                                })
                                let result = checkArrayForChars(data)
                                if(result) {
                                    return (
                                        <div key={eventIndex} className="data-title data-sub-Avarage-style">
                                        <div className="sub-header" >
                                        {<label className='data-sub-Avarage-label'>-</label>}
                                        </div>
                                    </div>
                                    )
                                } else {
                                return (
                                <div key={eventIndex} className="data-title data-sub-Avarage-style">
                                    <div className="sub-header" >
                                    {subItem.event_average !== null && subItem.event_average===0 && <label className='data-sub-Avarage-label'>0.00</label>}
                                    {/* {subItem.event_average !== null &&subItem.event_average!==0 && subItem.event_average.toString().length < 2 &&<label className='data-sub-Avarage-label'>{"0"+subItem.event_average+".00"}</label>} */}
                                    {subItem.event_average !== null &&subItem.event_average!==0 &&  subItem.event_average.toString().split('.')[0].length < 2 &&<label className='data-sub-Avarage-label'>{"0"+subItem.event_average}</label>}
                                    {/* {subItem.event_average !== null &&subItem.event_average!==0 && subItem.event_average.toString().length > 1 && <label className='data-sub-Avarage-label'>{subItem.event_average.includes(".") ? (subItem.event_average + "0") : subItem.event_average }</label>} */}
                                    {subItem.event_average !== null &&subItem.event_average!==0 && subItem.event_average.toString().length > 1 && <label className='data-sub-Avarage-label'>{
                                    // subItem.event_average % 1 !=0 ? subItem.event_average + "0" : subItem.event_average + ".00"
                                    subItem.event_average
                                    }</label>}
                                    </div>
                                </div>
                                )
                                }
                                    })}
                            {/* Avg Section */}
                        </div>
                    ))}
                </div>
            </ScrollSyncPane>
        </div>
    )
}


export default ClassGradeBookAverageFooter