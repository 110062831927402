import React, {useEffect} from "react";
import PropTypes from "prop-types";
import paymentSuccess from "Assets/PayPalModule/paymentSuccess.png";
import professionalSmall from "Assets/SubscriptionsModule/professionalSmall.png";
import premiumSmall from "Assets/SubscriptionsModule/premiumSmall.png";
import successPlanImg from 'Assets/SubscriptionsModule/successPlanImg.png'
import * as routes from "Router/RoutesURL";
import Button from "react-bootstrap/Button";
import { Col, Row } from "reactstrap";

const PayPalSuccess = (props) => {
  const { goBack, goToAcademic, item } = props;
  let planItem = item || JSON.parse(sessionStorage.getItem('paymentObj'))
  let planContent = planItem?.cc_subscription_content?.split("\\n");

  useEffect(() => {
    if(sessionStorage.getItem('userHasFreePlan') == '0') {

      sessionStorage.removeItem('userHasFreePlan')   
      setTimeout(() => {
          window.location.href = routes.ACADEMICDASHBOARD
      }, 2000);
  } 
  },[])

  return (
    <div className="paymentSucessDiv p-0 m-0" style={{ height: 400, width: '98%' }}>
      <Row className="h-100">
        <Col
          md={6}
          lg={6}
          sm={12}
          xs={12}
          className="d-flex flex-wrap align-items-center justify-content-center flex-column"
        >
            <img src={successPlanImg} className="img-fluid" alt='successs' width={165}/>
          <p className="subscriptionSuccessParaStyle mt-4">
            Subscription Successful!
          </p>
        </Col>
        <Col
          md={6}
          lg={6}
          sm={12}
          xs={12}
          className="d-flex flex-column justify-content-center"
        >
          <div className="d-flex flex-column align-items-start justify-content-center">
            <img
              src={planItem?.plan_logo }
              alt={planItem?.cc_subscription_title}
              width={'90px'}
            />
            <p
              className={`mt-3 ${
                planItem?.cc_subscription_title === "Premium"
                  ? "premiumPara"
                  : "professionalPara"
              }`}
              style={{color : planItem?.color_code}}
            >
              {planItem?.cc_subscription_title}
            </p>
          </div>
          <div className="planDetailsPoint">
            <p className="mBot5 dataPlans" dataPlans>
              * {planItem?.cc_subscription_description}
            </p>
            {planContent?.map((planContentItem, idx) => {
              return (
                <p className="mBot5 dataPlans" key={idx}>
                  {planContentItem}
                </p>
              );
            })}
          </div>
          <p className="selectedPlanSuccessPara mt-3">
            {planItem?.selectedSubType == "annual"
              ? `$${planItem?.cc_subscription_annual_price} / year`
              : `$${planItem?.cc_subscription_price} / month`}
          </p>
        </Col>
      </Row>
      {/* <img src={paymentSuccess} alt={''} className='paymentSuccessImage' />
            <br /> */}
      {/* ------ Redirect Button------ */}
      {/* <div className='paypalButtonDiv'>
                <Button className='goBackButton' onClick={goBack}>Back</Button>
                <Button className='goHomeButton' onClick={goToAcademic}>Return to Home</Button>
            </div> */}
      {/* ------ Redirect Button------ */}
    </div>
  );
};
PayPalSuccess.propTypes = {
  goToAcademic: PropTypes.func,
  goBack: PropTypes.func,
};
export default PayPalSuccess;
