import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/analytics'
import "firebase/performance";
import 'firebase/messaging';

var firebaseConfig = {
  apiKey: "AIzaSyA6o-a9qL6DaqUjCSoCAp8652p_OKkU5UY",
  authDomain: "pr-chr-prd-mgmt-01.firebaseapp.com",
  projectId: "pr-chr-prd-mgmt-01",
  storageBucket: "us-central-01-bucket-prd",
  messagingSenderId: "582101023673",
  appId: "1:582101023673:web:c5f5121b4147d65f0cd468"
}
// Initialize Firebase

const firebaseAnalytics = firebase.initializeApp(firebaseConfig);
const firebaseSetProperties = firebase.analytics();
const perf = firebase.performance();
const storage = firebase.storage();
// const messaging = firebase.messaging();


let messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
}




const getToken = (setTokenFound) => {
  if (messaging && messaging?.getToken) {
    // return messaging.getToken({ vapidKey: 'BHWqeWRl3ReBgDgMNPVDPSIPyqyoyH0fjx8AV45CQ8VRl_xZ_ghuVr_oH3yyBfqsxn-XlN-6DohvUcPUPidNYDo' }
     // return messaging.getToken({ vapidKey: 'BHWqeWRl3ReBgDgMNPVDPSIPyqyoyH0fjx8AV45CQ8VRl_xZ_ghuVr_oH3yyBfqsxn-XlN-6DohvUcPUPidNYDo' }
    // {/* for dev*/}
    // return messaging.getToken({ vapidKey: 'BMBKxTo5c3vX-kVk8HsJODD3A7h7vM78njtCPIayThCKP7U-ubqQzU5XYVdouGrBxLvM5jcFqnFK-SIc0ZlNAbs' }
    {/* for stg*/}
    return messaging.getToken({ vapidKey: 'BDamaijIICJhw7KgW_v_Rl7RoIsLfPBbVuNllU3iHKcxhHXcu6RxovJ0Exz7ZAUmX7IWtMNoEIsezzNf_65WkzE' }
    ).then((currentToken) => {
      if (currentToken && currentToken.length > 0) {
        sessionStorage.setItem("FBDeviceToken", currentToken)
        setTokenFound(true);
      } else {
        setTokenFound(false);
      }
    }).catch((error) => {
      console.log(error)
    });
  } else {
    setTokenFound(false);
  }
}

const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export {
  storage, firebaseAnalytics, firebaseSetProperties, perf, getToken, messaging, onMessageListener, firebase as default
}