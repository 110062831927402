import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import disNonAtt from 'Assets/RubricsModule/disNonAtt.svg'
import absentAtt from 'Assets/RubricsModule/absentAtt.svg'
import RubricsAssessmentTable from './RubricsAssessmentTable';
import "./FillAssessmentComp.scss";

const FillAssessComp = (props) => {
  const {
    viewRubricDetailsStates,
    viewStudentsAssessmentDetailsState,
    saveAssessmentState,
    viewStudentsAssessmentDetails,
    saveAssessment,
    ClassDetails,
    selectedRubric,
    action,
    setShowFade,
    setfinalSubmitFade,
    setRubricDetailsMain,
    rubricDetailsMain,
    viewRubricDetails,
    setShowReset,
    doReset,
    showReset,
    resetAll,
    loader,
    history,
    setShowConfirm,
    showConfirm,
    setIsReset,
    isReset,
    setDoReset
  } = props;

  
  
  const [viewRubricDetailsState, setViewRubricDetailsState] = useState("");
  const [selectedStudentForData, setSelectedStudentForData] = useState("");
  const [showAbsentPopUp, setShowAbsentPopUp] = useState(false);
  const [selectedStudentForAbsentData, setSelectedStudentForAbsentData] = useState("");
  const [absentcomment, setAbsentcomment] = useState("");
  const [selectedStudentForPresent, setSelectedStudentForPresent] = useState("");
  const [selectedStudentForAbsent, setSelectedStudentForAbsent] = useState("");
  const [enableEditScore, setEnableEditScore] = useState(false);
  const [selectStudentScore, setSelectStudentScore] = useState(0)
  const [confirmPopUp, SetConfirmPopUp] = useState(showConfirm);
  const tableRightSection = [`tableRightSection ${selectedStudentForData === "" ? "forNoneData" : ""}`];
  const numbersArr = Array.from({ length: 10 }, (v, k) => k + 1);

  useEffect(() => {

    if (selectedStudentForData !== "") {
      const apiData = {
        "class_id": ClassDetails.cc_class_id,
        "rubric_id": selectedRubric.cc_rubric_id,
        "student_id": selectedStudentForData
      }

      viewStudentsAssessmentDetails(apiData)
    }
  }, [selectedStudentForData])

  useEffect(() => {
    let defaultPresentValues = [];
    let defaultAbsentValues = [];
    let currentPresentValues = [];
    let currentAbsentValues = [];
    let isDoneValues = [];
    let defaultStudentPoints = [];
    let currentStudentPoints = [];
    let defaultStudentComments = [];
    let currentStudentComments = [];

    let defaultArr = JSON.parse(sessionStorage.getItem("viewRubricDetailsStates"));
    viewRubricDetailsState && defaultArr && defaultArr.studentArr.map(val => {
      if (val.availability_status === "present") {
        defaultPresentValues.push(val.availability_status);
      } else if (val.availability_status === "absent") {
        defaultAbsentValues.push(val.availability_status);
      }
    })

    viewRubricDetailsState && viewRubricDetailsState && viewRubricDetailsState.studentArr.map(value => {
      if (value.availability_status === "present") {
        currentPresentValues.push(value.availability_status)
      } else if (value.availability_status === "absent") {
        currentAbsentValues.push(value.availability_status);
      }
    })

    if (defaultPresentValues.length !== currentPresentValues.length || defaultAbsentValues.length !== currentAbsentValues.length) {
      setShowFade(false);
      setShowReset(true);
    } else {
      setShowFade(true);
      setShowReset(false);
    }

    viewStudentsAssessmentDetailsState !== null && viewStudentsAssessmentDetailsState?.map(val => {
      defaultStudentPoints.push(val.cc_ra_assessment_point);
    })
    if (viewRubricDetailsStates && viewRubricDetailsState && viewStudentsAssessmentDetailsState && selectedStudentForData) {
      const assessDataIndex = viewRubricDetailsStates && viewRubricDetailsState && viewRubricDetailsStates.studentArr.findIndex(student => student.cc_student_id === selectedStudentForData);
      viewRubricDetailsStates && viewStudentsAssessmentDetailsState && viewRubricDetailsState && assessDataIndex && viewRubricDetailsState.studentArr[assessDataIndex].assessmentArr.map(values => {
        currentStudentPoints.push(values.cc_ra_assessment_point);
      })
    }

    // /-----reset for comments -------- \
    if (JSON.stringify(currentStudentPoints) == JSON.stringify(defaultStudentPoints)) {
      setShowFade(true);
    } else {
      setShowFade(false);
    }

    const combinedAvailabilty = currentPresentValues.concat(currentAbsentValues);
    viewRubricDetailsState && viewRubricDetailsState.studentArr.map(value => {
      if (value.assessment_completed === "1") {
        isDoneValues.push("1");
      }
    })

    let doneValues = [];
    if (viewRubricDetailsState && viewRubricDetailsState.studentArr) {
      viewRubricDetailsState.studentArr.map(val => {
        if (val.assessment_completed === "1") {
          doneValues.push(val.assessment_completed);
        }
      })
    }

    if (viewRubricDetailsState && doneValues && doneValues.length === viewRubricDetailsState.studentArr.length) {
      setfinalSubmitFade(false);
    } else {
      setfinalSubmitFade(true);
    }
  })

  useEffect(() => {
    let currentInfo = [];
    let defaultInfo = [];
    let currentAssessPoints = [];
    let defaultAssessPoints = [];
    let currentAssessComments = [];
    let defaultAssessComments = [];
    let defaultArr = JSON.parse(sessionStorage.getItem("viewRubricDetailsStates"));
    viewRubricDetailsStates && viewRubricDetailsState && viewRubricDetailsState.studentArr.map(val => {
      val.assessmentArr.map(value => {
        currentInfo.push(value)
      })
    })
    viewRubricDetailsStates && viewRubricDetailsState && defaultArr && defaultArr.studentArr.map(val => {
      if (val.assessmentArr.length > 0) {
        val.assessmentArr.map(value => {
          defaultInfo.push(value)
        })
      } else {
        defaultArr.criteriaArr.map(value => {
          defaultInfo.push({ ...value, "cc_ra_assessment_point": "", "cc_ra_assessment_comment": "" })
        })
      }
    })

    if (currentInfo && currentInfo.length > 0) {
      currentInfo.map(val => {
        if (selectedStudentForData === val.cc_student_id) {
          currentAssessPoints.push(val.cc_ra_assessment_point);
        }
      })
    }

    if (defaultInfo && defaultInfo.length > 0) {
      defaultInfo.map(val => {
        if (selectedStudentForData === val.cc_student_id) {
          defaultAssessPoints.push(val.cc_ra_assessment_point);
        }
      })
    }
    if (currentInfo && currentInfo.length > 0) {
      currentInfo.map(val => {
        if (selectedStudentForData === val.cc_student_id) {
          currentAssessComments.push(val.cc_ra_assessment_comment);
        }
      })
    }

    if (defaultInfo && defaultInfo.length > 0) {
      defaultInfo.map(val => {
        if (selectedStudentForData === val.cc_student_id) {
          defaultAssessComments.push(val.cc_ra_assessment_comment);
        }
      })
    }

    let is_same = defaultAssessPoints.length == currentAssessPoints.length && defaultAssessPoints.every((element, index) => {
      return element === currentAssessPoints[index];
    });
    let is_sameComments = defaultAssessComments.length == currentAssessComments.length && defaultAssessComments.every((element, index) => {
      return element === currentAssessComments[index];
    });

    if (!is_same || !is_sameComments) {
      setShowReset(true);
    } else {
      setShowReset(false);
    }
  })

  useEffect(() => {
    if (doReset) {
      let defaultArr = JSON.parse(sessionStorage.getItem("viewRubricDetailsStates"));
      setViewRubricDetailsState(defaultArr);
    }
  }, [doReset])

  useEffect(() => {
   
    const allDataList = viewRubricDetailsStates && viewRubricDetailsStates && viewRubricDetailsStates.studentArr.map(val => {
      let dataVal = { ...val };
      
      let criData = [];
      if (dataVal.assessmentArr.length === 0) {
     
        viewRubricDetailsStates && viewRubricDetailsStates.criteriaArr.map(value => {
          const valObj = {};
          valObj["cc_criteria_id"] = value.cc_criteria_id;
          valObj["cc_criteria_title"] = value.cc_criteria_title;
          valObj["cc_ra_assessment_comment"] = "";
          valObj["cc_ra_assessment_point"] = "";
          valObj["cc_ra_created_at"] = value.cc_criteria_created_at;
          valObj["cc_ra_created_by"] = "";
          valObj["cc_ra_id"] = "";
          valObj["cc_ra_is_active"] = value.cc_criteria_is_active;
          valObj["cc_ra_student_availability_comment"] = "";
          valObj["cc_ra_student_availability_status"] = "";
          valObj["cc_ra_updated_at"] = value.cc_criteria_updated_at;
          valObj["cc_ra_updated_by"] = "";
          valObj["cc_rubric_id"] = selectedRubric?.cc_rubric_id;
          valObj["cc_student_id"] = val.cc_student_id;
          valObj["isDone"] = 0;
          criData.push(valObj);
        })
      } else if (dataVal.assessmentArr.length === viewRubricDetailsStates.criteriaArr.length) {
        console.log('246 else if')
        viewRubricDetailsStates && viewRubricDetailsStates.criteriaArr.map(value => {
          dataVal.assessmentArr.map(values => {
            if (value.cc_criteria_id === values.cc_criteria_id) {
              let valObj = { ...values, cc_criteria_title: value.cc_criteria_title };
              
              criData.push(valObj);
            }
          })
        })
      }
      else if (viewRubricDetailsStates && dataVal.assessmentArr.length !== viewRubricDetailsStates.criteriaArr.length) {
        console.log('257 else if')
        dataVal.assessmentArr.map((values, index) => {
          const valObje = {};
          valObje["cc_criteria_id"] = values?.cc_criteria_id;
          valObje["cc_criteria_title"] = viewRubricDetailsState && viewRubricDetailsStates && viewRubricDetailsStates?.criteriaArr[index]?.cc_criteria_title;
          valObje["cc_ra_assessment_comment"] = values?.cc_ra_assessment_comment;
          valObje["cc_ra_assessment_point"] = values.cc_ra_assessment_point;
          valObje["cc_ra_created_at"] = values?.cc_ra_created_at;
          valObje["cc_ra_created_by"] = values?.cc_ra_created_by;
          valObje["cc_ra_id"] = values?.cc_ra_id;
          valObje["cc_ra_is_active"] = values?.cc_ra_is_active;
          valObje["cc_ra_student_availability_comment"] = values?.cc_ra_student_availability_comment;
          valObje["cc_ra_student_availability_status"] = values?.cc_ra_student_availability_status;
          valObje["cc_ra_updated_at"] = values?.cc_ra_updated_at;
          valObje["cc_ra_updated_by"] = values?.cc_ra_updated_by;
          valObje["cc_rubric_id"] = values?.cc_rubric_id;
          valObje["cc_student_id"] = dataVal?.cc_student_id;
          criData.push(valObje);
        })

        const criterData = [];
        viewRubricDetailsStates && viewRubricDetailsStates.criteriaArr.map(values => {
          criterData.push(values);
        })
        const assessArrData = [];
        dataVal.assessmentArr.map(value => {
          assessArrData.push(value);
        })
        let valuesA = criterData.reduce((criterData, { cc_criteria_id }) => Object.assign(criterData, { [cc_criteria_id]: cc_criteria_id }), {});
        let valuesB = assessArrData.reduce((criterData, { cc_criteria_id }) => Object.assign(criterData, { [cc_criteria_id]: cc_criteria_id }), {});
        let result = [...criterData.filter(({ cc_criteria_id }) => !valuesB[cc_criteria_id]), ...assessArrData.filter(({ cc_criteria_id }) => !valuesA[cc_criteria_id])];
        result && result.map(values => {
          const valObj = {};
          valObj["cc_criteria_id"] = values.cc_criteria_id;
          valObj["cc_criteria_title"] = values.cc_criteria_title;
          valObj["cc_ra_assessment_comment"] = "";
          valObj["cc_ra_assessment_point"] = "";
          valObj["cc_ra_created_at"] = values.cc_criteria_created_at;
          valObj["cc_ra_created_by"] = "";
          valObj["cc_ra_id"] = "";
          valObj["cc_ra_is_active"] = values.cc_criteria_is_active;
          valObj["cc_ra_student_availability_comment"] = "";
          valObj["cc_ra_student_availability_status"] = "";
          valObj["cc_ra_updated_at"] = values.cc_criteria_updated_at;
          valObj["cc_ra_updated_by"] = "";
          valObj["cc_rubric_id"] = selectedRubric?.cc_rubric_id;
          valObj["cc_student_id"] = dataVal.cc_student_id;
          valObj["isDone"] = 0;
          criData.push(valObj);
        })
      }
      
      return dataVal = { ...dataVal, "assessmentArr": criData }
    })
    sessionStorage.setItem("viewRubricDetailsStates", JSON.stringify({ ...viewRubricDetailsStates, "studentArr": allDataList }));
    
    setViewRubricDetailsState({ ...viewRubricDetailsStates, "studentArr": allDataList });
  }, [viewRubricDetailsStates])

  useEffect(() => {
    if (viewRubricDetailsStates && viewRubricDetailsState && viewStudentsAssessmentDetailsState && viewStudentsAssessmentDetailsState.length > 0) {
      let newDetailData = viewRubricDetailsState && viewRubricDetailsState && viewRubricDetailsState.studentArr.map(val => {
        let viewStudentAssessVal = { ...val };
        if (viewStudentAssessVal.cc_student_id === viewStudentsAssessmentDetailsState[0].cc_student_id) {
          if (viewStudentAssessVal.assessmentArr.length === viewStudentsAssessmentDetailsState.length) {
            const criData = [];
            viewStudentsAssessmentDetailsState && viewStudentsAssessmentDetailsState?.map((val, index) => {
              if (viewRubricDetailsState?.criteriaArr[index]?.cc_criteria_id === val?.cc_criteria_id) {
                const valObj = {};
                valObj["cc_criteria_id"] = val.cc_criteria_id;
                valObj["cc_criteria_title"] = viewRubricDetailsState?.criteriaArr[index]?.cc_criteria_title;
                valObj["cc_ra_assessment_comment"] = val.cc_ra_assessment_comment;
                valObj["cc_ra_assessment_point"] = val.cc_ra_assessment_point;
                valObj["cc_ra_created_at"] = val.cc_criteria_created_at;
                valObj["cc_ra_created_by"] = val.cc_ra_created_by;
                valObj["cc_ra_id"] = val.cc_ra_id;
                valObj["cc_ra_is_active"] = val.cc_ra_is_active;
                valObj["cc_ra_student_availability_comment"] = val.cc_ra_student_availability_comment;
                valObj["cc_ra_student_availability_status"] = val.cc_ra_student_availability_status;
                valObj["cc_ra_updated_at"] = val.cc_ra_updated_at;
                valObj["cc_ra_updated_by"] = val.cc_ra_updated_by;
                valObj["cc_rubric_id"] = val.cc_rubric_id;
                valObj["cc_student_id"] = Number(val.cc_student_id);
                valObj["isDone"] = "1";
                criData.push(valObj);
              }
            })
            viewStudentAssessVal = { ...viewStudentAssessVal, assessmentArr: criData, assessment_completed: "1" }
          } else {
            const criData = [];
            viewStudentsAssessmentDetailsState.map((val, index) => {
              const valObje = {};
              if (viewRubricDetailsState?.criteriaArr[index]?.cc_criteria_id === val?.cc_criteria_id) {
                valObje["cc_criteria_id"] = val.cc_criteria_id;
                valObje["cc_criteria_title"] = viewRubricDetailsState.criteriaArr[index].cc_criteria_title;
                valObje["cc_ra_assessment_comment"] = val.cc_ra_assessment_comment;
                valObje["cc_ra_assessment_point"] = val.cc_ra_assessment_point;
                valObje["cc_ra_created_at"] = val.cc_criteria_created_at;
                valObje["cc_ra_created_by"] = val.cc_ra_created_by;
                valObje["cc_ra_id"] = val.cc_ra_id;
                valObje["cc_ra_is_active"] = val.cc_ra_is_active;
                valObje["cc_ra_student_availability_comment"] = val.cc_ra_student_availability_comment;
                valObje["cc_ra_student_availability_status"] = val.cc_ra_student_availability_status;
                valObje["cc_ra_updated_at"] = val.cc_ra_updated_at;
                valObje["cc_ra_updated_by"] = val.cc_ra_updated_by;
                valObje["cc_rubric_id"] = val.cc_rubric_id;
                valObje["cc_student_id"] = Number(val.cc_student_id);
                valObje["isDone"] = "1";
                criData.push(valObje);
              }
            })
            const assessArrData = [];
            viewRubricDetailsState && viewRubricDetailsState.criteriaArr.map(val => {
              assessArrData.push(val);
            })
            const results = assessArrData.filter(({ cc_criteria_id: id1 }) => !viewStudentsAssessmentDetailsState.some(({ cc_criteria_id: id2 }) => id2 === id1));
            results && results.map(values => {
              const valObj = {};
              valObj["cc_criteria_id"] = values.cc_criteria_id;
              valObj["cc_criteria_title"] = values.cc_criteria_title;
              valObj["cc_ra_assessment_comment"] = "";
              valObj["cc_ra_assessment_point"] = "";
              valObj["cc_ra_created_at"] = values.cc_criteria_created_at;
              valObj["cc_ra_created_by"] = "";
              valObj["cc_ra_id"] = "";
              valObj["cc_ra_is_active"] = values.cc_criteria_is_active;
              valObj["cc_ra_student_availability_comment"] = "";
              valObj["cc_ra_student_availability_status"] = "";
              valObj["cc_ra_updated_at"] = values.cc_criteria_updated_at;
              valObj["cc_ra_updated_by"] = "";
              valObj["cc_rubric_id"] = selectedRubric?.cc_rubric_id;
              valObj["cc_student_id"] = viewStudentsAssessmentDetailsState[0].cc_student_id;
              valObj["isDone"] = 0;
            criData.push(valObj);
            })
            let avgValues = [];
            criData && criData.map(val => {
              if (val.cc_ra_assessment_point !== undefined) {
                avgValues.push(Number(val.cc_ra_assessment_point))
              }
            })
            let sumation = avgValues.reduce((a, b) => a + b, 0)
            let sum = sumation / criData.length;
            viewStudentAssessVal = { ...viewStudentAssessVal, assessmentArr: criData, assessment_completed: "1", "average_score": sum * 10, "availability_status": "present" }
          
          }
        } else {
          viewStudentAssessVal = { ...viewStudentAssessVal }
        }
        return { ...viewStudentAssessVal }
      })
      setViewRubricDetailsState({ ...viewRubricDetailsState, studentArr: newDetailData });
    }
  }, [viewStudentsAssessmentDetailsState])

  useEffect(() => {
   
    if (action === "SAVE_ASSESSMENT_SUCCESS" && saveAssessmentState) {
      if (viewRubricDetailsState && viewRubricDetailsStates && saveAssessmentState && saveAssessmentState.status === 200 && saveAssessmentState?.data?.assessment_details_array[0].availability_status === "absent") {
          console.log(421, 'if')
        const criData = [];
        let valRubDetailsmain = viewRubricDetailsState && viewRubricDetailsState.studentArr.map(val => {
          let rubVal = { ...val };
          if (rubVal.cc_student_id === selectedStudentForAbsent) {
            console.log('if')
            const valObj = {};
            valObj["cc_criteria_id"] = "";
            valObj["cc_criteria_title"] = "";
            valObj["cc_ra_assessment_comment"] = saveAssessmentState.data.assessment_details_array[0].assessment_comment;
            valObj["cc_ra_assessment_point"] = "";
            valObj["cc_ra_created_at"] = "";
            valObj["cc_ra_created_by"] = "";
            valObj["cc_ra_id"] = saveAssessmentState.data.assessment_details_array[0].id;
            valObj["cc_ra_is_active"] = "";
            valObj["cc_ra_student_availability_comment"] = "";
            valObj["cc_ra_student_availability_status"] = saveAssessmentState.data.assessment_details_array[0].availability_status;
            valObj["cc_ra_updated_at"] = "";
            valObj["cc_ra_updated_by"] = "";
            valObj["cc_rubric_id"] = selectedRubric?.cc_rubric_id;
            valObj["cc_student_id"] = Number(saveAssessmentState.data.assessment_details_array[0].cc_student_id);
            criData.push(valObj);
            rubVal = { ...rubVal, "assessmentArr": criData, "availability_status": saveAssessmentState.data.assessment_details_array[0].availability_status, "assessment_completed": "1" }
          } else {
            rubVal = { ...rubVal }
          }
          return rubVal
        })
        sessionStorage.setItem("viewRubricDetailsStates", JSON.stringify({ ...viewRubricDetailsState, "studentArr": valRubDetailsmain }));
        setViewRubricDetailsState({ ...viewRubricDetailsState, "studentArr": valRubDetailsmain });
      } else if (viewRubricDetailsState && saveAssessmentState && saveAssessmentState.status === 200 && saveAssessmentState?.data?.assessment_details_array[0]?.availability_status === "present") {
        console.log('else if')
      
        const valObj = [];
        let valRubDetails = viewRubricDetailsState && viewRubricDetailsState.studentArr && viewRubricDetailsState.studentArr.map(val => {
          let rubVal = { ...val };
          if (rubVal.cc_student_id === selectedStudentForPresent) {
            
            console.log(459, 'if')
            if (viewRubricDetailsState.criteriaArr.length !== saveAssessmentState.data.assessment_details_array.length) {
              console.log(464, 'if')
              const criData = [];
              viewRubricDetailsStates && viewRubricDetailsState && viewRubricDetailsState.criteriaArr.map(values => {
                const valObj = {};
                valObj["cc_criteria_id"] = values.cc_criteria_id;
                valObj["cc_criteria_title"] = values.cc_criteria_title;
                valObj["cc_ra_assessment_comment"] = "";
                valObj["cc_ra_assessment_point"] = "";
                valObj["cc_ra_created_at"] = values.cc_criteria_created_at;
                valObj["cc_ra_created_by"] = "";
                valObj["cc_ra_id"] = "";
                valObj["cc_ra_is_active"] = values.cc_criteria_is_active;
                valObj["cc_ra_student_availability_comment"] = "";
                valObj["cc_ra_student_availability_status"] = "";
                valObj["cc_ra_updated_at"] = values.cc_criteria_updated_at;
                valObj["cc_ra_updated_by"] = "";
                valObj["cc_rubric_id"] = selectedRubric?.cc_rubric_id;
                valObj["cc_student_id"] = Number(saveAssessmentState.data.assessment_details_array[0].cc_student_id);
                valObj["isDone"] = 0;
                criData.push(valObj);
              })
              criData.shift();
              const valObje = {};
              valObje["cc_criteria_id"] = saveAssessmentState.data.assessment_details_array[0].criteria_id;
              valObje["cc_criteria_title"] = viewRubricDetailsState.criteriaArr[0].cc_criteria_title;
              valObje["cc_ra_assessment_comment"] = saveAssessmentState.data.assessment_details_array[0].assessment_comment;
              valObje["cc_ra_assessment_point"] = "";
              valObje["cc_ra_created_at"] = "";
              valObje["cc_ra_created_by"] = "";
              valObje["cc_ra_id"] = saveAssessmentState.data.assessment_details_array[0].id;
              valObje["cc_ra_is_active"] = "";
              valObje["cc_ra_student_availability_comment"] = "";
              valObje["cc_ra_student_availability_status"] = saveAssessmentState.data.assessment_details_array[0].availability_status;
              valObje["cc_ra_updated_at"] = "";
              valObje["cc_ra_updated_by"] = "";
              valObje["cc_rubric_id"] = selectedRubric?.cc_rubric_id;
              valObje["cc_student_id"] = Number(saveAssessmentState.data.assessment_details_array[0].cc_student_id);
              criData.unshift(valObje);
              rubVal = { ...rubVal, assessmentArr: criData, "assessment_completed": saveAssessmentState.data.assessment_completed }
            } else {
              console.log('else')
              const criData = [];
              viewRubricDetailsState && saveAssessmentState && saveAssessmentState.data.assessment_details_array.map((val, index) => {
                const valObj = {};
                valObj["cc_criteria_id"] = val.criteria_id;
                valObj["cc_criteria_title"] = viewRubricDetailsState.criteriaArr[index].cc_criteria_title;
                valObj["cc_ra_assessment_comment"] = val.assessment_comment;
                valObj["cc_ra_assessment_point"] = val.assessment_point;
                valObj["cc_ra_created_at"] = "";
                valObj["cc_ra_created_by"] = "";
                valObj["cc_ra_id"] = val.id;
                valObj["cc_ra_is_active"] = "";
                valObj["cc_ra_student_availability_comment"] = val.availability_comment;
                valObj["cc_ra_student_availability_status"] = val.availability_status;
                valObj["cc_ra_updated_at"] = "";
                valObj["cc_ra_updated_by"] = "";
                valObj["cc_rubric_id"] = selectedRubric?.cc_rubric_id;
                valObj["cc_student_id"] = Number(val.cc_student_id);
                criData.push(valObj);
              })
              
              rubVal = { ...rubVal, assessmentArr: criData, "assessment_completed": "1" }
            }
          } else {
            
            // console.log(524, 'else')
            
            rubVal = { ...rubVal }
          }
          valObj.push(rubVal);
        })
        sessionStorage.setItem("viewRubricDetailsStates", JSON.stringify({ ...viewRubricDetailsState, "studentArr": valObj }));
        setViewRubricDetailsState({ ...viewRubricDetailsState, "studentArr": valObj });
      }
    }
  }, [action, saveAssessmentState])

  const selectStudent = (val) => {
    
    setShowReset(false)
    setSelectStudentScore(val.average_score)
    if (val.availability_status !== "absent") {
      setSelectedStudentForData(val.cc_student_id);
      setEnableEditScore(false);
    }
  }

  const disAttRemarks = (data) => {

    setSelectStudentScore(data?.average_score)
    if (!loader) {
      setSelectedStudentForPresent(data.cc_student_id);
      setSelectedStudentForData(data.cc_student_id);
      let viewStudentAssessDataForList = viewRubricDetailsState && viewRubricDetailsState.studentArr.map((value, j) => {
        let viewStudentAssessVal = { ...value };
        if (viewStudentAssessVal.cc_student_id === data.cc_student_id) {
          viewStudentAssessVal = { ...viewStudentAssessVal, "availability_status": "present" }
        } else {
          viewStudentAssessVal = { ...viewStudentAssessVal }
        }
        return viewStudentAssessVal
      })
      
      setViewRubricDetailsState({ ...viewRubricDetailsState, "studentArr": viewStudentAssessDataForList })

      viewRubricDetailsState && viewRubricDetailsState.studentArr.map(val => {
        if (val.cc_student_id === data.cc_student_id) {
          const detailsData = [{
            "id": data.assessmentArr[0].cc_ra_id,
            "criteria_id": viewRubricDetailsState.criteriaArr[0].cc_criteria_id,
            "availability_status": "present",
            "assessment_point": "",
            "assessment_comment": "",
            "availability_comment": "",
            "average_score": ""
          }]
          const apiData = {
            "class_id": JSON.stringify(ClassDetails.cc_class_id),
            "rubric_id": JSON.stringify(selectedRubric.cc_rubric_id),
            "student_id": JSON.stringify(data.cc_student_id),
            "assessment_details_array": detailsData
          }
          
          saveAssessment(apiData);
        }
      })
    }
  }

  const setAttRemarks = (data) => {
    if (!loader) {
      setSelectedStudentForAbsent(data.cc_student_id);
      setSelectedStudentForAbsentData(data);
      setShowAbsentPopUp(true);
    }
  }

  const cancelAbsentBox = () => {
    setShowAbsentPopUp(false);
    setAbsentcomment("");
    setSelectedStudentForAbsent("");
    setSelectedStudentForAbsentData("");
  }

  const confirmAbsentReport = (data) => {
    
    setShowAbsentPopUp(false);
    let viewStudentAssessDataForList = viewRubricDetailsState && viewRubricDetailsState.studentArr.map((value, j) => {
      let viewStudentAssessVal = { ...value };
      if (viewStudentAssessVal.cc_student_id === selectedStudentForAbsentData.cc_student_id) {
        viewStudentAssessVal = { ...viewStudentAssessVal, "availability_status": "absent", "average_score": "" ,"assessment_completed" : "1"}
      } else {
        viewStudentAssessVal = { ...viewStudentAssessVal }
      }
      return viewStudentAssessVal
    })
    setViewRubricDetailsState({ ...viewRubricDetailsState, "studentArr": viewStudentAssessDataForList })

    viewRubricDetailsState && viewRubricDetailsState.studentArr.map((value) => {
      if (value.cc_student_id === selectedStudentForAbsentData.cc_student_id) {
        if (value.assessmentArr.length > 0) {
          const detailsData = [{
            "id": value.assessmentArr[0].cc_ra_id,
            "criteria_id": "",
            "availability_status": "absent",
            "assessment_point": "",
            "assessment_comment": "",
            "availability_comment": absentcomment,
            "average_score": ""
          }]
          const apiData = {
            "class_id": JSON.stringify(ClassDetails.cc_class_id),
            "rubric_id": JSON.stringify(selectedRubric.cc_rubric_id),
            "student_id": JSON.stringify(selectedStudentForAbsent),
            "assessment_details_array": detailsData
          }

          saveAssessment(apiData);
        } else {
          const detailsData = [{
            "id": "",
            "criteria_id": "",
            "availability_status": "absent",
            "assessment_point": "",
            "assessment_comment": "",
            "availability_comment": absentcomment,
            "average_score": ""
          }]
          const apiData = {
            "class_id": JSON.stringify(ClassDetails.cc_class_id),
            "rubric_id": JSON.stringify(selectedRubric.cc_rubric_id),
            "student_id": JSON.stringify(selectedStudentForAbsent),
            "assessment_details_array": detailsData
          }
          saveAssessment(apiData);
        }
      }
    })
    setAbsentcomment("");
  }

  const changePercentage = (criteria, index, num) => {
    if (enableEditScore) {
      let viewStudentAssessData = viewStudentsAssessmentDetailsState && viewRubricDetailsState && viewRubricDetailsState.studentArr.map((value, j) => {
        let viewStudentAssessVal = { ...value };
        let avgValues = [];
        if (viewStudentAssessVal.cc_student_id === criteria.cc_student_id) {
          viewStudentAssessVal.assessmentArr.map(val => {
            if (val.cc_criteria_id === criteria.cc_criteria_id) {
              val["cc_ra_assessment_point"] = num;
              val["cc_ra_average_score"] = num * 10;
            }
            if (val.cc_ra_assessment_point !== undefined) {
              avgValues.push(Number(val.cc_ra_assessment_point))
            }
          })
          let sumation = avgValues.reduce((a, b) => a + b, 0)
          let sum = sumation / viewStudentAssessVal.assessmentArr.length;
          viewStudentAssessVal = { ...viewStudentAssessVal, "average_score": sum * 10, "availability_status": "present" }
        } else {
          viewStudentAssessVal = { ...viewStudentAssessVal }
        }
        return viewStudentAssessVal
      })
      setViewRubricDetailsState(viewStudentAssessData);

      let viewStudentAssessDataForList = viewRubricDetailsState && viewRubricDetailsState.studentArr.map((value, j) => {
        let viewStudentAssessVal = { ...value };
        let avgValues = [];
        if (viewStudentAssessVal.cc_student_id === criteria.cc_student_id) {
          viewStudentAssessVal.assessmentArr.map(val => {
            if (val.cc_ra_assessment_point !== undefined) {
              avgValues.push(Number(val.cc_ra_assessment_point))
            }
          })
          let sumation = avgValues.reduce((a, b) => a + b, 0)
          let sum = sumation / viewStudentAssessVal.assessmentArr.length;
          viewStudentAssessVal = { ...viewStudentAssessVal, "average_score": sum * 10, "availability_status": "present" }
        } else {
          viewStudentAssessVal = { ...viewStudentAssessVal }
        }
        return viewStudentAssessVal
      })
      setViewRubricDetailsState({ ...viewRubricDetailsState, "studentArr": viewStudentAssessDataForList })
    }
  }

  const changeCommentUpdate = (criteria, index, comment) => {
    if (enableEditScore) {
      let viewStudentAssessData = viewStudentsAssessmentDetailsState && viewRubricDetailsState && viewRubricDetailsState.studentArr.map((value, j) => {
        let viewStudentAssessVal = { ...value };
        let avgValues = [];
        if (viewStudentAssessVal.cc_student_id === criteria.cc_student_id) {
          viewStudentAssessVal.assessmentArr.map(val => {
            if (val.cc_criteria_id === criteria.cc_criteria_id) {
              val["cc_ra_assessment_comment"] = comment;
            }
          })
        } else {
          viewStudentAssessVal = { ...viewStudentAssessVal }
        }
        return viewStudentAssessVal
      })
      setViewRubricDetailsState({ ...viewRubricDetailsState, studentArr: viewStudentAssessData })
    }
  }

  const saveCommentCriteria = (value) => {

    let avgValues = [];
    const assessDataIndex = viewRubricDetailsState.studentArr.findIndex(student => student.cc_student_id === selectedStudentForData);
    viewRubricDetailsState.studentArr[assessDataIndex].assessmentArr.map(val => {
      if (val.cc_ra_assessment_point !== undefined) {
        avgValues.push(Number(val.cc_ra_assessment_point))
      }
    })

    let sumation = avgValues.reduce((a, b) => a + b, 0)
    let sum = (sumation / viewRubricDetailsState.criteriaArr.length) * 10;
    let crr = [];
    viewStudentsAssessmentDetailsState && viewRubricDetailsState && viewRubricDetailsState.studentArr[assessDataIndex].assessmentArr.map(values => {
      if (values.cc_student_id === selectedStudentForData) {
        let detailsData = {
          "id": values.cc_ra_id,
          "criteria_id": values.cc_criteria_id,
          "availability_status": "present",
          "assessment_point": values.cc_ra_assessment_point,
          "assessment_comment": values.cc_ra_assessment_comment,
          "availability_comment": "",
          "average_score": sum
        }
        crr.push(detailsData)
      }
    })
    const apiData = {
      "class_id": JSON.stringify(ClassDetails.cc_class_id),
      "rubric_id": JSON.stringify(selectedRubric.cc_rubric_id),
      "student_id": JSON.stringify(selectedStudentForData),
      "assessment_details_array": crr
    }
    saveAssessment(apiData)

    const rubDetails = viewStudentsAssessmentDetailsState && viewRubricDetailsState && viewRubricDetailsState.studentArr.map(values => {
      let rubVal = { ...values }
      if (rubVal.cc_student_id === selectedStudentForData) {
        rubVal = { ...rubVal, assessment_completed: "1" }
      } else {
        rubVal = { ...rubVal }
      }
      return rubVal
    })
    setViewRubricDetailsState({ ...viewRubricDetailsState, studentArr: rubDetails })
  }

  const enableEditScores = () => {
    setEnableEditScore(true);
  }
  const cancelWarnModal = () => {
    setShowConfirm(false);

  }

  // const closeWarnModal = () => {
  //   if (isReset) {
  //     console.log(752)
  //     setDoReset(true)
  //     setIsReset(false)
  //     setShowConfirm(false);
  //   }
  //   else if (!isReset) {
  //     console.log("GO BACK 123")
  //     setIsReset(false)
  //     history.goBack();

  //   }

  // }

  return (
    <div className="rubricsContainer">
      <div className="table-rubrics table-Fix-Size">
        {/* -------------Student List ------ */}
        <div className="table-rubrics-table">
          <div className="table-left-section">
            {viewRubricDetailsStates && viewRubricDetailsState && viewRubricDetailsState.studentArr.map((item, k) => {
              const headerStyle = ["headerStyle", selectedStudentForData === item.cc_student_id ? "selectedProfile" : ""];
              const avgScoresText = ["avgScoresText", item?.average_score <= "30" ? "begin" : item?.average_score <= "60" && item?.average_score > "30" ? "develope" : item?.average_score <= "80" && item?.average_score > "60" ? "accomplish" : item?.average_score <= "100" && item?.average_score > "80" ? "exemplar" : ""];
              const profileImg = ["profile-img", item.availability_status === "absent" ? "opaqueProf" : ""];
              const profileDummyRosterProfile = ["profileDummyRosterProfile", item.availability_status === "absent" ? "opaqueProf" : ""];
              const nameLable = ["nameLable", item.availability_status === "absent" ? "opaqueProf" : ""];
              return (<div className={headerStyle.join(' ')} key={k}>
                <div className="profile">
                  {item.cc_student_pic_url ?
                    <img className={profileImg.join(" ")} src={item.cc_student_pic_url} alt="" width="50" height="50" onClick={() => { selectStudent(item) }} />
                    :
                    <div className={profileDummyRosterProfile.join(" ")} onClick={() => { selectStudent(item) }}>
                      <h5 className='rosterProfileImageText'>{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
                    </div>
                  }
                  <label className={nameLable.join(" ")} onClick={() => { selectStudent(item) }}>{item.cc_student_last_name?.replace(/(.{8})..+/, "$1…")}, {item.cc_student_first_name?.replace(/(.{8})..+/, "$1…")}</label>
                  <div className='emojiIconsRub'>
                    {item.availability_status === "absent" ? (
                      <img className='emojiIcons-img' src={absentAtt}
                        onClick={() => { disAttRemarks(item) }}
                        alt='disEmoji' width='40' height='40' />
                    ) : ""}
                    {item.availability_status === "" ?
                      (<img className='emojiIcons-img' src={disNonAtt}
                        onClick={() => { setAttRemarks(item) }}
                        alt='disEmoji' width='40' height='40' />
                      ) : ""}
                    {item.availability_status === "present" && item?.average_score <= 0 ?
                      (<img className='emojiIcons-img' src={disNonAtt}
                        onClick={() => { setAttRemarks(item) }}
                        alt='disEmoji' width='40' height='40' />
                      ) : ""}

                    {item.availability_status === "present" && item?.average_score > 0 ?
                      (<p className={avgScoresText.join(" ")}
                        onClick={() => { setAttRemarks(item) }}
                      >{`${parseFloat(item?.average_score !== null && item?.average_score).toFixed(2)}%`}</p>) : ""}
                    {/* >{`${(item.average_score !== "" && item?.average_score)}%`}</p>) : ""}*/}
                    { /*>{`${(item.average_score)?.toFixed(2)}%`}</p>) : ""}*/}
                  </div>
                </div>
              </div>)
            })}
          </div>
          {/* -------------Student List ------ */}
          {/* -------------Table List ------ */}
          <div className={tableRightSection.join(' ')}>
            {!selectedStudentForData && <p className="NoneSelected">Click on student to Fill Assessment</p>}
            {selectedStudentForData && viewRubricDetailsState && <div>
              <RubricsAssessmentTable
                numbersArr={numbersArr}
                selectStudentScore={selectStudentScore}
                changePercentage={changePercentage}
                changeCommentUpdate={changeCommentUpdate}
                saveCommentCriteria={saveCommentCriteria}
                viewRubricDetailsState={viewRubricDetailsState}
                selectedStudentForData={selectedStudentForData}
                setShowReset={setShowReset}
                showReset={showReset}
                viewRubricDetailsStates={viewRubricDetailsStates}
                enableEditScores={enableEditScores}
                enableEditScore={enableEditScore}
                setEnableEditScore={setEnableEditScore}
                doReset={doReset}
                resetAll={resetAll}
                loader={loader}
                setViewRubricDetailsState={setViewRubricDetailsState}
                viewStudentsAssessmentDetailsState={viewStudentsAssessmentDetailsState}
              />
            </div>}
          </div>
        </div>
      </div>
      <Modal centered className="rubAbsent-modal-warning br-8" show={showAbsentPopUp} >
        <Modal.Header>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <div className="dateBlock">
          <div>
            <Form.Control
              onChange={(e) => { setAbsentcomment(e.target.value) }}
              as="textarea" rows="6" placeholder="Please Add Comment"
              value={absentcomment}
            />
          </div>
        </div>
        <Modal.Footer>
          <Button variant="default" className="cancelAtt-btn"
            onClick={cancelAbsentBox}
          >
            Cancel
          </Button>
          <Button variant="default" className="getReport-btn"
            onClick={confirmAbsentReport}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Confirmation Modal */}
      {/* <Modal centered className="rubricBack-modal-warning br-8" show={showConfirm} onHide={() => { SetConfirmPopUp(false) }}>
        <Modal.Body>
          <div>
            <img src={warningIcon} alt='' className='warningIcon' />
            <p className="waitTitle">Wait!</p>
            <div className="modalText"><p>Do you want to go back, your data will be lost.</p></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" className="cancelAtt-btn"
            onClick={cancelWarnModal}
          >
            Cancel
          </Button>
          <Button variant="default" className="getReport-btn"
            onClick={closeWarnModal}
          >
            {isReset === true ? "Reset" : "Go Back"}
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/* Confirmation Modal */}
    </div>
  );
}
export default FillAssessComp
