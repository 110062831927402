import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import CommonTextField from 'Components/CommonTextField/CommonTextField';
import cancelBlack from 'Assets/RosterModule/cancelBlack.png'
import camera from 'Assets/RosterModule/camera.png'
import {
    getRoster,
    editRoster,
    addRosterContact,
    editRosterContact,
    deleteRosterContact,
    addRosterInfo,
    editRosterInfo,
    deleteRosterInfo,
    addLinkRoster
} from 'Actions/RosterAction/rosterProfileAction';
import UpgradePopUp from '../../upgradePopUp/upgradePopUp'
import {  getRosterImage } from "Config/commonFirebaseImage";
import QRCode from 'qrcode.react';
import { createNotification } from 'Config/notificationtoast';
import { getRandomName } from "Utils/Helper";
import { FirebseStroageFolder } from 'Config/Constant';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import { storage } from "../../../firebase/firebase"
import './StudentProfile.scss';

class StudentProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rosterInfo: [],
            additionalContactsArray: [],
            additionalInformationArray: [],

            // EditModal ....
            UserProfileURL: '',
            UserProfile: '',
            UserProfileSize: '',
            FirstName: '',
            LastName: '',
            Email: '',
            isError: '',
            isAddStudentSubmit: false,

            //LinkModal ....
            linkFullName: '',
            linkEmail: '',
            linkRelation: 'Father',
            linkRemark: false,

            //Contact Text Para...
            ContactFullname: '',
            ContactEmail: '',
            ContactTitle: 'wewe',
            ContactRelation: '',
            ContactStatus: '',
            ContactsStatus: 'new',
            editContactsIndex: '',
            InvelidMessageEmail: '',

            //Note Text Para...
            editNoteIndex: '',
            NoteStatus: 'new',
            Note: '',
            showPopUp: false,


            // Link Modal Para...
            LinkModal: false,
            modalType: '',
            FullName: '',
            EmailAddress: '',
            LinkFieldDisable: false,
            // linkRelation: 'Father',
            // LinkRemark: false,
        };
    }



    // ---------get roster by Defult-------
    UNSAFE_componentWillMount() {
        this.getRosterDetails()
    }
    // ---------get roster by Defult-------

    // ---------componentWillReceiveProps-------
    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.rosterDetailsData && nextProps.rosterDetailsData.action === "GET_ROSTER_SUCCESS") {
            if (nextProps.rosterDetailsData.get_roster_Data && nextProps.rosterDetailsData.get_roster_Data.status == 200) {
                if (nextProps.rosterDetailsData.get_roster_Data.data) {
                    this.setState({
                        rosterInfo: nextProps.rosterDetailsData.get_roster_Data.data[0],
                        additionalContactsArray: nextProps.rosterDetailsData.get_roster_Data.data[1],
                        additionalInformationArray: nextProps.rosterDetailsData.get_roster_Data.data[2]
                    }, () => {

                    })
                    let RosterDetails = nextProps.rosterDetailsData.get_roster_Data.data[0]
                    if (RosterDetails[0].cc_student_pic_url) {
                        if (RosterDetails[0].cc_student_pic_url.includes("https") === true) {
                        }
                        else {
                            // RosterDetails[0].cc_student_pic_url = await getImage(RosterDetails[0].cc_student_pic_url);
                            RosterDetails[0].cc_student_pic_Mainurl = await getRosterImage(RosterDetails[0].cc_student_pic_url);

                        }
                    }
                    this.setState({
                        rosterInfo: RosterDetails
                    }, () => {
                        this.openStudentEditModal()

                    })
                }
            }
        }
        if (nextProps.rosterDetailsData.error && (nextProps.rosterDetailsData.error.message === "Note limit has been exceeded. Please upgrade the plan"
            || nextProps.rosterDetailsData.error.message === "Media limit has been exceeded. Please upgrade the plan"
            || nextProps.rosterDetailsData.error.message === "Please update your subscription plan")) {
            this.setState({
                showPopUp: true
            })
        }
    }


    // ---------getRosterList-------
    getRosterDetails() {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { id = '' } = state;
        this.setState({
            studentId: id
        })
        const value = {
            "student_id": id,
        }

        this.props.getRoster(value)
    }
    // ---------getRosterList-------


    // ---------Open Modal Value Fill-------
    openStudentEditModal() {
        let info = this.state.rosterInfo
        this.setState({
            FirstName: info[0].cc_student_first_name,
            LastName: info[0].cc_student_last_name,
            Email: info[0].cc_student_email_id,
            UserProfileURL: info[0].cc_student_pic_Mainurl,
            UserProfile: info[0].cc_student_pic_url,
            isError: '',
            isAddStudentSubmit: false,
        })
    }
    // ---------Open Modal Value Fill-------


    // ---------Edit Current Roster-------
    editRoster = () => {
        if (this.state.showLoaderUpload) {
            createNotification('error', 'The image is still uploading.');
            return;

        }
        this.setState({
            isAddStudentSubmit: true,
        })
        if ((!this.state.FirstName || (this.state.FirstName && this.state.FirstName.trim().length <= 0)) || (!this.state.FirstName || (this.state.FirstName && this.state.FirstName.trim().length <= 0))) {
            return false;
          }
          const pattern2 = /^[a-zA-Z]*$/;
          if ((!pattern2.test(this.state.FirstName)) || !pattern2.test(this.state.FirstName)) {
            return;
          }
        if (this.state.Email) {
            let valid = /^(?![.])[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?:\.[A-Za-z]{2,5})?$/;
            if (!valid.test(this.state.Email)) {
                this.setState({
                    InvelidMessageEmail: 'Please enter valid email*',
                });
                return false;
            }
        }
        const { UserProfile, FirstName, LastName, Email, rosterInfo, UserProfileSize } = this.state;
        const value = {
            "student_pic_size": UserProfileSize,
            "student_first_name": FirstName,
            "student_last_name": LastName,
            "student_email": Email,
            "student_pic_url": UserProfile,
            "student_id": rosterInfo[0].cc_student_id,
        }
        window.$('#myModal2').modal('hide');
        this.props.editRoster(value)

    }
    // ---------Edit Current Roster-------


    // ---------handleChange-------
    handleChange = (event) => {
        this.setState(
            {
                isAddStudentSubmit: false,
                InvelidMessageEmail: '',
                [event.target.name]: event.target.value,
            },
            () => { }
        );

    }
    // ---------handleChange-------


    // ---------Image Upload on Firebase-------
    handleChangeFile = (e) => {
        if (e.length === 0) {
            return false;
        }
        const data = JSON.parse(sessionStorage.getItem('UserData'))
        const imageAsFile = e[0];
        this.setState({
            showLoaderUpload: true
        })

        // ---------Image Type-------
        const ImageTypeArray = imageAsFile.name.split(".");
        const ImageType = ImageTypeArray[ImageTypeArray.length - 1];
        const media_Size = (imageAsFile.size / 1000000);
        // ---------Image Type-------

        // ---------DummyName-------
        const GetRendomName = getRandomName()
        const DummyName = GetRendomName + '.' + ImageType;
        // ---------DummyName-------

        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@")
        const FolderName = FinfFolderNameArray[0]
        // ---------FolderName-------


        const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/roster/${DummyName}`).put(imageAsFile)
        uploadTask.on('state_changed',
            (snapShot) => {

            }, (err) => {
                this.setState({
                    showLoaderUpload: false
                })
                createNotification('error', 'The image was not uploaded. Please try again.');
            }, () => {
                storage.ref(`${FirebseStroageFolder}/${FolderName}/roster/`).child(DummyName).getDownloadURL()

                    .then(fireBaseUrl => {
                        this.setState({
                            showLoaderUpload: false
                        })
                        // createNotification('success', 'Great! The Image Upload was successful!');
                        this.setState({
                            UserProfileURL: fireBaseUrl,
                            UserProfile: `${FirebseStroageFolder}/${FolderName}/roster/${DummyName}`,
                            UserProfileSize: media_Size,
                        }, () => {
                        })
                    })
            })
    }
    // ---------Image Upload on Firebase-------

    handleClick = event => {
        const { target = {} } = event || {};
        target.value = "";
    };


    // --------------------Link ----------------//

    // ------addNew Link-------
    newLink() {
        this.setState({
            isAddStudentSubmit: true,
        })
        if (!this.state.linkFullName || !this.state.linkEmail || !this.state.LinkRemark) {
            return false;
        }
        const valid = /^(?![.])[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?:\.[A-Za-z]{2,5})?$/;
        if (!valid.test(this.state.linkEmail)) {
            this.setState({
                InvelidMessageEmail: 'Please enter valid email*',
            });
            return false;
        }
        const { linkFullName, linkEmail, linkRelation, LinkRemark, rosterInfo } = this.state;
        const Data = {
            "student_id": rosterInfo[0].cc_student_id,
            "parent_name": linkFullName,
            "parent_email": linkEmail,
            "parent_relation": linkRelation,
            "is_acknowledged": LinkRemark,
        }

        this.setState({
            LinkModal: !this.state.LinkModal,
        })
        this.props.addLinkRoster(Data)
    }
    // ------addNew Link-------

    // ------ Link Update-------
    LinkModalUpdate(value, index) {
        this.setState({
            LinkModal: !this.state.LinkModal,
            modalType: 'link',
            linkFullName: value.cc_contact_name,
            linkEmail: value.cc_contact_email_id,
            linkRelation: 'Father',
            linkRemark: false,
            LinkFieldDisable: true,
            isAddStudentSubmit: false,

        })

    }
    // ------ Link Update-------
    addLink() {
        this.setState({
            LinkModal: !this.state.LinkModal,
            modalType: 'link',
            linkFullName: '',
            linkEmail: '',
            linkRelation: 'Father',
            linkRemark: false,
            LinkFieldDisable: false,
            isAddStudentSubmit: false,


        })
    }
    // --------------------Link ----------------



    // --------------------Contacts ----------------

    // ---------Add New Contacts-------
    addContact() {
        this.setState({
            isAddStudentSubmit: true,
        })
        if (!this.state.ContactTitle || !this.state.ContactFullname || !this.state.ContactEmail) {
            return false;
        }
        const valid = /^(?![.])[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?:\.[A-Za-z]{2,5})?$/;
        if (!valid.test(this.state.ContactEmail)) {
            this.setState({
                InvelidMessageEmail: 'Please Enter Valid Email*',
            });
            return false;
        }
        const { rosterInfo, ContactTitle, ContactFullname, ContactEmail, ContactRelation, ContactStatus, editContactsIndex, additionalContactsArray } = this.state;
        let data = {
            "student_id": rosterInfo[0].cc_student_id,
            "student_contact_title": ContactTitle,
            "student_contact_name": ContactFullname,
            "student_contact_email_id": ContactEmail,
            "ContactRelation": ContactRelation,
            "ContactStatus": ContactStatus,
        }
        if (this.state.ContactsStatus === 'new') {
            this.props.addRosterContact(data)
            this.setState({
                LinkModal: !this.state.LinkModal,
                modalType: 'contact',
                ContactsStatus: 'new',
                ContactFullname: '',
                ContactEmail: '',
                ContactTitle: '',
                ContactRelation: '',
                ContactStatus: 'Link',
                editContactsIndex: ''
            })
        } else {
            const ArryaContact = additionalContactsArray;
            data.student_contact_id = ArryaContact[editContactsIndex].cc_contact_id
            this.props.editRosterContact(data)
            this.setState({
                LinkModal: !this.state.LinkModal,
                modalType: 'contact',
                ContactsStatus: 'new',
                ContactFullname: '',
                ContactEmail: '',
                ContactTitle: '',
                ContactRelation: '',
                ContactStatus: 'Link',
                editContactsIndex: ''
            })
        }
        

    }
    // ---------Add New Contacts-------
    NewContact() {
        this.setState({
            LinkModal: !this.state.LinkModal,
            modalType: 'contact',
            ContactsStatus: 'new',
            ContactFullname: '',
            ContactEmail: '',
            ContactTitle: '',
            ContactRelation: '',
            ContactStatus: 'Link',
            editContactsIndex: ''
        })
    }

    // ---------Edit Contacts-------
    editContact(value, Index) {
        this.setState({
            LinkModal: !this.state.LinkModal,
            modalType: 'contact',
            ContactsStatus: 'edit',
            ContactFullname: value.cc_contact_name,
            ContactEmail: value.cc_contact_email_id,
            ContactTitle: value.cc_contact_title,
            ContactRelation: value.ContactRelation,
            ContactStatus: value.ContactStatus,
            editContactsIndex: Index
        })
    }
    // ---------Edit Contacts-------

    // ---------Delete Contacts-------
    deleteContacts(Index) {
        let ArryaContact = this.state.additionalContactsArray;

        const data = {
            "student_id": this.state.rosterInfo[0].cc_student_id,
            "student_contact_detail_id": ArryaContact[Index].cc_contact_id,
        }
        this.props.deleteRosterContact(data)
    }
    // ---------Delete Contacts-------

    // --------------------Contacts ----------------


    // --------------------Note ----------------

    // ---------Add New Note-------
    addNote() {
        this.setState({
            isAddStudentSubmit: true,
        })
        if (!this.state.Note) {
            return false;
        }
        const { rosterInfo, Note, } = this.state;
        let data = {
            "student_id": rosterInfo[0].cc_student_id,
            "student_info_details": Note
        }
        if (this.state.NoteStatus === 'new') {
            this.props.addRosterInfo(data)

        } else {
            const { additionalInformationArray, editNoteIndex } = this.state;
            data.student_info_id = additionalInformationArray[editNoteIndex].cc_info_id;
            this.props.editRosterInfo(data)

        }
        this.setState({
            LinkModal: !this.state.LinkModal,
            modalType: 'note',
            Note: ''
        })

    }
    // ---------Add New Note-------


    // ---------Edit Note-------
    editNote(value, Index) {
        this.setState({
            LinkModal: !this.state.LinkModal,
            modalType: 'note',
            NoteStatus: 'edit',
            Note: value.cc_info_details,
            editNoteIndex: Index
        })
    }
    // ---------Edit Note-------


    // ---------Delete Note-------
    deleteNote(Index) {
        const { additionalInformationArray, rosterInfo } = this.state;
        const data = {
            "student_info_id": additionalInformationArray[Index].cc_info_id,
            "student_id": rosterInfo[0].cc_student_id,
        }
        this.setState({
            LinkModal: false,
            modalType: 'note',
            Note: ''
        })
        this.props.deleteRosterInfo(data)


    }
    // ---------Delete Note-------

    // --------------------Note ----------------


    // ---------Input Handle change-------
    OnChangeTextArea = event => {
        this.setState(
            {
                InvelidMessageEmail: '',
                isAddStudentSubmit: false,
                [event.target.name]: event.target.value,
            },
            () => { }
        );
    };
    // ---------Input Handle change-------

    handleClosePopUp = (e) => {
        this.setState({
            showPopUp: false,
        })
    }

    render() {
        const { loader } = this.props
        let { additionalContactsArray, additionalInformationArray, rosterInfo, FirstName , LastName } = this.state;
        const { location = {} } = this.props
        const { state = {} } = location;
        const { studentPermission = '' } = state;
        const _ = require('lodash');
        const disLinkWithText = ['linkWithText', studentPermission !== null && studentPermission === 'read' ? 'opacityProp' : '']
        const disStudentEditButton = ['studentEditButton', studentPermission !== null && studentPermission === 'read' ? 'opacityProp' : '']
        const disAddButtonforContacts = ['addButtonforContacts', studentPermission !== null && studentPermission === 'read' ? 'opacityProp' : '']
        const pattern2 = /^[a-zA-Z]*$/;
        const saveButtonNw = [(FirstName != "" && LastName != "") ? 'saveButton studentButtonBorderRadius' : 'saveButton studentButtonBorderRadius saveButtonlessOpac ']

        return (
            <div className='studentMainContainer'>
                {/* -----------------Student Profile----------- */}
                <div className='studentProfileSectionDiv'>
                    {rosterInfo.length > 0 && <div className='studentProfileDiv'>
                        <div style={{ display: 'inline-flex' }} >
                            {rosterInfo[0].cc_student_pic_Mainurl ?
                                <img src={rosterInfo[0].cc_student_pic_Mainurl} alt='' className='studentProfileimage objectFitCover' />
                                : <div className='studentDummyDivProfile'>
                                    <h5 className='studentImageDummyText'>{rosterInfo[0].cc_student_first_name.charAt(0)}{rosterInfo[0].cc_student_last_name.charAt(0)}</h5>
                                </div>}
                            {studentPermission !== null && studentPermission === 'read' ?
                                <Button className={disStudentEditButton.join(' ')} color="primary">
                                    <i className="studentEditIcon material-icons" >edit </i>
                                    <span className='studentEditText' > Edit Profile</span>
                                </Button> :
                                <Button onClick={() => { if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") { return} else { this.openStudentEditModal() } }} className='studentEditButton studentButtonBorderRadius' color="primary" 
                                data-toggle="modal" 
                                // data-target="#myModal2"
                                data-target={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? '' : '#myModal2'}
                                disabled={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? true : false}
                                
                                >
                                    <i className="studentEditIcon material-icons" >edit </i>
                                    <span className='studentEditText' > Edit Profile</span>
                                </Button>}
                        </div>

                        <p className='studentProfileName'>{rosterInfo?.[0]?.cc_student_last_name.replace(/(.{13})..+/, "$1…")},{rosterInfo?.[0]?.cc_student_first_name.replace(/(.{13})..+/, "$1…")}</p>
                        <p className='studentProfileEmail'>
                            {_.truncate(rosterInfo[0].cc_student_email_id, {
                                'length': 30,
                                'omission': '...'
                            })}
                        </p>
                        {studentPermission !== null && studentPermission === 'read' ?
                            <p className={disLinkWithText.join(' ')}>Link with Parent App   </p> :
                            <p onClick={() => { this.addLink() }} className='linkWithText'>Link with Parent App   </p>}

                    </div>}
                    {rosterInfo.length > 0 && studentPermission === null && studentPermission !== 'read' ? <div className='mainQRDiv'>
                        <QRCode
                             id={rosterInfo[0].cc_student_code}
                             value={rosterInfo[0].cc_student_code}
                            // size={190}
                            level={"H"}
                            className='QRImage'
                            includeMargin={true}
                        />
                        <div style={{ width: 250, marginTop: 10 }}>
                            <p className='studentProfileMainNote'>Share Profile with Teacher</p>
                            <p className='studentProfileNote'>“Create Copy” privileges will always be granted when a student is added/shared via QR scan.</p>
                        </div>
                    </div> : ''}

                </div>
                {/* -----------------Student Profile----------- */}


                {/* -----------------additionalContact----------- */}
                <div className='additionalContactMainDiv'>
                    <div className='additionalContactMainTextDiv'>
                        <div>
                            <p className='additionalContactText'>Additional Contacts</p>
                        </div>
                        <div>
                            {studentPermission !== null && studentPermission === 'read' ?
                                <Button className={disAddButtonforContacts.join(' ')} color="primary" >
                                    <i className="addContactButtonIcon material-icons" >add </i>
                                    <span className='addContactsText' > Add New</span>
                                </Button> :
                                <Button onClick={() => { this.NewContact() }} className='addButtonforContacts studentButtonBorderRadius' color="primary" >
                                    <i className="addContactButtonIcon material-icons" >add </i>
                                    <span className='addContactsText' > Add New</span>
                                </Button>}
                        </div>
                    </div>
                    <div className='additionalCOntactCardMainDiv'>
                        {additionalContactsArray && additionalContactsArray.length > 0 && additionalContactsArray.map((Item, index) => (<div key={index} className='additionalCard'>
                            <div className='additionalCardInfo'>
                                <div style={{ textAlign: 'left' }}>
                                    <p className='additionalNameInfoText'>{Item?.cc_contact_name?.replace(/(.{13})..+/, "$1…")}</p>
                                    <p className='additionalEmailInfoText'>{Item.cc_contact_email_id}</p>
                                </div>
                                <div>
                                    <p className='additionalRelationInfoText'>{Item.cc_parent_relation === null ? '__' : Item.cc_parent_relation}</p>
                                </div>
                            </div>
                            <div className='additionalCardAction'>

                                <div>
                                    {Item.cc_parent_action === 'Shared' && <div className='sharedStatusButton'>
                                        <p className='sharedStatusText'>Shared</p>
                                    </div>}
                                    {Item.cc_parent_action === 'awaiting' && <div className='activeStatusButton'>
                                        <p className='activeStatusText'>Awating</p>
                                    </div>}
                                    {Item.cc_parent_action === 'linked' && <div className='linkedStatusButton'>
                                        <p className='linkedStatusText'>Linked</p>
                                    </div>}
                                    {Item.cc_parent_action === null && <div>
                                        <p onClick={() => { this.LinkModalUpdate(Item, index) }}
                                            className='linkWithText'>Link</p>
                                    </div>}
                                </div>
                                <div>
                                    <div onClick={() => { this.editContact(Item, index) }} className='editDivBlackAdditional' >
                                        <i className="editIconAdditional material-icons">edit</i>
                                    </div>
                                    <div onClick={() => { this.deleteContacts(index) }} className='deleteDivRedAdditional'>
                                        <i className="editIconAdditional material-icons">delete</i>
                                    </div>
                                </div>
                            </div>
                        </div>))}
                    </div>
                    {additionalContactsArray.length === 0 && <div className='NoValueDiv'>
                        {loader ? "" : <p className='NotAvaliable'>No Additional Contacts</p>}
                    </div>}

                </div>
                {/* -----------------additionalContact----------- */}


                {/* -----------------additionalInfo----------- */}
                <div className='additionalInfoMainDiv'>
                    <div className='additionalContactMainTextDiv'>
                        <div>
                            <p className='additionalContactText'>Additional Information</p>
                        </div>
                        <div>
                            {studentPermission !== null && studentPermission === 'read' ?
                                <Button
                                    className={disAddButtonforContacts.join(' ')} color="primary" >
                                    <i className="addContactButtonIcon material-icons" >add </i>
                                    <span className='addContactsText' > Add New</span>
                                </Button> :
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            LinkModal: !this.state.LinkModal,
                                            modalType: 'note',
                                            Note: '',
                                            NoteStatus: 'new',
                                            InvelidMessageEmail: '',
                                            isAddStudentSubmit: false,
                                        })
                                    }} className='addButtonforContacts studentButtonBorderRadius' color="primary" >
                                    <i className="addContactButtonIcon material-icons" >add </i>
                                    <span className='addContactsText' > Add New</span>
                                </Button>}
                        </div>
                    </div>

                    {additionalInformationArray.length === 0 ? <div className='NoValueDiv'>
                        {loader ? "" : <p className='NotAvaliable'>No Additional Information</p>}
                    </div> :
                        <div className='additionalInfoCardMainDiv'>
                            {additionalInformationArray && additionalInformationArray.length > 0 && additionalInformationArray.map((item, index) => (<div key={index} className='yellowCard'>
                                <div className='yellowCardAction'>
                                    <div></div>
                                    <div>
                                        <div onClick={() => { this.editNote(item, index) }} className='editDivBlack' >
                                            <i className="editIcon material-icons">edit</i>
                                        </div>
                                        <div onClick={() => { this.deleteNote(index) }} className='deleteDivRed'>
                                            <i className="editIcon material-icons">delete</i>
                                        </div>
                                    </div>
                                </div>
                                <div className='yellowCardTextDiv'>
                                    <p className='yelloCardText'>{item.cc_info_details}</p>
                                </div>
                            </div>))}

                        </div>}

                </div>
                {/* -----------------additionalInfo----------- */}


                {/* ---------------Modal for Link  Div--------------- */}
                <div>
                    <Modal size="lg" isOpen={this.state.LinkModal} centered={true}>

                        {this.state.modalType === 'link' && <ModalBody className='linkModalMain'>
                            <div className='linkModalHeader'>
                                <p className='linkModalHeadind'>Link With Parent App</p>


                            </div>
                            <div className='inputpasswordFieldMainDiv'>
                                <div className='inputInfoField'>
                                    <h5 className='inputLableInfo'>FullName</h5>
                                    <CommonTextField
                                        margin="normal"
                                        variant="outlined"
                                        type='linkFullName'
                                        disabled={this.state.LinkFieldDisable}
                                        value={this.state.linkFullName}
                                        onChange={this.OnChangeTextArea}
                                        name="linkFullName"
                                        className="InfoInputType studentProfileInputTypeAdContact"
                                        placeholder="Enter full name" />
                                    {(!this.state.linkFullName && this.state.isAddStudentSubmit) && <p className="errormessageRegister">Please enter fullname*</p>}
                                </div>
                                <div className='inputInfoField'>
                                    <h5 className='inputLableInfo'>Email Address</h5>
                                    <CommonTextField
                                        margin="normal"
                                        variant="outlined"
                                        disabled={this.state.LinkFieldDisable}
                                        type='linkEmail'
                                        value={this.state.linkEmail}
                                        onChange={this.OnChangeTextArea}
                                        name="linkEmail"
                                        className="InfoInputType studentProfileInputTypeAdContact"
                                        placeholder="Enter email address" />
                                    {(!this.state.linkEmail && this.state.isAddStudentSubmit) && <p className="errormessageRegister">Please enter email*</p>}
                                    {(this.state.linkEmail && this.state.InvelidMessageEmail) && <p className="errormessageRegister">Please enter valid email*</p>}

                                </div>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <p className='linkRelationShipText'>Select Relationship</p>
                                <div className='linkRelationShipDiv'>
                                    <Button onClick={() => { this.setState({ linkRelation: 'Father' }) }}
                                        className={this.state.linkRelation === 'Father' ? 'linkButtonforSelect' : 'linkButtonforUnSelect'} color="primary" >
                                        <span className={this.state.linkRelation === 'Father' ? 'linkSelectedText' : 'linkUnSelectedText'} > Father</span>
                                    </Button>
                                    <Button onClick={() => { this.setState({ linkRelation: 'Mother' }) }}
                                        className={this.state.linkRelation === 'Mother' ? 'linkButtonforSelect' : 'linkButtonforUnSelect'} color="primary" >
                                        <span className={this.state.linkRelation === 'Mother' ? 'linkSelectedText' : 'linkUnSelectedText'} > Mother</span>
                                    </Button>
                                    <Button onClick={() => { this.setState({ linkRelation: 'Guardian' }) }}
                                        className={this.state.linkRelation === 'Guardian' ? 'linkButtonforSelect' : 'linkButtonforUnSelect'} color="primary" >
                                        <span className={this.state.linkRelation === 'Guardian' ? 'linkSelectedText' : 'linkUnSelectedText'} > Guardian</span>
                                    </Button>
                                </div>
                            </div>
                            <div className='linkChechBoxDiv'>
                                <i onClick={() => { this.setState({ LinkRemark: !this.state.LinkRemark }) }} className="linkCheckBoxColor material-icons">{this.state.LinkRemark ? 'check_circle' : 'radio_button_unchecked'}</i>
                                <p>I acknowledge that this information is valid and I am willingly sharing this information with the contact email address documented here. Clicking the submit button will send a link email address to download a parent app and link with student.</p>
                            </div>
                            {(!this.state.LinkRemark && this.state.isAddStudentSubmit) && <p className="errormessageRegister">Please select acknowledge*</p>}

                            <div className='linkMainDivForCenter'>
                                <div className='linkCancelButtonDiv'>
                                    <div onClick={() => { this.setState({ LinkModal: !this.state.LinkModal }) }} className='linkCancelButton'>
                                        Cancel
                                    </div>
                                    <div>
                                        <Button className='linkSubmitButton' onClick={() => { this.newLink() }} color="primary" >
                                            <span className='linkSubmitButtonText' > Submit</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </ModalBody>}
                        {this.state.modalType === 'contact' && <ModalBody className='linkModalMain'>
                            <div className='linkModalHeader'>
                                <p className='linkModalHeadind'>Add Additional Contact</p>
                            </div>
                            <div className='mainFieldDivAdditionalModal'>
                                <div className='inputFieldLabelDiv'>
                                    <p className='inputFieldLabelText'>Title : </p>
                                </div>
                                <div className='inputFieldAdditionalModal'>

                                    <CommonTextField
                                        margin="normal"
                                        variant="outlined"
                                        type='ContactTitle'
                                        value={this.state.ContactTitle}
                                        onChange={this.OnChangeTextArea}
                                        name="ContactTitle"
                                        className="InfoInputType studentProfileInputTypeAdContact"
                                        placeholder="Enter title" />
                                    {(!this.state.ContactTitle && this.state.isAddStudentSubmit) && <p className="errormessageRegister">Please enter title*</p>}
                                </div>
                            </div>
                            <div className='mainFieldDivAdditionalModal'>
                                <div className='inputFieldLabelDiv'>
                                    <p className='inputFieldLabelText'>Contact Name : </p>
                                </div>
                                <div className='inputFieldAdditionalModal'>

                                    <CommonTextField
                                        margin="normal"
                                        variant="outlined"
                                        type='ContactFullname'
                                        value={this.state.ContactFullname}
                                        onChange={this.OnChangeTextArea}
                                        name="ContactFullname"
                                        className="InfoInputType studentProfileInputTypeAdContact"
                                        placeholder="Enter full name" />
                                    {(!this.state.ContactFullname && this.state.isAddStudentSubmit) && <p className="errormessageRegister">Please enter full name*</p>}
                                </div>
                            </div>
                            <div className='mainFieldDivAdditionalModal'>
                                <div className='inputFieldLabelDiv'>
                                    <p className='inputFieldLabelText'>Email Id : </p>
                                </div>
                                <div className='inputFieldAdditionalModal'>

                                    <CommonTextField
                                        margin="normal"
                                        variant="outlined"
                                        type='ContactEmail'
                                        value={this.state.ContactEmail}
                                        onChange={this.OnChangeTextArea}
                                        name="ContactEmail"
                                        className="InfoInputType studentProfileInputTypeAdContact"
                                        placeholder="Enter email Address" />
                                    {(!this.state.ContactEmail && this.state.isAddStudentSubmit) && <p className="errormessageRegister">Please enter email*</p>}
                                    {(this.state.ContactEmail && this.state.InvelidMessageEmail) && <p className="errormessageRegister">Please enter valid email*</p>}
                                </div>
                            </div>

                            <div className='linkMainDivForCenter'>
                                <div className='linkCancelButtonDiv'>
                                    <div onClick={() => { this.setState({ LinkModal: !this.state.LinkModal }) }} className='linkCancelButton'>
                                        Cancel
                                    </div>
                                    <div>
                                        <Button onClick={() => { this.addContact() }} className='linkSubmitButton' color="primary" >
                                            <span className='linkSubmitButtonText' > Submit</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </ModalBody>}
                        {this.state.modalType === 'note' && <ModalBody className='linkModalMain'>
                            <div className='linkModalHeader'>
                                <p className='linkModalHeadind'>Add Additional Information</p>
                            </div>
                            <div className='textAreaDiv'>
                                <textarea name='Note' onChange={this.OnChangeTextArea} value={this.state.Note} placeholder='Add note' className="textAreaCss" rows="50" >{this.state.Note}</textarea>
                                {(!this.state.Note && this.state.isAddStudentSubmit) && <p className="errormessageRegister">Please enter Note*</p>}


                            </div>

                            <div className='linkMainDivForCenter'>
                                <div className='linkCancelButtonDiv'>
                                    <div onClick={() => { this.setState({ LinkModal: !this.state.LinkModal }) }} className='linkCancelButton'>
                                        Cancel
                                    </div>
                                    <div>
                                        <Button onClick={() => { this.addNote() }} className='linkSubmitButton' color="primary" >
                                            <span className='linkSubmitButtonText' > Submit</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </ModalBody>}
                    </Modal>
                </div>
                {/* ---------------Modal  Div--------------- */}

                {/* ---------------Modal for Link  Div------------ */}

                {/* ---------------Modal Edit Student------------ */}
                <div className="modal right fade ClassRosterModal" id="myModal2" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header modal-header-add-student">
              <h4 className="modal-title Addstudentmodal" id="myModalLabel2">
                Add Student
              </h4>
              <button
                type="button"
                className="close closeround"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="cancelText" aria-hidden="true">
                  {" "}
                  <img
                    src={cancelBlack}
                    alt=""
                    className="closeIconInModal"
                  />{" "}
                 
                </span>
              </button>
            </div>

                            <div className="modal-body">
                                {/* -----------Add Student Profile----------- */}
                                {this.state.UserProfileURL === '' || this.state.UserProfileURL === undefined ? <div className='RosterImageMainDiv'>
                                    <div className='addStudentImageDiv'>

                                        {this.state.showLoaderUpload ? <img src={loaderImag} alt='' className='loaderIconCss' /> :
                                            <label htmlFor="file"><img src={camera} alt='' className='cameraIconCss' /></label>}

                                    </div>
                                    <input className="custom-file-input" onChange={(e) => this.handleChangeFile(e.target.files)} onClick={this.handleClick} type="file" id="file" name="file" accept="image/*" />

                                </div> :
                                    <div style={{ backgroundImage: `url(${this.state.UserProfileURL})` }} className='RosterImageMainSelectedDiv'>
                                        <div className='addStudentImageDiv'>

                                            <img src={this.state.UserProfileURL} alt='' className='RosterImageWhenSelect' />


                                        </div>
                                        <Button className='changeButton'>
                                            {this.state.showLoaderUpload ? <img src={loaderImag} alt='' className='loaderIconCssAlreadySelected' /> :
                                                <label htmlFor="file" className='changeButtonText'>Change</label>}
                                        </Button>
                                        <input className="custom-file-input" onChange={(e) => this.handleChangeFile(e.target.files)} onClick={this.handleClick} type="file" id="file" name="file" accept="image/*" />

                                    </div>}

                                {/* -----------Add Student Profile----------- */}

                                {/* -----------All Input Type----------- */}
                                <div className='allInputMainDiv'>
                                    <div className='studentAddInputDiv m-1' style={{position : "relative", right : 5}}>
                                        {/* <h5 className='studentInputLableInfo'>First name*</h5> */}
                                        <CommonTextField
                                            margin="normal"
                                            variant="outlined"
                                            type='FirstName'
                                            value={this.state.FirstName}
                                            onChange={this.handleChange}
                                            name="FirstName"
                                            className="studentAddInput"
                                            placeholder="First Name*" />
                                       {(!this.state.FirstName || (this.state.FirstName && this.state.FirstName.trim().length <= 0)) && this.state.isAddStudentSubmit && (
                    <p className="errormessageRegister">
                      Please enter first name
                    </p>
                  )}

                                        {this.state.FirstName && (this.state.FirstName && this.state.FirstName.trim().length != 0) && !pattern2.test(this.state.FirstName) && this.state.isAddStudentSubmit && (
                   <p className="errormessageRegister">Please enter only letters</p>
                     )}

                                    </div>
                                    <div className='studentAddInputDiv m-1' style={{position : "relative", right : 5}}>
                                        {/* <h5 className='studentInputLableInfo'>Last name*</h5> */}
                                        <CommonTextField
                                            margin="normal"
                                            variant="outlined"
                                            type='LastName'
                                            value={this.state.LastName}
                                            onChange={this.handleChange}
                                            name="LastName"
                                            className="studentAddInput"
                                            placeholder="Last Name*" />
                                        {(!this.state.LastName || (this.state.LastName && this.state.LastName.trim().length <= 0)) && this.state.isAddStudentSubmit && (
                    <p className="errormessageRegister">
                      Please enter last name
                    </p>
                  )}
                  
                  {this.state.LastName &&(this.state.LastName.trim().length != 0) && !pattern2.test(this.state.LastName) && this.state.isAddStudentSubmit && (
                   <p className="errormessageRegister">Please enter only letters</p>
                     )}



                                    </div>
                                    <div className='studentAddInputDiv m-1' style={{position : "relative", right : 5}}>
                                        {/* <h5 className='studentInputLableInfo'>Email ID (Optional)</h5> */}
                                        <CommonTextField
                                            margin="normal"
                                            variant="outlined"
                                            type='Email'
                                            value={this.state.Email}
                                            onChange={this.handleChange}
                                            name="Email"
                                            className="studentAddInput"
                                            placeholder="Email ID" />
                                        {(this.state.Email && this.state.InvelidMessageEmail) && <p className="errormessageRegister">Please enter valid email*</p>}



                                    </div>
                                    {/* -----------All Input Type----------- */}

                                    {/* -----------All Button----------- */}
                                    <div className="modalButtonClass studentEditSaveButtonDiv modalNW-justify-center justify-content-center">
                                        <div>

                                        </div>
                                        <div>
                                            <Button className='saveButton studentButtonBorderRadius' onClick={() => { this.editRoster() }} color="primary" >
                                                <span className='saveText' > Save</span>
                                            </Button>

                                        </div>
                                    </div>
                                    {/* -----------All Button----------- */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ---------------Modal Edit Student------------ */}
                {this.state.showPopUp && <UpgradePopUp handleClosePopUp={this.handleClosePopUp} />}
            </div >

        )
    }
}
const mapStateToProps = state => ({
    state: state,
    rosterDetailsData: state.rosterProfile,
    loader: state.rosterProfile.loader
});
const mapDispatchToProps = dispatch => {
    return {

        getRoster: (data) => dispatch(getRoster(data)),
        editRoster: (data) => dispatch(editRoster(data)),

        // -------Contact---------
        addRosterContact: (data) => dispatch(addRosterContact(data)),
        editRosterContact: (data) => dispatch(editRosterContact(data)),
        deleteRosterContact: (data) => dispatch(deleteRosterContact(data)),

        // -------Information---------
        addRosterInfo: (data) => dispatch(addRosterInfo(data)),
        editRosterInfo: (data) => dispatch(editRosterInfo(data)),
        deleteRosterInfo: (data) => dispatch(deleteRosterInfo(data)),

        // -------Link---------
        addLinkRoster: (data) => dispatch(addLinkRoster(data)),


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    StudentProfile
);