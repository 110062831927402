import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, ModalHeader } from "reactstrap";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import { getFormattedDate, addDaysGetSpan } from "Utils/Helper";
import calendarIcon from "Assets/NoteModule/calendar.svg";
import delCriteria from "Assets/RubricsModule/delCriteria.png";
import Home from "Assets/RubricsModule/Home.png";
import pencilIcon from "Assets/StudentGradeBookModule/pencil.png";
import undo from "Assets/AttendanceModule/undo.svg";
import arrowRight from "Assets/LessonPlanModule/arrowRight.svg";
import crossMark from "Assets/AttendanceModule/crossMark.svg";
import loaderImag from "Assets/ProfileModule/PepperyMedium.gif";
import FillAssessComp from "./FillAssessComp";
import {
  addRubric,
  viewRubricDetails,
  deleteCriteria,
  viewStudentsAssessmentDetails,
  saveAssessment,
  getDynamicRubricsCriteria,
  deletePreviousCriteriaData,
} from "Actions/ClassAction/ClassRubricsAction";
import "./AddNewRubrics.scss";
import Modal from "react-bootstrap/Modal";
import warningIcon from "Assets/FormativeAssessmentModule/warningIcon.png";
import DiscreteSlider from "./DiscreteSlider";

const AddNewRubrics = (props) => {
  const {
    addRubric,
    viewRubricDetails,
    deleteCriteria,
    viewRubricDetailsStates,
    addNewRubricState,
    viewStudentsAssessmentDetails,
    saveAssessment,
    viewStudentsAssessmentDetailsState,
    saveAssessmentState,
    history,
    state,
    fillBtnEnables2,
    type,
  } = props;
const dispatch = useDispatch()
  const {
    ClassDetails,
    selectionType,
    SelectedRubric,
    Backpage,
    academicYear,
  } = history?.location?.state || {};
  const { loader, action } = state.classRubrics;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [mainDate, setMainDate] = useState("");
  const [title, setTitle] = useState("");
  const [criteriaList, setCriteriaList] = useState([
    { id: "", cc_criteria_title: "" },
  ]);
  const [showAssessment, setShowAssessment] = useState(false);
  const [rubricDetailsMain, setRubricDetailsMain] = useState([]);
  const [btnEnables, setBtnEnables] = useState(false);
  const [fillBtnEnables, setFillBtnEnables] = useState(false);
  const [showFade, setShowFade] = useState(true);
  const [FinalSubmitFade, setfinalSubmitFade] = useState(true);
  const [showReset, setShowReset] = useState(false);
  const [doReset, setDoReset] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [selectedRubric, setSelectedRubric] = useState([]);
  const [headerStyle, setHeaderStyle] = useState(false);
  const signDate = sessionStorage.getItem("separatorFormat");
  const formatDate = sessionStorage.getItem("dateFormat");
  const rubricsSelectedClassDataSecond = JSON.parse(
    sessionStorage.getItem("rubricsSelectedClassSecond")
  );
  const rubricsSelectedClassData = JSON.parse(
    sessionStorage.getItem("rubricsSelectedClass")
  );
  const confirmSlectDiv = [
    "confirmSlectDiv",
    btnEnables === false ? "opacityFade" : "",
  ];
  const confirmSlectedDiv = [
    "confirmSlectDiv",
    FinalSubmitFade === true ? "opacityFade" : "",
  ];
  const addNewLessonFade = [
    fillBtnEnables === false && btnEnables === false
      ? "addNewLessonFade"
      : "addNewLesson",
  ];
  const addNewLessonFade2 = [
    fillBtnEnables2 === false ? "addNewLessonFade" : "addNewLesson",
  ];
  const undoSelects = [
    "undoSelects",
    showFade === true ? "opacityFadeUndo" : "",
  ];
  const [showConfirm, setShowConfirm] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [proceedToGenerate, setProceedToGenerate] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [scoreSliderValue, setScoreSliderValue] = useState(1);
  const [criteriaSliderValue, setCriteriaSliderValue] = useState(1);
  const [generateEditCriteria, setGenerateEditCriteria] = useState(false)
  const [showDeleteCriteriaTitle, setShowDeleteCriteriaTitle] = useState(false)
  const [deleteUrlValue, setDeleteUrlValue] = useState(null)
  const [deleteUrlIndex, setDeleteUrlIndex] = useState(null)
  const today = Date.now();
  const currentDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(today);

  const dynamicCriteriaLoader = useSelector((state) => state?.classRubrics?.Dynamic_Criteria_Loader)

  const dynamicCriteriaSuccessData = useSelector((state) => state?.classRubrics?.Get_Dynamic_Criteria)
  const dynamicCriteriaFailure = useSelector((state) => state?.classRubrics?.Get_Dynamic_Criteria_Error)

  

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setProceedToGenerate(false);
    setGenerateEditCriteria(false)
    setShowDeleteCriteriaTitle(false)
  };
  const handleEditModalShow = () => setShowEditModal(true);

  useEffect(() => {
    if (SelectedRubric !== "") {
      setSelectedRubric(SelectedRubric);
    }
  }, []);

  useEffect(() => {
    if(dynamicCriteriaSuccessData?.status == 200) {
      let lastElem = dynamicCriteriaSuccessData?.data?.pop()
      const dynamicCriteriaArray = dynamicCriteriaSuccessData?.data?.map((item, idx) => {
        
        return {
          id : '',
          cc_criteria_title : item
        }
      })
      
      setCriteriaList(dynamicCriteriaArray)
      setShowEditModal(false)
	  setProceedToGenerate(false)
    } 
  },[dynamicCriteriaSuccessData])

  useEffect(() => {
    if(dynamicCriteriaFailure?.status == 400 || dynamicCriteriaFailure?.status == 501) {
      setShowEditModal(false)
      setProceedToGenerate(false)
    }
  },[dynamicCriteriaFailure])

  useEffect(() => {
    if (proceedToGenerate) {
      const timer = setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown, proceedToGenerate]);

  useEffect(() => {
    const dateParam = getFormattedDate(selectedDate, formatDate, signDate);
    setMainDate(dateParam);
  }, [selectedDate]);

  

  useEffect(() => {
    if (selectionType === "edit" && selectedRubric) {
      const apiData = {
        class_id: ClassDetails.cc_class_id,
        rubric_id: SelectedRubric.cc_rubric_id,
      };
      setClicked(false);
      viewRubricDetails(apiData);
    }
  }, [selectionType]);

  useEffect(() => {
    if (!showAssessment && selectedRubric && selectedRubric.cc_rubric_id) {
      const apiData = {
        class_id: ClassDetails.cc_class_id,
        rubric_id: selectedRubric.cc_rubric_id,
      };
      setClicked(false);
      viewRubricDetails(apiData);
    }
  }, [showAssessment]);

  useEffect(() => {
    if (
      clicked &&
      selectionType === "addNew" &&
      action === "ADD_RUBRIC_SUCCESS" &&
      addNewRubricState
    ) {
      const apiData = {
        class_id: ClassDetails.cc_class_id,
        rubric_id: addNewRubricState.rubric_id,
      };
      viewRubricDetails(apiData);
    }
  }, [action, clicked]);

  useEffect(() => {
    if (
      selectionType === "addNew" &&
      action === "ADD_RUBRIC_SUCCESS" &&
      addNewRubricState
    ) {
      const apiData = {
        class_id: ClassDetails.cc_class_id,
        rubric_id: addNewRubricState.rubric_id,
      };
      viewRubricDetails(apiData);
    }
  }, [action, clicked]);

  useEffect(() => {
    if (
      selectionType === "edit" &&
      action === "ADD_RUBRIC_SUCCESS" &&
      addNewRubricState
    ) {
      const apiData = {
        class_id: ClassDetails.cc_class_id,
        rubric_id: addNewRubricState.rubric_id,
      };
      viewRubricDetails(apiData);
    }
  }, [action, clicked]);

  useEffect(() => {
    if (selectionType === "addNew") {
      let criTitles = [];
      criteriaList &&
        criteriaList.map((val) => {
          if (val.cc_criteria_title !== "") {
            criTitles.push(val.cc_criteria_title);
          }
        });

      if (criTitles.length >= 1 && title !== "") {
        setBtnEnables(true);
      } else {
        setBtnEnables(false);
      }
    }
  }, [criteriaList, title]);

  useEffect(() => {
    if (
      action !== "GET_RUBRIC_LIST_SUCCESS" &&
      viewRubricDetailsStates &&
      viewRubricDetailsStates?.cc_class_id
    ) {
      let criterias = [];
      viewRubricDetailsStates &&
        viewRubricDetailsStates?.criteriaArr.map((val) => {
          criterias.push(val.cc_criteria_title);
        });
      let newAddedCri = viewRubricDetailsStates?.criteriaArr.filter(
        (val) =>
          !criteriaList.some(
            (value) => val.cc_criteria_title === value.cc_criteria_title
          )
      );
      const dateParamDefault = getFormattedDate(
        viewRubricDetailsStates?.cc_rubric_date,
        formatDate,
        signDate
      );
      if (
        newAddedCri.length > 0 ||
        title !== viewRubricDetailsStates?.cc_rubric_title ||
        mainDate !== dateParamDefault ||
        criterias.length !== criteriaList.length
      ) {
        setBtnEnables(true);
        setFillBtnEnables(false);
      } else {
        setBtnEnables(false);
        setFillBtnEnables(true);
      }
    }
  }, [title, mainDate, criteriaList, viewRubricDetailsStates]);

  useEffect(() => {
    if (selectionType === "edit" && action === "VIEW_RUBRIC_DETAILS_SUCCESS") {
      sessionStorage.setItem(
        "viewRubricDetailsStates",
        JSON.stringify(viewRubricDetailsStates)
      );
      setClicked(false);
      setSelectedRubric(viewRubricDetailsStates);
      setRubricDetailsMain(viewRubricDetailsStates);
    }
  }, [viewRubricDetailsStates]);

  useEffect(() => {
    if (
      selectionType === "addNew" &&
      action === "VIEW_RUBRIC_DETAILS_SUCCESS"
    ) {
      sessionStorage.setItem(
        "viewRubricDetailsStates",
        JSON.stringify(viewRubricDetailsStates)
      );
      setClicked(false);
      setSelectedRubric(viewRubricDetailsStates);
      setRubricDetailsMain(viewRubricDetailsStates);
    }
  }, [viewRubricDetailsStates]);

  useEffect(() => {
    if (showReset) {
      setDoReset(false);
    }
  }, [showReset]);

  useEffect(() => {
    if (selectionType === "addNew") {
      setRubricDetailsMain("");
    }
  }, []);

  useEffect(() => {
    if (rubricDetailsMain && rubricDetailsMain.cc_rubric_title) {
      const criteriaDetails = [];
      rubricDetailsMain &&
        rubricDetailsMain.criteriaArr.map((val) => {
          const criValues = {};
          criValues["id"] = val.cc_criteria_id;
          criValues["cc_criteria_title"] = val.cc_criteria_title;
          criteriaDetails.push(criValues);
        });
      const dateParam = getFormattedDate(
        rubricDetailsMain.cc_rubric_date,
        formatDate,
        signDate
      );
      setMainDate(dateParam);
      setCriteriaList(criteriaDetails);
      if (Backpage === "importRubrics") {
        if (action === "ADD_RUBRIC_SUCCESS") {
          setTitle(rubricDetailsMain.cc_rubric_title);
          const dateParam = getFormattedDate(
            rubricDetailsMain.cc_rubric_date,
            formatDate,
            signDate
          );
          setMainDate(dateParam);
        } else {
          const dateParam = getFormattedDate(new Date(), formatDate, signDate);
          setMainDate(dateParam);
          setTitle("Copy of " + rubricDetailsMain.cc_rubric_title);
        }
      } else {
        setTitle(rubricDetailsMain.cc_rubric_title);
      }
    }
  }, [rubricDetailsMain]);

  useEffect(() => {
    if (rubricDetailsMain && rubricDetailsMain.cc_rubric_title) {
      if (Backpage === "importRubrics") {
        if (title !== rubricDetailsMain.cc_rubric_title) {
          setTitle("Copy of " + rubricDetailsMain.cc_rubric_title);
        } else {
          setTitle(rubricDetailsMain.cc_rubric_title);
        }
      }
    }
  }, [rubricDetailsMain]);

  const getDateFormatAsApi = (date) => {
    const updatedDate = moment(date).add(1, "days");
    // const FormatDate = updatedDate.toISOString().split('T')[0]
    const FormatDate = moment(date)
      .format("YYYY-MM-DD[T]HH:mm:ss:SSS")
      .split("T")[0];
    return FormatDate;
  };

  // const BackTOHome = () => {
  // 	if (!showAssessment) {
  // 		console.log(1234)
  // 		setSelectedDate(new Date());
  // 		setTitle("");
  // 		setBtnEnables(false);
  // 		history.goBack();
  // 	} else {
  // 		console.log(5678)
  // 		setShowAssessment(false)
  // 	}
  // }

  const getSliderValue = (value, label) => {
    if (label === "score") {
      setScoreSliderValue(value);
    } else {
      setCriteriaSliderValue(value);
    }
  };

  useEffect(() => {
    
  }, [scoreSliderValue, criteriaSliderValue]);

  const BackTOHome = () => {
    if (selectionType === "edit" && Backpage !== "importRubrics") {
      if (fillBtnEnables === true) {
        if (title === title || title !== "") {
          // setShowConfirm(false);
          // history.goBack();
          if (!showAssessment) {
            setSelectedDate(new Date());
            setTitle("");
            setBtnEnables(false);
            history.goBack();
          } else if (showReset) {
            setShowConfirm(true);
          } else {
            setShowAssessment(false);
          }
        }
      } else {
        setShowConfirm(true);
      }
    }

    if (Backpage === "importRubrics") {
      if (fillBtnEnables === true) {
        if (title === title || title !== "") {
          // setShowConfirm(false);
          // history.goBack();
          if (!showAssessment) {
            setSelectedDate(new Date());
            setTitle("");
            setBtnEnables(false);
            history.goBack();
          } else if (showReset) {
            setShowConfirm(true);
          } else {
            setShowAssessment(false);
          }
        }
      }
      // else if (!showReset) {
      // 	console.log("importRubrics-2");
      // 	setShowConfirm(false);
      // 	history.goBack();
      // }
      else {
        setShowConfirm(true);
      }
    }

    if (selectionType === "addNew") {
      if (fillBtnEnables === true) {
        if (title === title) {
          // setShowConfirm(false);
          // history.goBack();
          if (!showAssessment) {
            setSelectedDate(new Date());
            setTitle("");
            setBtnEnables(false);
            history.goBack();
          } else if (showReset) {
            setShowConfirm(true);
          } else {
            setShowAssessment(false);
          }
        } else {
          setShowConfirm(true);
        }
      } else if (
        title !== "" ||
        criteriaList.length > 1 ||
        criteriaList[0].cc_criteria_title !== "" ||
        mainDate !== currentDate
      ) {
        setShowConfirm(true);
      } else {
        history.goBack();
      }
    }
  };

  const BackToDash = () => {
    history.replace("/home/academic");
  };

  const saveNewRubrics = () => {
    if (rubricDetailsMain) {
      const criteriaDetails = [];
      const criteriaDetailsImport = [];
      criteriaList &&
        criteriaList.map((val) => {
          if (val.cc_criteria_title !== "") {
            criteriaDetails.push(val);
          }
        });
      criteriaList &&
        criteriaList.map((val) => {
          if (val.cc_criteria_title !== "") {
            criteriaDetailsImport.push({ ...val, id: "" });
          }
        });
      if (Backpage !== "importRubrics") {
        const apiData = {
          class_id: JSON.stringify(ClassDetails?.cc_class_id),
          title: title,
          rubric_date: getDateFormatAsApi(selectedDate),
          rubric_status: "drafted",
          rubric_id: rubricDetailsMain?.cc_rubric_id,
          criteria_details_array: criteriaDetails,
        };
        addRubric(apiData);
      } else {
        const apiData = {
          class_id: JSON.stringify(ClassDetails.cc_class_id),
          title: title,
          rubric_date: getDateFormatAsApi(selectedDate),
          rubric_status: "drafted",
          // "rubric_id": "",
          criteria_details_array: criteriaDetailsImport,
        };
        addRubric(apiData);
        // setShowAssessment(true);
        // history.goBack();
      }
    } else {
      setClicked(true);
      const criteriaDetails = [];
      criteriaList &&
        criteriaList.map((val) => {
          if (val.cc_criteria_title !== "") {
            criteriaDetails.push(val);
          }
        });
      const apiData = {
        class_id: JSON.stringify(ClassDetails.cc_class_id),
        title: title,
        rubric_date: getDateFormatAsApi(selectedDate),
        rubric_status: "drafted",
        // "rubric_id": "",
        criteria_details_array: criteriaDetails,
      };
      addRubric(apiData);
    }
  };
  const submitAllDataRubric = () => {
    const spanDate = addDaysGetSpan(selectedDate, 1);
    const criteriaDetails = [];
    criteriaList &&
      criteriaList.map((val) => {
        if (val.cc_criteria_title !== "") {
          criteriaDetails.push({ ...val, id: JSON.stringify(val.id) });
        }
      });
    const apiData = {
      class_id: JSON.stringify(ClassDetails.cc_class_id),
      title: title,
      rubric_date: getDateFormatAsApi(selectedDate),
      rubric_status: "completed",
      rubric_id: rubricDetailsMain.cc_rubric_id,
      criteria_details_array: criteriaDetails,
    };
    addRubric(apiData);
    history.goBack();
    setCriteriaList([{ id: "", cc_criteria_title: "" }]);
    setSelectedDate(new Date());
    setTitle("");
  };

  const unSavetWhenClose = () => {
    if (selectionType === "edit") {
      if (fillBtnEnables === true) {
        if (title === title || title !== "") {
          setShowConfirm(false);
          history.goBack();
          setCriteriaList([{ id: "", cc_criteria_title: "" }]);
          setSelectedDate(new Date());
          setTitle("");
        }
      } else {
        setShowConfirm(true);
      }
    }

    if (selectionType === "addNew") {
      if (fillBtnEnables === true) {
        if (title === title) {
          setShowConfirm(false);
          history.goBack();
          setCriteriaList([{ id: "", cc_criteria_title: "" }]);
          setSelectedDate(new Date());
          setTitle("");
        } else {
          setShowConfirm(true);
        }
      } else if (
        title !== "" ||
        criteriaList.length > 1 ||
        criteriaList[0].cc_criteria_title !== "" ||
        mainDate !== currentDate
      ) {
        setShowConfirm(true);
      } else {
        history.goBack();
        setCriteriaList([{ id: "", cc_criteria_title: "" }]);
        setSelectedDate(new Date());
        setTitle("");
      }
    }
    if (showReset) {
      setShowConfirm(true);
    }
  };

  const addUrlList = (value, index) => {
    let newUrlList = [...criteriaList];
    newUrlList.map((val, k) => {
      if (index === k) {
        newUrlList[index].cc_criteria_title = value;
        return setCriteriaList(newUrlList);
      } else {
        return setCriteriaList(newUrlList);
      }
    });
  };

  const addMoreUrls = () => {
    if (!loader) {
      let newUrlList = [...criteriaList];
      if (criteriaList.length < 5) {
        newUrlList.push({ id: "", cc_criteria_title: "" });
        setCriteriaList(newUrlList);
      }
    }
  };

  const deleteUrls = (data, index) => {
    setDeleteUrlValue(null)
    setDeleteUrlIndex(null)
    let newUrlList = [...criteriaList];
    if (!loader) {
      if (criteriaList.length > 1) {
        if (data.id !== "") {
          const filteredItems = newUrlList.filter(
            (item) => item.id !== data.id
          );
          setCriteriaList(filteredItems);
          if (data.cc_criteria_title !== "") {
            const apiData = {
              class_id: JSON.stringify(ClassDetails.cc_class_id),
              rubric_id: rubricDetailsMain.cc_rubric_id,
              id: data.id,
            };
            deleteCriteria(apiData);
          }
        } else {
          newUrlList.splice(index, 1);
          setCriteriaList(newUrlList);
        }
      }
    }
  };
  const showFillAssessment = () => {
    saveNewRubrics();
    setShowAssessment(true);
  };

  const showImportedFillAssessment = () => {
    saveNewRubrics();
    const criteriaDetailsImport = [];
    criteriaList &&
      criteriaList.map((val) => {
        if (val.cc_criteria_title !== "") {
          criteriaDetailsImport.push({ ...val, id: "" });
        }
      });

    // const apiData = {
    // 	"class_id": JSON.stringify(ClassDetails.cc_class_id),
    // 	"title": title,
    // 	"rubric_date": getDateFormatAsApi(selectedDate),
    // 	"rubric_status": "drafted",
    // 	// "rubric_id": "",
    // 	"criteria_details_array": criteriaDetailsImport,
    // }
    // addRubric(apiData);
    // setShowAssessment(true);

    if (Backpage === "importRubrics") {
      setShowAssessment(true);
      setHeaderStyle(true);
    } else {
      const apiData = {
        class_id: JSON.stringify(ClassDetails.cc_class_id),
        title: title,
        rubric_date: getDateFormatAsApi(selectedDate),
        rubric_status: "drafted",
        // "rubric_id": "",
        criteria_details_array: criteriaDetailsImport,
      };
      addRubric(apiData);
      setShowAssessment(true);
    }
  };

  const hideFillAssessment = () => {
    if (!showReset) {
      setShowConfirm(false);
      setShowAssessment(false);
      // history.goBack();
    } else {
      setShowConfirm(true);
    }
  };
  const resetAll = () => {
    setDoReset(true);
  };
  // const resetAll = () => {
  // 	if (!showReset) {
  // 		console.log("importRubrics-6");
  // 		setShowConfirm(false);
  // 		setShowAssessment(false)
  // 		// history.goBack();
  // 		setIsReset(false)
  // 	}
  // 	else {
  // 		setShowConfirm(true);
  // 		setIsReset(true)
  // 	}
  // 	// setDoReset(true);
  // }

  const addFromCal = (dates) => {
    setSelectedDate(dates);
  };

  const cancelWarnModal = () => {
    setShowConfirm(false);
  };

  const closeWarnModal = () => {
    if (!showAssessment) {
      setSelectedDate(new Date());
      setTitle("");
      setBtnEnables(false);
      history.goBack();
      setHeaderStyle(false);
    } else {
      setShowConfirm(false);
      setShowAssessment(false);
      setHeaderStyle(false);
    }
  };

  
  const getDynamicCriteriaHandler = () => {
    let obj = {
      rubric_title: title,
      criteria_count : criteriaSliderValue
    }
    
    dispatch(getDynamicRubricsCriteria(obj))
  }
  
  const generateViaAIHandler = () => {
    
    const isEmpty = criteriaList?.some((item) => item?.cc_criteria_title?.length == 0)
    
    if(selectionType == 'edit') {
      setGenerateEditCriteria(true)
    } else {
    setShowEditModal(true);
    }
  }

  const deleteCriteriaHandler = () => {
    const apiData = {
      rubric_id: rubricDetailsMain?.cc_rubric_id,
    };
    dispatch(deletePreviousCriteriaData(apiData))
    setGenerateEditCriteria(false)
    setShowEditModal(true)
  }


  return (
    <div className="addNewRubricsContainer">
      <div className="addRubricsMainDiv">
        <div className="RubricsDetailsMain">
          {Backpage !== "importRubrics" && (
            <div className="ClassBackDiv" onClick={BackTOHome}>
              <i className="lessonBackIcon material-icons">chevron_left</i>
              {/* <p className='lessonClassBactText'>Rubrics</p> */}
            </div>
          )}
          {Backpage === "importRubrics" && (
            <div className="importClassBackDiv" onClick={BackTOHome}>
              <i className="importBackIcon material-icons">chevron_left</i>
            </div>
          )}
          {/* {Backpage === "importRubrics" && <div className='rubricSeperator'></div>} */}
          <div className="attClassDetailTextDiv">
            {/* {Backpage === "importRubrics" ? <p className={headerStyle === true ? 'importHead2' : 'importHead'}>{`Import Rubrics: ${ClassDetails.cc_class_name}`}</p> :
							<p className={selectionType === "edit" ? 'head2' : "head"}>{ClassDetails.cc_class_name}</p>
						} */}
            {Backpage === "importRubrics" ? (
              <p className="importHead">{`Import Rubrics: ${ClassDetails?.cc_class_name}`}</p>
            ) : selectionType === "edit" && Backpage !== "importRubrics" ? (
              <p className="head">{`Edit Rubrics: ${ClassDetails?.cc_class_name}`}</p>
            ) : (
              <p className="head">{`Create Rubrics: ${ClassDetails?.cc_class_name}`}</p>
            )}

            {showAssessment && (
              <div className="displayFlexClassRoster">
                <p className="topicHead">Topic: </p>
                <p className="topicTextName">
                  {title?.replace(/(.{20})..+/, "$1…")}{" "}
                </p>
                <p className="topicDate">- {mainDate}</p>
              </div>
            )}
          </div>
        </div>
        <div className="displayFlexInSeletAtt">
          {!showAssessment && (
            <div className="undoSelects" onClick={unSavetWhenClose}>
              <img
                src={crossMark}
                className="closeIcon"
                alt=""
                width="20"
                height="20"
              />
              <p className="iconText">Cancel</p>
            </div>
          )}
          {showAssessment && (
            <div className="buttonMarginTop">
              <Button className="editBtnAsses" onClick={hideFillAssessment}>
                <img className="pencilIcon" src={pencilIcon} alt="" />
                <span className="viewAttText">Edit Criteria</span>
              </Button>
            </div>
          )}
          {!showAssessment && (
            <div className={confirmSlectDiv.join(" ")} onClick={saveNewRubrics}>
              <i className="confirmedIcon material-icons">check</i>
              <p className="iconText">Save</p>
            </div>
          )}

          {showAssessment && showReset && (
            <div className="undoSelects" onClick={resetAll}>
              <img
                src={undo}
                className="closeIcon"
                alt=""
                width="20"
                height="20"
              />
              <p className="iconText">Reset</p>
            </div>
          )}
          {showAssessment && (
            <div
              className={confirmSlectedDiv.join(" ")}
              onClick={submitAllDataRubric}
            >
              <i className="confirmedIcon material-icons">check</i>
              <p className="iconText">Submit</p>
            </div>
          )}
        </div>
        {!showAssessment && Backpage !== "importRubrics" && (
          <div className="buttonMarginTop">
            <Button
              className={addNewLessonFade.join(" ")}
              onClick={showFillAssessment}
            >
              <img className="lessonIcons" src={arrowRight} alt="" />
              <span className="viewAttText">Fill Rubrics</span>
            </Button>
          </div>
        )}
        {!showAssessment && Backpage === "importRubrics" && (
          <div className="buttonMarginTop">
            <Button
              className={addNewLessonFade2.join(" ")}
              onClick={showImportedFillAssessment}
            >
              <img className="lessonIcons" src={arrowRight} alt="" />
              <span className="viewAttText">Fill Rubrics</span>
            </Button>
          </div>
        )}
      </div>
      {loader && (
        <div>
          <img src={loaderImag} alt="" className="loaderIconCss" />
        </div>
      )}
      {!showAssessment && (
        <div>
          <div className="planTitleMainDiv">
            <div className="planTitleDiv generateAIBtnRurbicsDiv">
              <div className="planTitle">
                <p className="headTitlesPlan">Rubric Title</p>
                <input
                  type="text"
                  className="mailDiv"
                  name="email"
                  placeholder="Enter title"
                  id="email"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  maxLength="32"
                  style={{ fontWeight: 600 }}
                />
              </div>
              <div className="planDateTitle">
                <p className="headTitlesPlan">Date</p>
                <div className="dateSubBlock">
                  <div className="dayDateTextDiv">
                    <p className="dayDateText">{mainDate}</p>
                  </div>
                  <DatePicker
                    onChange={(date) => {
                      addFromCal(date);
                    }}
                    customInput={
                      <img src={calendarIcon} alt="" width="18" height="18" />
                    }
                    minDate={moment().toDate()}
                  />
                </div>
              </div>
              <Button
                className="mr-3 generateAIBtnRurbicsDiv_button ml-auto mt-auto"
                disabled={title?.length == 0}
                onClick={() => {
                 generateViaAIHandler()
                }}
              >
                <span className="viewAttText">Generate via AI</span>
              </Button>
            </div>
            {Backpage === "importRubrics" && (
              <div className="importClassDiv">
                <p className="importClassText">
                  — Import from {rubricsSelectedClassDataSecond.cc_class_name}{" "}
                  class
                </p>
              </div>
            )}
          </div>
          <div className="criteriaMainDiv">
            <div className="criteriaTitleDiv">
              <p className="criteriaTitleText">Create Criteria</p>
              <p className="limitationText"> (Up to 05)</p>
            </div>
            {criteriaList && criteriaList.length > 1 ? (
              <div className="criteriaInputsMainDiv">
                <div className="rationalInputBox">
                  {criteriaList &&
                    criteriaList.map((valu, l) => {
                      return (
                        <div className="rationalInputTextDiv" key={l}>
                          <p className="indexingNo">{`${l + 1}:`}</p>
                          <input
                            maxlength="26"
                            name="message"
                            type="text"
                            className="rationalInputText"
                            value={valu.cc_criteria_title}
                            onChange={(e) => addUrlList(e.target.value, l)}
                            placeholder="Add Criteria*"
                          />
                          {criteriaList && criteriaList.length < 2 && (
                            <p
                              className="rationalSubTitle"
                              onClick={addMoreUrls}
                            >
                              <span
                                style={{
                                  fontSize: " 20px",
                                  marginTop: "-4px",
                                  marginRight: "5px",
                                }}
                              >
                                +
                              </span>{" "}
                              Add More
                            </p>
                          )}
                          {l !== 0 && (
                            <div
                              className="removeDiv"
                              onClick={() => {
                                setDeleteUrlValue(valu)
                                setDeleteUrlIndex(l)
                                setShowDeleteCriteriaTitle(true)
                              }}
                            >
                              <img
                                className="delIcon"
                                src={delCriteria}
                                alt="delete"
                                width="16"
                                height="16"
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
                {criteriaList &&
                  criteriaList.length < 5 &&
                  criteriaList.length > 1 && (
                    <p className="rationalSubTitle" onClick={addMoreUrls}>
                      <span
                        style={{
                          fontSize: " 20px",
                          marginTop: "-4px",
                          marginRight: "5px",
                        }}
                      >
                        +
                      </span>{" "}
                      Add More
                    </p>
                  )}
              </div>
            ) : (
              <div className="criteriaInputsMainsDiv">
                <div className="rationalInputsBox">
                  {criteriaList &&
                    criteriaList.map((valu, l) => {
                      return (
                        <div className="rationalInputTextsDiv" key={l}>
                          <p className="indexingNo">{`${l + 1}:`}</p>
                          <input
                            maxlength="26"
                            name="message"
                            type="text"
                            className="rationalInputTexts"
                            value={valu.cc_criteria_title}
                            onChange={(e) => addUrlList(e.target.value, l)}
                            placeholder="Add Criteria*"
                          />
                          <p className="rationalSubTitle" onClick={addMoreUrls}>
                            <span
                              style={{
                                fontSize: " 20px",
                                marginTop: "-4px",
                                marginRight: "5px",
                              }}
                            >
                              +
                            </span>{" "}
                            Add More
                          </p>
                          {l !== 0 && (
                            <div
                              className="removeDiv"
                              onClick={() => {
                                // deleteUrls(valu, l);
                                setDeleteUrlValue(valu)
                                setDeleteUrlIndex(l)
                                setShowDeleteCriteriaTitle(true)
                              }}
                            >
                              <img
                                className="delIcon"
                                src={delCriteria}
                                alt="delete"
                                width="16"
                                height="16"
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {showConfirm && (
        <Modal
          centered
          className="rubricBack-modal-warning br-8"
          show={showConfirm}
          onHide={() => {
            setShowConfirm(false);
          }}
        >
          <Modal.Body>
            <div>
              <img src={warningIcon} alt="" className="warningIcon" />
              <p className="waitTitle">Wait!</p>
              <div className="modalText">
                <p>Do you want to go back, your data will be lost.</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              className="cancelAtt-btn"
              onClick={cancelWarnModal}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              className="getReport-btn"
              onClick={closeWarnModal}
            >
              Go Back
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showAssessment && viewRubricDetailsStates && (
        <FillAssessComp
          hideFillAssessment={hideFillAssessment}
          criteriaList={criteriaList}
          viewRubricDetailsStates={viewRubricDetailsStates}
          viewStudentsAssessmentDetailsState={
            viewStudentsAssessmentDetailsState
          }
          saveAssessmentState={saveAssessmentState}
          viewStudentsAssessmentDetails={viewStudentsAssessmentDetails}
          saveAssessment={saveAssessment}
          ClassDetails={ClassDetails}
          selectedRubric={
            selectionType === "edit"
              ? selectedRubric
              : addNewRubricState && {
                  cc_rubric_id: addNewRubricState.rubric_id,
                }
          }
          action={action}
          setShowFade={setShowFade}
          setfinalSubmitFade={setfinalSubmitFade}
          addRubric={addRubric}
          setRubricDetailsMain={setRubricDetailsMain}
          rubricDetailsMain={rubricDetailsMain}
          viewRubricDetails={viewRubricDetails}
          setShowReset={setShowReset}
          doReset={doReset}
          showReset={showReset}
          resetAll={resetAll}
          loader={loader}
          history={history}
          showConfirm={showConfirm}
          setShowConfirm={setShowConfirm}
          isReset={isReset}
          setIsReset={setIsReset}
          setDoReset={setDoReset}
        />
      )}

      {/* ---------------Prepare Modal --------------*/}
      <Modal
        centered
        className="delete-modal-warning br-8 RubricsgenerateModal "
        show={showEditModal}
        onHide={handleEditModalClose}
        // backdrop={editStandardLoader ? "static" : false}
      >
        {!proceedToGenerate ? (
          <>
            <ModalHeader className="rubricsModalHeader pb-0">
              <p>Select Criteria and Score for better results</p>
            </ModalHeader>
            <Modal.Body className="pb-0 criteriamodalbody pt-0">
              <p className="mb-0">Criteria Count</p>
              <DiscreteSlider
                sliderValue={5}
                label={"criteria"}
                getSliderValue={getSliderValue}
              />
              {/* <div className="mt-3">
                <p className="mb-0">Score Count</p>
                <DiscreteSlider
                  sliderValue={10}
                  label={"score"}
                  customClassName={"customDiscreetScoreSlider"}
                  getSliderValue={getSliderValue}
                />
              </div> */}

              <Button
                variant="default"
                className="delete-modal-warning proceed-btn mt-4"
                onClick={() => {
                  setProceedToGenerate(true);
                  // setCountdown(3);
                  getDynamicCriteriaHandler()
                }}
              >
                Proceed
              </Button>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Body className="pb-0">
                <div class="sk-fading-circle">
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>

              <p className={`pleaseWaitText ${countdown == 0 && "d-none"}`}>
                please wait…
              </p>
              <p className="prepareText">Preparing your Rubrics</p>

              {countdown <= 2 && (
                <p className="dataPara">
                  For <span>{title}</span>
                </p>
              )}
              {countdown <= 0 && (
                <p className="dataPara">
                  with <span>{criteriaSliderValue} Criteria</span>
                </p>
              )}
              {countdown > 0 && (
                <div
                  className="snippet d-flex justify-content-center mb-4"
                  data-title="dot-flashing"
                >
                  <div className="stage">
                    <div className="dot-flashing"></div>
                  </div>
                </div>
              )}
            </Modal.Body>
          </>
        )}
      </Modal>

      {/* --------------generate edit criteria ------------*/}
      <Modal
        centered
        className="delete-modal-warning br-8 RubricsgenerateModal "
        show={generateEditCriteria}
        onHide={handleEditModalClose}
        // backdrop={editStandardLoader ? "static" : false}
      >
         <Modal.Header>
            <Modal.Title>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img
                  src={warningIcon}
                  alt=""
                  style={{ width: "60px", height: "60px" }}
                />
                <span
                  style={{
                    fontSize: "24px",
                    color: "#ff1f1f",
                    marginTop: "10px",
                  }}
                >
                  Wait!
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
            <Modal.Body className="pb-0">
            <p>
            Generating new criteria will replace the existing criteria. Are you sure you want to proceed? This action will permanently delete the current criteria and any associated data will be lost. Please review your selections before confirming.
            </p>
            </Modal.Body>
            <Modal.Footer>
            <Button
              variant="default"
              className="delete-modal-warning cancel-btn"
              onClick={() => {
               setGenerateEditCriteria(false)
              }}
              style={{
                background : "#fff",
                color : "#000"
              }}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              className="delete-modal-warning generateBtnContainer"
              style={{ borderRadius: "8px" }}
              onClick={() => {
                deleteCriteriaHandler()
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
      </Modal>

      {/* -------------- delete criteria list modal -------------*/}
      <Modal
        centered
        className="delete-modal-warning br-8 RubricsgenerateModal "
        show={showDeleteCriteriaTitle}
        onHide={handleEditModalClose}
        // backdrop={editStandardLoader ? "static" : false}
      >
         <Modal.Header>
            <Modal.Title>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img
                  src={warningIcon}
                  alt=""
                  style={{ width: "60px", height: "60px" }}
                />
                <span
                  style={{
                    fontSize: "24px",
                    color: "#ff1f1f",
                    marginTop: "10px",
                  }}
                >
                  Wait!
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
            <Modal.Body className="pb-0">
            <p>
            Are you sure you want to delete this criteria? Any associated data tied to this criteria may be affected. Please review your selection carefully before proceeding.
            </p>
            </Modal.Body>
            <Modal.Footer>
            <Button
              variant="default"
              className="delete-modal-warning cancel-btn"
              onClick={() => {
               setShowDeleteCriteriaTitle(false)
              }}
              style={{
                background : "#fff",
                color : "#000"
              }}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              className="delete-modal-warning generateBtnContainer"
              style={{ borderRadius: "8px" }}
              onClick={() => {
                deleteUrls(deleteUrlValue, deleteUrlIndex)
                setShowDeleteCriteriaTitle(false)
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  state: state,
  addNewRubricState: state.classRubrics.Add_New_Rubrics,
  viewRubricDetailsStates: state.classRubrics.View_Rubric_Details,
  deleteCriteriaState: state.classRubrics.Delete_Criteria,
  viewStudentsAssessmentDetailsState:
    state.classRubrics.View_Students_Assessment_Details,
  saveAssessmentState: state.classRubrics.Save_Assessment,
});
const mapDispatchToProps = (dispatch) => {
  return {
    addRubric: (data) => dispatch(addRubric(data)),
    viewRubricDetails: (data) => dispatch(viewRubricDetails(data)),
    deleteCriteria: (data) => dispatch(deleteCriteria(data)),
    viewStudentsAssessmentDetails: (data) =>
      dispatch(viewStudentsAssessmentDetails(data)),
    saveAssessment: (data) => dispatch(saveAssessment(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewRubrics);
