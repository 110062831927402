import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from '@mui/material/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import MicRecorder from 'mic-recorder-to-mp3';
import addTransperent from 'Assets/NoteModule/addTransperent.svg';
import StudentNoteProgressbarComponent from '../../StudentNoteModule/StudentNoteProgressbarComponent/StudentNoteProgressbar';
import back from 'Assets/CommonComponent/back.png'
import NoteCommentCard from '../../../NoteModule/NoteCommentCardComponent/NoteCommentCard';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import UpgradePopUp from '../../../upgradePopUp/upgradePopUp'
import audioWave from 'Assets/NoteModule/audioWave.png';
import attachedIcon from 'Assets/NoteModule/paperclip.svg';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { IconButton } from '@material-ui/core';
import "react-datepicker/dist/react-datepicker.css";
import EditIcon from '@mui/icons-material/Edit';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Timer from 'react-compound-timer'
import playIcon from 'Assets/NoteModule/play.svg';
import closeIcon from 'Assets/NoteModule/close.svg';
import tickIcon from 'Assets/NoteModule/tick.svg';
import closeMediaIcon from 'Assets/NoteModule/closeMedia.svg';
import deleteIcon from 'Assets/NoteModule/delete.svg';
import closeBtnIcon from 'Assets/NoteModule/closeBtnSquare.svg';
import tick from 'Assets/NoteModule/tick.svg';
import note_pdf from 'Assets/NoteModule/note_pdf.png';
import note_doc from 'Assets/NoteModule/note_doc.png';
import note_csv from 'Assets/NoteModule/note_csv.png';
import note_ppt from 'Assets/NoteModule/note_ppt.png';
import note_xls from 'Assets/NoteModule/note_xls.png';
import saveBtnIcon from 'Assets/NoteModule/saveBtnSquare.svg';
import calendarIcon from 'Assets/NoteModule/calendar.svg';
import starIcon from 'Assets/NoteModule/star.svg';
import starFilled from 'Assets/NoteModule/starFilled.svg';
import starFilledgray from 'Assets/NoteModule/grayStar.svg';
import startRecordingIcon from 'Assets/NoteModule/startRecording.svg';
import stopIcon from 'Assets/NoteModule/stop.svg';
import * as moment from 'moment';
import { FirebseStroageFolder, firebaseBucket } from 'Config/Constant';
import { formatAudioDuration, getFormattedDate } from "Utils/Helper";
import { storage } from "../../../../firebase/firebase";
import { getRandomName, getDateFormat } from "Utils/Helper";
import { createNotification } from 'Config/notificationtoast';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import { setMinutes, setHours } from "date-fns";
import IframeGoogleDoc from '../../StudentNoteModule/IframeGoogleDoc';
import './ClassNoteAdd.scss';
import styled from 'styled-components';
import starYellow from 'Assets/NoteModule/starYellow.svg';
import remiderIconPink from 'Assets/NoteModule/remiderIconPink.svg';
import starYellowBorder from 'Assets/NoteModule/starYellowBorder.svg';
import warningIcon from 'Assets/NoteModule/warning-icon.png'
import { useDispatch, useSelector } from 'react-redux';
import { textTransform } from '@mui/system';
import ClassNoteCommentCard from '../ClassNoteViewComponent/ClassNoteCommentCard';
import RadioCardLabel from 'Screens/NoteModule/StudentNoteModule/StudentNoteAddComponent/RadioCardLabel';
import { getAudioFromVertex, getAudioSummaryFromVertex, resetStudentNotes } from 'Actions/NoteAction/studentNoteAction';
import { getClassTagEdit } from 'Actions/ClassAction/classTagAction';


const ClassNoteAdd = (props) => {
    const { addGroupNote, editGroupNote, getClassTag, classTag, notesData, getTagLoader, upgradePopUp, history, location } = props;
    const { studentsInfo, selectedStudentsForGroupNote, classDetails  } = location.state || "";
    const dispatch = useDispatch()
    let quality_note = JSON.parse(sessionStorage.getItem('quality_note'));
    const signDate = sessionStorage.getItem('separatorFormat')
    const formatDate = sessionStorage.getItem('dateFormat')
    let stateDate = getFormattedDate(new Date(), formatDate, signDate);
    const { data, AcademicData } = location.state;
    const [selectedVideo, setSelectedVideo] = useState({});
    const [modalDocSelect, setModalDocSelect] = useState({})
    const [selectedImage, setSelectedImage] = useState({});
    const [record, setRecord] = useState(false);
    const [show, setShow] = useState(false);
    const [showImageModalState, setShowImageModalState] = useState(false);
    const [showVideoModalState, setShowVideoModalState] = useState(false);
    const [pictureVideoList, setPictureVideoList] = useState([]);
    const [audioList, setAudioList] = useState([]);
    const [isEnabled, setIsEnabled] = useState(false);
    const [isEnabledArtifact, setIsEnabledArtifact] = useState("no");
    const [auditTitle, setAuditTitle] = useState("");
    const [addDate, setAddDate] = useState(stateDate);
    const [updatedDate, setUpdatedDate] = useState('')
    const [addTime, setAddTime] = useState("");
    const [isSaved, setIsSaved] = useState(true);
    const [noteLevel, setNoteLevel] = useState("");
    const [noteScore, setNoteScore] = useState("");
    const [mediaUploadPercentage, setMediaUploadPercentage] = useState(0);
    const [uploadAudioStatus, setUploadAudioStatus] = useState(false);
    const [qualityPoints, setQuality_points] = useState(0);
    const [uploadMediaStatus, setUploadMediaStatus] = useState(false);
    const [note_tag_comments, setNote_tag_comments] = useState([]);
    const [TransID, setTransId] = useState();
    const [SummID, setSummid] = useState();
    const [work_assignment, setWork_assignment] = useState('');
    const [pageType, setPageType] = useState(props?.location?.state?.pageType);
    const [class_id, setClass_id] = useState('');
    const [noteData, setNoteData] = useState([]);
    const [docList, setDocList] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState("");
    const [showDocPopUp, setShowDocPopUp] = useState(false);
    const [student_enrolled_classes, setStudent_enrolled_classes] = useState([]);
    const [text_quality_note, setText_quality_note] = useState(0);
    const [qualityNote, setQualityNote] = useState(quality_note)
    const [confirmationForBack, setConfirmationForBack] = useState(false);
    const [goBackModal, setGoBackModal] = useState(false)
    const [isClassDisabled, setIsClassDisabled] = useState(false);
    const [confirmationType, setConfirmationType] = useState('');
    const [ChangeNoteData, setChangeNoteData] = useState([]);
    const [deletedMultimedia, setDeletedMultimedia] = useState([])
    const [deleteMediaObjIndex, setDeleteMediaObjIndex] = useState(null)
    const [deleteMediaObjType, setDeleteMediaObjType] = useState(null)
    const [showPopUp, setShowPopUp] = useState(false);
    const [recorder] = useState(new MicRecorder({ bitRate: 128 }));
    const selctedStudentsInfo = [];
    const StudentAllNoteDetails = [];
    const formatClock = JSON.parse(sessionStorage.getItem('clockFormat'));
    const isLinkedText = 'Share with linked contact';
    const [isLinkedStatus, setisLinkedStatus] = useState(false);
    const [isLinkedStatusForAll, setisLinkedStatusForAll] = useState(false);
    const [disableSmartBtn, setDisableSmartBtn] = useState(false)
    const [showDivs, setshowDivs] = useState(false)
    const [showDivsEdit, setshowDivsEdit] = useState(false)
     //generateAudioAIModal
     const [showGenerateAudioAIModal, setShowGenerateAudioAIModal] = useState(false)
     const [transcribeAudioModal, setTranscribeAudioModal]= useState(false)
     const [showSummarizeModal, setShowSummarizeModal]= useState(false)
     const [showSelectAudioModal,setShowSelectAudioModal]= useState(false)
     const [addStandardValue, setAddStandardValue] = useState('')
     const [selectAudioObj, setSelectAudioObj] = useState(null)
     const [selectAudioTotalDuration, setSelectAudioTotalDuration] = useState('0:00')
     const [audioTranscribe, setAudioTranscribe] = useState(null)
     const [audioSummarization, setAudioSummarization] = useState(null)
     const [audioSummarizationforsmall, setAudioSummarizationforsmall] = useState(null)
     const [editAudioTranscribeAndSummarize, setEditAudioTranscribeAndSummarize]= useState(false)
     const [selectedText, setSelectedText] = useState(null)
     const [showPopover, setShowPopover]= useState(false)
     const [showTranscribePopover, setShowTranscribePopover]= useState(false)
     const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 })
     const [popoverTranscribePosition, setPopoverTranscribePosition] = useState({ top: 0, left: 0 })
     const [combinedSummaryText, setCombinedSummaryText] =  useState(null)
     const [countdown, setCountdown] =  useState(5)
     const [isAudioArtifact, setisAudioArtifact] = useState('no')
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const currentYear = props.history.location.state.academicYear;


   console.log('audioSummarization :>> ', audioSummarization);
    const states = useSelector((state) => state)

    useEffect(() => {
        let pageType = '';
        let class_id = '';
        if (props?.location?.state?.pageType == 'New') {
            // ------set New data for show in note----------

            setIsClassDisabled(false);
            setClass_id(class_id);
            setPageType(pageType);
            setNoteData([]);
            setNote_tag_comments([]);
            setStudent_enrolled_classes([]);
            setNoteLevel('');
            setNoteScore('');
            setIsEnabledArtifact('no');
            setWork_assignment('');
            setPictureVideoList([]);
            setAudioList([]);
            // setAudioList([
            //     {
            //         "cc_attachment_fullurl": "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-stg/o/rgnl%2Fholmesstguser%2Fnotes%2Fnotes-4045%2F48c31d1b-c8b2-4ef2-3a0d-76ba2723a928.mp3?alt=media&token=3880cae2-5b71-444e-bb50-5daa111bf182",
            //         "cc_attachment_url": "rgnl/holmesstguser/notes/notes-4045/48c31d1b-c8b2-4ef2-3a0d-76ba2723a928.mp3",
            //         "cc_attachment_name": "",
            //         "cc_attachment_type": "audio",
            //         "cc_attachment_size": 0.484608,
            //         "cc_attachment_operation": "add"
            //     }
            // ])
            setDocList([]);
            setQuality_points(0);
            // ------set New data for show in note----------
        } else {
            console.log('props :>> ', props);
            console.log("getting here 111222666" ,props?.location?.state?.data?.note_tag_comments)
            sessionStorage.setItem(
                "finalNoteCommentCardsAfterRemove",
                JSON.stringify(props?.location?.state?.data?.note_tag_comments)
              );
            //set data for edit notes
            let attachments = props?.location?.state?.data?.note_attachment;
            let DummyAudioNote = [];
            let DummyVideoNote = [];
            let DummyDocNote = [];
            for (let item of attachments) {
                if (item.cc_attachment_type === "audio") {
                    DummyAudioNote.push(item)
                } else if (item.cc_attachment_type === "other") {
                    DummyDocNote.push(item)
                } else {
                    DummyVideoNote.push(item)
                }

            }
                   
            const { data } = props.location.state;
        
            // Sorting comments: prioritize "Audio Summarization" and "Audio Transcription"
            const audioComments = data.note_tag_comments.filter(comment =>
                ["Audio Summarization", "Audio Transcription"].includes(comment.cc_tag_title)
            );
            const otherComments = data.note_tag_comments.filter(comment =>
                !["Audio Summarization", "Audio Transcription"].includes(comment.cc_tag_title)
            );
            const sortedComments = [...audioComments, ...otherComments];
    
            // Set data for editing
            setPageType('Edit');
            setPictureVideoList(data.note_attachment.filter(item => item.cc_attachment_type !== "audio" && item.cc_attachment_type !== "other"));
            setAudioList(data.note_attachment.filter(item => item.cc_attachment_type === "audio"));
            setDocList(data.note_attachment.filter(item => item.cc_attachment_type === "other"));
            setNote_tag_comments(sortedComments);
            setNoteLevel(data.cc_note_level);
            setNoteScore(data.cc_note_score);
            setIsEnabledArtifact(data.cc_note_mark_artified);
            setWork_assignment(data.cc_work_assignment);
            setQuality_points(data.cc_note_quality_points);
            setAddDate(getFormattedDate(data.cc_note_reminder_date, formatDate, signDate));
            setAddTime(data.cc_note_reminder);
            getEditTagData()
            getTextPowerPoint(data.note_tag_comments);
            forceUpdate();
        }
    }, []);

    useEffect(() => {
        if (selectedDoc) {
            setShowDocPopUp(true);
        }
    }, [selectedDoc])

    useEffect(() => {
        if (pageType === 'New') {
            getTagData();
            getLevelandScoreByClass()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const signDate = sessionStorage.getItem('separatorFormat')
        const formatDate = sessionStorage.getItem('dateFormat')
        if(typeof addDate === 'string') {
            setUpdatedDate(addDate)
        } else {
        const formattedDate = getFormattedDate(addDate, formatDate, signDate);
        setUpdatedDate(formattedDate)
        }
    }, [addDate]);

    // useEffect(() => {
    //     if (pageType !== 'New' && note_tag_comments) {
    //         getTextPowerPoint(note_tag_comments)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [note_tag_comments])

    useEffect(() => {
        if (upgradePopUp && upgradePopUp.data &&
            (upgradePopUp.data.insufficient_space == 1 && qualityPoints > 0)) {
            setShowPopUp(true)
        }
    }, [upgradePopUp])

    useEffect(() => {
        if (classTag.action == "GET_CLASS_TAG_SUCCESS_EDIT") {
            if (pageType == 'Edit') {
        //  console.log('YESSSSSSSSSSSSSSSSSSSSSS' , this?.props?.history);
        console.log('props?.location?.state?.data?.note_tag_comments :>> ', props?.location?.state);
        //  this.setState({
        //     tags_for_edit: nextProps.ClassTagEdit.Tag_Data_Edit,
        //     note_tag_comments: this?.props?.history?.location?.state?.data?.note_tag_comments
        //  })
         settags_for_edit(state123) 
        }
        else {
console.log('object :>> ', props?.location?.state)
            // this.setState({
            // tags_for_edit: [],
        // })

        }
        }
        if (classTag.action === 'GET_CLASS_TAG_SUCCESS') {
            if (classTag.Tag_Data) {
                if (pageType == 'Edit') {
                    console.log('props?.location?.state?.data?.note_tag_comments #### :>> ', props?.location?.state?.data?.note_tag_comments);
                    setNote_tag_comments(props?.location?.state?.data?.note_tag_comments)
                    }
                    else {

                    
                if (classTag.Tag_Data.length > 0) {
                    console.log('getting here ##### :>> ', classTag.Tag_Data); 
                    let tagData = classTag.Tag_Data
                    for (let item of tagData) {
                        item.tag_comment_text = ''
                    }
                    setNote_tag_comments(tagData);
                } else {
                    console.log('getting here ##### :>> ', classTag.Tag_Data); 
                    setNote_tag_comments([])
                }
            }
            }
        }

        if (notesData.action === 'ADD_GROUP_NOTE_SUCCESS') {
            if (notesData.Add_Group_Note) {
                if (notesData.Add_Group_Note.status === "Success") {
                    if (!isSaved) {
                        setIsSaved(true)
                        goBackPage()
                    }

                }

            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classTag.action])

    // ----------get Text Points at edit time-----
    const getTextPowerPoint = (data) => {
        let newText_quality_note = 0
        for (let item of data) {
            if (item.tag_comment_text) {
                if (newText_quality_note < quality_note?.max_allotted_text) {
                    newText_quality_note = Number(newText_quality_note) + Number(quality_note?.text_allotted_points)
                }
            }
        }
        setText_quality_note(newText_quality_note);
    }
    // ----------get Text Points at edit time-----


    // ---------set Comment Tag Data-------
    const setCommentData = (data) => {
        console.log('data :>> ', data);
        // ------Add Power points------
        let oldText_quality_note = text_quality_note;
        let newText_quality_note = 0

        for (let item of data) {
            if (item.tag_comment_text) {
                if (newText_quality_note < quality_note?.max_allotted_text) {
                    newText_quality_note = Number(newText_quality_note) + Number(quality_note?.text_allotted_points)
                }
            }

        }
        let quality_points = qualityPoints
        if (oldText_quality_note > newText_quality_note) {

            let value = Number(oldText_quality_note) - Number(newText_quality_note);
            quality_points = Number(quality_points) - Number(value);

        } else {
            let value = Number(newText_quality_note) - Number(oldText_quality_note)
            quality_points = Number(quality_points) + Number(value);

        }
        // ------Add Power points------
        setText_quality_note(newText_quality_note)
        setQuality_points(quality_points)
        setNote_tag_comments(data);
        setIsSaved(false)
    console.log('data 111222666 :>> ', data);
        sessionStorage.setItem(
            "finalNoteCommentCardsAfterRemove",
            JSON.stringify(data)
          );
  
    }
    // ---------set Comment Tag Data-------

    // ------set lavel/score value according to class----
    const getLevelandScoreByClass = () => {
        const classes = student_enrolled_classes;
        for (let item of classes) {
            if (item.cc_class_id === parseInt(class_id)) {
                if (item.cc_class_student_level === null || item.cc_class_student_score === null) {
                    setNoteLevel('')
                    setNoteScore('')
                } else {
                    setNoteLevel(item.cc_class_student_level)
                    setNoteScore(item.cc_class_student_score)
                }

            }
        }
    }
    // ------set lavel/score value according to class----

    const state123 = useSelector((state) => state?.classTagEdit?.Tag_Data_Edit)
    console.log('state123 :>> ', state123);
    // ---------Get Class Tag Data-------
    const getTagData = () => {

        const data = {
            "class_id": sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id
        }
        getClassTag(data)
    }

    const getEditTagData = () => {
        const data = {
            "class_id": sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id
        }
        dispatch(getClassTagEdit(data))
    }
    // ---------Get Class Tag Data-------



    // ---------Select Class On WorkAssignment--------
    const onChangeWorkAssignment = event => {
        setWork_assignment(event.target.value);
        setIsSaved(false);
    }
    // ---------Select Class On WorkAssignment--------
    const handleClosePopUp = event => {
        setShowPopUp(false);
    }

    // ---------Input Handle change For Level/Score-------
    const getNoteLevel = event => {
        const scoreValue = /^[a-zA-Z0-9]+$/;
        if (event.target.value.length < 3 && (!event.target.value || event.target.value.match(scoreValue))) {
            setNoteLevel(event.target.value)
            setIsSaved(false);
        }
    }
    // if (event.target.value.length < 3) {
    //     setNoteLevel(event.target.value)
    //     setIsSaved(false);
    // }

    const getNoteScore = event => {
        const scoreValue = /^[0-9]+$/;
        if (event.target.value.length < 3 && (!event.target.value || event.target.value.match(scoreValue))) {
            setNoteScore(event.target.value)
            setIsSaved(false);
        }
        // setNoteScore(event.target.value)
        // setIsSaved(false);
    };
    // ---------Input Handle change For Level/Score-------


    // ---------Calender Date Picker-------
    const setDate = (date) => {
        setAddDate(date);
        setIsSaved(false);
    }
    // ---------Calender Date Picker-------

    // ---------Calender Date Picker-------
    const setTime = (date) => {
        setAddTime(moment(date).format('LT'));
        setIsSaved(false);
    }
    // ---------Calender Date Picker-------



    // -------------------- Audio Modal ------------------

    // ---------Audio Modal open-------
    const handleShowAudioModal = () => {
        setShow(true);
        setRecord(false);
        setIsEnabled(false);
        setIsSaved(false);
    }
    // ----------Audio Modal open-------

    // ----------Audio Recording Start-------
    const startRecorder = () => {
        recorder.start()
            .then(() => {
                setRecord(true)
            })
            .catch(e => {
                console.error(e);
            });
    }
    // ----------Audio Recording Start-------

    // ----------Save Audio Recording-------
    const stopRecording = () => {
        setShow(false);
        setRecord(false);
        setIsEnabled(false);
        setIsSaved(false);
        recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                setRecord(false);
                const file = new File(buffer, 'Recorded_audio.mp3', {
                    type: blob.type,
                    lastModified: Date.now(),
                });
                const data = JSON.parse(sessionStorage.getItem('UserData'))
                setUploadAudioStatus(true);
                const media_Size = (blob.size / 1000000);

                // ---------DummyName-------

                const GetRendomName = getRandomName();
                const DummyName = GetRendomName + '.mp3';
                // ---------DummyName-------

                // ---------FolderName-------
                const FindFolderNameArray = data.cc_user_email_id.split("@")
                const FolderName = FindFolderNameArray[0]
                // ---------FolderName-------

                const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id}/${DummyName}`).put(file)
                uploadTask.on('state_changed',
                    (snapShot) => {
                        const progress = Math.round(
                            (snapShot.bytesTransferred / snapShot.totalBytes) * 100
                        );
                        setMediaUploadPercentage(progress)
                    }, (err) => {
                        setUploadAudioStatus(false);
                        createNotification('error', 'Audio Not Uploaded. Please give it another shot!');
                    }, () => {
                        storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id}/`).child(DummyName).getDownloadURL()

                            .then(fireBaseUrl => {
                                let value = {
                                    cc_attachment_fullurl: fireBaseUrl,
                                    cc_attachment_url: `${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id}/${DummyName}`,
                                    cc_attachment_name: auditTitle || `${moment().format('LL')} ${moment().format('LT')}`,
                                    cc_attachment_type: 'audio',
                                    cc_attachment_size: media_Size,
                                    cc_attachment_operation: "add"
                                }
                                let DummyList = audioList;
                                DummyList.push(value)
                                let quality_points = qualityPoints;
                                // quality_points = Number(quality_points) + Number(quality_note.audio_allotted_points)
                                const isAudioInArrayLength = audioList.filter((item) => {
                                    return item.cc_attachment_type === 'audio'
                                })
                                if (isAudioInArrayLength.length > 1) {
                                    quality_points = qualityPoints
                                } else {
                                    quality_points = Number(quality_points) + Number(quality_note.audio_allotted_points)
                                }
                                setUploadAudioStatus(false);
                                setAudioList(DummyList);
                                setDisableSmartBtn(false)
                                setQuality_points(quality_points);
                                setAuditTitle("");
                                setIsSaved(false);
                                // createNotification('success', 'Audio Upload Successfully');
                            })
                    })
            });

    }
    // ----------Save Audio Recording-------

    // ----------Set Audio Title-------
    const setAudioTitle = (e) => {
        if (e.target.value !== undefined) {
            setAuditTitle(e.target.value);
            setIsSaved(false);
        }
    }
    // ----------Set Audio Title-------

    // ----------Edit Audio Title-------
    const enableAudioNameEditing = () => {
        setIsEnabled(true)
    }
    // ----------Edit Audio Title-------


    // ---------Audio Modal Close-------

    const handleClose = () => {
        recorder.stop();
        setShow(false);
        setRecord(false);
        setIsEnabled(false);
        setIsSaved(false);
    }

    const handlePause = () => {
        recorder.stop();
        setRecord(false);
        setIsEnabled(false);
    }
    // ---------Audio Modal Close-------

    // -------------------- Audio Modal ------------------

    // ----------------------- Video upload ----------------------

    // ---------Get Image and Video-------
    const getMediaHandle = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        e.target.value = '';
        if (file !== undefined) {
            if (file.type.indexOf("image") !== -1 || file.type.indexOf("video") !== -1) {
                reader.onloadend = () => {
                    uploadMediaFile(file)
                }
                reader.readAsDataURL(file);
            } else if ((file.type.indexOf("msword") !== -1 || file.type.indexOf("vnd.ms-excel") !== -1 || file.type.indexOf("vnd.ms-powerpoint") !== -1 || file.type.indexOf("mspowerpoint") !== -1 || file.type.indexOf("powerpoint") !== -1 || file.type.indexOf("x-mspowerpoint") !== -1 || file.type.indexOf("pdf") !== -1 || file.type.indexOf("csv") !== -1 || file.type.indexOf("doc") !== -1 || file.type.indexOf("docx") !== -1)) {
                reader.onloadend = () => {
                    uploadDocFile(file)
                }
                reader.readAsDataURL(file);
            } else {
                alert("Please select Image, Video, Pdf, Doc, Docx, Xls, Ppt, pptx, Csv file only.")
            }
        }
    }
    // ---------Get Image and Video-------

    // ---------doc Upload on Firebase-------
    const uploadDocFile = (e) => {
        const data = JSON.parse(sessionStorage.getItem('UserData'))
        const docAsFile = e;
        setUploadMediaStatus(true)


        const media_Size = (docAsFile.size / 1000000);
        // ---------Media Type-------
        const mediaTypeArray = docAsFile.type.split("/")
        const mediaType = mediaTypeArray[1]
        // ---------Media Type-------

        // ---------Image Type-------
        const ImageTypeArray = docAsFile.name.split(".")
        const ImageType = ImageTypeArray[ImageTypeArray.length - 1]

        // ---------Image Type-------

        // ---------DummyName-------
        const GetRendomName = getRandomName()
        const DummyName = GetRendomName + '.' + ImageType;
        // ---------DummyName-------

        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@")
        const FolderName = FinfFolderNameArray[0]
        // ---------FolderName-------


        const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id}/${DummyName}`).put(docAsFile)
        uploadTask.on('state_changed',
            (snapShot) => {
                const progress = Math.round(
                    (snapShot.bytesTransferred / snapShot.totalBytes) * 100
                );
                setMediaUploadPercentage(progress);
            }, (err) => {
                setUploadMediaStatus(false);

                createNotification('error', 'Multimedia not uploaded. Please give it another shot!');
            }, () => {
                storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id}/`).child(DummyName).getDownloadURL()
                    .then(fireBaseUrl => {
                        let value = {
                            cc_attachment_fullurl: fireBaseUrl,
                            cc_attachment_url: `${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id}/${DummyName}`,
                            cc_attachment_type: "other",
                            cc_attachment_size: media_Size,
                            cc_attachment_operation: "add"
                        }
                        let DummyList = docList;
                        DummyList.push(value)
                        let quality_points = qualityPoints;
                        if (docList.length > 1) {
                            quality_points = qualityPoints
                        } else {
                            if (mediaType === "image") {
                                quality_points = Number(quality_points) + Number(quality_note.image_allotted_points)
                            } else if (mediaType === "video") {
                                quality_points = Number(quality_points) + Number(quality_note.video_allotted_points)
                            } else {
                                quality_points = Number(quality_points) + Number(quality_note.text_allotted_points)
                            }
                        }
                        setUploadMediaStatus(false);
                        setDocList(DummyList);
                        setQuality_points(quality_points);
                        setIsSaved(false);
                        // createNotification('success', 'Multimedia Upload successful!');
                    })
            })
    }
    // ---------Image Upload on Firebase-------

    // ---------Doc Show on Modal -------
    const handleDocModalShow = (item) => {
        const mediaTypeArray = item.cc_attachment_url.split(".")
        const mediaType = mediaTypeArray[1]
        const encodeUrlStr = encodeURIComponent(item.cc_attachment_fullurl);
        setSelectedDoc(encodeUrlStr)
        setModalDocSelect(item)
    }
    // ---------Doc Show on Modal -------

    const handleDocModalClose = () => {
        setShowDocPopUp(false);
        setSelectedDoc("");
        setModalDocSelect({})
    }


    // ---------Image Upload on Firebase-------
    const uploadMediaFile = (e) => {
        const data = JSON.parse(sessionStorage.getItem('UserData'))
        const imageAsFile = e;
        setUploadMediaStatus(true)
        const media_Size = (imageAsFile.size / 1000000);
        // ---------Media Type-------
        const mediaTypeArray = imageAsFile.type.split("/")
        const mediaType = mediaTypeArray[0]
        // ---------Media Type-------

        // ---------Image Type-------
        const ImageTypeArray = imageAsFile.name.split(".")
        const ImageType = ImageTypeArray[ImageTypeArray.length - 1]

        // ---------Image Type-------

        // ---------DummyName-------
        const GetRendomName = getRandomName()
        const DummyName = GetRendomName + '.' + ImageType;
        // ---------DummyName-------

        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@")
        const FolderName = FinfFolderNameArray[0]
        // ---------FolderName-------


        const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id}/${DummyName}`).put(imageAsFile)
        uploadTask.on('state_changed',
            (snapShot) => {
                const progress = Math.round(
                    (snapShot.bytesTransferred / snapShot.totalBytes) * 100
                );
                setMediaUploadPercentage(progress);
            }, (err) => {
                setUploadMediaStatus(false);

                createNotification('error', 'Multimedia not uploaded. Please give it another shot!');
            }, () => {
                storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id}/`).child(DummyName).getDownloadURL()
                    .then(fireBaseUrl => {
                        let value = {
                            cc_attachment_fullurl: fireBaseUrl,
                            cc_attachment_url: `${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id}/${DummyName}`,
                            cc_attachment_type: mediaType,
                            cc_attachment_size: media_Size,
                            cc_attachment_operation: "add"
                        }
                        let DummyList = pictureVideoList;
                        DummyList.push(value)
                        let quality_points = qualityPoints;

                        if (mediaType === "image") {
                            // quality_points = Number(quality_points) + Number(quality_note.image_allotted_points)
                            let imageArray = []
                            const isImageInArray = pictureVideoList.some((item) => {
                                return item.cc_attachment_type === 'image'
                            })
                            const isImageInArrayLength = pictureVideoList.filter((item) => {
                                return item.cc_attachment_type === 'image'
                            })
                            // imageArray.push(isImageInArrayLength)
                            // console.log(943, imageArray, imageArray?.length)
                            if (isImageInArrayLength.length > 1) {
                                quality_points = qualityPoints
                            } else {
                                quality_points = Number(quality_points) + Number(quality_note.image_allotted_points)
                            }
                        } else {
                            // quality_points = Number(quality_points) + Number(quality_note.video_allotted_points)
                            const isVideoInArrayLength = pictureVideoList.filter((item) => {
                                return item.cc_attachment_type === 'video'
                            })
                            if (isVideoInArrayLength.length > 1) {
                                quality_points = qualityPoints
                            } else {
                                quality_points = Number(quality_points) + Number(quality_note.video_allotted_points)
                            }
                        }
                        setUploadMediaStatus(false);
                        setPictureVideoList(DummyList);
                        setQuality_points(quality_points);
                        setIsSaved(false);
                        // createNotification('success', 'Multimedia Upload successful!');

                    })
            })
    }
    // ---------Image Upload on Firebase-------

    // ----------------------- Video upload ----------------------

    // ----------------------- Modal Fuction ----------------------

    // ---------Video Show on Modal -------
    const handleVideoModalShow = (item) => {
        let tmp = item
        // this.setState({ showVideoModalState: true, selectedVideo: tmp })
        setShowVideoModalState(true);
        setSelectedVideo(tmp);
    }
    // ---------Video Show on Modal -------

    // ---------Video Hide on Modal -------
    const handleVideoModalClose = () => {
        setShowVideoModalState(false);
        setSelectedVideo([]);
    }
    // ---------Video Hide on Modal -------


    // ---------Image Show on Modal -------
    const handleImageModalShow = (item) => {
        let tmp = item
        setShowImageModalState(true);
        setSelectedImage(tmp);
    }
    // ---------Image Show on Modal -------


    // ---------Image Hide on Modal -------
    const handleImageModalClose = () => {
        setShowImageModalState(false);
        setSelectedImage([]);
    }
    // ---------Image Hide on Modal -------

    // ----------------------- Modal Fuction ----------------------

    const onDeleteUpdateMedia = (i, type) => {
        if (props?.location?.state?.pageType == 'Edit') {
            if (type === 'audio') {
                let DummyArrays = audioList
                let crr = DummyArrays.map((val, j) => {
                    if (j === i) {
                        if (val.cc_attachment_operation === "Unchanged") {
                            return (
                                { ...val, "cc_attachment_operation": "unchangedRemoved" }
                            )
                        } else {
                            return (
                                { ...val, "cc_attachment_operation": "remove" }
                            )
                        }
                    } else {
                        return { ...val }
                    }
                })
                let disableSmartAiBtn = crr?.every((item) => item?.cc_attachment_operation == "unchangedRemoved" || item?.cc_attachment_operation == "remove")
        setDisableSmartBtn(disableSmartAiBtn)
                setAudioList(crr)
                setDeleteMediaObjIndex(i)
                setDeleteMediaObjType(type)
            } else if (type === 'other') {
                let DummyArrays = docList
                let crr = DummyArrays.map((val, j) => {

                    if (j === i) {
                        if (val.cc_attachment_operation === "Unchanged") {
                            return (
                                { ...val, "cc_attachment_operation": "unchangedRemoved" }
                            )
                        } else {
                            return (
                                { ...val, "cc_attachment_operation": "remove" }
                            )
                        }
                    } else {
                        return { ...val }
                    }
                })
                setDocList(crr)

                // setDeleteMediaObj(i,type)
                setDeleteMediaObjIndex(i)
                setDeleteMediaObjType(type)

            } else {
                let DummyArrays = pictureVideoList
                let crr = DummyArrays.map((val, j) => {

                    if (j === i) {
                        if (val.cc_attachment_operation === "Unchanged") {
                            return (
                                { ...val, "cc_attachment_operation": "unchangedRemoved" }
                            )
                        } else {
                            return (
                                { ...val, "cc_attachment_operation": "remove" }
                            )
                        }
                    } else {
                        return { ...val }
                    }

                })
                setPictureVideoList(crr)
                setDeleteMediaObjIndex(i)
                setDeleteMediaObjType(type)
            }
        } else {
            onDeleteMedia(i, type)
        }
    }


    useEffect(() => {
        if (typeof deleteMediaObjType == 'string') {
            onDeleteMedia(deleteMediaObjIndex, deleteMediaObjType)
        }
    }, [deleteMediaObjIndex, deleteMediaObjType])


    useEffect(() => {
    }, [deletedMultimedia])

    useEffect(() => {
        // let disableSmartAiBtn = audioList?.every((item) => item?.cc_attachment_operation == "unchangedRemoved" || item?.cc_attachment_operation == "remove")
        // console.log(disableSmartAiBtn)
        // setDisableSmartBtn(disableSmartAiBtn)
    },[audioList])

    // --------------------Delete  Audio/Video/Image from Firebase  ------------------
    const onDeleteMedia = (i, type) => {
        let DummyArray = []
        if (type === 'audio') {
            DummyArray = audioList
        } else if (type === 'other') {
            DummyArray = docList
        } else {
            DummyArray = pictureVideoList
        }

        const data = JSON.parse(sessionStorage.getItem('UserData'))

        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@")
        const FolderName = FinfFolderNameArray[0]
        // ---------FolderName-------

        const fileName = DummyArray[i]?.cc_attachment_url;

        const mediaType = DummyArray[i]?.cc_attachment_type;
        let deletedMultimediaArray = []
        if (pageType === 'Edit') {
            deletedMultimediaArray = [...deletedMultimedia, DummyArray[i]]
            setDeletedMultimedia(deletedMultimediaArray)
            // forceUpdate()
            // deletedMultimediaArray.push(DummyArray[i])
            
            // setDeletedMultimedia(deletedMultimediaArray)
            // forceUpdate()
            try {
                let quality_points = qualityPoints;
                if (mediaType === 'audio') {
                    const audioDummyArray = DummyArray.filter((item) => {
                        return item.cc_attachment_type === 'audio'
                    })
                    const deleteAudioArray = deletedMultimediaArray.filter((item) => {
                        return item.cc_attachment_type === 'audio'
                    })
                    if (audioDummyArray.length == deleteAudioArray.length) {
                        if (quality_points > qualityNote.audio_allotted_points) {
                            quality_points = Number(quality_points) - Number(qualityNote.audio_allotted_points)
                        } else {
                            quality_points = 0;
                        }
                    } else {
                        quality_points = qualityPoints
                    }
                    setAudioList(DummyArray)
                    setQuality_points(quality_points)
                    // this.setState({
                    //     audioList: DummyArray,
                    //     quality_points: quality_points,
                    //     isSaved: false
                    // });

                } else {
                    if (mediaType === "image") {
                        const imageDummyArray = DummyArray.filter((item) => {
                            return item.cc_attachment_type === 'image'
                        })
                        const deleteImageArray = deletedMultimediaArray.filter((item) => {
                            return item.cc_attachment_type === 'image'
                        })
                        if (imageDummyArray.length == deleteImageArray.length) {
                            if (quality_points > qualityNote.image_allotted_points) {
                                quality_points = Number(quality_points) - Number(qualityNote.image_allotted_points)
                            } else {
                                quality_points = 0;
                            }
                        } else {
                            quality_points = qualityPoints
                        }
                    } else if (mediaType === "other") {
                        const docDummyArray = DummyArray.filter((item) => {
                            return item.cc_attachment_type === "other"
                        })
                        const deleteDocArray = deletedMultimediaArray.filter((item) => {
                            return item.cc_attachment_type === "other"
                        })
                        if (docDummyArray.length == deleteDocArray.length) {
                            if (quality_points > qualityNote.text_allotted_points) {
                                quality_points = Number(quality_points) - Number(qualityNote.text_allotted_points)
                            } else {
                                quality_points = 0;
                            }
                        } else {
                            quality_points = qualityPoints
                        }
                    } else {
                        const videoDummyArray = DummyArray.filter((item) => {
                            return item.cc_attachment_type === "video"
                        })
                        const deleteVideoArray = deletedMultimediaArray.filter((item) => {
                            return item.cc_attachment_type === "video"
                        })
                        if (videoDummyArray.length == deleteVideoArray.length) {
                            if (quality_points > qualityNote.video_allotted_points) {
                                quality_points = Number(quality_points) - Number(qualityNote.video_allotted_points)
                            } else {
                                quality_points = 0;
                            }
                        } else {
                            quality_points = qualityPoints
                        }
                    }

                    if (mediaType === "other") {
                        setDocList(DummyArray)
                        setQuality_points(quality_points)
                        // this.setState({
                        //     docList: DummyArray,
                        //     quality_points: quality_points,
                        //     isSaved: false
                        // });
                    } else {
                        setPictureVideoList(DummyArray)
                        setQuality_points(quality_points)
                        // this.setState({
                        //     pictureVideoList: DummyArray,
                        //     quality_points: quality_points,
                        //     isSaved: false
                        // });
                    }
                }
            } catch (e) {
                createNotification('error', 'The Multimedia was not deleted. Please try again.');
            }
        } else {
            DummyArray.splice(i, 1)
            try {
                let quality_points = qualityPoints;
                if (type === 'audio') {
                    if (quality_points > quality_note.audio_allotted_points) {
                        quality_points = Number(quality_points) - Number(quality_note.audio_allotted_points)
                    } else {
                        quality_points = 0;
                    }
                    setAudioList(DummyArray);
                    setQuality_points(quality_points);
                    setIsSaved(false)

                } else {
                    if (mediaType === "image") {
                        if (quality_points > quality_note.image_allotted_points) {
                            quality_points = Number(quality_points) - Number(quality_note.image_allotted_points)
                        } else {
                            quality_points = 0;
                        }
                    } else if (mediaType === "other") {
                        if (quality_points > quality_note.text_allotted_points) {
                            quality_points = Number(quality_points) - Number(quality_note.text_allotted_points)
                        } else {
                            quality_points = 0;
                        }
                    } else {
                        if (quality_points > quality_note.video_allotted_points) {
                            quality_points = Number(quality_points) - Number(quality_note.video_allotted_points)
                        } else {
                            quality_points = 0;
                        }
                    }
                    if (mediaType === "other") {
                        setDocList(DummyArray);
                        setQuality_points(quality_points);
                        setIsSaved(false);
                    } else {
                        setPictureVideoList(DummyArray);
                        setQuality_points(quality_points);
                        setIsSaved(false);
                    }
                }
                // storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId') : classDetails.details.cc_class_id}/${fileName}`).delete()
                storage.ref(`${fileName}`).delete()
                // createNotification('success', 'Multimedia deleted successfully');
            } catch (e) {
                createNotification('error', 'The Multimedia was not deleted. Please try again.');
            }
        }
    }
    // -----------------Delete  Audio/Video/Image from Firebase  -------------



    // --------------------Change  note Details  ------------------
    const changeNote = (data) => {
        if (isSaved) {
            let attachments = data.note_attachment;
            let DummyAudioNote = [];
            let DummyVideoNote = [];
            let DummyDocNote = [];
            for (let item of attachments) {
                if (item.cc_attachment_type === "audio") {
                    DummyAudioNote.push(item)
                } if (item.cc_attachment_type === "other") {
                    DummyDocNote.push(item)
                } else {
                    DummyVideoNote.push(item)
                }
            }
            let assignment = '';
            if (data.cc_work_assignment === null || data.cc_work_assignment === "null") {
                assignment = '';
            } else {
                assignment = data.cc_work_assignment
            }
            setNoteData(data);
            setAddDate(data.cc_note_reminder_date);
            setNote_tag_comments(data.note_tag_comments);
            setNoteLevel(data.cc_note_level);
            setNoteScore(data.cc_note_score);
            setIsEnabledArtifact(data.cc_note_mark_artified);
            setWork_assignment(assignment);
            setPictureVideoList(DummyVideoNote);
            setAudioList(DummyAudioNote);
            setDocList(DummyDocNote);
            setQuality_points(data.cc_note_quality_points);
            setIsSaved(true);

        } else {
            setConfirmationForBack(true);
            setConfirmationType('noteChange');
            setChangeNoteData(data);
        }
    }
    // --------------------Change  note Details  ------------------



    // --------------------add note ------------------
//     const addNotes = () => {
//         if (qualityPoints === 0) {
//             createNotification('error', 'Your note is empty, “Please enter comments "');
//             return;
//         }
//         // const comments = props?.location?.state?.pageType == 'Edit' ? 
//         // (JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : props?.location?.state?.data?.note_tag_comments)
//         //  : note_tag_comments;
//         let comments = [...note_tag_comments]
//         let tagStatus = true;
//         for (let item of comments) {
//             if (item.tag_comment_text.length > 0) {
//                 tagStatus = false
//             }
//         }
//         if (tagStatus) {
//             createNotification('error', 'Your note is empty, “Please enter comments "');
//             return;
//         }

//         // --------get attachment array----
//         // const { pictureVideoList, audioList } = this.state
//         let attachment_array = [];
//         for (let item of docList) {
//             let attachment = {
//                 "cc_attachment_url": item.cc_attachment_url,
//                 "cc_attachment_size": item.cc_attachment_size,
//                 "cc_attachment_type": item.cc_attachment_type,
//                 "cc_attachment_name": "",
//                 "cc_attachment_operation": item.cc_attachment_operation,
//                 "cc_media_mark_artified": item.cc_media_mark_artified == 'yes' ? 'yes' : 'no' ,
//             }
//             attachment_array.push(attachment)
//         }
//         for (let item of pictureVideoList) {
//             let attachment = {
//                 "cc_attachment_url": item.cc_attachment_url,
//                 "cc_attachment_size": item.cc_attachment_size,
//                 "cc_attachment_type": item.cc_attachment_type,
//                 "cc_attachment_name": "",
//                 "cc_attachment_operation": item.cc_attachment_operation,
//                 "cc_media_mark_artified": item.cc_media_mark_artified == 'yes' ? 'yes' : 'no' ,
//             }
//             attachment_array.push(attachment)
//         }
//         for (let item of audioList) {
//             let attachment = {
//                 "cc_attachment_url": item.cc_attachment_url,
//                 "cc_attachment_size": item.cc_attachment_size,
//                 "cc_attachment_type": item.cc_attachment_type,
//                 "cc_attachment_name": item.cc_attachment_name || `${moment().format('LL')} ${moment().format('LT')}`,
//                 "cc_attachment_operation": item.cc_attachment_operation,
//                 "cc_media_mark_artified": item.cc_media_mark_artified == 'yes' ? 'yes' : 'no' ,
//             }
//             attachment_array.push(attachment)

//         }
//         // --------get attachment array----

//         // --------get Tags array----
//         // let AllNoteTag = props?.location?.state?.pageType == 'Edit' ?
//         //  (JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : props?.location?.state?.data?.note_tag_comments)
//         //   : note_tag_comments;
//         let AllNoteTag = [...note_tag_comments]
//         let customized_tag_list = '';
//         let customized_tag_array = [];
        
// let arrayOFString = []
// let finalArrStr = []
//         // ------Get customized_tag_comments JSON----
//         if (pageType === 'Edit') {
//             let index = 0;

//         // Create an object to store grouped tag_comment_text by cc_tag_title
// const groupedTagTexts = {};
// const orderArray = [];

// // Iterate over the input array to group tag_comment_text
// // AllNoteTag.forEach(obj => {
// //   const { cc_tag_title, tag_comment_text } = obj;

// //   if (cc_tag_title) {
// //     if (!groupedTagTexts[cc_tag_title]) {
// //       groupedTagTexts[cc_tag_title] = [];
// //       orderArray.push(cc_tag_title);
// //     }
// //     groupedTagTexts[cc_tag_title].push(tag_comment_text);
// //   }
// // });

// // // Create the result array based on the groupedTagTexts
// // const resultArray = Object.entries(groupedTagTexts).map(([title, texts]) => ({
// //   [title]: [texts.join(':::::')]
// // }));

  
  

// // finalArrStr = arrayOFString.map((subArray,index) => {
// //     if (subArray[0] && subArray[0].includes(':::::')) {
// //       // If there are multiple strings, split and remove single apostrophes
// //       if(index != subArray.length - 1 && index != subArray.length - 2) {
// //         return [subArray[0]];
// //       } else {
// //         return subArray[0].split(':::::').map(str => str.replace(/'/g, ''));
// //       }
// //     //   return subArray[0].split(',').map(str => str.replace(/'/g, ''));
// //     } else {
// //       // If there is a single string or an empty string, leave it as is
// //       return [subArray[0]];
// //     }
// //   });
  

  

//             // ------Get customized_tag Comment by Edit----

//             // const newArray = AllNoteTag.map((obj) => {
//             //     const result = [];
//             //     result.push(obj.tag_comment_text);
//             //     return result;
//             //   });
//             //   console.log(1240, newArray)
//             for (let item of AllNoteTag) {
//                 console.log(item)
//                 if (item.tag_id) {
//                     console.log(1236, 'if')
//                     let value = []
//                     value.push(item.tag_comment_text)
//                     customized_tag_array.push(value)
//                     index++
//                     console.log(1242,customized_tag_array)
//                 } else {
//                     console.log(1242, 'else')
//                     let value = customized_tag_array[index - 1]
//                     value.push(item?.tag_comment_text)
//                     customized_tag_array[index - 1] = value
//                     console.log(1246,customized_tag_array)
//                 }
//             }
//             // ------Get customized_tag Comment by Edit----
//         } else {
//             let index = 0;
//             // ------Get customized_tag Comment by Add----
//             for (let item of AllNoteTag) {
//                 if (item.cc_tag_id) {
//                     let value = []
//                     value.push(item.tag_comment_text)
//                     customized_tag_array.push(value)
//                     index++
//                 } else {
//                     let value = customized_tag_array[index - 1]
//                     value.push(item.tag_comment_text)
//                     customized_tag_array[index - 1] = value
//                 }
//             }
//             // ------Get customized_tag Comment by Add----
//         }
//         // ------Get customized_tag_comments JSON----

//         // ------Get customized_tag_list in one string----
//         if (pageType === 'Edit') {
//             // ------Get customized_tag_list by Edit----
//             for (let item of AllNoteTag) {
//                 if (customized_tag_list === '') {
//                     if (item.tag_id === '') {
//                         customized_tag_list = ''
//                     } else {
//                         customized_tag_list = item.tag_id
//                     }
//                 }
//                 else {
//                     if (!(item.tag_id === '')) {
//                         customized_tag_list = customized_tag_list + "," + item.tag_id
//                     }
//                 }
//             }
//             // ------Get customized_tag_list by Edit----
//         } else {
//             // ------Get customized_tag_list by New----
//             for (let item of AllNoteTag) {
//                 if (customized_tag_list === '') {
//                     if (item.cc_tag_id === '') {
//                         customized_tag_list = ''
//                     } else {
//                         customized_tag_list = item.cc_tag_id
//                     }
//                 }
//                 else {
//                     if (!(item.cc_tag_id === '')) {
//                         customized_tag_list = customized_tag_list + "," + item.cc_tag_id
//                     }
//                 }
//             }
//             // ------Get customized_tag_list by New----

//         }
//         // Convert the string to an array and remove duplicates using Set
// const uniqueArray = [...new Set(customized_tag_list.split(','))];

// // Convert the array back to a string
// const resultString = uniqueArray.join(',');
// console.log(finalArrStr)
// console.log(resultString);
//         // ------Get customized_tag_list in one string----
//         let apiData = {
//             "class_id": sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId').toString() : classDetails.details.cc_class_id.toString(),
//             "attachment_details_array": attachment_array,
//             "customized_tag_array": pageType == 'Edit' ? finalArrStr : customized_tag_array,
//             "customized_tag_list": pageType == 'Edit' ? resultString :  customized_tag_list,
//             "customized_tag_array": customized_tag_array,
//             "customized_tag_list":  customized_tag_list,
//             "linked_flag": 'false',
//             "note_reminder": addTime,
//             "note_reminder_date": getDateFormat(addDate),
//             "note_student_level": noteLevel,
//             "note_student_marked": isEnabledArtifact,
//             "note_student_score": noteScore,
//             "student_list": selectedStudentsForGroupNote.toString(),
//             "work_assignment": work_assignment,
//             "group_id": props?.location?.state?.groupDetail?.cc_group_id,
//         }

//         if (props?.location?.state?.pageType == 'Edit') {
//             // apiData.note_id = noteData.cc_note_id
//             let finalAttachments = [];
//             for (let item of attachment_array) {
//                 if (item.cc_attachment_operation === "remove" || item.cc_attachment_operation === "unchangedRemoved") {
//                     finalAttachments.push({ ...item, "cc_attachment_operation": "Remove" });
//                 } else if (item.cc_attachment_operation === "add") {
//                     finalAttachments.push(item);
//                 } else if (item.cc_attachment_operation === "Unchanged" || !item.cc_attachment_operation) {
//                     finalAttachments.push({ ...item, "cc_attachment_operation": "Unchanged" });
//                 }
//             }
//             apiData.attachment_details_array = finalAttachments;
//             apiData.note_ids_array = props?.location?.state?.data?.cc_note_batch_ids.split(',')
//             apiData.academicYearId = props?.location?.state?.academicYearId;
//             apiData.studentId = props?.location?.state?.selectedStudentsForGroupNote[props?.location?.state?.selectedStudentsForGroupNote?.length - 1];
//             apiData.group_id = props?.location?.state?.groupDetail?.cc_group_id;
//             apiData.pageNo = 1;
//             apiData.limit = 100;   
//             apiData.history = props?.history;
//             apiData.filter = 'both';
//             // this.props.editStudentNote(apiData)
//             const funObje = { history };
//             console.log(apiData)
//             editGroupNote(apiData, funObje)
//             {
//                 deletedMultimedia && deletedMultimedia.length > 0 && deletedMultimedia.map(async (val) => {
//                     if (val.cc_attachment_operation !== "Unchanged") {
//                         const data = JSON.parse(sessionStorage.getItem('UserData'))
//                         const FinfFolderNameArray = data.cc_user_email_id.split("@")
//                         const FolderName = FinfFolderNameArray[0]
//                         const fileName = val.cc_attachment_url;
//                         await storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId').toString() : classDetails.details.cc_class_id.toString()}/${fileName}`).delete()
//                     }
//                 })
//             }
//         } else {
//             let finalAttachments = [];
//             for (let item of attachment_array) {
//                 if (item.cc_attachment_operation !== "remove") {
//                     finalAttachments.push(item);
//                 }
//             }
//             apiData.attachment_details_array = finalAttachments;
//             apiData.history = props?.history
//             const funObje = { history };
//             console.log(apiData)
//             addGroupNote(apiData, funObje);
//             // this.props.addStudentNote(apiData)

//         }


//         //     } 
//         //     else {
//         //     apiData = {
//         //         "class_id": sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId').toString() : classDetails.details.cc_class_id.toString(),
//         //         "attachment_details_array": attachment_array,
//         //         "customized_tag_array": customized_tag_array,
//         //         "customized_tag_list": customized_tag_list,
//         //         "linked_flag": 'false',
//         //         "note_reminder": addTime,
//         //         "note_reminder_date": getDateFormat(addDate),
//         //         "note_student_level": noteLevel,
//         //         "note_student_marked": isEnabledArtifact,
//         //         "note_student_score": noteScore,
//         //         "student_list": selectedStudentsForGroupNote.toString(),
//         //         "work_assignment": work_assignment,
//         //         "group_id": props?.location?.state?.groupDetail?.cc_group_id
//         //     }
//         // }
//         // const funObje = { history };
//         // console.log(props)
//         // console.log(props?.location?.state?.pageType, props?.location?.state?.pageType == 'Edit')
//         // if(props?.location?.state?.pageType == 'Edit') {
//         //     console.log(999, 'if')
//         //     editGroupNote(apiData, funObje)
//         // } else {
//         //     console.log(1002, 'else')
//         // addGroupNote(apiData, funObje);
//         // }
//         // }
//     }
const addNotes = () => {
    console.log('note_tag_comments 555666555 :>> ', note_tag_comments);
    if (qualityPoints === 0) {
        createNotification('error', 'Your note is empty, “Please enter comments "');
        return;
    }
    // const comments = props?.location?.state?.pageType == 'Edit' ? 
    // (JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : props?.location?.state?.data?.note_tag_comments)
    //  : note_tag_comments;
    let comments = [...note_tag_comments]
    let tagStatus = true;
    console.log('comments :>> ', comments);
    for (let item of comments) {
        if (item.tag_comment_text?.length > 0) {
            tagStatus = false
        }
    }
    if (tagStatus) {
        createNotification('error', 'Your note is empty, “Please enter comments "');
        return;
    }

    // --------get attachment array----
    // const { pictureVideoList, audioList } = this.state
    let attachment_array = [];
    for (let item of docList) {
        let attachment = {
            "cc_attachment_url": item.cc_attachment_url,
            "cc_attachment_size": item.cc_attachment_size,
            "cc_attachment_type": item.cc_attachment_type,
            "cc_attachment_name": "",
            "cc_attachment_operation": item.cc_attachment_operation,
            "cc_media_mark_artified": item.cc_media_mark_artified == 'yes' ? 'yes' : 'no' ,
        }
        attachment_array.push(attachment)
    }
    for (let item of pictureVideoList) {
        let attachment = {
            "cc_attachment_url": item.cc_attachment_url,
            "cc_attachment_size": item.cc_attachment_size,
            "cc_attachment_type": item.cc_attachment_type,
            "cc_attachment_name": "",
            "cc_attachment_operation": item.cc_attachment_operation,
            "cc_media_mark_artified": item.cc_media_mark_artified == 'yes' ? 'yes' : 'no' ,
        }
        attachment_array.push(attachment)
    }
    for (let item of audioList) {
        let attachment = {
            "cc_attachment_url": item.cc_attachment_url,
            "cc_attachment_size": item.cc_attachment_size,
            "cc_attachment_type": item.cc_attachment_type,
            "cc_attachment_name": item.cc_attachment_name,
            "cc_attachment_operation": item.cc_attachment_operation,
            "cc_media_mark_artified": item.cc_media_mark_artified == 'yes' ? 'yes' : 'no' ,
        }
        attachment_array.push(attachment)
    }
    // --------get attachment array----

    // --------get Tags array----
    // let AllNoteTag = props?.location?.state?.pageType == 'Edit' ?
    //  (JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : props?.location?.state?.data?.note_tag_comments)
    //   : note_tag_comments;
    let AllNoteTag = [...note_tag_comments]
    let customized_tag_list = '';
    let customized_tag_array = [];
    
let arrayOFString = []
let finalArrStr = []
    // ------Get customized_tag_comments JSON----
    if (pageType === 'Edit') {
        let index = 0;

    // Create an object to store grouped tag_comment_text by cc_tag_title
const groupedTagTexts = {};
const orderArray = [];

// Iterate over the input array to group tag_comment_text
// AllNoteTag.forEach(obj => {
//   const { cc_tag_title, tag_comment_text } = obj;

//   if (cc_tag_title) {
//     if (!groupedTagTexts[cc_tag_title]) {
//       groupedTagTexts[cc_tag_title] = [];
//       orderArray.push(cc_tag_title);
//     }
//     groupedTagTexts[cc_tag_title].push(tag_comment_text);
//   }
// });

// // Create the result array based on the groupedTagTexts
// const resultArray = Object.entries(groupedTagTexts).map(([title, texts]) => ({
//   [title]: [texts.join(':::::')]
// }));




// finalArrStr = arrayOFString.map((subArray,index) => {
//     if (subArray[0] && subArray[0].includes(':::::')) {
//       // If there are multiple strings, split and remove single apostrophes
//       if(index != subArray.length - 1 && index != subArray.length - 2) {
//         return [subArray[0]];
//       } else {
//         return subArray[0].split(':::::').map(str => str.replace(/'/g, ''));
//       }
//     //   return subArray[0].split(',').map(str => str.replace(/'/g, ''));
//     } else {
//       // If there is a single string or an empty string, leave it as is
//       return [subArray[0]];
//     }
//   });




        // ------Get customized_tag Comment by Edit----

        // const newArray = AllNoteTag.map((obj) => {
        //     const result = [];
        //     result.push(obj.tag_comment_text);
        //     return result;
        //   });
        //   console.log(1240, newArray)
        for (let item of AllNoteTag) {
            console.log(item)
            if (item.tag_id) {
                console.log(1236, 'if')
                let value = []
                value.push(item.tag_comment_text)
                customized_tag_array.push(value)
                index++
                console.log(1242,customized_tag_array)
            } else {
                console.log(1242, 'else')
                let value = customized_tag_array[index - 1]
                value.push(item?.tag_comment_text)
                customized_tag_array[index - 1] = value
                console.log(1246,customized_tag_array)
            }
        }
        // ------Get customized_tag Comment by Edit----
    } else {
        let index = 0;
        // ------Get customized_tag Comment by Add----
        for (let item of AllNoteTag) {
            if (item.cc_tag_id) {
                let value = []
                value.push(item.tag_comment_text)
                customized_tag_array.push(value)
                index++
            } else {
                let value = customized_tag_array[index - 1]
                value.push(item.tag_comment_text)
                customized_tag_array[index - 1] = value
            }
        }
        // ------Get customized_tag Comment by Add----
    }
    // ------Get customized_tag_comments JSON----

    // ------Get customized_tag_list in one string----
    if (pageType === 'Edit') {
        // ------Get customized_tag_list by Edit----
        for (let item of AllNoteTag) {
            if (customized_tag_list === '') {
                if (item.tag_id === '') {
                    customized_tag_list = ''
                } else {
                    customized_tag_list = item.tag_id
                }
            }
            else {
                if (!(item.tag_id === '')) {
                    customized_tag_list = customized_tag_list + "," + item.tag_id
                }
            }
        }
        // ------Get customized_tag_list by Edit----
    } else {
        // ------Get customized_tag_list by New----
        for (let item of AllNoteTag) {
            if (customized_tag_list === '') {
                if (item.cc_tag_id === '') {
                    customized_tag_list = ''
                } else {
                    customized_tag_list = item.cc_tag_id
                }
            }
            else {
                if (!(item.cc_tag_id === '')) {
                    customized_tag_list = customized_tag_list + "," + item.cc_tag_id
                }
            }
        }
        // ------Get customized_tag_list by New----

    }
    // Convert the string to an array and remove duplicates using Set
const uniqueArray = [...new Set(customized_tag_list.split(','))];

// Convert the array back to a string
const resultString = uniqueArray.join(',');
console.log(finalArrStr)
console.log(resultString);
    // ------Get customized_tag_list in one string----
    let apiData = {
        "class_id": sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId').toString() : classDetails.details.cc_class_id.toString(),
        "attachment_details_array": attachment_array,
        // "customized_tag_array": pageType == 'Edit' ? finalArrStr : customized_tag_array,
        // "customized_tag_list": pageType == 'Edit' ? resultString :  customized_tag_list,
        "customized_tag_array": customized_tag_array,
        "customized_tag_list":  customized_tag_list,
        "linked_flag": 'false',
        "note_reminder": addTime,
        "note_reminder_date": getDateFormat(addDate),
        "note_student_level": noteLevel,
        "note_student_marked": isEnabledArtifact,
        "note_student_score": noteScore,
        "student_list": selectedStudentsForGroupNote.toString(),
        "work_assignment": work_assignment,
        "group_id": props?.location?.state?.groupDetail?.cc_group_id,
    }

    if (props?.location?.state?.pageType == 'Edit') {
        // apiData.note_id = noteData.cc_note_id
        let finalAttachments = [];
        for (let item of attachment_array) {
            if (item.cc_attachment_operation === "remove" || item.cc_attachment_operation === "unchangedRemoved") {
                finalAttachments.push({ ...item, "cc_attachment_operation": "Remove" });
            } else if (item.cc_attachment_operation === "add") {
                finalAttachments.push(item);
            } else if (item.cc_attachment_operation === "Unchanged" || !item.cc_attachment_operation) {
                finalAttachments.push({ ...item, "cc_attachment_operation": "Unchanged" });
            }
        }
        apiData.attachment_details_array = finalAttachments;
        apiData.note_ids_array = props?.location?.state?.data?.cc_note_batch_ids.split(',')
        apiData.academicYearId = props?.location?.state?.academicYearId;
        apiData.studentId = props?.location?.state?.selectedStudentsForGroupNote[props?.location?.state?.selectedStudentsForGroupNote?.length - 1];
        apiData.group_id = props?.location?.state?.groupDetail?.cc_group_id;
        apiData.pageNo = 1;
        apiData.limit = 100;   
        apiData.history = props?.history;
        apiData.filter = 'both';
        // this.props.editStudentNote(apiData)
        const funObje = { history };
        console.log(apiData)
        editGroupNote(apiData, funObje)
        {
            deletedMultimedia && deletedMultimedia.length > 0 && deletedMultimedia.map(async (val) => {
                if (val.cc_attachment_operation !== "Unchanged") {
                    const data = JSON.parse(sessionStorage.getItem('UserData'))
                    const FinfFolderNameArray = data.cc_user_email_id.split("@")
                    const FolderName = FinfFolderNameArray[0]
                    const fileName = val.cc_attachment_url;
                    await storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId').toString() : classDetails.details.cc_class_id.toString()}/${fileName}`).delete()
                }
            })
        }
    } else {
        let finalAttachments = [];
        for (let item of attachment_array) {
            if (item.cc_attachment_operation !== "remove") {
                finalAttachments.push(item);
            }
        }
        apiData.attachment_details_array = finalAttachments;
        apiData.history = props?.history
        const funObje = { history };
        console.log(apiData)
        addGroupNote(apiData, funObje);
        // this.props.addStudentNote(apiData)

    }
}
// const addNotes = () => {
//     // Check if there are no quality points
//     if (qualityPoints === 0) {
//         createNotification('error', 'Your note is empty, “Please enter comments "');
//         return;
//     }

//     let comments = [...note_tag_comments];
//     let tagStatus = true;
//      console.log('note_tag_comments 6968 :>> ', note_tag_comments);
//      sessionStorage.setItem("finalNoteCommentCardsAfterRemove", JSON.stringify(comments))
//     // Check if all comments are empty
//     for (let item of comments) {
//         if (item.tag_comment_text.length > 0) {
//             tagStatus = false;
//         } 
//     }
//     if (tagStatus) {
//         createNotification('error', 'Your note is empty, “Please enter comments "');
//         return;
//     }

//     // --------get attachment array----
//     let attachment_array = [];
//     for (let item of docList) {
//         let attachment = {
//             "cc_attachment_url": item.cc_attachment_url,
//             "cc_attachment_size": item.cc_attachment_size,
//             "cc_attachment_type": item.cc_attachment_type,
//             "cc_attachment_name": "",
//             "cc_attachment_operation": item.cc_attachment_operation,
//             "cc_media_mark_artified": item.cc_media_mark_artified === 'yes' ? 'yes' : 'no',
//         };
//         attachment_array.push(attachment);
//     }
//     for (let item of pictureVideoList) {
//         let attachment = {
//             "cc_attachment_url": item.cc_attachment_url,
//             "cc_attachment_size": item.cc_attachment_size,
//             "cc_attachment_type": item.cc_attachment_type,
//             "cc_attachment_name": "",
//             "cc_attachment_operation": item.cc_attachment_operation,
//             "cc_media_mark_artified": item.cc_media_mark_artified === 'yes' ? 'yes' : 'no',
//         };
//         attachment_array.push(attachment);
//     }
//     for (let item of audioList) {
//         let attachment = {
//             "cc_attachment_url": item.cc_attachment_url,
//             "cc_attachment_size": item.cc_attachment_size,
//             "cc_attachment_type": item.cc_attachment_type,
//             "cc_attachment_name": item.cc_attachment_name || `${moment().format('LL')} ${moment().format('LT')}`,
//             "cc_attachment_operation": item.cc_attachment_operation,
//             "cc_media_mark_artified": item.cc_media_mark_artified === 'yes' ? 'yes' : 'no',
//         };
//         console.log('item 6968:>> ', item);
//         attachment_array.push(attachment);
//     }
//     // --------get attachment array----

//     // --------get Tags array----
//     let AllNoteTag = [...note_tag_comments];
//     let customized_tag_list = '';
//     let customized_tag_array = [];

//     // ------Get customized_tag_comments JSON----
//     if (pageType === 'Edit') {
//         console.log('object 6968 :>> ' , AllNoteTag);
//         let index = 0;
//         for (let item of AllNoteTag) {
//             if (item.tag_id) {
//                 let value = [];
//                 value.push(item.tag_comment_text);
//                 customized_tag_array.push(value);
//                 index++;
//             } else {
//                 let value = customized_tag_array[index - 1];
//                 value.push(item?.tag_comment_text);
//                 customized_tag_array[index - 1] = value;
//             }
//         }
//         setNote_tag_comments(AllNoteTag);
//     } else {
//         let index = 0;
//         for (let item of AllNoteTag) {
//             if (item.cc_tag_id) {
//                 let value = [];
//                 value.push(item.tag_comment_text);
//                 customized_tag_array.push(value);
//                 index++;
//             } else {
//                 let value = customized_tag_array[index - 1];
//                 value.push(item.tag_comment_text);
//                 customized_tag_array[index - 1] = value;
//             }
//         }
//     }
//     // ------Get customized_tag_comments JSON----

//     // ------Get customized_tag_list in one string----
//     if (pageType === 'Edit') {
//         for (let item of AllNoteTag) {
//             if (customized_tag_list === '') {
//                 if (item.tag_id === '') {
//                     customized_tag_list = '';
//                 } else {
//                     customized_tag_list = item.tag_id;
//                 }
//             } else {
//                 if (!(item.tag_id === '')) {
//                     customized_tag_list = customized_tag_list + "," + item.tag_id;
//                 }
//             }
//         }
//     } else {
//         for (let item of AllNoteTag) {
//             if (customized_tag_list === '') {
//                 if (item.cc_tag_id === '') {
//                     customized_tag_list = '';
//                 } else {
//                     customized_tag_list = item.cc_tag_id;
//                 }
//             } else {
//                 if (!(item.cc_tag_id === '')) {
//                     customized_tag_list = customized_tag_list + "," + item.cc_tag_id;
//                 }
//             }
//         }
//     }

//     // Remove duplicates from customized_tag_list
//     const uniqueArray = [...new Set(customized_tag_list.split(','))];
//     const resultString = uniqueArray.join(',');

//     // Prepare apiData object
//     let apiData = {
//         "class_id": sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId').toString() : classDetails.details.cc_class_id.toString(),
//         "attachment_details_array": attachment_array,
//         "customized_tag_array": customized_tag_array,
//         "customized_tag_list": resultString,
//         "linked_flag": 'false',
//         "note_reminder": addTime,
//         "note_reminder_date": getDateFormat(addDate),
//         "note_student_level": noteLevel,
//         "note_student_marked": isEnabledArtifact,
//         "note_student_score": noteScore,
//         "student_list": selectedStudentsForGroupNote.toString(),
//         "work_assignment": work_assignment,
//         "group_id": props?.location?.state?.groupDetail?.cc_group_id,
//     };

//     // Handle Edit or New Note cases
//     if (props?.location?.state?.pageType === 'Edit') {
//         let finalAttachments = [];
//         for (let item of attachment_array) {
//             if (item.cc_attachment_operation === "remove" || item.cc_attachment_operation === "unchangedRemoved") {
//                 finalAttachments.push({ ...item, "cc_attachment_operation": "Remove" });
//             } else if (item.cc_attachment_operation === "add") {
//                 finalAttachments.push(item);
//             } else if (item.cc_attachment_operation === "Unchanged" || !item.cc_attachment_operation) {
//                 finalAttachments.push({ ...item, "cc_attachment_operation": "Unchanged" });
//             }
//         }
//         apiData.attachment_details_array = finalAttachments;
//         apiData.note_ids_array = props?.location?.state?.data?.cc_note_batch_ids.split(',');
//         apiData.academicYearId = props?.location?.state?.academicYearId;
//         apiData.studentId = props?.location?.state?.selectedStudentsForGroupNote[props?.location?.state?.selectedStudentsForGroupNote?.length - 1];
//         apiData.pageNo = 1;
//         apiData.limit = 100;
//         apiData.history = props?.history;
//         apiData.filter = 'both';

//         const funObje = { history };
//         console.log(apiData);
//         editGroupNote(apiData, funObje);
//     } else {
//         let finalAttachments = [];
//         for (let item of attachment_array) {
//             if (item.cc_attachment_operation !== "remove") {
//                 finalAttachments.push(item);
//             }
//         }
//         apiData.attachment_details_array = finalAttachments;
//         apiData.history = props?.history;

//         const funObje = { history };
//         console.log(apiData);
//         addGroupNote(apiData, funObje);
//     }
// };

    // --------------------add note ------------------



    // ---------Confirmation Modal Response -----------
  
  
  
  
    const confirmationToBack = () => {
        setConfirmationForBack(false);
        setIsSaved(true);
    }
    useEffect(() => {
        if (confirmationForBack) {
            if (confirmationType === 'back') {
                goBackPage()
            }
            else {
                changeNote(ChangeNoteData)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationForBack, isSaved, ChangeNoteData, changeNote, confirmationType]);
    // ---------Confirmation Modal Response -----------


    // --------------------Go Back ------------------
    const goBackPage = () => {
        if (isSaved) {
            history.goBack()
        }
        else {
            setConfirmationForBack(true)
            setConfirmationType('back')
        }
    }
    // --------------------Go Back ------------------
    // ---------BackTO-------
    const BackToDash = () => {
        history.replace("/home/academic")
    }
    // ---------BackTO-------
    // ---------BackTO-------
    const BackTOHome = () => {
        if (history.location.state.BackPage === "Academic Year") {
            history.replace("/home/academic")
        } else {
            history.goBack();
        }
    }
    // ---------BackTO-------
    // --------------------Select-Search-Option ------------------
    const options = [
        {
            type: "group",
            name: "",
            items: [{ name: "ABC", value: "" },]
        }
    ];
    // --------------------Select-Search-Option ------------------
    studentsInfo && selectedStudentsForGroupNote && studentsInfo.map(info => {
        if (info.cc_student_id) {
            selectedStudentsForGroupNote.map(selected => {
                return (
                    (info.cc_student_id === selected) ?
                        selctedStudentsInfo.push(info) : "")
            })
        }
        return selctedStudentsInfo
    })

    // --------------------Custom Button ------------------
    const PinkButton = styled(Button)({
        color: "#BD47E8",
        backgroundColor: "#BD47E81A",
        borderColor: "#BD47E8",
        borderRadius: "8px",
        "&:hover": {
            backgroundColor: "#BD47E81A",
            borderColor: "#BD47E8",
        },
        "&:disabled": {
            backgroundColor: "#FFFFFF",
            borderColor: "#00000066",
            color: "#00000066",
        },
        textTransform: 'capitalize!important',
    })

    const YellowButton = styled(Button)({
        color: "#FFA700",
        backgroundColor: "#FFA7001A",
        borderColor: "#FFA700",
        borderRadius: "8px",
        marginLeft: "10px",
        "&:hover": {
            backgroundColor: "#FFA7001A",
            borderColor: "#FFA700",
        },
        "&:disabled": {
            backgroundColor: "#FFFFFF",
            borderColor: "#00000066",
            color: "#00000066",
            startIcon: <img src={starYellow} alt="" width="14" height="14" />
        }
    })

    const GreyButton = styled(Button)({
        color: "#00000066",
        backgroundColor: "#FFFFFF",
        borderColor: "#00000066",
        borderRadius: "8px",
        marginLeft: "10px",
        "&:hover": {
            backgroundColor: "#FFFFFF",
            borderColor: "#00000066",
        },
        "&:disabled": {
            backgroundColor: "#FFFFFF",
            borderColor: "#00000066",
            color: "#00000066",
            startIcon: <img src={starYellow} alt="" width="14" height="14" />
        },
        textTransform: 'capitalize',
    })
    // --------------------Custom Button ------------------

    // const setMediaArtiAddEdit = (item, type) => {

    //     if (type === "image" || type === "video") {
    //         if (!item.cc_media_mark_artified || item?.cc_media_mark_artified === "no") {
    //             let mediaValue = pictureVideoList.map((val, i) => {
    //                 if (val?.cc_attachment_url === item?.cc_attachment_url) {
    //                     return { ...val, cc_media_mark_artified: "yes" }
    //                 } else {
    //                     return { ...val }
    //                 }

    //             })

    //             setPictureVideoList(mediaValue)
    //             // this.setState({
    //             //     pictureVideoList: mediaValue,
    //             // }, () => {
    //             //     this.forceUpdate()
    //             // })
    //         } else {
    //             console.log('else')
    //             let mediaValue = pictureVideoList.map((val, i) => {
    //                 if (val?.cc_attachment_url === item?.cc_attachment_url) {
    //                     return { ...val, cc_media_mark_artified: "no" }
    //                 } else {
    //                     return { ...val }
    //                 }
    //             })
    //             setPictureVideoList(mediaValue)
    //             // this.setState({
    //             //     pictureVideoList: mediaValue,
    //             // })
    //         }
    //     } else if (type === "doc") {
    //         if (!item.cc_media_mark_artified || item?.cc_media_mark_artified === "no") {
    //             let mediaValue = docList.map((val, i) => {
    //                 if (val?.cc_attachment_url === item?.cc_attachment_url) {
    //                     return { ...val, cc_media_mark_artified: "yes" }
    //                 } else {
    //                     return { ...val }
    //                 }
    //             })
    //             setDocList(mediaValue)
    //             // this.setState({
    //             //     docList: mediaValue,
    //             //     // modalDocSelect: mediaValue  
    //             // }, () => {
    //             //     this.forceUpdate()
    //             // })
    //         } else {
    //             let mediaValue = docList.map((val, i) => {
    //                 if (val?.cc_attachment_url === item?.cc_attachment_url) {
    //                     return { ...val, cc_media_mark_artified: "no" }
    //                 } else {
    //                     return { ...val }
    //                 }
    //             })
    //             setDocList(mediaValue)
    //             // this.setState({
    //             //     docList: mediaValue,
    //             //     // modalDocSelect: mediaValue
    //             // }, 
    //             // () => {
    //             //     this.forceUpdate()
    //             // }
    //             // )

    //         }
    //     }
    // }
    const setMediaArtiAddEdit = (item, type) => {

        if (type === "image" || type === "video") {
            const updatedList = pictureVideoList.map(val => 
                val?.cc_attachment_url === item?.cc_attachment_url
                    ? { ...val, cc_media_mark_artified: val?.cc_media_mark_artified === "yes" ? "no" : "yes" }
                    : { ...val }
            );
            setPictureVideoList(updatedList);
        } else if (type === "doc") {
            const updatedList = docList.map(val => 
                val?.cc_attachment_url === item?.cc_attachment_url
                    ? { ...val, cc_media_mark_artified: val?.cc_media_mark_artified === "yes" ? "no" : "yes" }
                    : { ...val }
            );
            setDocList(updatedList);
        } else if (type === "audio") {
            const updatedList = audioList.map(val => 
                val?.cc_attachment_url === item?.cc_attachment_url
                    ? { ...val, cc_media_mark_artified: val?.cc_media_mark_artified === "yes" ? "no" : "yes" }
                    : { ...val }
            );
            setAudioList(updatedList);
        }
    };
    
 const handleGenerateAudioAiModalClose = () => {
    setShowGenerateAudioAIModal(false)
    setTranscribeAudioModal(false)
    setShowSummarizeModal(false)
    setShowSelectAudioModal(false)
    setEditAudioTranscribeAndSummarize(false)
    setShowPopover(false)
    setShowTranscribePopover(false)
 }   

 //set radio value
const standardValueHandler = (event) => {
    setAddStandardValue(event.target.value)
};

const getAudioTimeHandler = (value) => {
        
}

const proceedToAudioHandler = () => {
    // this.setState({
    //     CommentData : JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove'))
    //  })
    // setCommentData()
    // console.clear()
    let tempSelectedAudioObj = audioList.filter((item) => item?.cc_attachment_url == addStandardValue)
    
    const audio = new Audio(tempSelectedAudioObj[0].cc_attachment_fullurl)
    

    audio.addEventListener('loadedmetadata', () => {
        setSelectAudioTotalDuration(formatAudioDuration(audio.duration))
        // this.setState({
        //     selectAudioTotalDuration: formatAudioDuration(audio.duration)
        // })
    });
    setSelectAudioObj(tempSelectedAudioObj[0])
    // this.setState({
    //     selectAudioObj: tempSelectedAudioObj[0]
    // })
    let obj = {
        audio_url: `${firebaseBucket}/${tempSelectedAudioObj?.[0]?.cc_attachment_url}`,
        class_id: sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId').toString() : classDetails.details.cc_class_id.toString()
    }
    dispatch(getAudioFromVertex(obj))
}


const transcribedAudio = useSelector((state) => state?.studentNote?.getTranscribeAudioSuccess)

useEffect(() => {
    if(transcribedAudio?.status != 200) {
        handleGenerateAudioAiModalClose()
    }
    if(transcribedAudio?.status == 200) {
        let dataArray = props?.location?.state?.pageType == 'Edit' ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : [...note_tag_comments]
        // Find the index of the object with cc_tag_title equal to text
        const positiveCommentsIndex = dataArray.findIndex(
            (obj) => obj.cc_tag_title === 'Audio Transcription'
        );

    //     if(positiveCommentsIndex !== -1 && audioTranscribe?.length > 0) {
    //         dataArray[positiveCommentsIndex].tag_comment_text = sessionStorage.getItem('finalTranscribedAudio') ? sessionStorage.getItem('finalTranscribedAudio') : audioTranscribe
    //     } else {
    //     // Check if the object exists and tag_comment_text is empt y
    //     if (positiveCommentsIndex !== -1 && (dataArray[positiveCommentsIndex]?.tag_comment_text?.trim()?.length == 0 || dataArray[positiveCommentsIndex]?.tag_comment_text?.trim()?.length > 0)) {
    //         // Update tag_comment_text
    //         dataArray[positiveCommentsIndex].tag_comment_text = transcribedAudio?.data?.note_description
    //     }
    // }
      
        

         // ------Add Power points------
         let oldText_quality_note = text_quality_note;
         let newText_quality_note = 0
         for (let item of dataArray) {
             if (item.tag_comment_text) {
                 if (newText_quality_note < quality_note.max_allotted_text) {
                     newText_quality_note = Number(newText_quality_note) + Number(quality_note.text_allotted_points)
                 }
             }
 
         }
         let quality_points = qualityPoints
         if (oldText_quality_note > newText_quality_note) {
             let value = Number(oldText_quality_note) - Number(newText_quality_note);
             quality_points = Number(quality_points) - Number(value);
 
         } else {
             let value = Number(newText_quality_note) - Number(oldText_quality_note)
             quality_points = Number(quality_points) + Number(value);
 
         }
         // ------Add Power points------
        //  this.setState({
        //     showGenerateAudioAIModal: false,
        //     transcribeAudioModal: true,
        //     audioTranscribe: nextProps?.notesData?.getTranscribeAudioSuccess?.data?.note_description,
        //     note_tag_comments: dataArray,
        //     text_quality_note: newText_quality_note,
        //     quality_points: quality_points,
        // })
        if(props?.location?.state?.pageType == 'Edit') {
        if(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')?.length > 0) {
            sessionStorage.setItem(
                "finalNoteCommentCardsAfterRemove",
                JSON.stringify(dataArray)
              );
        }
        }
        console.log('transcribedAudio 998866 :>> ', transcribedAudio);
        setShowGenerateAudioAIModal(false)
        setAudioTranscribe(transcribedAudio?.data?.note_description)
        setTranscribeAudioModal(note_tag_comments.length > 0 ? true : false)
        setNote_tag_comments(dataArray)
        setText_quality_note(newText_quality_note)
        setQuality_points(quality_points)
    }
},[transcribedAudio])

const summaryOfTranscribedAudio = useSelector((state) => state?.studentNote?.getAudioSummaryVertextSuccess)
const xyz = useSelector((state) => state?.studentNote)
console.log('xyzxyz :>> ', xyz);
useEffect(() => {
if (xyz?.isLoadingGetsummaryAudio || xyz?.isLoadingGetTranscribeAudio) {
    setShowGenerateAudioAIModal(true)
    setTranscribeAudioModal(false)
    setShowSummarizeModal(false)
}

}, [xyz])

useEffect(() => {
    if(summaryOfTranscribedAudio?.status == 200) {
        console.log('^&%^%^%^$^% summ');
             // Combine headings and passages into a single string
            //  const combinedText = summaryOfTranscribedAudio?.data?.text_summerized?.map(item => `${item.heading}\n\n${item.passage}`).join('\n\n');
            const content = summaryOfTranscribedAudio?.data?.text_summerized?.content;
            console.log("content", content);
            const title = summaryOfTranscribedAudio?.data?.text_summerized?.title;
            setAudioTranscribe(title)
            let dataArray2 = [...note_tag_comments];
            const AudioTranscriptionIndex = dataArray2.findIndex(
                (obj) => obj.cc_tag_title === 'Audio Transcription'
            );
            if (AudioTranscriptionIndex !== -1 && dataArray2[0].cc_tag_title === 'Audio Transcription') {
             console.log('object is getting here2222 :>> ', dataArray2[0].tag_comment_text);
                dataArray2[0].tag_comment_text = audioTranscribe;
                setNote_tag_comments(dataArray2)
            }
            else {
                    // Check if the object exists and tag_comment_text is empt y
                    if (AudioTranscriptionIndex !== -1 && (dataArray2[AudioTranscriptionIndex]?.tag_comment_text?.trim()?.length == 0 || dataArray2[AudioTranscriptionIndex]?.tag_comment_text?.trim()?.length > 0)) {
                        // Update tag_comment_text
                        dataArray2[AudioTranscriptionIndex].tag_comment_text = transcribedAudio?.data?.note_description
                    }
                }
            console.log('dataArray2 :>> ', dataArray2);
            const points = content.match(/\d+\.\s*[^.]+/g) || [content];
            
            
            const formattedPoints = points.map((point, index) => {
                let number = '';
                let text = point.trim();
              
                if (/\d+\.\s*/.test(point)) {
                  const [numberPart, ...textParts] = point.trim().split(/\.(.+)/);
                  number = parseInt(numberPart.trim());
                  text = textParts.join('.').trim();
                }
              
                /*const maxLineLength = 52; 
                const formattedText = text.split(' ').reduce((lines, word) => {
                  if (lines.length === 0) return [word];
                  
                  const lastLine = lines[lines.length - 1];
                  if ((lastLine + ' ' + word).length <= maxLineLength) {
                    lines[lines.length - 1] = lastLine + ' ' + word;
                  } else {
                    lines.push(word);
                  }
                  return lines;
                }, []).join('\n');*/
              
                return `${number ? `${number}. ` : ''}${text}`;
              }).join('\n\n');
            
            console.log("Formatted Points:\n", formattedPoints);
            
            const combinedText = `${formattedPoints}`;

            const formattedPoints2 = points.map((point, index) => {
                let number = '';
                let text = point.trim();
              
                if (/\d+\.\s*/.test(point)) {
                  const [numberPart, ...textParts] = point.trim().split(/\.(.+)/);
                  number = parseInt(numberPart.trim());
                  text = textParts.join('.').trim();
                }
              
                /*const maxLineLength = 52; 
                const formattedText = text.split(' ').reduce((lines, word) => {
                  if (lines.length === 0) return [word];
                  
                  const lastLine = lines[lines.length - 1];
                  if ((lastLine + ' ' + word).length <= maxLineLength) {
                    lines[lines.length - 1] = lastLine + ' ' + word;
                  } else {
                    lines.push(word);
                  }
                  return lines;
                }, []).join('\n');*/
              
                return `${number ? `${number}. ` : ''}${text}`;
              }).join('\n\n');


                        console.log("Formatted Points:\n", formattedPoints);
                        
                        const combinedText2 = `${formattedPoints2}`;
             let dataArray = props?.location?.state?.pageType == 'Edit' ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : [...note_tag_comments]
       const positiveCommentsIndex = dataArray.findIndex(
           (obj) => obj.cc_tag_title === 'Audio Summarization'
       );
       setAudioSummarization(combinedText)
       // const transcribeCommentsIndex = dataArray.findIndex(
       //     (obj) => obj.cc_tag_title === 'Audio Transcription'
       // );

       // Check if the object exists and tag_comment_text is empty
    //    console.log('positiveCommentsIndex :>> ', positiveCommentsIndex , dataArray[positiveCommentsIndex].tag_comment_text);
       if (positiveCommentsIndex !== -1 ) {
        console.log('object is getting here :>> ',);
           // Update tag_comment_text
           dataArray[positiveCommentsIndex].tag_comment_text = combinedText2;
       }

        // Check if the object exists and tag_comment_text is empty
       //  if (transcribeCommentsIndex !== -1 && this.state.audioTranscribe.length > 0) {
       //     // Update tag_comment_text
       //     dataArray[positiveCommentsIndex].tag_comment_text = this.state.audioTranscribe;
       // }
     
       

        // ------Add Power points------
        let oldText_quality_note = text_quality_note;
        let newText_quality_note = 0
        for (let item of dataArray) {
            if (item.tag_comment_text) {
                if (newText_quality_note < quality_note.max_allotted_text) {
                    newText_quality_note = Number(newText_quality_note) + Number(quality_note.text_allotted_points)
                }
            }

        }
        let quality_points = qualityPoints
        if (oldText_quality_note > newText_quality_note) {
            let value = Number(oldText_quality_note) - Number(newText_quality_note);
            quality_points = Number(quality_points) - Number(value);

        } else {
            let value = Number(newText_quality_note) - Number(oldText_quality_note)
            quality_points = Number(quality_points) + Number(value);

        }
        // ------Add Power points------
        if(props?.location?.state?.pageType == 'Edit') {
        if(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')?.length > 0) {
            sessionStorage.setItem(
                "finalNoteCommentCardsAfterRemove",
                JSON.stringify(dataArray)
              );
        }
        }
        setShowGenerateAudioAIModal(false)
        setTranscribeAudioModal(false)
        setShowSummarizeModal(note_tag_comments.length > 0)
        setAudioSummarization(combinedText)
        setAudioSummarizationforsmall(combinedText2)
        setCombinedSummaryText(combinedText2)
        setNote_tag_comments(dataArray || note_tag_comments)
        setText_quality_note(newText_quality_note)
        setQuality_points(quality_points)
        //    this.setState({
        //        showGenerateAudioAIModal: false,
        //        transcribeAudioModal: false,
        //        showSummarizeModal: true,
        //        audioSummarization: combinedText,
        //        combinedSummaryText: combinedText,
        //        note_tag_comments: dataArray,
        //        text_quality_note: newText_quality_note,
        //        quality_points: quality_points,
        //    })
    }
    // if(transcribedAudio?.status == 200) {
    //     setShowGenerateAudioAIModal(false)
    //     setAudioTranscribe(transcribedAudio?.data?.note_description)
    //     setTranscribeAudioModal(true)
    // }
},[summaryOfTranscribedAudio])
const [tags_for_edit, settags_for_edit] = useState([])
    console.log('tags_for_edit , note_tag_comments :>> ', tags_for_edit , note_tag_comments);
const transcribeSummarizeHandler = () => {
    let dataArray = note_tag_comments;
    console.log('tags_for_edit :>> ', tags_for_edit);
    console.log('CommentData transcribehandler :>> ', note_tag_comments);
    console.log('audioTranscribe :>> ', audioTranscribe);
    
    // Find the index where cc_tag_title is 'Audio Transcription'
    const AudioTranscriptionIndex = dataArray.findIndex(
        (obj) => obj.cc_tag_title === 'Audio Transcription'
    );
    const AudioSummIndex = dataArray.findIndex(
        (obj) => obj.cc_tag_title === 'Audio Summarization'
    );
    
    console.log('AudioTranscriptionIndex :>> ', AudioTranscriptionIndex , dataArray[AudioTranscriptionIndex]?.tag_comment_text);
    
    // Check if 'Audio Transcription' is found
    if (AudioTranscriptionIndex !== -1) {
        console.log('Audio Transcription found, replacing tag_comment_text :>> ', dataArray[AudioTranscriptionIndex].tag_comment_text);

        dataArray[AudioTranscriptionIndex].tag_comment_text = audioTranscribe;
        setNote_tag_comments(dataArray);
    }
    if (AudioSummIndex !== -1) {
        console.log('Audio Transcription found, replacing tag_comment_text :>> ', dataArray[AudioSummIndex].tag_comment_text);

        dataArray[AudioSummIndex].tag_comment_text = audioSummarization;

        setNote_tag_comments(dataArray);
    }
    console.log('audio transcribe:>> ', audioTranscribe , audioSummarization );
    const transcriptionTag = tags_for_edit.find(tag => tag.cc_tag_title === "Audio Transcription");
    const summarizationTag = tags_for_edit.find(tag => tag.cc_tag_title === "Audio Summarization");
    const transcriptionTagId = transcriptionTag?.cc_tag_id || Math.max(...dataArray.map(comment => comment.tag_id)) + 1;
    const summarizationTagId = summarizationTag?.cc_tag_id || Math.max(...dataArray.map(comment => comment.tag_id)) + 1;

    if (pageType === 'Edit') {
        const hasAudioTranscription = dataArray.some(comment => comment.cc_tag_title === "Audio Transcription");
        const hasAudioSummarization = dataArray.some(comment => comment.cc_tag_title === "Audio Summarization");

        if (!hasAudioTranscription) {
            let audioTranscriptionObj = {
                tag_comment_id: Math.max(...dataArray.map(comment => comment.tag_comment_id)) + 1,
                tag_comment_text: audioTranscribe || "",
                tag_id: transcriptionTagId, // Use tag_id from tags_for_edit
                tag_comment_add_on: new Date().toISOString(),
                tag_comment_note_id: note_tag_comments[0].tag_comment_note_id,
                tag_comment_student_id: note_tag_comments[0].tag_comment_student_id,
                cc_tag_title: "Audio Transcription",
                cc_tag_abbre: "A T"
            };
            dataArray.unshift(audioTranscriptionObj);
        }

        if (!hasAudioSummarization) {
            let audioSummarizationObj = {
                tag_comment_id: Math.max(...dataArray.map(comment => comment.tag_comment_id)) + 1,
                tag_comment_text: audioSummarization || '',
                tag_id: summarizationTagId, // Use tag_id from tags_for_edit
                tag_comment_add_on: new Date().toISOString(),
                tag_comment_note_id: note_tag_comments[0].tag_comment_note_id,
                tag_comment_student_id: note_tag_comments[0].tag_comment_student_id,
                cc_tag_title: "Audio Summarization",
                cc_tag_abbre: "A S"
            };
            
            // Corrected insertion logic:
            // Insert AS at index 1 if AT exists, else at index 0
            const insertIndex = dataArray.findIndex(comment => comment.cc_tag_title === "Audio Transcription") !== -1 ? 1 : 0;
            dataArray.splice(insertIndex, 0, audioSummarizationObj);
        }

        console.log('Updated dataArray :>> ', dataArray);

        setNote_tag_comments(dataArray)
        sessionStorage.setItem("finalNoteCommentCardsAfterRemove", JSON.stringify(dataArray))
    }
 else {
        console.log("dataArray foradd" ,dataArray , dataArray[0].cc_tag_id , dataArray[1].cc_tag_id)
    const transcriptionTagforadd = dataArray.find(tag => tag.cc_tag_title === "Audio Transcription");
    console.log('transcriptionTag foradd :>> ', transcriptionTagforadd);
    
    const summarizationTagforadd = dataArray.find(tag => tag.cc_tag_title === "Audio Summarization");
    console.log('summarizationTag foradd :>> ', summarizationTagforadd);
    
    const transcriptionTagIdforadd = transcriptionTagforadd?.cc_tag_id || Math.max(...dataArray.map(comment => comment.cc_tag_id)) + 1;
    const summarizationTagIdforadd = summarizationTagforadd?.cc_tag_id || Math.max(...dataArray.map(comment => comment.cc_tag_id)) + 1;
    setTransId(transcriptionTagIdforadd)
    setSummid(summarizationTagIdforadd)
        // console.log(dataArray , "nnnxxx")
        const hasAudioTranscription = dataArray.some(comment => comment.cc_tag_title === "Audio Transcription");
        const hasAudioSummarization = dataArray.some(comment => comment.cc_tag_title === "Audio Summarization");

        if (!hasAudioTranscription) {
            console.log('dont have AT foradd');
            let audioTranscriptionObj = {
                cc_tag_abbre:"A T",
                cc_tag_add_on:new Date().toISOString(),
                cc_tag_checked:"no",
                cc_tag_class_id:sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId').toString() : classDetails.details.cc_class_id.toString(),
                cc_tag_id:TransID || dataArray[0].cc_tag_id,
                cc_tag_modifyed_on:null,
                cc_tag_title:"Audio Transcription",
                tag_comment_text:audioTranscribe || ""
            };
            console.log('audioSummarizationObj , audioSummarizationObj foradd :>> ' , audioTranscriptionObj);
            dataArray.unshift(audioTranscriptionObj);
            setNote_tag_comments(dataArray)
            sessionStorage.setItem("finalNoteCommentCardsAfterRemove", JSON.stringify(dataArray))
        }

        if (!hasAudioSummarization) {
            console.log('dont have AS foradd');
            let audioSummarizationObj = {
                cc_tag_abbre: "A S",
                cc_tag_add_on: new Date().toISOString(),
                cc_tag_checked: "no",
                cc_tag_class_id: sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId').toString() : classDetails.details.cc_class_id.toString(),
                cc_tag_id: SummID || dataArray[1].cc_tag_id,
                cc_tag_modifyed_on: null,
                cc_tag_title: "Audio Summarization",
                tag_comment_text:audioSummarization || ""
            };
            console.log('audioSummarizationObj , audioSummarizationObj foradd :>> ' , audioSummarizationObj);
            

            const insertIndex = dataArray.findIndex(comment => comment.cc_tag_title === "Audio Transcription") !== -1 ? 1 : 0;
            dataArray.splice(insertIndex, 0, audioSummarizationObj);
            setNote_tag_comments(dataArray)
            sessionStorage.setItem("finalNoteCommentCardsAfterRemove", JSON.stringify(dataArray))
           
        }
        console.log('Updated dataArray foradd :>> ', dataArray);

       
    }
    sessionStorage.setItem('finalTranscribedAudio', audioTranscribe);
    let obj = {
        text_to_be_summerized: audioTranscribe
    };
    dispatch(getAudioSummaryFromVertex(obj));

    setTranscribeAudioModal(false);
    setShowGenerateAudioAIModal(true);
    setshowDivs(true);
    setshowDivsEdit(audioList?.length > 0 && pageType === 'Edit');
    // let dataArray = note_tag_comments;

    // // Check if "Audio Transcription" (A T) exists
    // const audioTranscriptionExists = dataArray.some(
    //     (obj) => obj.cc_tag_title === "Audio Transcription"
    // );

    // // Check if "Audio Summarization" (A S) exists
    // const audioSummarizationExists = dataArray.some(
    //     (obj) => obj.cc_tag_title === "Audio Summarization"
    // );

    // // If "Audio Transcription" doesn't exist, add it
    // if (!audioTranscriptionExists) {
    //     dataArray.push({
    //         tag_comment_id: null, // New item, so it can be null or any unique value
    //         tag_comment_text: audioTranscribe || "", // Use the transcribed audio if available
    //         tag_id: null, // Assuming null for now; you might want to generate unique IDs here
    //         tag_comment_add_on: new Date().toISOString(), // Add current timestamp
    //         tag_comment_note_id: null, // Assuming null for now
    //         tag_comment_student_id: null, // Assuming null for now
    //         cc_tag_title: "Audio Transcription",
    //         cc_tag_abbre: "A T",
    //     });
    // }

    // // If "Audio Summarization" doesn't exist, add it
    // if (!audioSummarizationExists) {
    //     dataArray.push({
    //         tag_comment_id: null, // New item, so it can be null or any unique value
    //         tag_comment_text: "", // Initially empty, will be updated later with the summarization
    //         tag_id: null, // Assuming null for now
    //         tag_comment_add_on: new Date().toISOString(), // Add current timestamp
    //         tag_comment_note_id: null, // Assuming null for now
    //         tag_comment_student_id: null, // Assuming null for now
    //         cc_tag_title: "Audio Summarization",
    //         cc_tag_abbre: "A S",
    //     });
    // }

    // // Store the transcribed audio in sessionStorage
    // sessionStorage.setItem('finalTranscribedAudio', audioTranscribe);

    // // Dispatch action to get audio summary from Vertex AI or some external service
    // const obj = {
    //     text_to_be_summerized: audioTranscribe,
    // };
    // dispatch(getAudioSummaryFromVertex(obj));

    // // Update state and modal visibility
    // setTranscribeAudioModal(false);
    // setShowGenerateAudioAIModal(true);
    // setshowDivs(true);
    // setshowDivsEdit(audioList?.length > 0 && pageType === 'Edit');

    // // Update the state with the modified dataArray
    // setNote_tag_comments(dataArray);
};


// const transcribeSummarizeHandler = () => {
//     let dataArray = note_tag_comments;
//     console.log('tags_for_edit , note_tag_comments :>> ', tags_for_edit , note_tag_comments);
//     console.log('CommentData transcribehandler :>> ', note_tag_comments);
//     console.log('audioTranscribe :>> ', audioTranscribe);

//     const transcriptionTagforadd = dataArray.find(tag => tag.cc_tag_title === "Audio Transcription");
//     console.log('transcriptionTag foradd :>> ', transcriptionTagforadd);
    
//     const summarizationTagforadd = dataArray.find(tag => tag.cc_tag_title === "Audio Summarization");
//     console.log('summarizationTag foradd :>> ', summarizationTagforadd);
    
//     const transcriptionTagIdforadd = transcriptionTagforadd?.cc_tag_id || Math.max(...dataArray.map(comment => comment.cc_tag_id)) + 1;
//     const summarizationTagIdforadd = summarizationTagforadd?.cc_tag_id || Math.max(...dataArray.map(comment => comment.cc_tag_id)) + 1;
//     setTransId(transcriptionTagIdforadd)
//     setSummid(summarizationTagIdforadd)
    
//     // Find the index where cc_tag_title is 'Audio Transcription'
//     const AudioTranscriptionIndex = dataArray.findIndex(
//         (obj) => obj.cc_tag_title === 'Audio Transcription'
//     );
//     const AudioSummIndex = dataArray.findIndex(
//         (obj) => obj.cc_tag_title === 'Audio Summarization'
//     );
    
//     console.log('AudioTranscriptionIndex :>> ', AudioTranscriptionIndex , dataArray[AudioTranscriptionIndex]?.tag_comment_text);
//     sessionStorage.setItem(
//         "finalNoteCommentCardsAfterRemove",
//         JSON.stringify(dataArray)
//       );
//     // Check if 'Audio Transcription' is found
//     if (AudioTranscriptionIndex !== -1) {
//         console.log('5556667777', dataArray);
//         console.log('Audio Transcription found, replacing tag_comment_text :>> ', dataArray[AudioTranscriptionIndex].tag_comment_text);

//         dataArray[AudioTranscriptionIndex].tag_comment_text = audioTranscribe;
//         setNote_tag_comments(dataArray);
//     }
//     if (AudioSummIndex !== -1) {
//         console.log('5556667777', dataArray);
//         console.log('Audio Transcription found, replacing tag_comment_text :>> ', dataArray[AudioSummIndex].tag_comment_text);

//         dataArray[AudioSummIndex].tag_comment_text = audioSummarization;

//         setNote_tag_comments(dataArray);
//     }
//     console.log('audio transcribe:>> ', audioTranscribe , audioSummarization );
//     const transcriptionTag = tags_for_edit.find(tag => tag.cc_tag_title === "Audio Transcription");
//     const summarizationTag = tags_for_edit.find(tag => tag.cc_tag_title === "Audio Summarization");
//     const transcriptionTagId = transcriptionTag?.cc_tag_id || Math.max(...dataArray.map(comment => comment.tag_id)) + 1;
//     const summarizationTagId = summarizationTag?.cc_tag_id || Math.max(...dataArray.map(comment => comment.tag_id)) + 1;

//     if (pageType === 'Edit') {
//         console.log(dataArray , "nnnxxx")
//         const hasAudioTranscription = dataArray.some(comment => comment.cc_tag_title === "Audio Transcription");
//         const hasAudioSummarization = dataArray.some(comment => comment.cc_tag_title === "Audio Summarization");

//         if (!hasAudioTranscription) {
//             let audioTranscriptionObj = {
//                 tag_comment_id: Math.max(...dataArray.map(comment => comment.tag_comment_id)) + 1,
//                 tag_comment_text: audioTranscribe || "",
//                 tag_id: transcriptionTagId, // Use tag_id from tags_for_edit
//                 tag_comment_add_on: new Date().toISOString(),
//                 tag_comment_note_id: note_tag_comments[0].tag_comment_note_id,
//                 tag_comment_student_id: note_tag_comments[0].tag_comment_student_id,
//                 cc_tag_title: "Audio Transcription",
//                 cc_tag_abbre: "A T"
//             };
//             dataArray.unshift(audioTranscriptionObj);
//         }

//         if (!hasAudioSummarization) {
//             let audioSummarizationObj = {
//                 tag_comment_id: Math.max(...dataArray.map(comment => comment.tag_comment_id)) + 1,
//                 tag_comment_text: audioSummarization || '',
//                 tag_id: summarizationTagId, // Use tag_id from tags_for_edit
//                 tag_comment_add_on: new Date().toISOString(),
//                 tag_comment_note_id: note_tag_comments[0].tag_comment_note_id,
//                 tag_comment_student_id: note_tag_comments[0].tag_comment_student_id,
//                 cc_tag_title: "Audio Summarization",
//                 cc_tag_abbre: "A S"
//             };
            
//             // Corrected insertion logic:
//             // Insert AS at index 1 if AT exists, else at index 0
//             const insertIndex = dataArray.findIndex(comment => comment.cc_tag_title === "Audio Transcription") !== -1 ? 1 : 0;
//             dataArray.splice(insertIndex, 0, audioSummarizationObj);
//         }

//         console.log('Updated dataArray :>> ', dataArray);

//         setNote_tag_comments(dataArray)
//         sessionStorage.setItem("finalNoteCommentCardsAfterRemove", JSON.stringify(dataArray))
//     }
//     // else {
//     //     console.log("dataArray foradd" ,dataArray , TransID , SummID)

//     //     // console.log(dataArray , "nnnxxx")
//     //     const hasAudioTranscription = dataArray.some(comment => comment.cc_tag_title === "Audio Transcription");
//     //     const hasAudioSummarization = dataArray.some(comment => comment.cc_tag_title === "Audio Summarization");

//     //     if (!hasAudioTranscription) {
//     //         console.log('dont have AT foradd');
//     //         let audioTranscriptionObj = {
//     //             cc_tag_abbre:"A T",
//     //             cc_tag_add_on:new Date().toISOString(),
//     //             cc_tag_checked:"no",
//     //             cc_tag_class_id:sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId').toString() : classDetails.details.cc_class_id.toString(),
//     //             cc_tag_id:TransID,
//     //             cc_tag_modifyed_on:null,
//     //             cc_tag_title:"Audio Transcription",
//     //             tag_comment_text:audioTranscribe || ""
//     //         };
//     //         dataArray.unshift(audioTranscriptionObj);
//     //     }

//     //     if (!hasAudioSummarization) {
//     //         console.log('dont have AS foradd');
//     //         let audioSummarizationObj = {
//     //             cc_tag_abbre: "A S",
//     //             cc_tag_add_on: new Date().toISOString(),
//     //             cc_tag_checked: "no",
//     //             cc_tag_class_id: sessionStorage.getItem('notesclassId') > 0 ? sessionStorage.getItem('notesclassId').toString() : classDetails.details.cc_class_id.toString(),
//     //             cc_tag_id: SummID,
//     //             cc_tag_modifyed_on: null,
//     //             cc_tag_title: "Audio Summarization",
//     //             tag_comment_text:audioSummarization || ""
//     //         };
//     //         console.log('audioSummarizationObj , audioSummarizationObj foradd :>> ', audioSummarizationObj , audioSummarizationObj);
            

//     //         const insertIndex = dataArray.findIndex(comment => comment.cc_tag_title === "Audio Transcription") !== -1 ? 1 : 0;
//     //         dataArray.splice(insertIndex, 0, audioSummarizationObj);
           
//     //     }
//     //     console.log('Updated dataArray foradd :>> ', dataArray);

//     //     setNote_tag_comments(dataArray)
//     //     sessionStorage.setItem("finalNoteCommentCardsAfterRemove", JSON.stringify(dataArray))
//     // }
//     sessionStorage.setItem('finalTranscribedAudio', audioTranscribe);
//     let obj = { 
//         text_to_be_summerized: audioTranscribe
//     };
//     dispatch(getAudioSummaryFromVertex(obj));
//     // setAudioSummarization(audioSummarization)
//     setTranscribeAudioModal(false);
//     setShowGenerateAudioAIModal(true);
//     setshowDivs(true);
//     setshowDivsEdit(audioList?.length > 0 && pageType === 'Edit');
    
// };

const findAndChangeTagContent = (value) => {
        
    let dataArray = [...note_tag_comments]
     // Find the index of the object with cc_tag_title equal to text
     const positiveCommentsIndex = dataArray.findIndex(
        (obj) => obj.cc_tag_title === value
    );
        
    // Check if the object exists and tag_comment_text is empty
    if (positiveCommentsIndex !== -1) {
        // Update tag_comment_text
        let textValue = ''
        if(value == "Audio Transcription") {
            textValue = audioTranscribe
        } else {
            textValue = audioSummarization
        }
        

        dataArray[positiveCommentsIndex].tag_comment_text = textValue
    }
    setNote_tag_comments(dataArray)
}

const logH5Content = (event) => {
    const selectedText = window.getSelection().toString();
    setSelectedText(selectedText)
    const divId = event.currentTarget.id;
    const h5Content = document.getElementById(divId)?.querySelector('h5')?.textContent;
    
    if (selectedText) {
        
        if (divId == 'div3' || divId == 'div1') {
            handleSelection()
        } else {
            if (h5Content == 'Audio Summarization') {
                handleTranscribeSelection()
            }
        }
    }
    if (selectedText?.length == 0) {
        setShowTranscribePopover(false)
        setShowPopover(false)
    }
};

function handleSelection() {
    const selection = window.getSelection();
    if (selection && !selection.isCollapsed) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        const relativeParent = document.body.parentNode.getBoundingClientRect()
        setSelectedText(selection.toString())
        setShowPopover(true)
        setShowTranscribePopover(false)
        setPopoverPosition({ top: (rect.bottom + window.scrollY - 400),
            left: ((relativeParent.right - rect.right) / 3) + 'px'})
        // this.setState({
        //     selectedText: selection.toString(),
        //     showPopover: true,
        //     showTranscribePopover: false,
        //     popoverPosition: {
        //         //   top: (rect.bottom / 3) + 'px',
        //         //   left: (rect.width / 2) + 'px',
        //         // top: (rect.bottom - rect.top) + 'px',
        //         top: (rect.bottom + window.scrollY - 400),
        //         left: ((relativeParent.right - rect.right) / 3) + 'px'
        //         // left: rect.left + window.scrollX + (rect.width / 3),
        //     },
        // });
    } else {
        setSelectedText(null)
        setShowPopover(false)
    }
}

function handleTranscribeSelection() {
    const selection = window.getSelection();
    if (selection && !selection.isCollapsed) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        const relativeParent = document.body.parentNode.getBoundingClientRect()
        setSelectedText(selection.toString())
        setShowTranscribePopover(true)
        setShowPopover(false)
        setPopoverTranscribePosition({
            top: (rect.bottom + window.scrollY - 435),
            left: ((relativeParent.right - rect.right) / 3) + 'px'
        })
        // this.setState({
        //     selectedText: selection.toString(),
        //     showTranscribePopover: true,
        //     showPopover: false,
        //     popoverTranscribePosition: {
        //         //   top: (rect.bottom / 3) + 'px',
        //         //   left: (rect.width / 2) + 'px',
        //         // top: (rect.bottom - rect.top) + 'px',
        //         top: (rect.bottom + window.scrollY - 435),
        //         left: ((relativeParent.right - rect.right) / 3) + 'px'
        //         // left: rect.left + window.scrollX + (rect.width / 3),
        //     },
        // });
    } else {
        setSelectedText(null)
        setShowTranscribePopover(false)
        setShowPopover(false)
        // this.setState({
        //     selectedText: null,
        //     showTranscribePopover: false,
        //     showPopover: false
        // });
    }
}
// const selectAllTexttransc = () => {
//     const preElement = document.querySelector("#newdiv"); // Target the <pre> element inside the div
    
//     if (preElement && window.getSelection && document.createRange) {
//         const range = document.createRange();
//         range.selectNodeContents(preElement);
//         const selection = window.getSelection();
//         selection.removeAllRanges();
//         selection.addRange(range);
        
//         // Check if the entire text is selected
//         const selectedText = selection.toString().trim();
//         const fullText = preElement.innerText.trim();

//         // Show popover only if the entire text is selected
//         // setTimeout(() => {
//             if (selectedText === fullText) {
//                 setShowPopover(true)
//                 // setTimeout(() => {
//                     // this.setState({ showPopover: true });
//                 // }, 500);
//             } else {
//                 setShowPopover(false)
//             }
//         // }, 500);
//     }
// }

// const selectAllText = () => {
//     const preElement = document.querySelector("#div2 pre"); // Target the <pre> element inside the div
    
//     if (preElement && window.getSelection && document.createRange) {
//         const range = document.createRange();
//         range.selectNodeContents(preElement);
//         const selection = window.getSelection();
//         selection.removeAllRanges();
//         selection.addRange(range);

//         // Introduce a slight delay to ensure the selection is fully processed
//         setTimeout(() => {
//             // Check if the entire text is selected
//             const selectedText = selection.toString().trim();
//             const fullText = preElement.innerText.trim();
    
//             if (selectedText === fullText) {
//                 this.setState({ showTranscribePopover: true });
//             } else {
//                 this.setState({ showTranscribePopover: false });
//             }
//         }, 100); // Delay of 100ms, adjust if necessary
//     }
// }




function handleHighlight(text) {
    let dataArray = []

    if (props?.location?.state?.pageType === "Edit") {
        dataArray = JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : note_tag_comments
        // Find the index of the object with cc_tag_title equal to text
        const positiveCommentsIndex = dataArray.findIndex(
          (obj) => obj.cc_tag_title === text
        );
  
        // Check if the object exists and tag_comment_text is empty
        if (
          positiveCommentsIndex !== -1 &&
          dataArray[positiveCommentsIndex].tag_comment_text === ""
        ) {
          // Update tag_comment_text
          dataArray[positiveCommentsIndex].tag_comment_text = selectedText;
        } else {
          // Create a new object with empty tag_comment_text right after the updated object
          const newObject = {
            cc_tag_abbre: dataArray[positiveCommentsIndex]?.cc_tag_abbre,
            cc_tag_title: text,
            tag_comment_add_on: new Date(),
            tag_comment_id: "",
            tag_comment_note_id: dataArray[positiveCommentsIndex]?.tag_comment_note_id,
            tag_comment_student_id: dataArray[positiveCommentsIndex]?.tag_comment_student_id,
            tag_comment_text: selectedText,
            tag_id: "",
          };
          // Insert the new object right after the updated object
          dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
        }
        sessionStorage.setItem(
          "finalNoteCommentCardsAfterRemove",
          JSON.stringify(dataArray)
        );
        setNote_tag_comments(dataArray)
      } else {
        // Find the index of the object with cc_tag_title equal to text
        dataArray = [...note_tag_comments]
        const positiveCommentsIndex = dataArray.findIndex(
          (obj) => obj.cc_tag_title === text
        );
  
        // Check if the object exists and tag_comment_text is empty
        if (
          positiveCommentsIndex !== -1 &&
          dataArray[positiveCommentsIndex].tag_comment_text === ""
        ) {
          // Update tag_comment_text
          dataArray[positiveCommentsIndex].tag_comment_text = selectedText;
        } else {
          // Create a new object with empty tag_comment_text right after the updated object
          const newObject = {
            cc_tag_abbre: dataArray[positiveCommentsIndex]?.cc_tag_abbre,
            cc_tag_add_on: new Date(),
            cc_tag_checked: "no",
            cc_tag_class_id: '',
            cc_tag_id: '',
            cc_tag_modifyed_on: new Date(),
            tag_comment_text: selectedText,
            cc_tag_title: dataArray[positiveCommentsIndex]?.cc_tag_title
          };
          // Insert the new object right after the updated object
          dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
        }
        sessionStorage.setItem(
          "finalNoteCommentCardsAfterRemove",
          JSON.stringify(dataArray)
        );
        setNote_tag_comments(dataArray)
      }

    // Find the index of the object with cc_tag_title equal to text
    // const positiveCommentsIndex = dataArray.findIndex(
    //     (obj) => obj.cc_tag_title === text
    // );

    // // Check if the object exists and tag_comment_text is empty
    // if (positiveCommentsIndex !== -1 && dataArray[positiveCommentsIndex].tag_comment_text === '') {
    //     // Update tag_comment_text
    //     dataArray[positiveCommentsIndex].tag_comment_text = selectedText;
    // } else {
    //     // Create a new object with empty tag_comment_text right after the updated object
    // const newObject = {
    //     cc_tag_id: '', // Provide a new ID for the new object
    //     cc_tag_title: text,
    //     cc_tag_class_id: 2202,
    //     cc_tag_checked: "no",
    //     cc_tag_add_on: "2023-10-27T07:54:17.000Z",
    //     cc_tag_modifyed_on: null,
    //     cc_tag_abbre: "P C",
    //     tag_comment_text: selectedText
    // };
    //   // Insert the new object right after the updated object
    //   dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
    // }
  
    

     // ------Add Power points------
     let oldText_quality_note = text_quality_note;
     let newText_quality_note = 0
     for (let item of dataArray) {
         if (item.tag_comment_text) {
             if (newText_quality_note < quality_note.max_allotted_text) {
                 newText_quality_note = Number(newText_quality_note) + Number(quality_note.text_allotted_points)
             }
         }

     }
     let quality_points = qualityPoints
     if (oldText_quality_note > newText_quality_note) {
         let value = Number(oldText_quality_note) - Number(newText_quality_note);
         quality_points = Number(quality_points) - Number(value);

     } else {
         let value = Number(newText_quality_note) - Number(oldText_quality_note)
         quality_points = Number(quality_points) + Number(value);

     }
     // ------Add Power points------
     setShowPopover(false)
     setShowTranscribePopover(false)
    
     setText_quality_note(newText_quality_note)
     setQuality_points(quality_points)
     setShowSummarizeModal(false)
     sessionStorage.setItem('noteCommentData', JSON.stringify(dataArray))
    // this.setState({
    //     showPopover: false,
    //     showTranscribePopover: false,
    //     note_tag_comments: dataArray,
    //     text_quality_note: newText_quality_note,
    //     quality_points: quality_points,
    //     showSummarizeModal: false
    // }, () => {
    //     sessionStorage.setItem('noteCommentData', JSON.stringify(dataArray))
    // });
}
const selectAllTexttransc = () => {
    const preElement = document.querySelector("#newdiv"); // Target the <pre> element inside the div
    
    if (preElement && window.getSelection && document.createRange) {
        const range = document.createRange();
        range.selectNodeContents(preElement);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        
        // Check if the entire text is selected
        const selectedText = selection.toString().trim();
        const fullText = preElement.innerText.trim();

        // Show popover only if the entire text is selected
        // setTimeout(() => {
            if (selectedText === fullText) {
                console.log('selec == full');
                // setTimeout(() => {
                    setShowPopover(true)
                // }, 500);
            } else {
                setShowPopover(false)
            }
        // }, 500);
    }
}

const selectAllText = () => {
    const preElement = document.querySelector("#div2 pre"); // Target the <pre> element inside the div
    
    if (preElement && window.getSelection && document.createRange) {
        const range = document.createRange();
        range.selectNodeContents(preElement);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        // Introduce a slight delay to ensure the selection is fully processed
        setTimeout(() => {
            // Check if the entire text is selected
            const selectedText = selection.toString().trim();
            const fullText = preElement.innerText.trim();
    
            if (selectedText === fullText) {
                setShowTranscribePopover(true)
            } else {
                setShowTranscribePopover(false)
            }
        }, 100); // Delay of 100ms, adjust if necessary
    }
}
function handleTranscribeHighlight(text) {

    let dataArray = [...note_tag_comments];
    let lastPositiveCommentsIndex = -1;
    for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i].cc_tag_title === text) {
            lastPositiveCommentsIndex = i;
        }
    }

    if (props?.location?.state?.pageType === "Edit") {
        dataArray = JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : note_tag_comments;
        
        if (
            lastPositiveCommentsIndex !== -1 &&
            dataArray[lastPositiveCommentsIndex].tag_comment_text === ""
        ) {
            // Update tag_comment_text of the last "Positive Comments"
            dataArray[lastPositiveCommentsIndex].tag_comment_text = selectedText;
        } else {
            // Create a new object with the appropriate details
            const newObject = {
                cc_tag_abbre: dataArray[lastPositiveCommentsIndex]?.cc_tag_abbre,
                cc_tag_title: text,
                tag_comment_add_on: new Date(),
                tag_comment_id: "",
                tag_comment_note_id: dataArray[lastPositiveCommentsIndex]?.tag_comment_note_id,
                tag_comment_student_id: dataArray[lastPositiveCommentsIndex]?.tag_comment_student_id,
                tag_comment_text: selectedText,
                tag_id: "",
            };

            // Insert the new object right after the last "Positive Comments"
            if (lastPositiveCommentsIndex !== -1) {
                dataArray.splice(lastPositiveCommentsIndex + 1, 0, newObject);
            } else {
                dataArray.push(newObject);
            }

            // Reorganize audio-related comments
            const audioComments = dataArray.filter(comment =>
                comment.cc_tag_title === "Audio Summarization" || comment.cc_tag_title === "Audio Transcription"
            );
            const otherComments = dataArray.filter(comment =>
                comment.cc_tag_title !== "Audio Summarization" && comment.cc_tag_title !== "Audio Transcription"
            );
        
            dataArray = [...audioComments, ...otherComments];
        }
        
        sessionStorage.setItem(
            "finalNoteCommentCardsAfterRemove",
            JSON.stringify(dataArray)
        );
        setNote_tag_comments(dataArray);
    } else {
        if (
            lastPositiveCommentsIndex !== -1 &&
            dataArray[lastPositiveCommentsIndex].tag_comment_text === ""
        ) {
            // Update tag_comment_text of the last "Positive Comments"
            dataArray[lastPositiveCommentsIndex].tag_comment_text = selectedText;
        } else {
            // Create a new object with the appropriate details
            const newObject = {
                cc_tag_abbre: dataArray[lastPositiveCommentsIndex]?.cc_tag_abbre,
                cc_tag_add_on: new Date(),
                cc_tag_checked: "no",
                cc_tag_class_id: '',
                cc_tag_id: '',
                cc_tag_modifyed_on: new Date(),
                tag_comment_text: selectedText,
                cc_tag_title: text
            };

            // Insert the new object right after the last "Positive Comments"
            if (lastPositiveCommentsIndex !== -1) {
                dataArray.splice(lastPositiveCommentsIndex + 1, 0, newObject);
            } else {
                dataArray.push(newObject);
            }
        }

        sessionStorage.setItem(
            "finalNoteCommentCardsAfterRemove",
            JSON.stringify(dataArray)
        );
        setNote_tag_comments(dataArray);
    }

    // ------Add Power points------
    let oldText_quality_note = text_quality_note;
    let newText_quality_note = 0;
    for (let item of dataArray) {
        if (item.tag_comment_text) {
            if (newText_quality_note < quality_note.max_allotted_text) {
                newText_quality_note = Number(newText_quality_note) + Number(quality_note.text_allotted_points);
            }
        }
    }
    
    let quality_points = qualityPoints;
    if (oldText_quality_note > newText_quality_note) {
        let value = Number(oldText_quality_note) - Number(newText_quality_note);
        quality_points = Number(quality_points) - Number(value);
    } else {
        let value = Number(newText_quality_note) - Number(oldText_quality_note);
        quality_points = Number(quality_points) + Number(value);
    }
    
    // ------End of Power points logic------
    
    setShowPopover(false);
    setShowTranscribePopover(false);
    setText_quality_note(newText_quality_note);
    setQuality_points(quality_points);
    setShowSummarizeModal(false);

    sessionStorage.setItem('noteCommentData', JSON.stringify(dataArray));
}

// function handleTranscribeHighlight(text) {

//     let dataArray = [...note_tag_comments];
//     let lastPositiveCommentsIndex = -1;
//     for (let i = 0; i < dataArray.length; i++) {
//         if (dataArray[i].cc_tag_title === text) {
//             lastPositiveCommentsIndex = i;
//         }
//     }

//     if (props?.location?.state?.pageType === "Edit") {
//         dataArray = JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : note_tag_comments
//         // Find the index of the object with cc_tag_title equal to text
//         const positiveCommentsIndex = dataArray.findIndex(
//           (obj) => obj.cc_tag_title === text
//         );
  
//         // Check if the object exists and tag_comment_text is empty
//         if (
//           positiveCommentsIndex !== -1 &&
//           dataArray[positiveCommentsIndex].tag_comment_text === ""
//         ) {
//           // Update tag_comment_text
//           dataArray[positiveCommentsIndex].tag_comment_text = selectedText;
//         } else {
//           // Create a new object with empty tag_comment_text right after the updated object
//           const newObject = {
//             cc_tag_abbre: dataArray[positiveCommentsIndex]?.cc_tag_abbre,
//             cc_tag_title: text,
//             tag_comment_add_on: new Date(),
//             tag_comment_id: "",
//             tag_comment_note_id: dataArray[positiveCommentsIndex]?.tag_comment_note_id,
//             tag_comment_student_id: dataArray[positiveCommentsIndex]?.tag_comment_student_id,
//             tag_comment_text: selectedText,
//             tag_id: "",
//           };
//           // Insert the new object right after the updated object
//           dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
//         }
//         sessionStorage.setItem(
//           "finalNoteCommentCardsAfterRemove",
//           JSON.stringify(dataArray)
//         );
//         setNote_tag_comments(dataArray)
//       } else {
//         // Find the index of the object with cc_tag_title equal to text
//         dataArray =  [...note_tag_comments]
//         const positiveCommentsIndex = dataArray.findIndex(
//           (obj) => obj.cc_tag_title === text
//         );
  
//         // Check if the object exists and tag_comment_text is empty
//         if (
//           positiveCommentsIndex !== -1 &&
//           dataArray[positiveCommentsIndex].tag_comment_text === ""
//         ) {
//           // Update tag_comment_text
//           dataArray[positiveCommentsIndex].tag_comment_text = selectedText;
//         } else {
//           // Create a new object with empty tag_comment_text right after the updated object
//           const newObject = {
//             cc_tag_abbre: dataArray[positiveCommentsIndex]?.cc_tag_abbre,
//             cc_tag_add_on: new Date(),
//             cc_tag_checked: "no",
//             cc_tag_class_id: '',
//             cc_tag_id: '',
//             cc_tag_modifyed_on: new Date(),
//             tag_comment_text: selectedText,
//             cc_tag_title: dataArray[positiveCommentsIndex]?.cc_tag_title
//           };
//           // Insert the new object right after the updated object
//           dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
//         }
//         sessionStorage.setItem(
//           "finalNoteCommentCardsAfterRemove",
//           JSON.stringify(dataArray)
//         );
//         setNote_tag_comments(dataArray)
//       }

//     // Find the index of the object with cc_tag_title equal to text
//     // const positiveCommentsIndex = dataArray.findIndex(
//     //     (obj) => obj.cc_tag_title === text
//     // );

//     // // Check if the object exists and tag_comment_text is empty
//     // if (positiveCommentsIndex !== -1 && dataArray[positiveCommentsIndex].tag_comment_text === '') {
//     //     // Update tag_comment_text
//     //     dataArray[positiveCommentsIndex].tag_comment_text = selectedText;
//     // } else {
//     //     // Create a new object with empty tag_comment_text right after the updated object
//     // const newObject = {
//     //     cc_tag_id: '', // Provide a new ID for the new object
//     //     cc_tag_title: text,
//     //     cc_tag_class_id: 2202,
//     //     cc_tag_checked: "no",
//     //     cc_tag_add_on: "2023-10-27T07:54:17.000Z",
//     //     cc_tag_modifyed_on: null,
//     //     cc_tag_abbre: "P C",
//     //     tag_comment_text: selectedText
//     // };
//     //   // Insert the new object right after the updated object
//     //   dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
//     // }

    

//       // ------Add Power points------
//       let oldText_quality_note = text_quality_note;
//       let newText_quality_note = 0
//       for (let item of dataArray) {
//           if (item.tag_comment_text) {
//               if (newText_quality_note < quality_note.max_allotted_text) {
//                   newText_quality_note = Number(newText_quality_note) + Number(quality_note.text_allotted_points)
//               }
//           }

//       }
//       let quality_points = qualityPoints
//       if (oldText_quality_note > newText_quality_note) {
//           let value = Number(oldText_quality_note) - Number(newText_quality_note);
//           quality_points = Number(quality_points) - Number(value);

//       } else {
//           let value = Number(newText_quality_note) - Number(oldText_quality_note)
//           quality_points = Number(quality_points) + Number(value);

//       }
//       // ------Add Power points------
//       setShowPopover(false)
//       setShowTranscribePopover(false)
//       setText_quality_note(newText_quality_note)
//       setQuality_points(quality_points)
//       setShowSummarizeModal(false)
//       sessionStorage.setItem('noteCommentData', JSON.stringify(dataArray))
//     // this.setState({
//     //     showTranscribePopover: false,
//     //     showPopover: false,
//     //     note_tag_comments: dataArray,
//     //     text_quality_note: newText_quality_note,
//     //     quality_points: quality_points,
//     //     showSummarizeModal: false
//     // },() => {
//     //     sessionStorage.setItem('notCommentData', JSON.stringify(dataArray))
//     // });
// }
    return (
        <div className='addClassNoteMainContainer'>
            <Row className='px-3'>
                {/* ---------------Header Bar------------ */}
                <Col xs="12" sm="12" md="12" className='headerBar '>
                    <Col xs="5" className=" note-header-title ">
                        <div className='CNA-stdNoteBackDiv'>
                            <img className='calIcon' src={back} alt='' width='60' height='40' onClick={() => {
                                // BackToDash()
                                if (qualityPoints > 0) {
                                    setGoBackModal(true)
                                } else {
                                    BackToDash()
                                    dispatch(resetStudentNotes())
                                }
                            }} />
                        </div>
                        <div className='CNA-stdNoteDetailTextDiv'>
                            <div className='CNA-stdNoteDisplayFlex'>
                                <p className='CNA-currentYear' onClick={() => {

                                    // BackToDash()
                                    if (qualityPoints > 0) {
                                        setGoBackModal(true)
                                    } else {
                                        BackToDash()
                                        dispatch(resetStudentNotes())
                                    }

                                }}><b>{currentYear}</b></p>
                                <i className="classForIcon material-icons">chevron_right</i>
                                <p className='CNA-stdNoteGrade1' onClick={() => {

                                    // setConfirmationForBack(true)
                                    if (qualityPoints > 0) {
                                        setGoBackModal(true)
                                    } else {
                                        BackTOHome()
                                        dispatch(resetStudentNotes())
                                    }
                                }}>Group Note </p>
                                <i className="classForIcon material-icons">chevron_right</i>
                                <p className='CNA-stdNoteGrade2'>Create Group Note</p>
                            </div>
                        </div>
                    </Col>
                    {/* ---------------Header Bar------------ */}
                    <Col className='powerCenter' xs="2">
                        {/* ---------------Progress Bar------------ */}
                        {qualityPoints === 0 ? <div className="blankPowerPointaDic">
                            <p className='powerText'>Power</p>
                            <p className='powerPointText'>Points</p>
                        </div> :
                            <StudentNoteProgressbarComponent max_score={quality_note?.max_score} value={qualityPoints} type={'points'} />}
                        {/* ---------------Progress Bar------------ */}

                    </Col>
                    <Col xs="5" className="text-right action-div">
                        {/* <Button variant="default" className="action-btn" onClick={goBackPage} >
                            <img src={closeBtnIcon} alt='' />
                        </Button>
                        <Button variant="default" className="action-btn" onClick={addNotes} >
                            <img src={saveBtnIcon} alt='' />
                        </Button> */}
                        <Button variant="default" className="action-btn" onClick={() => { props.history.goBack(); dispatch(resetStudentNotes()); setTranscribeAudioModal(false) }}
                            style={{ background: "#ea3b3b", color: '#fff', width: 100, height: 40, borderRadius: 8, textTransform: 'capitalize' }}
                        // disabled={quality_points == 0 ? true : false}
                        >
                            <img src={closeIcon} alt='' /> Cancel
                        </Button>
                        <Button style={qualityPoints == 0 ? { cursor: "not-allowed", background: "#2ec428", color: '#fff', width: 100, height: 40, borderRadius: 8, textTransform: 'capitalize' } : { cursor: "pointer", background: "#2ec428", color: '#fff', width: 100, height: 40, borderRadius: 8, textTransform: 'capitalize' }} variant="default" className="action-btn" onClick={() => { addNotes(); dispatch(resetStudentNotes()) }} >
                            <img src={tick} alt='' />&nbsp;&nbsp; Save
                            {/* <img src={saveBtnIcon} alt='' /> */}
                        </Button>
                        {console.log('note_tag_comments #### :>> ', note_tag_comments)}
                    </Col>
                </Col>
            </Row>
            <Row>
                {/* ---------------left panel------------ */}
                <Col md="4" lg="3" className="left-section">
                    <Card className="br-8">
                        <Card.Body className='CNA-left-panel-card'>

                            {/* ---------------Back Button------------ */}
                            <Row className="CNA-left-panel-txt" variant="secondary" >
                                <Col xs="12" sm="6"  >
                                    <span className='CNA-left-panel-grp-txt'>Group Name</span>
                                </Col>
                                <Col xs="12" sm="6" >
                                    <spna className="CNA-left-panel-total-std-txt">Total Students: {selctedStudentsInfo?.length}</spna>
                                </Col>
                            </Row>
                            {/* ---------------Back Button------------ */}

                            {/* ---------------Profile Section------------ */}

                            {/* ---------------Profile Details------------ */}
                            <Card.Text as="div">
                                <Row className="profile-card">
                                    <Col xs="12" className="profile-col" >
                                        {selctedStudentsInfo && selctedStudentsInfo.map((profiles, k) => {
                                            return (
                                                (profiles.cc_student_pic_url) ?
                                                    <div className="profiles-card-img" key={k}>
                                                        <Card.Img className="profile-img" variant="top" alt='' src={profiles.cc_student_pic_url} />
                                                    </div>
                                                    :
                                                    <div className='studentsProfileImageDiv' key={k}>
                                                        <div className='imageDummyRosterProfile'>
                                                            <h5 className='rosterProfileImageText rosterProfileImageTextPadding'>{profiles.cc_student_first_name.charAt(0)}{profiles.cc_student_last_name.charAt(0)}</h5>
                                                        </div>
                                                    </div>
                                            )
                                        })}
                                    </Col>
                                </Row>
                            </Card.Text>
                            {/* ---------------Profile Details------------ */}

                            {/* ---------------Profile Section------------ */}
                        </Card.Body>
                    </Card>
                </Col>
                {/* ---------------left panel------------ */}


                {/* ---------------Right panel------------ */}
                <Col md="8" lg="9" className="right-section">

                    {/* ---------------Second Top header panel------------ */}
                    <Card className="CNA-NoteFilterSection br-8">
                        <Card.Body>
                            <Row>
                                <Col xs="6" md="6" lg="3" className="CNA-note-header-title noteDate CNA-datepicker">
                                    <DatePicker

                                        onChange={date => { setDate(date) }}
                                        customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                                    />
                                    <span className="CNA-note-header-title-date">
                                        {updatedDate}
                                    </span>
                                </Col>
                                <Col xs="6" md="6" lg="4" className="CNA-addClassNoteMainContainer text-center">

                                    <div className='CNA-class-txt'>
                                        <p className='CNA-class-txt-1'>Class Name:  </p>
                                        <p className='CNA-class-txt-2 class-note-classname'>{sessionStorage.getItem('noteClassName') ? sessionStorage.getItem('noteClassName').replace(/(.{13})..+/, "$1…") : classDetails.name.replace(/(.{13})..+/, "$1…")}</p>
                                    </div>

                                </Col>
                                <Col xs="12" md="12" lg="5" className="pl-2 CNA-note-header-right-section">
                                    {addTime ?
                                        <PinkButton variant="outlined" size="small" className="CNA-PinkBtn" style={{ textTransform: 'capitalize', borderRadius: 8, marginTop: 3 }}>
                                            <DatePicker
                                                selected={new Date()}
                                                onChange={date => { setTime(date) }}
                                                showTimeSelect
                                                timeFormat={formatClock === "24 Hours" ? "HH:mm" : "haa"}
                                                timeIntervals={60}
                                                minDate={new Date()}
                                                minTime={setMinutes(new Date(), 60)}
                                                maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                                maxDate={new Date()}
                                                customInput={<img src={remiderIconPink} alt="" width="14" height="14" />}
                                                dateFormat="Pp"
                                                timeCaption="Hour"
                                            />
                                            {addTime}
                                        </PinkButton> :
                                        <GreyButton variant="outlined" size="small" style={{ textTransform: 'capitalize', borderRadius: 8, border: '0.75px solid #AAAAAA', color: "#00000066", marginTop: 3 }}>
                                            <DatePicker
                                                selected={new Date()}
                                                onChange={date => { setTime(date) }}
                                                showTimeSelect
                                                timeFormat={formatClock === "24 Hours" ? "HH:mm" : "haa"}
                                                timeIntervals={60}
                                                minDate={new Date()}
                                                minTime={setMinutes(new Date(), 60)}
                                                maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                                maxDate={new Date()}
                                                customInput={<img src={remiderIconPink} alt="" width="14" height="14" />}
                                                dateFormat="Pp"
                                                timeCaption="Hour"
                                            />
                                            Reminder
                                        </GreyButton>}
                                    {isEnabledArtifact === 'yes' ?
                                        <YellowButton
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                setIsEnabledArtifact('no'); setIsSaved(false)
                                            }}
                                            style={{ textTransform: 'capitalize', borderRadius: 8, marginTop: 3, marginLeft: 10 }}
                                        >
                                            <img src={starIcon} className="reminderIcon" alt="" width="16" height="18" />
                                            Remove Artifact
                                        </YellowButton> :
                                        <GreyButton
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                setIsEnabledArtifact('yes'); setIsSaved(false)
                                            }}
                                            style={{ textTransform: 'capitalize', borderRadius: 8, border: '0.75px solid #AAAAAA', color: "#00000066", marginTop: 3, marginLeft: 10 }}
                                        >
                                            <img src={starYellowBorder} className="reminderIcon" alt="" width="16" height="18" />
                                            Mark as Artifact
                                        </GreyButton>}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {/* ---------------Second Top header panel------------ */}

                    <Row>

                        {/* ---------------Picture/Video and Audio panel------------ */}
                        <Col xs="12" md="12" lg="8" className="pr-7-5">
                            <Card className="CNA-add-media-sec mt-15 br-8">
                                <Card.Body>
                                    <Row>
                                        {/* ---------------Picture/Video/Level/Socre------------ */}
                                        <Col xs="12">
                                            <Row>

                                                {/* ---------------Picture/Video panel------------ */}
                                                <Col xs="12" className="title">
                                                    <div className="multimediaTitles">
                                                        <p className="attachTitles">Multimedia Notes</p>
                                                        <p className="suggestion">— Supported Files: JPEG, MP4, PDF, DOCX, CSV, XLSX, PPTX</p>
                                                    </div>
                                                    <div className="audio-sec">
                                                        <Button className="btn-add-media">
                                                            {uploadMediaStatus ?
                                                                /* ---------------Progress Bar------------ */
                                                                <StudentNoteProgressbarComponent value={mediaUploadPercentage} type={'media'} />
                                                                /* ---------------Progress Bar------------ */

                                                                :
                                                                <img className="add-media" src={addTransperent} alt='' />}


                                                            <Form.Control type="file" accept="video/*|image/*|pdf/*|doc/*|docx/*|gif/*|jpg/*|jpeg/*|png/*|xls/*|ppt/*|pptx/*|csv/*" onChange={e => { getMediaHandle(e) }} />
                                                        </Button>
                                                        <div className="CNA-picture-video-sec">
                                                            {pictureVideoList.length > 0 && pictureVideoList.map((item, i) => {
                                                                return (
                                                                    item.cc_attachment_operation !== "remove" && item.cc_attachment_operation !== "unchangedRemoved" && <div key={i}>
                                                                        {item.cc_attachment_type !== 'image' ? (
                                                                            <div className="note" style={{ position: 'relative' }}>
                                                                                <div className="img-div">
                                                                                    <img src={playIcon} className="playIcon" alt='' width="18" height="18" onClick={() => { handleVideoModalShow(item) }} />
                                                                                </div>
                                                                                <img className="deleteBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { onDeleteUpdateMedia(i, 'video') }} />
                                                                                {item.cc_media_mark_artified == 'yes' &&
                                                                                    <div className="badgeDoNotet"></div>
                                                                                }
                                                                            </div>
                                                                        ) : (
                                                                            <div className="note" style={{ backgroundImage: `url(${item.cc_attachment_fullurl})`, position: 'relative' }}
                                                                            // onClick={() => { handleImageModalShow(item) }}
                                                                            >
                                                                                <div className='img-div-only'
                                                                                onClick={() => { handleImageModalShow(item) }}
                                                                                // onClick={() => { console.log('some');handleImageModalShow(item) }}
                                                                                >
                                                                                </div>
                                                                                <img className="deleteImgBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { onDeleteUpdateMedia(i, 'video') }} />
                                                                                {item.cc_media_mark_artified == 'yes' &&
                                                                                    <div className="badgeDoNotet"></div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            })}
                                                            {docList.length > 0 && (
                                                                docList.map((item, i) => {
                                                                    return (
                                                                        item.cc_attachment_operation !== "remove" && item.cc_attachment_operation !== "unchangedRemoved" && <div key={i} style={{ marginLeft: i > 0 && 15 }}>
                                                                            <div className="note">
                                                                                <div className="img-divDoc" style={{ position: 'relative' }}>
                                                                                    {item.cc_attachment_url.split(".").pop() === "pdf" && <img src={note_pdf} alt='' className="csvIcon" onClick={() => { handleDocModalShow(item) }} />}
                                                                                    {item.cc_attachment_url.split(".").pop() === "doc" || item.cc_attachment_url.split(".").pop() === "docx" ? <img src={note_doc} alt='' className="csvIcon" onClick={() => { handleDocModalShow(item) }} /> : ""}
                                                                                    {item.cc_attachment_url.split(".").pop() === "ppt" || item.cc_attachment_url.split(".").pop() === "pptx" ? <img src={note_ppt} alt='' className="csvIcon" onClick={() => { handleDocModalShow(item) }} /> : ""}
                                                                                    {item.cc_attachment_url.split(".").pop() === "xls" && <img src={note_xls} alt='' className="csvIcon" onClick={() => { handleDocModalShow(item) }} />}
                                                                                    {item.cc_attachment_url.split(".").pop() === "csv" && <img src={note_csv} alt='' className="csvIcon" onClick={() => { handleDocModalShow(item) }} />}
                                                                                    {item.cc_media_mark_artified == 'yes' &&
                                                                                        <div className="badgeDoNotet"></div>
                                                                                    }
                                                                                </div>
                                                                                <img className="deleteBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { onDeleteUpdateMedia(i, 'other') }} style={{ position: 'relative' }} />
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                }))}
                                                            {docList.length <= 0 || pictureVideoList.length <= 0 ?
                                                                <p className="multimedia-suggestion">As a best practice remember to add media<br></br> notes to support your text notes</p> :
                                                                <p className="multimedia-suggestion"></p>
                                                            }

                                                        </div>
                                                    </div>
                                                </Col>
                                                {/* ---------------Picture/Video panel------------ */}

                                            </Row>
                                        </Col>
                                        {/* ---------------Picture/Video/Level/Socre------------ */}

                                        <Col xs="12" className="hr-divider">
                                            <hr></hr>
                                        </Col>

                                        {/* ---------------Audio panel------------ */}
                                        <Col xs="12" className="title">

                                            <p>Audio Notes</p>
                                            <div className="audio-sec">
                                                <Button className="btn-add-media" style={{ marginTop: 3 }} onClick={handleShowAudioModal}>
                                                    {uploadAudioStatus ?
                                                        <StudentNoteProgressbarComponent className='upload_progress' value={mediaUploadPercentage} type={'media'} />

                                                        :
                                                        <img className="add-media" src={addTransperent} alt='' />}

                                                </Button>
                                                <div className="audit-sec">
                                                    {audioList.map((item, index) => {
                                                        return (
                                                            item.cc_attachment_operation !== "remove" && item.cc_attachment_operation !== "unchangedRemoved" && <div key={index} className="audio-div">
                                                                <div className="audio-sub-div">
                                                                <img 
                style={{ position: "relative", top: "0px", marginLeft: "16px" }} 
                src={item.cc_media_mark_artified === "yes" ? starFilled : starFilledgray} 
                className="reminderIcon" 
                alt="" 
                width="16" 
                height="18" 
                role='button'
                onClick={() => setMediaArtiAddEdit(item, 'audio')}
            />
            <p className="audio-filename" style={{ position: "relative", top: "-31px"}}>
         
                {((item.cc_attachment_name == null || item.cc_attachment_name === "") ? `${moment().format('LL')} ${moment().format('LT')}` : item.cc_attachment_name) || `${moment().format('LL')} ${moment().format('LT')}` }
            </p>
                                                                    <audio style={{ position: "relative", top: "-28px"}} controls className="player" preload="false" controlsList="nodownload" src={item.cc_attachment_fullurl} ></audio>
                                                                </div>
                                                                <img className="deleteBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { onDeleteUpdateMedia(index, 'audio') }} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                               {
                                                pageType == 'Edit' ? 
                                                <>
                                                 <Button variant='outlined' className='smartAI_btn text-capitalize' onClick={() => {
                                                    // this.setState({
                                                    //     // showGenerateAudioAIModal: true,
                                                    //     // transcribeAudioModal: true,
                                                    //     // showSummarizeModal: true
                                                    //     showSelectAudioModal: true
                                                    // })
                                                    setShowSelectAudioModal(true)
                                                }}
                                                // style={{display : audioList?.length > 0 ? 'block' : 'none'}}
                                                disabled={uploadAudioStatus || disableSmartBtn}
                                                >Smart Ai</Button>
                                                </>
                                                :
                                                <>
                                                 <Button variant='outlined' className='smartAI_btn text-capitalize' onClick={() => {
                                                    // this.setState({
                                                    //     // showGenerateAudioAIModal: true,
                                                    //     // transcribeAudioModal: true,
                                                    //     // showSummarizeModal: true
                                                    //     showSelectAudioModal: true
                                                    // })
                                                    setShowSelectAudioModal(true)
                                                }}
                                                // style={{display : audioList?.length > 0 ? 'block' : 'none'}}
                                                disabled={uploadAudioStatus || (audioList?.length > 0 ? false : true)}
                                                >Smart Ai</Button>
                                                </>
                                               }     
                                           
                                        </Col>
                                        {/* ---------------Audio panel------------ */}

                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* ---------------Picture/Video and Audio panel------------ */}
                        {/* ---------------Miscellaneous panel toggle button------------ */}
                        <Col xs="12" md="12" lg="4" className="pl-7-5">
                            <Card className="CNA-add-Level-Score-sec mt-15 br-8">
                                <Card.Body>
                                    <Row className='level-row'>
                                        <Col xs="6" className="level-title">
                                            <div className="level-sub-div">
                                                <p className='level-sec'>
                                                    Level:
                                                </p>
                                                <FormControl
                                                    placeholder="Aa"
                                                    aria-label="Level"
                                                    name='noteLevel'
                                                    value={noteLevel}
                                                    onChange={getNoteLevel}
                                                />
                                            </div>
                                        </Col>
                                        <hr></hr>
                                        <Col xs="6" className="score-title">
                                            <div className="score-sub-div">
                                                <p className='score-sec'>
                                                    Score:
                                                </p>
                                                <FormControl
                                                    placeholder="00"
                                                    aria-label="Score"
                                                    name='noteScore'
                                                    value={noteScore}
                                                    onChange={getNoteScore}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="CNA-add-assignment-sec mt-15 br-8">
                                <Card.Body>
                                    <Row>
                                        <Col xs="12" className="title">
                                            <span style={{ float: "left" }}>{isLinkedText}</span>
                                            {pageType === 'New' ? <Form.Check
                                                disabled={isLinkedStatusForAll ? false : true}
                                                type="switch"
                                                id="custom-switch"
                                                className="text-right"
                                                label=""
                                                value={work_assignment}
                                                checked={isLinkedStatus}

                                            /> :
                                                <Form.Check
                                                    disabled={isLinkedStatusForAll ? false : true}
                                                    type="switch"
                                                    id="custom-switch"
                                                    className="text-right"
                                                    label=""
                                                    value={work_assignment}
                                                    checked={isLinkedStatus}

                                                />}
                                        </Col>
                                        {/* =========================== */}
                                        {isLinkedStatusForAll ?
                                            <Col xs="12" className='contactTextAreaTopDist'>
                                                <Form.Control onChange={onChangeWorkAssignment} as="textarea" rows="6" value={work_assignment}
                                                    placeholder="Write assignment note here…"
                                                />
                                            </Col> :
                                            <Col xs="12" className=''>

                                            </Col>}

                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* ---------------Miscellaneous panel------------ */}

                        {/* ---------------Comment Section------------ */}
                        <Col xs="12" className="mt-15">
                            {getTagLoader && <img src={loaderImag} alt='' className='loaderUploadIcon' />}
                            {/* {
                                note_tag_comments && 
                                <ClassNoteCommentCard 
                                type={'Edit'}
                                pageType={pageType}
                                width="4"
                                class_id={classDetails.value}
                                CommentData={props?.location?.state?.pageType == 'Edit' ? props?.location?.state?.data?.note_tag_comments : note_tag_comments}
                                // CommentData={this.state.note_tag_comments}
                                // CommentData={this?.props?.history?.location?.state?.data?.note_tag_comments}
                                setCommentData={setCommentData}
                                // coreStandardNotes={this.state.getNoteSpiralTagData}
                                // quality_points = {qualityPoints}
                                studentInfo={studentsInfo}
                            // setSpeedNote = {this.setSpeedNote.binf(this)}
                                />
                            } */}
                            {note_tag_comments && note_tag_comments.length > 0 &&
                                <>
                                    {/* <NoteCommentCard
                                    allProps={props}
                                    type={'Edit'}
                                    pageType={pageType}
                                    width="4"
                                    allData={noteData}
                                    class_id={classDetails.value}
                                    // CommentData={note_tag_comments}
                                    CommentData={props?.location?.state?.pageType == 'Edit' ? props?.location?.state?.data?.note_tag_comments : note_tag_comments}
                                    StudentAllNoteDetails={StudentAllNoteDetails}
                                    setCommentData={setCommentData}
                                    studentInfo={studentsInfo}
                                /> */}

                                    <NoteCommentCard
                                        allProps={props}
                                        type={'Edit'}
                                        pageType={pageType?.trim().length == 0 ? 'New' : pageType}
                                        width="4"
                                        allData={noteData}
                                        class_id={classDetails.cc_class_id}
                                        // CommentData={JSON.parse(JSON.stringify(note_tag_comments))}
                                        // CommentData={props?.location?.state?.pageType == 'Edit' ? 
                                        // (JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : JSON.parse(JSON.stringify(note_tag_comments)))
                                        //  :
                                        //   JSON.parse(JSON.stringify(note_tag_comments))}
                                        CommentData={
                                            pageType === 'Edit' ? 
                                            (
                                                (() => {
                                                    let comments = JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) || note_tag_comments;
                                        
                                                    // Filter out Audio Transcription (AT) and Audio Summarization (AS) comments
                                                    const audioTranscription = comments.find(comment => comment.cc_tag_title === "Audio Transcription");
                                                    const audioSummarization = comments.find(comment => comment.cc_tag_title === "Audio Summarization");
                                        
                                                    // Filter out other comments
                                                    const otherComments = comments.filter(comment => 
                                                        comment.cc_tag_title !== "Audio Transcription" && comment.cc_tag_title !== "Audio Summarization"
                                                    );
                                        
                                                    // Start with an empty array
                                                    let orderedComments = [];
                                        
                                                    // Push Audio Transcription (AT) to the 0th position if it exists
                                                    if (audioTranscription) {
                                                        orderedComments.push(audioTranscription);
                                                    }
                                        
                                                    // Push Audio Summarization (AS) to the 1st position if it exists
                                                    if (audioSummarization) {
                                                        orderedComments.push(audioSummarization);
                                                    }
                                        
                                                    // Append other comments after AT and AS
                                                    orderedComments = [...orderedComments, ...otherComments];
                                        
                                                    return orderedComments;
                                                })()
                                            ) 
                                            : 
                                            JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) || note_tag_comments
                                        }
                                        
                                        
                                        StudentAllNoteDetails={StudentAllNoteDetails}
                                        setCommentData={setCommentData}
                                        studentInfo={studentsInfo}
                                        logH5Content={logH5Content}
                                        finalAudioList={audioList}
                                        note_tag_comments={note_tag_comments}
                                        showDivs = {showDivs}
                                    showDivsEdit = {showDivsEdit}
                                    audioSummarizationtext = {audioSummarizationforsmall ||  audioSummarization}
                                    corestandards = {props?.location?.state?.classGroupNotesDataHere?.class_core_standard}

                                    />
                                </>
                            }
                         {console.log('123123123 :>> ',  JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')))}
                         { console.log('JSON.parse(sessionStorage.getItem classnote ', JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) , note_tag_comments)}
                         {console.log('123123123 222 :>> ', note_tag_comments)}
                         {console.log('123123123 xxxyyy audioSummarizationforsmall ||  audioSummarization :>> ', audioSummarizationforsmall )}
                         {console.log('123123123 xxxyyy audioSummarization :>> ', audioSummarization )}
                        </Col>
                        {/* ---------------Comment Section------------ */}
                    </Row>
                </Col >
                {/* ---------------Right panel------------ */}

            </Row >

            {/* ---------------Record Audio Modal------------ */}
            <Modal Modal className="add-media-modal br-8" show={show} onHide={handleClose} >
                <Modal.Header>
                    <Modal.Title>
                        <Button variant="default" onClick={enableAudioNameEditing}>
                            <i className="fa fa-pencil"></i>
                        </Button>
                        {isEnabled ? (
                            <Form.Control type="text" placeholder="Enter Audio Title Here" onChange={(e) => { setAudioTitle(e) }} />
                        ) : (
                            `${moment().format('LL')} ${moment().format('LT')}`
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="timer" >
                        <Timer
                            lastUnit="m"
                            initialTime={0}
                            checkpoints={[
                                {
                                    time: 60000 * 3,
                                    callback: () => stopRecording(),
                                },
                            ]}
                            startImmediately={!record}
                        >
                            {({ start, resume, pause, stop, reset, timerState }) => (<div>
                                <div className={record ? "display-none record-btn text-center" : "record-btn text-center"}>
                                    <Button onClick={startRecorder}>
                                        <img src={startRecordingIcon} onClick={reset} alt='' />
                                    </Button>
                                    <p className="record-info">Recording Duration Limit: 3 Mins</p>
                                </div>
                                <div className={!record ? "display-none record-btn text-center" : "record-btn text-center"} >
                                    {/* <div> */}
                                    <Button className="stopIcon" onClick={pause}>
                                        <img src={stopIcon} alt='' />
                                    </Button>
                                    <Button className="playAfterPause" onClick={resume}>
                                        <img src={playIcon} className="playIconPause" alt='' width="35" height="35" />
                                    </Button>
                                    <div className='timer_Div' style={{ width: '75px', marginTop: '45px', marginRight: '34px' }}>
                                        0<Timer.Hours />:0<Timer.Minutes />:<Timer.Seconds />
                                    </div>
                                </div>
                            </div>)}
                        </Timer>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {!record ? (
                        <Button variant="danger" onClick={handleClose} style={{ backgroundColor: '#EB4041', borderRadius: '8px' }}>
                            <img src={closeIcon} alt='' />
                        </Button>
                    ) : (
                        <>
                            <Button variant="danger" className="deleteIcon" onClick={handleClose} style={{ backgroundColor: '#EB4041', borderRadius: '8px' }}>
                                {/* <img src={deleteIcon} alt='' style={{backgroundColor:'#EB4041 !important'}}  /> */}
                                <i className="fa fa-trash-o" style={{ fontSize: "25px", color: "white", backgroundColor: "rgb(235, 64, 65)" }} ></i>
                            </Button>
                            <div style={{ width: 20 }}>

                            </div>
                            <Button variant="success" onClick={stopRecording} style={{ backgroundColor: '#4AD245', borderRadius: '8px' }}>
                                <img src={tickIcon} alt='' />
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal >
            {/* ---------------Record Audio Modal------------ */}


            {/* ---------------Play Video Modal------------ */}
            <Modal className="play-video-modal br-8 CNA-picture-video-modal" show={showVideoModalState} onHide={handleVideoModalClose}>
                <Modal.Header>
                    <span className="mediaArti">Mark As Artifact
                        {/* <i className={this.state.selectedImage.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star-o starUnSelected"} 
                                //  key={i} 
                                 aria-hidden="true" 
                                 onClick={() => { this.setMediaArtiAddEdit(this.state.selectedImage, "image") }}
                                >
                                    </i> : ""
                                */}

                        {pictureVideoList && pictureVideoList.length > 0 && pictureVideoList.map((val, i) => (
                            val.cc_attachment_url === selectedVideo.cc_attachment_url ? <i className={val.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star starUnSelected"} key={i} aria-hidden="true" onClick={() => {
                                setMediaArtiAddEdit(selectedVideo, "video")
                            }}></i> : ""
                        ))}
                    </span>
                    <Button variant="default" className="delete-btn" onClick={handleVideoModalClose} style={{width : 40, height : 40, borderRadius : 8}}>
                        <img src={closeIcon} alt='' />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {selectedVideo.cc_attachment_url !== undefined && (
                        <video width="750" height="400" style={{ border: "12px solid #e9e9e9" }} controls src={selectedVideo.cc_attachment_fullurl}></video>
                    )}
                </Modal.Body>
            </Modal>
            {/* ---------------Play Video Modal------------ */}


            {/* ---------------Image View Modal------------ */}
            <Modal className="play-video-modal br-8 CNA-picture-video-modal" show={showImageModalState} onHide={handleImageModalClose}>
                <Modal.Header style={{justifyContent : 'space-between'}}>
                    <span className="mediaArti">Mark As Artifact
                        {/* <i className={this.state.selectedImage.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star-o starUnSelected"} 
                                //  key={i} 
                                 aria-hidden="true" 
                                 onClick={() => { this.setMediaArtiAddEdit(this.state.selectedImage, "image") }}
                                >
                                    </i> : ""
                                */}

                        {pictureVideoList && pictureVideoList.length > 0 && pictureVideoList.map((val, i) => (
                            val.cc_attachment_url === selectedImage.cc_attachment_url ? <i className={val.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star starUnSelected"} key={i} aria-hidden="true" onClick={() => {
                                setMediaArtiAddEdit(selectedImage, "image")
                            }}></i> : ""
                        ))}
                    </span>
                    <Button variant="default" className="delete-btn" onClick={handleImageModalClose} style={{width : 40, height : 40, borderRadius : 8}}>
                        <img src={closeIcon} alt='' />
                    </Button>
                </Modal.Header>
                <Modal.Body>

                    {selectedImage.cc_attachment_url !== undefined && (
                        <img src={selectedImage.cc_attachment_fullurl} alt="" style={{ width: "100%" }} />
                    )}
                </Modal.Body>
            </Modal>
            {/* ---------------Image View Modal------------ */}

                            {/*---------showselectaudiomodal --------------------*/}
                            <Modal centered className="delete-modal-warning selectAudioModal  br-8" show={showSelectAudioModal} onHide={handleGenerateAudioAiModalClose}>
                    <Modal.Header className="rubricsModalHeader generateAudioModalHeader pb-0 align-items-center">
                        <p>Select an Audio Note to Generate Smart AI Notes</p>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={addStandardValue}
                                onChange={standardValueHandler}
                                style={{ display: 'block' }}
                            >
                                <Row>
                                    {
                                        audioList?.filter((item) => item?.cc_attachment_operation  != "unchangedRemoved").map((audioItem, audioIdx) => {
                                                
                                            return (
                                                <>
                                                    <Col xs={audioList?.filter((item) => item?.cc_attachment_operation  != "unchangedRemoved")?.length == 1 ? 12 : 6} key={audioItem?.cc_attachment_url} style={{ paddingRight: 0 }}>
                                                        <FormControlLabel className='audioRadioBtn' key={audioItem} value={audioItem?.cc_attachment_url} control={<Radio />} 
                                                        label={<RadioCardLabel audioObj={audioItem} audioIndex={audioIdx} getAudioTimeHandler={getAudioTimeHandler} />}
                                                        // style={{display : audioItem?.cc_attachment_operation  == "unchangedRemoved" ? 'none' : 'block'}}
                                                        />
                                                    </Col>
                                                </>
                                            )
                                        })
                                    }
                                </Row>
                            </RadioGroup>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className='mt-4'>
                        <Button
                            variant="default"
                            className="delete-modal-warning cancel-btn mr-3"
                            onClick={handleGenerateAudioAiModalClose}
                            style={{ background: "transparent", color: "#000", textTransform: 'capitalize' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="default"
                            className="delete-modal-warning save-standard-btn"
                            style={{ borderRadius: "8px", color: "#fff", textTransform: 'capitalize', 
                            opacity: (addStandardValue.length == 0) ? '0.5' : '1' 
                        }}
                            onClick={() => {
                                proceedToAudioHandler()
                                setShowGenerateAudioAIModal(true)
                                setShowSelectAudioModal(false)
                            }}
                            disabled={addStandardValue.length == 0}
                        >
                            Proceed
                        </Button>
                    </Modal.Footer>

                </Modal>

    {/*-----------generate AI Audio  ---------------*/}
    <Modal centered className="delete-modal-warning generateAudioModal br-8" show={showGenerateAudioAIModal} onHide={handleGenerateAudioAiModalClose}>
                    <Modal.Header className="rubricsModalHeader generateAudioModalHeader pb-0" closeButton>
                    <div className='w-100 d-flex insertDriveButton'>
                            <IconButton aria-label="notes">
                                <InsertDriveFileIcon />
                            </IconButton>
                        <div className='d-flex flex-column align-items-center'>
                            <p className='mb-0'>{selectAudioObj?.cc_attachment_name?.length > 0 ? selectAudioObj?.cc_attachment_name : `${moment().format('LL')} ${moment().format('LT')}`}.mp3</p>
                            <span className='time'>{selectAudioTotalDuration} {this?.state?.selectAudioTotalDuration?.split(':')[0] == 0 ? 'Sec' : 'Min'}</span>
                        </div>
                        <div className='d-flex flex-column w-100'>
                           <img src={audioWave} alt='audioWave' className='img-fluid' />
                        </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div class="sk-fading-circle">
                            <div class="sk-circle1 sk-circle"></div>
                            <div class="sk-circle2 sk-circle"></div>
                            <div class="sk-circle3 sk-circle"></div>
                            <div class="sk-circle4 sk-circle"></div>
                            <div class="sk-circle5 sk-circle"></div>
                            <div class="sk-circle6 sk-circle"></div>
                            <div class="sk-circle7 sk-circle"></div>
                            <div class="sk-circle8 sk-circle"></div>
                            <div class="sk-circle9 sk-circle"></div>
                            <div class="sk-circle10 sk-circle"></div>
                            <div class="sk-circle11 sk-circle"></div>
                            <div class="sk-circle12 sk-circle"></div>
                        </div>
                        <p className="prepareText">Generating Your Group Notes</p>
                        <p className={`pleaseWaitText`}>
                            please wait…
                        </p>
                    </Modal.Body>
                </Modal>


                 {/*--------------transcribe audio modal ----------------*/}
                 <Modal centered className="delete-modal-warning generateAudioModal transcribeAudioModalClass br-8" show={transcribeAudioModal} onHide={handleGenerateAudioAiModalClose}>

<Modal.Header className="rubricsModalHeader generateAudioModalHeader pb-0 align-items-center" closeButton>
    <div className='w-100 d-flex insertDriveButton'>
        <IconButton aria-label="notes">
            <InsertDriveFileIcon />
        </IconButton>
        <div className='d-flex flex-column align-items-center'>
            <p className='mb-0'> {selectAudioObj?.cc_attachment_name?.length > 0 ? selectAudioObj?.cc_attachment_name : `${moment().format('LL')} ${moment().format('LT')}`}.mp3</p>
            <span className='time'>{selectAudioTotalDuration} {selectAudioTotalDuration?.split(':')[0] == 0 ? 'Sec' : 'Min'}</span>
        </div>
        <div className='d-flex flex-column w-100'>
       <img src={audioWave} alt='audioWave' className='img-fluid' />
    </div>
    </div>
</Modal.Header>
<Modal.Body className="pb-0" style={{ position: 'relative' }}>

    {
        editAudioTranscribeAndSummarize ?
            <>
                <Form.Control as="textarea" rows={5} onChange={(e) => {
                    setAudioTranscribe(e.target.value)
                }}>
                    {audioTranscribe}
                </Form.Control>
            </>
            :
            <div style={{ position: 'relative' }} id='div3' 
            // onMouseUp={this.logH5Content}
            >
                <p className='transcribe_para'>
                    <div>
                        <div>
                            {audioTranscribe || ''}
                        </div>
                    </div>
                </p>
            </div>
    }


</Modal.Body>
<Modal.Footer className='justify-content-between transscribe-audio-modal-footer'>
    <Button variant="secondary" className='summarize_btn' startIcon={<InsertDriveFileIcon />} size='small' onClick={() => {
        transcribeSummarizeHandler()
    }}
    disabled={audioTranscribe?.trim()?.length == 0}
    >
        Summarize
    </Button>

    {
        editAudioTranscribeAndSummarize ?
            <>
                <div className='d-flex'>
                    <Button className="deleteIcon" onClick={() => {
                        setEditAudioTranscribeAndSummarize(false)
                        setAudioTranscribe(transcribedAudio?.data?.note_description)
                        
                    }} style={{ backgroundColor: '#fff', color: '#EB4041', textTransform: 'uppercase',  borderRadius: '8px', minWidth: 40 }}>
                        Cancel
                    </Button>
                    <div style={{ width: 20 }}>

                    </div>
                    <Button variant="success" onClick={() => {
                       findAndChangeTagContent('Audio Transcription')
                       setEditAudioTranscribeAndSummarize(false)
                    }} style={{ backgroundColor: '#fff', color : '#4AD245', textTransform: 'uppercase', borderRadius: '8px', minWidth: 40 }}>
                       Save
                    </Button>
                </div>
            </>
            :
            <>
                <Button variant="primary" className='summarize_btn' startIcon={<EditIcon />} size='small' onClick={() => {
                    setEditAudioTranscribeAndSummarize(true)
                }}>
                    Edit
                </Button>
            </>
    }
</Modal.Footer>
</Modal>


 {/* ----------------summarize modal -----------------------*/}
 <Modal centered className="delete-modal-warning generateAudioModal summarizeModal br-8" show={showSummarizeModal} onHide={handleGenerateAudioAiModalClose}>
                    <Modal.Header className="rubricsModalHeader generateAudioModalHeader pb-0 align-items-center" closeButton>
                        <div className='w-100 d-flex insertDriveButton'>
                            <IconButton aria-label="notes">
                                <InsertDriveFileIcon />
                            </IconButton>
                            <div className='d-flex flex-column align-items-center'>
                                <p className='mb-0'> {selectAudioObj?.cc_attachment_name?.length > 0 ? selectAudioObj?.cc_attachment_name : `${moment().format('LL')} ${moment().format('LT')}`}.mp3</p>
                                <span className='time'>{selectAudioTotalDuration} {selectAudioTotalDuration?.split(':')[0] == 0 ? 'Sec' : 'Min'}</span>
                            </div>
                            <div className='d-flex flex-column w-100'>
                           <img src={audioWave} alt='audioWave' className='img-fluid' />
                        </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="pb-0">

                        <div className='d-flex justify-content-between'>
                            <div className='summarize_para' id="div1" onMouseUp={logH5Content} onDoubleClick={selectAllTexttransc}>
                                <h5>Audio Transcription</h5>
                                <div>
                                    {showPopover && (
                                         <div  style={{
                                            position: "fixed", // Ensure the modal covers the entire viewport
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            padding: "10px",
                                            background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background to dim the underlying content
                                            zIndex: 999, // High z-index to appear above everything else
                                            display: 'flex',
                                            justifyContent: 'center', // Center content horizontally
                                            alignItems: 'center', // Center content vertically
                                        }}>  
                                        <div
                                            style={{
                                                // position: "absolute",
                                                // top: popoverPosition.top,
                                                // // left: popoverPosition.left,
                                                // left: 0,
                                                // padding: "10px",
                                                // background: "#fff",
                                                // boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                                // zIndex: 999,
                                                // display: 'flex',
                                                // // justifyContent: 'space-between',
                                                // width: 375,
                                                // flexWrap : 'wrap'
                                                background: "#fff",
                                                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                                padding: "20px 10px", 
                                                borderRadius : "8px",
                                                width: "499px", // Keep the width as you specified
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                             <button onClick={() => {handleHighlight('Positive Comments')}} className='mr-2 ml-2 mt-2 positive_comment_btn'>Positive Comments</button>
                                             <button onClick={() => {handleHighlight('Teaching Point')}} className='mr-2 ml-2 mt-2 teaching_point_btn'>Teaching Point</button>
                                             <button onClick={() => {handleHighlight('Instructional Need')}} className='mr-2 ml-2 mt-2 instructional_need_btn'>Instructional Need</button>
                                             <button onClick={() => {handleHighlight('Comments 1')}} className='mr-2 ml-2 mt-2 comment_one_btn'>Comments 1</button>
                                             <button onClick={() => {handleHighlight('Comments 2')}} className='mr-2 ml-2 mt-2 comment_one_btn'>Comments 2</button>

                                             <span onClick={() => setShowTranscribePopover(false)} style={{position: "relative",
                                            left: "50%",
                                            top: "-53px",
                                            fontSize: "x-large",
                                            color: "rgba(112, 112, 112, 0.4901960784)",
                                            fontWeight: "700",
                                             color: "rgba(112, 112, 112, 0.4901960784)",
                                            fontWeight: "700"
                                        }} 
                                            role='button'
                                            className='closeIcon' aria-hidden="true">×</span>
                                        </div>
                                        </div>
                                    )}
                                    <div>
                                        {/* <p>
                                            All engineers are good writers… of code. But I believe that in
                                            order to become a better engineer, you should improve your writing skills.
                                        </p>
                                        <p>
                                            From the dawn of times, people were writing. We have written using symbols,
                                            like in Ancient Egypt. And we have written using letters, like in Renaissance times.
                                            And all of us got at least one writing assignment in school, without the “Why?”
                                            And yet, today writing is so underrated that most people want to avoid it.
                                            But the truth is–you will have to write. Comments, documentation, design documents,
                                            presentations. Whether you like it or not. So why not become better at it?
                                        </p> */}
                                        <p id='newdiv' style={{font  :'normal normal normal 14px/20px Open Sans'}}>{sessionStorage.getItem('finalTranscribedAudio') || audioTranscribe || ''}</p>
                                    </div>
                                </div>
                                {/* <p style={{ position: 'relative' }}>
                                    {showPopover && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: popoverPosition.top,
                                                left: popoverPosition.left,
                                                padding: "10px",
                                                background: "#fff",
                                                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                                zIndex: 999,
                                            }}
                                        >
                                            <button onClick={this.handleHighlight}>Highlight</button>
                                        </div>
                                    )}
                                    {audioTranscribe || ''}
                                </p> */}
                            </div>

                            <div className='summarize_para' id="div2" onMouseUp={logH5Content} onDoubleClick={selectAllText}>
                                <h5 style={{ zIndex: 2 }}>Audio Summarization</h5>
                               
                                    {
                                        editAudioTranscribeAndSummarize ? 
                                        <>
                                         <Form.Control as="textarea" rows={6} onChange={(e) => {
                                            setAudioSummarization(e.target.value)
                                    }}
                                    className='audioNoteTextAreaEdit'
                                    >
                                        {audioSummarization || ''}
                                        {/* Objectives of lesson: Explain the process of photosynthesis, List the three components necessary for photosynthesis to take place. List the products of photosynthesis, Compare the leaves of a plant with all the components. Larger Goals: Photosynthesis, Carbon Dioxide, Oxygen, Glucose Specific Objectives: Explain the process of photosynthesis, List the three components necessary for photosynthesis to take place. List the products of photosynthesis, Compare the leaves of a plant that has all the components needed for photosynthesis to one that has a component missing. */}
                                    </Form.Control>
                                        </>
                                        :
                                        <>
                                         <div style={{ position: 'relative' }}>
                                    {showTranscribePopover && (
                                        <div onClick={(e) => e.stopPropagation()}   style={{
                                            position: "fixed", // Ensure the modal covers the entire viewport
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            padding: "10px",
                                            background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background to dim the underlying content
                                            zIndex: 999, // High z-index to appear above everything else
                                            display: 'flex',
                                            justifyContent: 'center', // Center content horizontally
                                            alignItems: 'center', // Center content vertically
                                        }}>  
                                        <div
                                            style={{
                                                // position: "absolute",
                                                // top: popoverTranscribePosition.top,
                                                // // left: popoverTranscribePosition.left,
                                                // left: '-8px',
                                                // padding: "10px",
                                                // background: "#fff",
                                                // boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                                // zIndex: 999,
                                                // display: 'flex',
                                                // // justifyContent: 'space-between',
                                                // width: 375,
                                                // flexWrap : 'wrap'

                                                background: "#fff",
                                                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                                padding: "20px 10px", 
                                                borderRadius : "8px",
                                                width: "499px", // Keep the width as you specified
                                                flexWrap: 'wrap',
                                            }}
                                            
                                        >
                                        
                                            <button onClick={() => {handleTranscribeHighlight('Positive Comments')}} className='mr-2 ml-2 positive_comment_btn'>Positive Comments</button>
                                            <button onClick={() => {handleTranscribeHighlight('Teaching Point')}} className='mr-2 ml-2 teaching_point_btn'>Teaching Point</button>
                                            <button onClick={() => {handleTranscribeHighlight('Instructional Need')}} className='mr-2 ml-2 mt-2 instructional_need_btn'>Instructional Need</button>
                                            <button onClick={() => {handleTranscribeHighlight('Comments 1')}} className='mr-2 ml-2 mt-2 comment_one_btn'>Comments 1</button>
                                            <button onClick={() => {handleTranscribeHighlight('Comments 2')}} className='mr-2 ml-2 mt-2 comment_one_btn'>Comments 2</button>
                                        <span onClick={() => setShowTranscribePopover(false)} style={{position: "relative",
                                            left: "50%",
                                            top: "-53px",
                                            fontSize: "x-large",
                                            color: "rgba(112, 112, 112, 0.4901960784)",
                                            fontWeight: "700"
                                        }} 
                                            role='button'
                                            className='closeIcon' aria-hidden="true">×</span>
                                            
                                        </div>
                                        </div>
                                    )}
                                    <pre style={{font  :'normal normal normal 14px/20px Open Sans' ,     whiteSpace: "break-spaces"}}>
                                    
                                    {audioSummarization || ''}
                                    </pre>
                                         {/* Objectives of lesson: Explain the process of photosynthesis, List the three components necessary for photosynthesis to take place. List the products of photosynthesis, Compare the leaves of a plant with all the components. Larger Goals: Photosynthesis, Carbon Dioxide, Oxygen, Glucose Specific Objectives: Explain the process of photosynthesis, List the three components necessary for photosynthesis to take place. List the products of photosynthesis, Compare the leaves of a plant that has all the components needed for photosynthesis to one that has a component missing. */}
                                         </div>
                                        </>
                                    }
                            </div>
                        </div>
                        <p className='hint_para mt-2'>Hint: Select data to tag as type of comment i.e. positive comment</p>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-between transscribe-audio-modal-footer'>
                        {
                            editAudioTranscribeAndSummarize ?
                                <>
                                    <div className='d-flex'>
                                        <Button className="deleteIcon" onClick={() => {
                                            setEditAudioTranscribeAndSummarize(false)
                                            setAudioTranscribe(transcribedAudio?.data?.note_description)
                                            setAudioSummarization(audioSummarization?.length > 0 ? audioSummarization : combinedSummaryText)
                                            // this.setState({
                                            //     editAudioTranscribeAndSummarize: false,
                                            //     audioTranscribe: this.props?.getTranscribeAudioSuccess?.data?.note_description,
                                            //     audioSummarization: audioSummarization?.length > 0 ? audioSummarization : combinedSummaryText
                                            // })
                                        }} style={{ backgroundColor: '#fff', color: '#EB4041', textTransform: 'uppercase',  borderRadius: '8px', minWidth: 40 }}>
                                            Cancel
                                        </Button>
                                        <div style={{ width: 20 }}>

                                        </div>
                                        <Button variant="success" onClick={() => {
                                            setEditAudioTranscribeAndSummarize(false)
                                            findAndChangeTagContent('Audio Summarization')
                                        }} style={{ backgroundColor: '#fff', color : '#4AD245', textTransform: 'uppercase', borderRadius: '8px', minWidth: 40, opacity: audioSummarization?.trim()?.length == 0 ? '0.5' : '1' }}
                                        disabled={audioSummarization?.trim()?.length == 0}
                                        >
                                           Save
                                        </Button>
                                    </div>
                                </>
                                :
                                <>
                                    <Button variant="primary" className='summarize_btn' startIcon={<EditIcon />} size='small' onClick={() => {
                                        setEditAudioTranscribeAndSummarize(true)
                                    }}>
                                        Edit
                                    </Button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>

            {/* --------------Confirmation Modal------------ */}
            {/* <Modal centered className="delete-modal-warning br-8" show={confirmationForBack} >
                <Modal.Header>
                    <Modal.Title>Info </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>*Are you sure you want to {confirmationType === 'back' ? " go back " : " change note "} without saving? </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="cancel-btn" onClick={confirmationToBack}>
                        Yes
                    </Button>
                    <Button variant="default" className="delete-btn" onClick={() => {
                        setConfirmationForBack(false);
                    }} >
                        No
                    </Button>
                </Modal.Footer>
            </Modal> */}

            <Modal centered className="delete-modal-warning br-8" show={goBackModal} >
                <Modal.Header>
                    <Modal.Title>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <img src={warningIcon} alt="" style={{ width: "60px", height: "60px" }} />
                            <span style={{ fontSize: '24px', color: '#ff1f1f', marginTop: '10px' }}>Wait!</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to {confirmationType === 'back,' ? " go back, " : " change note, "}your data will be lost. </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="cancel-btn-div cancel-btn-div-note" onClick={() => { setGoBackModal(false); }}
                        style={{ marginRight: 18 }}
                    >
                        Cancel
                    </Button>
                    <Button variant="default" className="goback-btn-div goback-btn-div-note" onClick={() => {
                        // confirmationToBack(); 
                        // setConfirmationType('back') 
                        history.goBack()

                    }}>
                        Go back
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered className="doc-modal-warning br-8" show={showDocPopUp} >
                <Modal.Header>
                    <span className="mediaArti">Mark As Artifact
                        {/* <i className={this.state.selectedImage.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star-o starUnSelected"} 
                                //  key={i} 
                                 aria-hidden="true" 
                                 onClick={() => { this.setMediaArtiAddEdit(this.state.selectedImage, "image") }}
                                >
                                    </i> : ""
                                */}

                        {docList && docList.length > 0 && docList.map((val, i) => (
                            (val.cc_attachment_url === modalDocSelect.cc_attachment_url) ? <i className={val.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star starUnSelected"} key={i} aria-hidden="true" onClick={() => { setMediaArtiAddEdit(modalDocSelect, "doc") }}></i> : ""
                        ))}
                    </span>
                    <Button variant="default" className="delete-btn" onClick={handleDocModalClose} style={{ minWidth: 'auto' }}>
                        <img src={closeIcon} alt='' />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <IframeGoogleDoc url={selectedDoc} />
                </Modal.Body>
            </Modal>
            {/* --------------Confirmation Modal------------ */}
            {showPopUp && <UpgradePopUp handleClosePopUp={handleClosePopUp} />}
        </div >
    )
}

export default ClassNoteAdd;