import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import ClassAttendanceDashboard from './ClassAttendanceDashboard';
import AddEditFirstClassAttendance from './AddEditFIrstClassAttendance';
import {
    getClassAttList,
    addClassAtt,
    getClassAttByDateRange,
    sendEmailReport

} from 'Actions/ClassAction/ClassAttendanceAction';

const ClassAttendanceIndex = (props) => {
    const { getClassAtt, getClassAttList, history } = props;
    const { classDetail } = history.location.state;
    const [attendanceStatusEdited, setAttendanceStatusEdited] = useState(false);
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    const [earlierDate, setEarlierDate] = useState(new Date());
    const [isSelected, setIsSelected] = useState(true);
    const [changeClassName, setChangeClassName] = useState(null)
    const className = classDetail?.cc_class_name
    const isClasses = history.location.state?.isClasses
    useEffect(() => {
        if (classDetail) {
            const data = {
                date: getDateFormatAsApi(new Date()),
                class_id: classDetail.cc_class_id ? classDetail.cc_class_id : classDetail
            }
            getClassAttList(data)
        }
    }, [])

    useEffect(() => {
        checkIsEdited(getClassAtt)
    })

    const getDateFormatAsApi = date => {
        const offsetDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        const formattedDate = offsetDate.toISOString().split('T')[0];
        return formattedDate;
    };

    const changeSelected = (value) => {
        setIsSelected(value)
    }

    const classNameChangeHandler = (value) => {
        setChangeClassName(value)
    }

    const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

    const checkIsEdited = (getClassAtt) => {
        const attendanceData = []
        let attStatus = []
        getClassAtt &&
            getClassAtt.map(student => {
                let studentValue = { ...student }
                let studentList = {}
                if (studentValue.cc_student_id) {
                    studentList = {
                        ...studentList,
                        attendance_status: studentValue.cc_attendance_status,
                    }
                    attendanceData.push(studentList)
                }
                return attendanceData
            })

        attendanceData &&
            attendanceData.map(studentAttData => {
                attStatus.push(studentAttData.attendance_status)
                return attStatus
            })
        const valAttStatus = countOccurrences(attStatus, null);

        if (getClassAtt && valAttStatus === getClassAtt.length) {
            setAttendanceStatusEdited(false);
            setIsFirstOpen(false);
        } else {
            setAttendanceStatusEdited(true);
            setIsFirstOpen(true);
        }
    }

    return (
        <div>{console.log('attendanceStatusEdited :>> ', attendanceStatusEdited)}
            {attendanceStatusEdited && isFirstOpen ?
                <ClassAttendanceDashboard
                    {...props}
                    className={className}
                    isClasses={isClasses}
                    setEarlierDate={setEarlierDate}
                    changeClassName={changeClassName}
                    showData = {attendanceStatusEdited}
                    
                    /> :
                <AddEditFirstClassAttendance
                    {...props}
                    setIsFirstOpen={setIsFirstOpen}
                    earlierDate={earlierDate}
                    setEarlierDate={setEarlierDate}
                    className={className}
                    isClasses={isClasses}
                    isSelected={isSelected}
                    changeSelected={changeSelected}
                    classNameChangeHandler={classNameChangeHandler}
                    changeClassName={changeClassName}
                    showData = {attendanceStatusEdited}
                />
            }
        </div>
    )
}

const mapStateToProps = state => ({
    state: state,
    getClassAtt: state.classAttendance.Get_Att_List,
    addClassAttList: state.classAttendance.Add_Att_List,
    classAttByDateRangeList: state.classAttendance.Get_Att_List_By_Date,
    // classAttByDateRangeList: state.classAttendance.Send_Email
});
const mapDispatchToProps = dispatch => {
    return {
        getClassAttList: (data) => dispatch(getClassAttList(data)),
        addClassAtt: (data) => dispatch(addClassAtt(data)),
        getClassAttByDateRange: (data) => dispatch(getClassAttByDateRange(data)),
        sendEmailReport: (data) => dispatch(sendEmailReport(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ClassAttendanceIndex
);
