import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
const StudentGradeBookFooter = (props) => {
    const { eventAverage , filterSelect } = props;
    
    
    return (
        <Col xs="12">
            <Card className="mt-15">
                <Card.Body>
                    <Row>
                        <Col xs="8" className="text-left left-p-sec">
                            <p>— Select above Grading Event to calculate average score.</p>
                        </Col>
                        <Col xs="4" className="text-right">
                            <div className="avg-sec">
                                <span className="avg">{(eventAverage === 0 || eventAverage === '' || eventAverage === 'null' || eventAverage === null ) ? '00.00' : eventAverage.toFixed(2)}</span>
                                <span className="avg-title">Average Score</span>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default StudentGradeBookFooter 