import React, { useState, useEffect } from 'react';
import cancelBlack from 'Assets/RosterModule/cancelBlack.png'
import OutsideClickHandler from 'react-outside-click-handler';
import DivLoader from 'Components/LoadingComponent/DivLoader';
import NotificationList from '../NotificationListModule/NotificationList'
import './NotificationDashboard.scss';
import { useDispatch } from 'react-redux';
import { getRosterClassByAcademicYear } from 'Actions/AcademicAction/academicAction';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const NotificationDashboard = (props) => {

    const { showModal,
        onOutside,
        getNotificationRequest,
        getNotification,
        rejectSharedClassRequest,
        rejectSharedStudentRequest,
        deleteNotificationRequest,
        deleteNotification,
        notificationMarkAsReadRequest,
        getAcademicList,
        getAcademicScore,
        getAcademicYears,
        getUserSettingRequest,
        state,
        rejectSharedClass,
        rejectSharedStudent } = props;
        const dispatch = useDispatch()
        const location = useLocation()
    const [pageNo] = useState(1);
    const [notificationDetails, setNotificationDetails] = useState(getNotification?.data?.data)
    useEffect(() => {
        if (getNotification) {
            setNotificationDetails(getNotification?.data?.data)
        }
    }, [getNotification])

    useEffect(() => {
        let apiData = {
            "page_no": pageNo,
        }
        if (deleteNotification && deleteNotification.status == 200)
            getNotificationRequest(apiData)
    }, [deleteNotification])

    useEffect(() => {
        let apiData = {
            "page_no": pageNo,
        }
        if (rejectSharedClass) {
            if (rejectSharedClass.status == 200) {
                getNotificationRequest(apiData);
                if(!sessionStorage.getItem('selectedAcademicYear')) {
                    window.location.reload()
                } else {
                getAcademicList();
                // getAcademicScore();
                getAcademicYears();
                // getUserSettingRequest();
                let data = {
                    "academic_year_id" : sessionStorage.getItem('selectedAcademicYear') != 'undefined' && sessionStorage.getItem('selectedAcademicYear'),
                    "roster_limit": "",
            "class_limit": "",
            "blank_class": "",
                }
                dispatch(getRosterClassByAcademicYear(data))
            }
            }
        }
    }, [rejectSharedClass])

    useEffect(() => {
        let apiData = {
            "page_no": pageNo,
        }
        if (rejectSharedStudent) {
            if (rejectSharedStudent.status == 200) {
                getNotificationRequest(apiData);
                if(!sessionStorage.getItem('selectedAcademicYear')) {
                    window.location.reload()
                } else {
                getAcademicList();
                // getAcademicScore();
                getAcademicYears();
                // getUserSettingRequest();
                let data = {
                    "academic_year_id" : sessionStorage.getItem('selectedAcademicYear') != 'undefined' && sessionStorage.getItem('selectedAcademicYear'),
                    "roster_limit": "",
            "class_limit": "",
            "blank_class": "",
                }
                dispatch(getRosterClassByAcademicYear(data))
            }
            }
        }
    }, [rejectSharedStudent])

    const markAsRead = () => {
        if(location.pathname !== "/home/ClassAttendance") {
            notificationMarkAsReadRequest()
        }
    }

    const onAccept = (value) => {
        let notificationVal = notificationDetails && notificationDetails.map(val => {
            let notificationValue = { ...val };
            if (val.cc_noti_id === value.cc_noti_id && value.cc_noti_status === 0) {
                return { ...val, cc_noti_status: 1 }
            } else {
                return { ...val }
            }
            notificationValue = { ...notificationValue }
            return notificationValue
        })
        setNotificationDetails(notificationVal)

        let apiData = {
            "status": 1,
            "notification_id": value.cc_noti_id
        }
        if (value.cc_class_id === 0) {
            rejectSharedStudentRequest(apiData)
        } else {
            rejectSharedClassRequest(apiData)
        }
    }

    const onCancel = (value) => {
        let notificationVal = notificationDetails && notificationDetails.map(val => {
            let notificationValue = { ...val };
            if (val.cc_noti_id === value.cc_noti_id && value.cc_noti_status === 1) {
                return { ...val, cc_noti_status: 0 }
            } else {
                return { ...val }
            }
            notificationValue = { ...notificationValue }
            return notificationValue
        })
        setNotificationDetails(notificationVal)

        let apiData = {
            "status": 0,
            "notification_id": value.cc_noti_id
        }
        if (value.cc_class_id === 0) {
            rejectSharedStudentRequest(apiData)
        } else {
            rejectSharedClassRequest(apiData)
        }
    }


    const onDelete = (value) => {
        // let filteredArray = notificationDetails.filter(values => values.cc_noti_id !== value.cc_noti_id);
        // setNotificationDetails(filteredArray)

        if (value.cc_permission_type === "no_action") {
            let apiData = { action: "parent", notification_id: value.cc_noti_id }
            deleteNotificationRequest(apiData);
        } else {
            let apiData = { action: "null", notification_id: value.cc_noti_id }
            deleteNotificationRequest(apiData);
        }
    }

    return (
        <div className="modal-dialog notificationDashboard" role="document" 
        style={{ 
            // marginRight: 50, 
        top: 68
         }}>
            {showModal && <OutsideClickHandler
                onOutsideClick={() => { onOutside() }}>
                <div className="modal-content" style={{width: 422, height: '88vh'}}>
                    <div className="modal-header">
                        <h4 className="modal-title" id="notificationModal">Notifications</h4>
                        <button type="button" className="closeHeader" data-dismiss="modal" aria-label="Close" onClick={markAsRead}>
                            <img src={cancelBlack} alt='' className='closeIconInClassModal' /> </button>
                    </div>
                    <div className="modal-body notificationModalBody">
                        {state?.Notification?.loader ? <div> <DivLoader /> </div> : ""}
                        {notificationDetails && notificationDetails?.length === 0 ? <h4 className="noNotificationText" id="notificationModal">No Notifications</h4> : ""}
                        {notificationDetails && notificationDetails?.map((item, index) => (
                            <NotificationList key={index} item={item} onAccept={onAccept} onCancel={onCancel} onDelete={onDelete} />
                        ))}
                    </div>
                </div>
            </OutsideClickHandler>}
        </div>

    );

}
export default NotificationDashboard;